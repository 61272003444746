import { Injectable } from '@angular/core';
import { ApiService, instantiateApiResponse } from '@prism-frontend/services/legacy/api.service';
import { ConfirmedOrMetadate } from '@prism-frontend/typedefs/confirmed-or-metadate';
import { HoldForCalendar } from '@prism-frontend/typedefs/hold-for-calendar';
import { InstantiatedAPIResponseAndValidationResults } from '@prism-frontend/utils/decorators/validate-children';
import { convertGETParamsToLaravelConventions } from '@prism-frontend/utils/static/convert-to-laravel-params';

export interface BaseCalendarFilterAPIParameters {
	/**
	 * required start/end in YYYY-MM-DD format
	 */
	start: string;
	end: string;

	/**
	 * Optionally exclude a date range
	 */
	excludeDuration?: {
		excludeFrom: string;
		excludeUntil: string;
	};

	/**
	 * venue filter, avaialable to all users
	 */
	stageIds: number[];

	/**
	 * artist filter, only available to agents (for now)
	 */
	talentAgentIds: number[];
}

export interface HoldAPIParameters extends BaseCalendarFilterAPIParameters {
	includeClearedHolds: boolean;
	includePendingHolds: boolean;
}

function formatCalendarApiParams(
	params: BaseCalendarFilterAPIParameters | HoldAPIParameters
): BaseCalendarFilterAPIParameters {
	const returnParams: BaseCalendarFilterAPIParameters | HoldAPIParameters = {
		...params,
	};
	// validate parameters
	const datePattern: RegExp = /\d{4}-\d{2}-\d{2}/;
	if (!datePattern.test(returnParams.start) || !datePattern.test(returnParams.end)) {
		// eslint-disable-next-line no-console
		console.warn(returnParams);
		throw new Error('Either "start" or "end" is invalid');
	}

	let excludeParams: { excludeFrom?: string; excludeUntil?: string } = {};
	if (returnParams.excludeDuration) {
		excludeParams = {
			excludeFrom: returnParams.excludeDuration.excludeFrom,
			excludeUntil: returnParams.excludeDuration.excludeUntil,
		};
	}

	delete returnParams.excludeDuration;

	return {
		...returnParams,
		...excludeParams,
	};
}

@Injectable({ providedIn: 'root' })
export class CalendarAPIService {
	public constructor(private apiService: ApiService) {}

	/**
	 * load holds for a given set of query parameters
	 *
	 * @param params calendar params
	 * @returns
	 */
	public async loadHoldDateRange(params: HoldAPIParameters): Promise<HoldForCalendar[]> {
		const results: HoldForCalendar[] = await this.apiService.getP(this.apiService.ep.HOLDS, {
			params: convertGETParamsToLaravelConventions(formatCalendarApiParams(params)),
		});
		const response: InstantiatedAPIResponseAndValidationResults<HoldForCalendar[]> = await instantiateApiResponse<
			HoldForCalendar[]
		>(HoldForCalendar, results);
		return response.data;
	}

	public async loadConfirmedAndMetaDate(params: BaseCalendarFilterAPIParameters): Promise<ConfirmedOrMetadate[]> {
		const results: ConfirmedOrMetadate[] = await this.apiService.getP(this.apiService.ep.CONFIRMED_AND_META_DATES, {
			params: convertGETParamsToLaravelConventions(formatCalendarApiParams(params)),
		});

		const response: InstantiatedAPIResponseAndValidationResults<ConfirmedOrMetadate[]> =
			await instantiateApiResponse<ConfirmedOrMetadate[]>(ConfirmedOrMetadate, results);
		return response.data;
	}
}
