import { PrismDate } from '@prism-frontend/typedefs/date';
import { PrismEvent } from '@prism-frontend/typedefs/event';
import { Permission } from '@prism-frontend/typedefs/permission';
import { Stage } from '@prism-frontend/typedefs/stage';
import { TalentData } from '@prism-frontend/typedefs/talentData';
import { getReadableForegroundTextFor } from '@prism-frontend/utils/static/static-color-helpers';
import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { plainToClass, Transform, Type } from 'class-transformer';
import { IsBoolean, IsInstance, IsInt, IsOptional } from 'class-validator';
import moment from 'moment';

export type TalentDataForHold = Pick<TalentData, 'id' | 'talent_agent_id' | 'talent' | 'headliner'> & {
	event_id: number;
};

export interface ArtistIdFilterable {
	hasArtistId: (artistId: number) => boolean;
}

export interface HoldForRender {
	hold_level: number | null;
	is_pending: boolean;
}

/**
 * given a value with `hold_level` and `is_pending`, returns the proper label for the hold value
 *
 * @param holdFor the hold object to render
 * @returns the proper label for the hold value
 */
export function renderHoldLevel(holdFor: HoldForRender): string {
	if (holdFor.is_pending) {
		return 'P';
	}
	return `H${holdFor.hold_level}`;
}

class HoldBackendModel implements HoldForRender {
	@IsInt() public id: number;

	@IsInt() public event_id: number;

	@IsInt() public hold_level: number | null = 0;

	/**
	 * given a hold level, sets the hold level and updates the is_pending flag
	 *
	 * @param value the desired hold level
	 */
	public setHoldLevel(value: number | null): void {
		this.hold_level = value;

		if (this.hold_level === null) {
			this.is_pending = true;
		} else {
			this.is_pending = false;
		}
	}

	@IsBoolean() public is_pending: boolean = false;

	@IsBoolean()
	@Transform(castToBoolean())
	public cleared: boolean = false;

	@IsOptional()
	@IsInt()
	public cleared_by_user_id: number | null = null;

	@IsOptional()
	@IsInt()
	public cleared_by_event_id: number | null = null;

	@IsBoolean()
	@Transform(castToBoolean())
	public is_challenge_date: boolean = false;

	// This denotes whether this hold is a 'placeholder', event name is not visible,
	// but it will show whether there is a confirmed event or a hold and what level
	@IsBoolean()
	@Transform(castToBoolean())
	public isPlaceholder: boolean = false;

	// PRSM-3057 this should be optional
	@Type((): typeof PrismEvent => {
		return PrismEvent;
	})
	@IsInstance(PrismEvent)
	public event: PrismEvent = new PrismEvent();

	// This should be named stages and contain all stages on the event associated with the hold
	@Type((): typeof Stage => {
		return Stage;
	})
	@IsInstance(Stage, { each: true })
	public stage: Stage[] = [];

	@Type((): typeof PrismDate => {
		return PrismDate;
	})
	@IsInstance(PrismDate)
	public date: PrismDate;

	public permissions?: Permission[];
}

export interface ConfirmHoldPayload {
	final_hold_ids: number[];
	should_send_confirmation_email: boolean;
	clear_all_holds: boolean;
}

export interface ClearOrRestoreAllHoldsResponse {
	holdsCleared?: number;
	holdsRestored?: number;
}

export class HoldForCalendar extends HoldBackendModel implements ArtistIdFilterable {
	public constructor(holdForCalendar: Partial<HoldForCalendar>) {
		super();
		return plainToClass(HoldForCalendar, holdForCalendar);
	}

	@IsBoolean()
	public was_accepted: boolean = false;

	// User has 'manage-holds' permission and is allowed to drag a hold in the calendar
	@IsBoolean()
	public startEditable: boolean = false;

	// used by the front-end when creating shared holds requests
	@IsBoolean()
	public isRequest: boolean = false;

	@IsBoolean()
	public isSharedAndNew: boolean = false;

	@IsBoolean()
	public is_target_date: boolean = false;

	// for backwards compatibility:
	// returns "2020-06-03" format
	public get hold_date(): string {
		return this.date.date.split(' ')[0];
	}

	// for backwards compatibility:
	public get all_day(): boolean {
		return this.date.all_day;
	}

	// for backwards compatibility:
	public set all_day(flag: boolean) {
		this.date.all_day = flag;
	}

	// for backwards compatibility:
	// returns "2020-07-19 00:00:00" format
	public get start_time(): string {
		return this.date.start_time;
	}

	// for backwards compatibility:
	// returns "2020-07-19 00:00:00" format
	public get end_time(): string {
		return this.date.end_time;
	}

	// for backwards compatibility:
	public get stage_id(): number {
		return this.stage[0].id;
	}

	public get color(): string {
		return this.stage[0].color;
	}

	public get readableColor(): string {
		return getReadableForegroundTextFor(this.stage[0].color);
	}

	public get title(): string {
		// Placeholders will not have an event
		if (this.isPlaceholder) {
			return 'Hold';
		}
		return this.event.title;
	}

	public get start(): moment.Moment {
		return moment(this.hold_date);
	}

	/**
	 * returns true if a hold has a talent deal matching the artist ID, false otherwise
	 */
	public hasArtistId(artistId: number): boolean {
		return (
			this.event.talent_data.filter((talent: TalentData): boolean => {
				return talent.talent_agent_id === artistId;
			}).length > 0
		);
	}
}
