import { AllCostCalc2 } from '@prism-frontend/typedefs/AllCostCalc2';
import { CostCalcDependentValue } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { CostCalc } from '@prism-frontend/typedefs/enums/calc';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';
import { fetchLegacyEMSParamsFromCostCalc2 } from '@prism-frontend/utils/static/fetchLegacyEMSParamsFromCostCalc2';
import _ from 'lodash';

export const COST_CALC_DEPENDENT_COST_CALC: CostCalcDependentValue<CostCalc2> = _.chain(AllCostCalc2)
	.map((costCalc2: CostCalc2): [CostCalc2, CostCalc2] => {
		return [costCalc2, costCalc2];
	})
	.fromPairs()
	.value() as unknown as CostCalcDependentValue<CostCalc2>;

/**
 * Builds a `CostCalcDependentValue<T>` by computing values for each `CostCalc2` key using both legacy and new parameters.
 *
 * This function iterates over all `CostCalc2` values and computes a value of type `T` for each key by invoking
 * the provided `compute` function with the corresponding legacy parameters (`costCalc`, `external`) and the new parameter (`costCalc2`).
 *
 * @template T - The type of the computed values.
 * @param compute - A function that computes a value of type `T` given `costCalc`, `external`, and `costCalc2`.
 * @returns A `CostCalcDependentValue<T>` mapping each `CostCalc2` to its computed value.
 */
export function buildCostCalcDependentValue<T>(
	compute: (costCalc: CostCalc, external: boolean, costCalc2: CostCalc2) => T
): CostCalcDependentValue<T> {
	// Initialize the result object
	const value: CostCalcDependentValue<T> = {} as unknown as CostCalcDependentValue<T>;

	// Iterate over all CostCalc2 values
	for (const costCalc2 of AllCostCalc2) {
		// Fetch the legacy parameters corresponding to the current CostCalc2
		const [costCalc, external]: [CostCalc, boolean] = fetchLegacyEMSParamsFromCostCalc2(costCalc2);

		// Compute the value using both legacy and new parameters
		value[costCalc2] = compute(costCalc, external, costCalc2);
	}

	// Return the resulting object
	return value;
}

/**
 * Extract a value of type `T` from a `CostCalcDependentValue<T>` using the
 * current `CostCalc2`. This function throws an error if the input object is not a valid.
 *
 * When reading values from a `CostCalcDependentValue<T>` _within_ your
 * fetchPrismEventRollup code, you should *ALWAYS* use this helper.
 * This guarantees type safety in a way that referencing something like
 * cost.isReported[costCalc2] does not (note that isReported is a boolean,
 * not a `CostCalcDependentValue<boolean>`).
 */
export function readCostCalcDependentValue<T>(from: CostCalcDependentValue<T>, costCalc: CostCalc2): T {
	if (!_.isObject(from)) {
		throw new Error(`Invalid cost calc dependent value ${from}`);
	}
	return from[costCalc];
}
