import { EMSFieldType } from '@prism-frontend//typedefs/enums/EMSFieldType';
import { RENDER_AS_EMPTY } from '@prism-frontend/components/ems-field/ems-field.typedefs';
import { AllEMSFields } from '@prism-frontend/typedefs/ems/AllEMSFields';
import {
	EMSFieldMetadataValueByCostCalc,
	resolveLabel,
} from '@prism-frontend/typedefs/ems/ems-field-explainer-helpers';
import { standardizeFieldNameWithStars } from '@prism-frontend/typedefs/ems/ems-static-helpers/ems-static-helpers';
import { CostCalc2 } from '@prism-frontend/typedefs/enums/CostCalc2';
import { Permission } from '@prism-frontend/typedefs/permission';
import * as _ from 'lodash';

export const EMPTY_EMS_FIELD_META: EMSFieldMeta = {
	emsMetadataId: '' as keyof EMSFieldDefs,
	emsPublicIdentifier: '' as keyof EMSFieldDefs,
	internalLabel: '',
	internalDescription: '',
	isRaw: true,
	isSMSOnly: false,
	type: EMSFieldType.string,
	calculation: '',
	costCalcDependent: false,
};

/**
 * return the ems meta ID from the EMSFieldMeta object below
 *
 * @param emsMetadataId the emsMetadataId to get the meta for
 * @returns EMSFieldMeta from the object below, or thows an error if its a key that does not exist in emsfieldmeta
 */
export function getFieldMeta(emsMetadataId: keyof EMSFieldDefs, supressNotFoundError: boolean = false): EMSFieldMeta {
	const parsedFieldName: keyof EMSFieldDefs = standardizeFieldNameWithStars(emsMetadataId);

	if (parsedFieldName === RENDER_AS_EMPTY) {
		return EMPTY_EMS_FIELD_META;
	}

	if (!EMSFieldsMeta[parsedFieldName]) {
		if (supressNotFoundError) {
			return EMPTY_EMS_FIELD_META;
		}
		throw new Error(`Field Name ${parsedFieldName} not found in EMSFieldsByName`);
	}
	return EMSFieldsMeta[parsedFieldName];
}

export interface EMSFieldMeta {
	/**
	 * The metadataId for this field on EMS. This same value can be found as a
	 * property on any ems object.
	 */
	emsMetadataId: keyof EMSFieldDefs;
	/**
	 * We sometimes store a user's preference about what fields to surface inside
	 * of strings in our database. The Email Templates feature currently does this,
	 * and in the future when custom fields have the ability to do math or
	 * store templated text, they will reference this property as well.
	 *
	 * By storing this property in user templates instead of emsMetadataId, we
	 * maintain the freedom to rename ems fields and change their emsMetadataIds
	 * without requiring a migration of user templates.
	 *
	 * If you are ADDING a new ems field, make the emsPublicIdentifier the same as
	 * the emsMetadataId. If renaming an ems property and changing its emsMetadataId,
	 * do NOT modify the emsPublicIdentifier without migrating its values in the
	 * backend.
	 *
	 * TODO PRSM-XXXX consider updating the events table to use this property as well.
	 */
	emsPublicIdentifier: string;
	/**
	 * NOT A USER FACING FIELD
	 *
	 * This property is surfaced on the EMS admin page and when hovering ems fields
	 * while admin mode is enabled.
	 *
	 * @deprecated Once all fields have userFacingLabels, we can remove this
	 */
	internalLabel: string;
	/**
	 * This flag indicates weather or not this ems property is (1) manually entered by
	 * a user, or (2) computed. Note that automatically imported ticket tier numbers
	 * are still "raw" values, even though they are not manually imported by the user.
	 *
	 * A better way to describe this may be "values where we store user-entered"
	 * data in the DB
	 */
	isRaw: boolean;
	/**
	 * Some ems fields only exists on SMS and TMS, but not EMSes. This flag
	 * indicates that is the case for the given field.
	 */
	isSMSOnly: boolean;
	/**
	 * Some ems fields only exists on TMS, but not in SMSes EMSes. This flag
	 * indicates that is the case for the given field.
	 */
	isTMSOnly?: boolean;
	/**
	 * Indicates the type of this field. This is used downstream to automatically
	 * format the raw value for display to the user.
	 */
	type: EMSFieldType;

	/**
	 * Denotes if the value of this field is an object or not–will return false for arrays.
	 */
	isObject?: boolean;
	/**
	 * true if we want to keep this field out of the Public-facing PrismEventRollup model
	 */
	private?: boolean;

	/**
	 * NOT A USER FACING FIELD
	 *
	 * Defining a new field? Feel free to set this to an empty string.
	 *
	 * This property is surfaced on the EMS admin page and when hovering ems fields
	 * while admin mode is enabled.
	 *
	 * @lzilioli had a crazy idea to use this value in order to replace some
	 * event.ts code with calls to math.js using this field. At this point,
	 * its primary use case is to express a rough formula to surface on the
	 * internal-only ems metadata table. Arguably, we could deprecate this field
	 * as its value is questionable.
	 */
	calculation: string | string[];
	/**
	 * Indicates if a given field's value may be null in some cases. Generally
	 * this would only be the case for data coming directly from the backend,
	 * as one of the primary jobs of EMS is to make everything type-safe so we
	 * can write simpler UI code without needing to worry about cases where a
	 * field we expect to be a number is null.
	 *
	 * Note that we have unit tests that will enforce the correctness of this value.
	 * (ems-fields.spec)
	 */
	isNullable?: boolean;
	/**
	 * Some ems fields, such as the event's name do not change based on costCalc,
	 * the event's name is the same no matter if we are looking at the event from
	 * an internal or external perspective.
	 *
	 * If your ems field's value doesn't vary based on the costCalc, set this to false.
	 *
	 * Note that we have unit tests that will enforce the correctness of this value.
	 * (ems-fields.spec)
	 */
	costCalcDependent: boolean;
	/**
	 * Some fields are !costCalcDependent, BUT our spec file that detects if a field
	 * is costCalcDependent is not perfect. For fields that are members of array objects,
	 * where elements in the array may be present for one costCalc, but not another
	 * (this is the case with additionalRevenue, and variableCosts, some elements
	 * do not show up in the external view). In these cases, we need to set this
	 * flag to true to indicate that extracting this property from the EMS across
	 * cost calcs will result in arrays that are not necessarily equal.
	 */
	canArrayIndicesVary?: boolean;
	/**
	 * This property is surfaced on the EMS admin page and when hovering ems fields
	 * while admin mode is enabled.
	 */
	internalDescription?: EMSFieldMetadataValueByCostCalc<string>;

	/**
	 * Specify the label for this field. You can either specify a string, which
	 * will cause the label to be the same regardless of cost calc, OR you
	 * can specify a map with a DEFAULT value and a value for any CostCalc2
	 * whose value should vary from DEFAULT.
	 *
	 * Currently, these values are only used by the events table for labeling
	 * its columns. In the future they will also be used when rendering the
	 * date interpolation dropdown for email templates and computed/templated
	 * custom fields.
	 */
	userFacingLabel?: EMSFieldMetadataValueByCostCalc<string>;
	/**
	 * Specify the description for this field. You can either specify a string, which
	 * will cause the label to be the same regardless of cost calc, OR you
	 * can specify a map with a DEFAULT value and a value for any CostCalc2
	 * whose value should vary from DEFAULT.
	 *
	 * Currently, these values are only used by the events table for labeling
	 * its columns. In the future they will also be used when rendering the
	 * date interpolation dropdown for email templates and computed/templated
	 * custom fields.
	 */
	userFacingDescription?: EMSFieldMetadataValueByCostCalc<string>;
	/**
	 * This value is currently unused, but every property that we surface in the
	 * events table today has a category. In the future events table column picker
	 * and the field picker in event templates will likely group fields together
	 * and surface category headers in order to make a long list of fields more
	 * navigable.
	 *
	 * CATEGORIES ORIGINALLY DEFINED BY PRODUCT HERE
	 * https://onesolstice.atlassian.net/wiki/spaces/PRISM/pages/2354282497/Deal+Tracker+CSV+Export+Details?atlOrigin=eyJpIjoiMjM1NTk5MDgyZGNlNDU4ZmIwZTQ3M2Q2OWNmZWFhOGEiLCJwIjoiYyJ9
	 */
	category?: EMSFieldCategory;
	/**
	 * List of permissions required for the user to view this field.
	 * At present these permissions are only honored by the data-interpolation-service
	 * but when we put events table on the home page, we will need honor them in
	 * events table as well.
	 *
	 * Note that ALL of these permissions must be granted to the user for them
	 * to see the field. If there is a specific event (for example, when sending
	 * a contract) in the given context, the permissions will be checked against
	 * that event, otherwise the permissions will be checked as global prmissions.
	 */
	permissions?: EMSFieldMetadataValueByCostCalc<Permission[]>;
	/**
	 * Indicates if the ems property is a top level property of the rollup or not
	 * If true, this property will be directly available on EMSRollup despite it might be
	 * copied over the costCalc EMSs: If false, it will be present in each particular
	 * CostCalc EMS of the rollup but wont be present as a direct property of it
	 * */
	isTopLevel?: boolean;

	/**
	 * true if the value is expected to change when the event status changes. ideally
	 * this is false for ALL NUMBERS in ems, and should only be true for things
	 * like eventStatus and eventStatusString
	 */
	isEventStatusDependent?: boolean;
}

export type EMSFieldCategory =
	| 'Artist Deal'
	| 'Deal Details'
	| 'Event Details'
	| 'Financial Summary'
	| 'Partner Deal'
	| 'Rental Details'
	| 'Revenue'
	| 'Show Metrics'
	| 'Custom Fields'
	| 'Tour Details';

const NET_GROSS_FIELD_METADATA: EMSFieldMeta = {
	isSMSOnly: false,
	emsMetadataId: 'netGross',
	emsPublicIdentifier: 'netGross',
	internalLabel: "Event's Net Gross",
	internalDescription: 'Gross ticket sales - taxes and fees',
	calculation: 'gross - totalTaxesAndFees',
	type: EMSFieldType.dollarAmount,
	isRaw: false,
	costCalcDependent: true,
	userFacingLabel: {
		DEFAULT: 'Net Gross',
		InternalPotential: 'Net Gross Potential',
		InternalActual: 'Net Gross Actual',
		ExternalBudgeted: 'Net Gross Potential',
	},
	userFacingDescription: {
		DEFAULT: 'Equals ticketing gross less taxes and pre-settlement fees',
		ExternalBudgeted: 'Equals maximum potential ticketing gross less taxes and pre-settlement fees',
		ExternalReported: 'Equals total actual ticketing gross less taxes and pre-settlement fees',
	},
	category: 'Financial Summary',
};

export type EMSFieldDefs = AllEMSFields<EMSFieldMeta>;

// #region EMSFieldsMeta
export const EMSFieldsMeta: EMSFieldDefs = {
	version: {
		isSMSOnly: false,
		emsMetadataId: 'version',
		emsPublicIdentifier: 'version',
		internalLabel: 'version',
		internalDescription: `EMSRollup version number. Bumped anytime a code change generates ems baseline changes.`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	isEMS: {
		isSMSOnly: false,
		emsMetadataId: 'isEMS',
		emsPublicIdentifier: 'isEMS',
		internalLabel: 'is EMS',
		internalDescription: `indicates if the EMS represents a EMS or not`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
		private: true,
	},
	isSMS: {
		isSMSOnly: false,
		emsMetadataId: 'isSMS',
		emsPublicIdentifier: 'isSMS',
		internalLabel: 'is SMS',
		internalDescription: `indicates if the EMS represents an SMS or not`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
		private: true,
	},
	isTMS: {
		isSMSOnly: false,
		emsMetadataId: 'isTMS',
		emsPublicIdentifier: 'isTMS',
		internalLabel: 'is TMS',
		internalDescription: `indicates if the EMS represents a TMS or not`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
		private: true,
	},
	isEMSRollup: {
		isSMSOnly: false,
		emsMetadataId: 'isEMSRollup',
		emsPublicIdentifier: 'isEMSRollup',
		internalLabel: 'is EMSRollup',
		internalDescription: `indicates if the object represents a EMSRollup or not`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
		private: true,
	},
	isSMSRollup: {
		isSMSOnly: false,
		emsMetadataId: 'isSMSRollup',
		emsPublicIdentifier: 'isSMSRollup',
		internalLabel: 'is SMSRollup',
		internalDescription: `indicates if the object represents a SMSRollup or not`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
		private: true,
	},
	isTMSRollup: {
		isSMSOnly: false,
		emsMetadataId: 'isTMSRollup',
		emsPublicIdentifier: 'isTMSRollup',
		internalLabel: 'is TMSRollup',
		internalDescription: `indicates if the object represents a TMSRollup or not`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
		private: true,
	},
	id: {
		isSMSOnly: false,
		emsMetadataId: 'id',
		emsPublicIdentifier: 'id',
		internalLabel: 'Event ID',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		costCalcDependent: false,
		category: 'Event Details',
		userFacingLabel: 'Event ID',
		userFacingDescription: 'The unique ID for this event record in Prism',
		isTopLevel: true,
	},
	ticketingURL: {
		isSMSOnly: false,
		emsMetadataId: 'ticketingURL',
		emsPublicIdentifier: 'ticketingURL',
		internalLabel: 'ticketing URL',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		category: 'Event Details',
		userFacingLabel: 'Ticketing URL',
		userFacingDescription: 'The URL for the tickets for this event',
		isNullable: true,
		isTopLevel: true,
	},
	stageNames: {
		isSMSOnly: true,
		emsMetadataId: 'stageNames',
		emsPublicIdentifier: 'stageNames',
		internalLabel: 'Stage Names',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: false,
		userFacingLabel: 'Stage Names',
		userFacingDescription: 'Name of stages on the show.',
		category: 'Event Details',
		isTopLevel: true,
	},
	tourName: {
		isSMSOnly: true,
		emsMetadataId: 'tourName',
		emsPublicIdentifier: 'tourName',
		internalLabel: 'Tour Name',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'Tour Name',
		userFacingDescription: 'Name of the tour the event is a part of, if any',
		category: 'Event Details',
		isTopLevel: true,
	},
	ageLimit: {
		isSMSOnly: true,
		emsMetadataId: 'ageLimit',
		emsPublicIdentifier: 'ageLimit',
		internalLabel: 'Age Limit',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'Age Limit',
		userFacingDescription: 'Age limit for the event',
		category: 'Event Details',
		isTopLevel: true,
	},
	currencySymbol: {
		isSMSOnly: true,
		emsMetadataId: 'currencySymbol',
		emsPublicIdentifier: 'currencySymbol',
		internalLabel: 'Currency Symbol',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'Currency Symbol',
		userFacingDescription: "The symbol for the event's currency.",
		category: 'Event Details',
		isTopLevel: true,
	},
	'events.*': {
		isSMSOnly: true,
		emsMetadataId: 'events.*',
		emsPublicIdentifier: 'events.*',
		internalLabel: 'events',
		internalDescription: `events that comprise the SMS`,
		calculation: '',
		type: EMSFieldType.PrismEventsArray,
		isRaw: true,
		costCalcDependent: false,
		private: true,
	},
	'warnings.*': {
		isSMSOnly: false,
		isTopLevel: true,
		emsMetadataId: 'warnings.*',
		emsPublicIdentifier: 'warnings.*',
		internalLabel: '[EMS,TMS,SMS,PrismEvent]Rollup Warnings',
		internalDescription: `Warnings generated when producing the [EMS,TMS,SMS,PrismEvent]Rollup`,
		calculation: '',
		type: EMSFieldType.EMSWarningArray,
		isRaw: true,
		costCalcDependent: false,
	},
	'warnings.*.description': {
		isSMSOnly: false,
		isTopLevel: true,
		emsMetadataId: 'warnings.*.description',
		emsPublicIdentifier: 'warnings.*.description',
		internalLabel: '[EMS,TMS,SMS,PrismEvent]Rollup Warning Description',
		internalDescription: `Warnings generated when producing the [EMS,TMS,SMS,PrismEvent]Rollup`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'warnings.*.type': {
		isSMSOnly: false,
		isTopLevel: true,
		emsMetadataId: 'warnings.*.type',
		emsPublicIdentifier: 'warnings.*.type',
		internalLabel: '[EMS,TMS,SMS,PrismEvent]Rollup Warning Type',
		internalDescription: `Warnings generated when producing the [EMS,TMS,SMS,PrismEvent]Rollup`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	name: {
		isSMSOnly: false,
		emsMetadataId: 'name',
		emsPublicIdentifier: 'name',
		internalLabel: 'Event Name',
		internalDescription: `Name of the event`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'Event Name',
		userFacingDescription: 'The name of the event',
		category: 'Event Details',
		isTopLevel: true,
	},
	capacity: {
		isSMSOnly: false,
		emsMetadataId: 'capacity',
		emsPublicIdentifier: 'capacity',
		internalLabel: 'Event capacity',
		internalDescription: `Capacity of all the stages on the event`,
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
		userFacingLabel: 'Capacity',
		userFacingDescription: 'The maximum capacity of the venue/stage',
		isTopLevel: true,
	},
	convertToUSD: {
		isSMSOnly: false,
		emsMetadataId: 'convertToUSD',
		emsPublicIdentifier: 'convertToUSD',
		internalLabel: 'Conver To USD?',
		internalDescription: `Event setting`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	// #region coProSummary
	coProSummary: {
		isObject: true,
		isSMSOnly: false,
		emsMetadataId: 'coProSummary',
		emsPublicIdentifier: 'coProSummary',
		internalLabel: 'Co Pro Summary',
		internalDescription: `Summary of various co pro calclations`,
		calculation: '',
		type: EMSFieldType.EMSCoProSummary,
		isRaw: false,
		costCalcDependent: true,
	},
	'coProSummary.remainingCoProPercentage': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.remainingCoProPercentage',
		emsPublicIdentifier: 'coProSummary.remainingCoProPercentage',
		internalLabel: 'Remaining copro %',
		internalDescription: `The remaining % of the copro share left on the event`,
		calculation: '',
		type: EMSFieldType.percentage,
		isRaw: false,
		costCalcDependent: true,
	},
	'coProSummary.orgProfitLabel': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.orgProfitLabel',
		emsPublicIdentifier: 'coProSummary.orgProfitLabel',
		internalLabel: 'Org Profit Label',
		internalDescription: `The label of the remaining balance after co pro deals, either 'Earnings' or 'Loss'`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: true,
	},
	// #region coProSummary.revenue.*
	'coProSummary.revenue.*': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.revenue.*',
		emsPublicIdentifier: 'coProSummary.revenue.*',
		internalLabel: 'Co Pro ticket summaries',
		internalDescription: `Summaries of each ticket split`,
		calculation: '',
		type: EMSFieldType.EMSCoProTicketSummaryArray,
		isRaw: false,
		costCalcDependent: true,
		canArrayIndicesVary: true,
	},
	'coProSummary.revenue.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.revenue.*.id',
		emsPublicIdentifier: 'coProSummary.revenue.*.id',
		internalLabel: 'CoPro Summary revenue Split ID',
		internalDescription: `an id for the revenue coPro expense split. Generated based on the revenue items ids`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: true,
	},
	'coProSummary.revenue.*.additionalRevenueName': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.revenue.*.additionalRevenueName',
		emsPublicIdentifier: 'coProSummary.revenue.*.additionalRevenueName',
		internalLabel: 'Ticket summary name',
		internalDescription: `The name of the ticket in the split`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: true,
	},
	'coProSummary.revenue.*.totalRevenue': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.revenue.*.totalRevenue',
		emsPublicIdentifier: 'coProSummary.revenue.*.totalRevenue',
		internalLabel: 'Total Revenue',
		internalDescription: `The total revenue for this additional rev`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'coProSummary.revenue.*.coProRevenueSplit.*': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.revenue.*.coProRevenueSplit.*',
		emsPublicIdentifier: 'coProSummary.revenue.*.coProRevenueSplit.*',
		internalLabel: 'Partner Deal Ticket Splits',
		internalDescription: `Array of co pro splits for this ticket`,
		calculation: '',
		type: EMSFieldType.EMSCoProTicketSplitArray,
		isRaw: false,
		costCalcDependent: true,
		canArrayIndicesVary: true,
	},
	'coProSummary.revenue.*.coProRevenueSplit.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.revenue.*.coProRevenueSplit.*.id',
		emsPublicIdentifier: 'coProSummary.revenue.*.coProRevenueSplit.*.id',
		internalLabel: 'CoPro Revenue Splits ID',
		internalDescription: `the revenue ID on this copro expense split`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: true,
	},
	'coProSummary.revenue.*.coProRevenueSplit.*.partnerDealId': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.revenue.*.coProRevenueSplit.*.partnerDealId',
		emsPublicIdentifier: 'coProSummary.revenue.*.coProRevenueSplit.*.partnerDealId',
		internalLabel: 'Partner Deal Ticket Splits ID',
		internalDescription: `the partner deal ID on this ticket split`,
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: true,
	},
	'coProSummary.revenue.*.coProRevenueSplit.*.partnerDealName': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.revenue.*.coProRevenueSplit.*.partnerDealName',
		emsPublicIdentifier: 'coProSummary.revenue.*.coProRevenueSplit.*.partnerDealName',
		internalLabel: 'Partner Deal Ticket Splits Name',
		internalDescription: `the partner deal name on this ticket split`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: true,
	},
	'coProSummary.revenue.*.coProRevenueSplit.*.coProSharePercentage': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.revenue.*.coProRevenueSplit.*.coProSharePercentage',
		emsPublicIdentifier: 'coProSummary.revenue.*.coProRevenueSplit.*.coProSharePercentage',
		internalLabel: 'Partner Deal Ticket share percentage',
		internalDescription: `the partner deal share % `,
		calculation: '',
		type: EMSFieldType.percentage,
		isRaw: true,
		costCalcDependent: true,
	},
	'coProSummary.revenue.*.coProRevenueSplit.*.coProRevenueSplit': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.revenue.*.coProRevenueSplit.*.coProRevenueSplit',
		emsPublicIdentifier: 'coProSummary.revenue.*.coProRevenueSplit.*.coProRevenueSplit',
		internalLabel: 'Partner Deal Ticket Split Total',
		internalDescription: `the total gross for this ticket for this partner`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	// #endregion coProSummary.revenue.*
	// #region coProSummary.fixedCosts.*
	'coProSummary.fixedCosts.*': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.fixedCosts.*',
		emsPublicIdentifier: 'coProSummary.fixedCosts.*',
		internalLabel: 'Co Pro fixed costs summaries',
		internalDescription: `Summaries of each ticket split`,
		calculation: '',
		type: EMSFieldType.EMSCoProTicketSummaryArray,
		isRaw: false,
		costCalcDependent: true,
	},
	'coProSummary.fixedCosts.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.fixedCosts.*.id',
		emsPublicIdentifier: 'coProSummary.fixedCosts.*.id',
		internalLabel: 'Co Pro fixed costs id',
		internalDescription: `a id for the particular fixed cost `,
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'coProSummary.fixedCosts.*.expenseName': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.fixedCosts.*.expenseName',
		emsPublicIdentifier: 'coProSummary.fixedCosts.*.expenseName',
		internalLabel: 'Co Pro fixed costs expense name',
		internalDescription: `the name of the expense being split`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'coProSummary.fixedCosts.*.expenseType': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.fixedCosts.*.expenseType',
		emsPublicIdentifier: 'coProSummary.fixedCosts.*.expenseType',
		internalLabel: 'Co Pro fixed costs expense type',
		internalDescription: `the type of the expense being split`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: true,
	},
	'coProSummary.fixedCosts.*.coProOverriddenAmount': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.fixedCosts.*.coProOverriddenAmount',
		emsPublicIdentifier: 'coProSummary.fixedCosts.*.coProOverriddenAmount',
		internalLabel: 'fixed costs amount overriden for co pro',
		internalDescription: 'The overriden value for this fixed cost in coPro deal',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: true,
		isNullable: true,
	},
	'coProSummary.fixedCosts.*.hasCoProOverriddenAmount': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.fixedCosts.*.hasCoProOverriddenAmount',
		emsPublicIdentifier: 'coProSummary.fixedCosts.*.hasCoProOverriddenAmount',
		internalLabel: 'if there are fixed costs amount overriden for co pro',
		internalDescription: 'true if there are overridden value for this fixed cost in coPro deal, false otherwise',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: true,
	},
	'coProSummary.fixedCosts.*.isReported': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.fixedCosts.*.isReported',
		emsPublicIdentifier: 'coProSummary.fixedCosts.*.isReported',
		internalLabel: 'Is Reported?',
		internalDescription: `If the cost is reported or not`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
	},
	'coProSummary.fixedCosts.*.coProExpenseSplit.*': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.fixedCosts.*.coProExpenseSplit.*',
		emsPublicIdentifier: 'coProSummary.fixedCosts.*.coProExpenseSplit.*',
		internalLabel: 'Co Pro fixed costs partner deal splits',
		internalDescription: `a split for each partner deal per cost`,
		calculation: '',
		type: EMSFieldType.EMSCoProExpenseSplitArray,
		isRaw: false,
		costCalcDependent: true,
	},
	'coProSummary.fixedCosts.*.coProExpenseSplit.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.fixedCosts.*.coProExpenseSplit.*.id',
		emsPublicIdentifier: 'coProSummary.fixedCosts.*.coProExpenseSplit.*.id',
		internalLabel: 'Fixed Cost CoPro Expense Split ID',
		internalDescription: `an id for the fixed cost coPro expense split. Generated based on the cost and partner deal ids`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: false,
	},
	'coProSummary.fixedCosts.*.coProExpenseSplit.*.partnerDealId': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.fixedCosts.*.coProExpenseSplit.*.partnerDealId',
		emsPublicIdentifier: 'coProSummary.fixedCosts.*.coProExpenseSplit.*.partnerDealId',
		internalLabel: 'Co Pro fixed costs partner deal id',
		internalDescription: `a id for the partner deal `,
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: true,
	},
	'coProSummary.fixedCosts.*.coProExpenseSplit.*.partnerDealName': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.fixedCosts.*.coProExpenseSplit.*.partnerDealName',
		emsPublicIdentifier: 'coProSummary.fixedCosts.*.coProExpenseSplit.*.partnerDealName',
		internalLabel: 'Co Pro fixed costs partner deal name',
		internalDescription: `a name for the partner deal `,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: true,
	},
	'coProSummary.fixedCosts.*.coProExpenseSplit.*.coProSharePercentage': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.fixedCosts.*.coProExpenseSplit.*.coProSharePercentage',
		emsPublicIdentifier: 'coProSummary.fixedCosts.*.coProExpenseSplit.*.coProSharePercentage',
		internalLabel: 'Co Pro fixed costs partner deal share percentage',
		internalDescription: `the % share for the partner deal `,
		calculation: '',
		type: EMSFieldType.percentage,
		isRaw: true,
		costCalcDependent: true,
	},
	'coProSummary.fixedCosts.*.coProExpenseSplit.*.coProCostSplit': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.fixedCosts.*.coProExpenseSplit.*.coProCostSplit',
		emsPublicIdentifier: 'coProSummary.fixedCosts.*.coProExpenseSplit.*.coProCostSplit',
		internalLabel: 'Co Pro fixed costs partner deal split cost',
		internalDescription: `the split cost for this partner deal for this cost`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'coProSummary.fixedCosts.*.groupCategory': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.fixedCosts.*.groupCategory',
		emsPublicIdentifier: 'coProSummary.fixedCosts.*.groupCategory',
		internalLabel: 'Co Pro fixed costs category',
		internalDescription: `the fixed cost category for this cost for this partner deal`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: true,
	},
	'coProSummary.fixedCosts.*.expenseTotal': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.fixedCosts.*.expenseTotal',
		emsPublicIdentifier: 'coProSummary.fixedCosts.*.expenseTotal',
		internalLabel: 'Co Pro fixed costs total',
		internalDescription: `the total cost for this partner deal for this cost`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'coProSummary.fixedCosts.*.computedTotal': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.fixedCosts.*.computedTotal',
		emsPublicIdentifier: 'coProSummary.fixedCosts.*.computedTotal',
		internalLabel: 'Co Pro fixed costs computed total',
		internalDescription: `the computed total cost for this partner deal for this cost, without overrides`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'coProSummary.fixedCosts.*.hiddenFromCoPro': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.fixedCosts.*.hiddenFromCoPro',
		emsPublicIdentifier: 'coProSummary.fixedCosts.*.hiddenFromCoPro',
		internalLabel: 'Co Pro fixed costs hidden from co pro',
		internalDescription: `true if this fixed cost is hidden from the co pro page, false otherwise`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: true,
	},
	// #endregion coProSummary.fixedCosts.*
	// #region coProSummary.variableCosts.*
	'coProSummary.variableCosts.*': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.variableCosts.*',
		emsPublicIdentifier: 'coProSummary.variableCosts.*',
		internalLabel: 'Co Pro variable costs summaries',
		internalDescription: `Summaries of each ticket split`,
		calculation: '',
		type: EMSFieldType.EMSCoProTicketSummaryArray,
		isRaw: false,
		costCalcDependent: true,
		canArrayIndicesVary: true,
	},
	'coProSummary.variableCosts.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.variableCosts.*.id',
		emsPublicIdentifier: 'coProSummary.variableCosts.*.id',
		internalLabel: 'Co Pro variable costs id',
		internalDescription: `the id for this partner deal for this cost`,
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: true,
		canArrayIndicesVary: true,
	},
	'coProSummary.variableCosts.*.expenseName': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.variableCosts.*.expenseName',
		emsPublicIdentifier: 'coProSummary.variableCosts.*.expenseName',
		internalLabel: 'Co Pro variable costs expense name',
		internalDescription: `the name of the expense being split`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: true,
		canArrayIndicesVary: true,
	},
	'coProSummary.variableCosts.*.expenseType': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.variableCosts.*.expenseType',
		emsPublicIdentifier: 'coProSummary.variableCosts.*.expenseType',
		internalLabel: 'Co Pro variable costs expense type',
		internalDescription: `the type of the expense being split`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: true,
		canArrayIndicesVary: true,
	},
	'coProSummary.variableCosts.*.hiddenFromCoPro': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.variableCosts.*.hiddenFromCoPro',
		emsPublicIdentifier: 'coProSummary.variableCosts.*.hiddenFromCoPro',
		internalLabel: 'Co Pro variable costs hidden from co pro',
		internalDescription: `true if this variable cost is hidden from the co pro page, false otherwise`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: true,
		canArrayIndicesVary: true,
	},
	'coProSummary.variableCosts.*.hasCoProOverriddenAmount': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.variableCosts.*.hasCoProOverriddenAmount',
		emsPublicIdentifier: 'coProSummary.variableCosts.*.hasCoProOverriddenAmount',
		internalLabel: 'Co Pro variable costs hidden from co pro',
		internalDescription: `true if this variable cost is hidden from the co pro page, false otherwise`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: true,
		canArrayIndicesVary: true,
	},
	'coProSummary.variableCosts.*.coProOverriddenAmount': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.variableCosts.*.coProOverriddenAmount',
		emsPublicIdentifier: 'coProSummary.variableCosts.*.coProOverriddenAmount',
		internalLabel: 'variable costs amount overriden for co pro',
		internalDescription: `The overriden value for this variable cost in coPro deal`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: true,
		isNullable: true,
		canArrayIndicesVary: true,
	},
	'coProSummary.variableCosts.*.isReported': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.variableCosts.*.isReported',
		emsPublicIdentifier: 'coProSummary.variableCosts.*.isReported',
		internalLabel: 'Is Reported?',
		internalDescription: `If the cost is reported or not`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: true,
		canArrayIndicesVary: true,
	},
	'coProSummary.variableCosts.*.coProExpenseSplit.*': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.variableCosts.*.coProExpenseSplit.*',
		emsPublicIdentifier: 'coProSummary.variableCosts.*.coProExpenseSplit.*',
		internalLabel: 'Co Pro variable costs partner deal splits',
		internalDescription: `a split for each partner deal per cost`,
		calculation: '',
		type: EMSFieldType.EMSCoProExpenseSplitArray,
		isRaw: false,
		costCalcDependent: true,
		canArrayIndicesVary: true,
	},
	'coProSummary.variableCosts.*.coProExpenseSplit.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.variableCosts.*.coProExpenseSplit.*.id',
		emsPublicIdentifier: 'coProSummary.variableCosts.*.coProExpenseSplit.*.id',
		internalLabel: 'Vairable Cost CoPro Expense Split ID',
		internalDescription: `an id for the variable cost coPro expense split. Generated based on the cost and partner deal ids`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: true,
	},
	'coProSummary.variableCosts.*.coProExpenseSplit.*.partnerDealId': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.variableCosts.*.coProExpenseSplit.*.partnerDealId',
		emsPublicIdentifier: 'coProSummary.variableCosts.*.coProExpenseSplit.*.partnerDealId',
		internalLabel: 'Co Pro variable costs partner deal id',
		internalDescription: `a id for the partner deal `,
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: true,
	},
	'coProSummary.variableCosts.*.coProExpenseSplit.*.partnerDealName': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.variableCosts.*.coProExpenseSplit.*.partnerDealName',
		emsPublicIdentifier: 'coProSummary.variableCosts.*.coProExpenseSplit.*.partnerDealName',
		internalLabel: 'Co Pro variable costs partner deal name',
		internalDescription: `a name for the partner deal `,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: true,
	},
	'coProSummary.variableCosts.*.coProExpenseSplit.*.coProSharePercentage': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.variableCosts.*.coProExpenseSplit.*.coProSharePercentage',
		emsPublicIdentifier: 'coProSummary.variableCosts.*.coProExpenseSplit.*.coProSharePercentage',
		internalLabel: 'Co Pro variable costs partner deal share percentage',
		internalDescription: `the % share for the partner deal `,
		calculation: '',
		type: EMSFieldType.percentage,
		isRaw: true,
		costCalcDependent: true,
	},
	'coProSummary.variableCosts.*.coProExpenseSplit.*.coProCostSplit': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.variableCosts.*.coProExpenseSplit.*.coProCostSplit',
		emsPublicIdentifier: 'coProSummary.variableCosts.*.coProExpenseSplit.*.coProCostSplit',
		internalLabel: 'Co Pro variable costs partner deal split cost',
		internalDescription: `the split cost for this partner deal for this cost`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'coProSummary.variableCosts.*.groupCategory': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.variableCosts.*.groupCategory',
		emsPublicIdentifier: 'coProSummary.variableCosts.*.groupCategory',
		internalLabel: 'Co Pro variable category',
		internalDescription: `this field is not set for variable costs in the co pro summary`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: true,
	},
	'coProSummary.variableCosts.*.expenseTotal': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.variableCosts.*.expenseTotal',
		emsPublicIdentifier: 'coProSummary.variableCosts.*.expenseTotal',
		internalLabel: 'Co Pro variable costs total',
		internalDescription: `the total cost for this partner deal for this cost`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'coProSummary.variableCosts.*.computedTotal': {
		isSMSOnly: false,
		emsMetadataId: 'coProSummary.variableCosts.*.computedTotal',
		emsPublicIdentifier: 'coProSummary.variableCosts.*.computedTotal',
		internalLabel: 'Co Pro variable costs computed total',
		internalDescription: `the computed total cost for this partner deal for this cost, without overrides`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	// #endregion coProSummary.variableCosts.*
	// #endregion coProSummary
	// #region customFields.*
	'customFields.*': {
		isSMSOnly: false,
		emsMetadataId: 'customFields.*',
		emsPublicIdentifier: 'customFields.*',
		internalLabel: 'Custom Fields',
		internalDescription: `The custom fields for a given event`,
		calculation: '',
		type: EMSFieldType.EMSCustomFieldArray,
		isRaw: false,
		costCalcDependent: false,
		isTopLevel: true,
	},
	'customFields.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'customFields.*.id',
		emsPublicIdentifier: 'customFields.*.id',
		internalLabel: 'Custom Field Id',
		internalDescription: `The database ID of this custom field value`,
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	'customFields.*.parentId': {
		isSMSOnly: false,
		emsMetadataId: 'customFields.*.parentId',
		emsPublicIdentifier: 'customFields.*.parentId',
		internalLabel: 'Custom Field Parent Id',
		internalDescription: `The database ID of the unique custom field for this org`,
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	'customFields.*.label': {
		isSMSOnly: false,
		emsMetadataId: 'customFields.*.label',
		emsPublicIdentifier: 'customFields.*.label',
		internalLabel: 'Custom Field Label',
		internalDescription: `The label of this custom field`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	'customFields.*.description': {
		isSMSOnly: false,
		emsMetadataId: 'customFields.*.description',
		emsPublicIdentifier: 'customFields.*.description',
		internalLabel: 'Custom Field Description',
		internalDescription: `The description of the custom field value`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	'customFields.*.rawValue': {
		isSMSOnly: false,
		emsMetadataId: 'customFields.*.rawValue',
		emsPublicIdentifier: 'customFields.*.rawValue',
		internalLabel: 'Custom Field Raw Value',
		internalDescription: `The raw event value of the custom value on this event`,
		calculation: '',
		type: EMSFieldType.EMSCustomFieldValue,
		isRaw: true,
		costCalcDependent: false,
		isNullable: true,
		isTopLevel: true,
	},
	'customFields.*.stringValue': {
		isSMSOnly: false,
		emsMetadataId: 'customFields.*.stringValue',
		emsPublicIdentifier: 'customFields.*.stringValue',
		internalLabel: 'Custom Field String Value',
		internalDescription: `The display event value of the custom value on this event`,
		calculation: '',
		type: EMSFieldType.EMSCustomFieldValue,
		isRaw: false,
		costCalcDependent: false,
		isTopLevel: true,
	},
	'customFields.*.key': {
		isSMSOnly: false,
		emsMetadataId: 'customFields.*.key',
		emsPublicIdentifier: 'customFields.*.key',
		internalLabel: 'Key',
		internalDescription: `Unique key for this custom field to be used in tables, etc.`,
		calculation: '',
		type: EMSFieldType.EMSCustomFieldValue,
		isRaw: false,
		costCalcDependent: false,
		isTopLevel: true,
	},
	'customFields.*.type': {
		isSMSOnly: false,
		emsMetadataId: 'customFields.*.type',
		emsPublicIdentifier: 'customFields.*.type',
		internalLabel: 'Custom Field Type',
		internalDescription: `The custom value type`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	'customFields.*.isAdvanced': {
		isSMSOnly: false,
		emsMetadataId: 'customFields.*.isAdvanced',
		emsPublicIdentifier: 'customFields.*.isAdvanced',
		internalLabel: 'Custom Field Type',
		internalDescription: `Indicates if a custom field is advanced or not. Advanced custom fields store templated formulas or strings, and resolve their value dynamically using the rest of the event's ems data.`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	'customFields.*.flatType': {
		isSMSOnly: false,
		emsMetadataId: 'customFields.*.flatType',
		emsPublicIdentifier: 'customFields.*.flatType',
		internalLabel: 'Custom Field Type',
		internalDescription: `The custom value flatType, which is a flattened version of the is_advanced,type tuple that comes from the backend.`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	// #endregion customFields.*
	isEventOwnedByAgent: {
		isSMSOnly: false,
		emsMetadataId: 'isEventOwnedByAgent',
		emsPublicIdentifier: 'isEventOwnedByAgent',
		internalLabel: 'Is this event for an agency org?',
		internalDescription: `true when the show is owned by an organization with the agency flag`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	isArchived: {
		isSMSOnly: false,
		emsMetadataId: 'isArchived',
		emsPublicIdentifier: 'isArchived',
		internalLabel: 'Event Archived?',
		internalDescription: `true when the show is archived`,
		userFacingDescription: 'Is this event archived?',
		userFacingLabel: 'Is Archived',
		category: 'Event Details',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	isForRentalEvent: {
		isSMSOnly: false,
		emsMetadataId: 'isForRentalEvent',
		emsPublicIdentifier: 'isForRentalEvent',
		internalLabel: 'Rental Show or Talent Show',
		internalDescription: `true when the show is a rental show, false when it is a talent show`,
		userFacingDescription: 'Is this event a rental?',
		userFacingLabel: 'Is Rental',
		category: 'Rental Details',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	type: {
		isSMSOnly: false,
		emsMetadataId: 'type',
		emsPublicIdentifier: 'type',
		internalLabel: 'MathSummaryType',
		internalDescription: `one of the following types: EMS, SMS, or TMS`,
		calculation: '',
		type: EMSFieldType.MathSummaryType,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	hasNetDeal: {
		isSMSOnly: false,
		emsMetadataId: 'hasNetDeal',
		emsPublicIdentifier: 'hasNetDeal',
		internalLabel: 'Has net deal',
		internalDescription: `true if at least one of the talent deals is a net deal, false otherwise`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: false,
		isTopLevel: true,
	},
	eventHasLinkedTicket: {
		isSMSOnly: false,
		emsMetadataId: 'eventHasLinkedTicket',
		emsPublicIdentifier: 'eventHasLinkedTicket',
		internalLabel: 'Has linked ticket',
		internalDescription: `true if the event's tickets are linked to a ticketing platform, false otherwise. This changes how netTicketGross is calculated`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: false,
		isTopLevel: true,
	},
	eventHasPlatformDiscount: {
		isSMSOnly: false,
		emsMetadataId: 'eventHasPlatformDiscount',
		emsPublicIdentifier: 'eventHasPlatformDiscount',
		internalLabel: 'Has platform discount',
		internalDescription: `true if the event's tickets have a platform discount`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: false,
		isTopLevel: true,
	},
	eventHasPlatformTax: {
		isSMSOnly: false,
		emsMetadataId: 'eventHasPlatformTax',
		emsPublicIdentifier: 'eventHasPlatformTax',
		internalLabel: 'Has platform tax',
		internalDescription: `true if the event's tickets are have a platform tax`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: false,
		isTopLevel: true,
	},
	eventHasNonPlatformTax: {
		isSMSOnly: false,
		emsMetadataId: 'eventHasNonPlatformTax',
		emsPublicIdentifier: 'eventHasNonPlatformTax',
		internalLabel: 'Has non platform tax',
		internalDescription: `true if the event has some non-platform tax`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: false,
		isTopLevel: true,
	},
	eventLastUpdated: {
		isSMSOnly: false,
		emsMetadataId: 'eventLastUpdated',
		emsPublicIdentifier: 'eventLastUpdated',
		internalLabel: 'Event Last Updated',
		internalDescription: `a timestamp of the event's last updated date`,
		calculation: '',
		type: EMSFieldType.date,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'Event Last Updated',
		userFacingDescription: 'The last time this event was updated in Prism',
		category: 'Event Details',
		isTopLevel: true,
	},
	eventCreatedDate: {
		isSMSOnly: false,
		emsMetadataId: 'eventCreatedDate',
		emsPublicIdentifier: 'eventCreatedDate',
		internalLabel: 'Event Created Date',
		internalDescription: `a timestamp of the event's creation date`,
		calculation: '',
		type: EMSFieldType.date,
		isRaw: true,
		costCalcDependent: false,
		isNullable: true,
		userFacingLabel: 'Event Created Date',
		userFacingDescription: 'The time the event was created',
		category: 'Event Details',
		isTopLevel: true,
	},
	eventOwnerId: {
		isSMSOnly: false,
		emsMetadataId: 'eventOwnerId',
		emsPublicIdentifier: 'eventOwnerId',
		internalLabel: 'Event Owner ID',
		internalDescription: `The ID of the event owner in the DB (this is the Staff ID)`,
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	eventOwnerName: {
		isSMSOnly: false,
		emsMetadataId: 'eventOwnerName',
		emsPublicIdentifier: 'eventOwnerName',
		internalLabel: 'Event Owner Name',
		internalDescription: `The name of the event owner`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'Event Owner',
		userFacingDescription: 'This is the Prism user who created the event in Prism.',
		category: 'Event Details',
		isTopLevel: true,
	},
	totalSellable: {
		isSMSOnly: false,
		emsMetadataId: 'totalSellable',
		emsPublicIdentifier: 'totalSellable',
		internalLabel: 'Total sellable',
		internalDescription: 'the sum of the sellable field across all tickets',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
		userFacingLabel: 'Total Sellable',
		userFacingDescription: 'Total Sellable tickets on the event (total Allotted tickets less Comps and Kills)',
		category: 'Show Metrics',
		isTopLevel: true,
	},
	taxRate: {
		isSMSOnly: false,
		emsMetadataId: 'taxRate',
		emsPublicIdentifier: 'taxRate',
		internalLabel: 'Tax Rate',
		internalDescription: 'the tax rate for the event from the event settings page',
		calculation: '',
		type: EMSFieldType.percentage,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'Tax Rate',
		userFacingDescription: 'The percentage of sales tax included in the face value of all tickets',
		category: 'Revenue',
		isTopLevel: true,
	},
	firstDate: {
		isSMSOnly: false,
		emsMetadataId: 'firstDate',
		emsPublicIdentifier: 'firstDate',
		internalLabel: 'First Date',
		internalDescription: 'The first date on the event (useful for sorting events by their start date)',
		category: 'Event Details',
		calculation: '',
		type: EMSFieldType.date,
		isRaw: false,
		isNullable: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	dateRangeString: {
		isSMSOnly: false,
		emsMetadataId: 'dateRangeString',
		emsPublicIdentifier: 'dateRangeString',
		internalLabel: 'Date Range',
		internalDescription: 'Human readable string describing the date range for the event',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: false,
		userFacingLabel: 'Dates',
		userFacingDescription: 'The range of dates for the event',
		category: 'Event Details',
		isTopLevel: true,
	},
	daysRangeString: {
		isSMSOnly: false,
		emsMetadataId: 'daysRangeString',
		emsPublicIdentifier: 'daysRangeString',
		internalLabel: 'Days Range',
		internalDescription: 'Human readable string describing the date range of days for the event',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: false,
		userFacingLabel: 'Days of Week',
		userFacingDescription: 'The range of days for the event',
		category: 'Event Details',
		isTopLevel: true,
	},
	'genres.*': {
		isSMSOnly: false,
		emsMetadataId: 'genres.*',
		emsPublicIdentifier: 'genres',
		internalLabel: 'Headliner Genres',
		internalDescription: `The genres pertaining to the headliners on the event`,
		calculation: '',
		type: EMSFieldType.stringArray,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'Genres',
		userFacingDescription: 'Genres pertaining to all artists on the show.',
		category: 'Event Details',
		isTopLevel: true,
	},
	withoutTaxMultiplier: {
		isSMSOnly: false,
		emsMetadataId: 'withoutTaxMultiplier',
		emsPublicIdentifier: 'withoutTaxMultiplier',
		internalLabel: 'Tax Rate Multiplier',
		internalDescription:
			'a value computed from tax rate that can be used to compute a value without tax; e.g. actualTotal = total * withoutTaxMultiplier',
		calculation: '1 / (1 + taxRate / 100)',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
		isTopLevel: true,
	},
	perTicketFacilityFee: {
		isSMSOnly: false,
		emsMetadataId: 'perTicketFacilityFee',
		emsPublicIdentifier: 'perTicketFacilityFee',
		internalLabel: 'Facility fee (per ticket)',
		internalDescription: `the event's facility fee from the event settings page`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	platformIdString: {
		isSMSOnly: false,
		emsMetadataId: 'platformIdString',
		emsPublicIdentifier: 'platformIdString',
		internalLabel: 'Platform ID String',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	// #region tickets.*
	'tickets.*': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*',
		emsPublicIdentifier: 'tickets.*',
		internalLabel: 'All ticket tiers for the event',
		internalDescription: 'A list of EMSTickets, one for each ticket in the revenue table',
		calculation: '',
		type: EMSFieldType.EMSTicketArray,
		isRaw: true,
		costCalcDependent: true,
	},
	'tickets.*.name': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.name',
		emsPublicIdentifier: 'tickets.*.name',
		internalLabel: 'Ticket tier',
		internalDescription: 'The name for the ticket tier, entered by the user on the revenue table',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'tickets.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.id',
		emsPublicIdentifier: 'tickets.*.id',
		internalLabel: 'Ticket id',
		internalDescription: 'the id for the ticket in our database. isNullable for TMS',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		// only on tours
		isNullable: true,
		costCalcDependent: false,
	},
	'tickets.*.order': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.order',
		emsPublicIdentifier: 'tickets.*.order',
		internalLabel: 'Ticket order',
		internalDescription: 'order if its been set by rearranging the tickets, else the tickets index in the array',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		isNullable: false,
		costCalcDependent: false,
	},
	'tickets.*.groupKey': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.groupKey',
		emsPublicIdentifier: 'tickets.*.groupKey',
		internalLabel: 'Ticket id',
		internalDescription: 'They key to group by in PDF docs; mostly for tours',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'tickets.*.price': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.price',
		emsPublicIdentifier: 'tickets.*.price',
		internalLabel: 'Individual ticket price',
		internalDescription: 'The price for tickets in that tier, entered by the user on the revenue table',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
	},
	'tickets.*.allotment': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.allotment',
		emsPublicIdentifier: 'tickets.*.allotment',
		internalLabel: 'Individual ticket allotment',
		internalDescription: 'Individual ticket allotment, input by the user on the Tickets table',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'tickets.*.comps': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.comps',
		emsPublicIdentifier: 'tickets.*.comps',
		internalLabel: 'Individual ticket comps',
		internalDescription:
			'The number of comped tickets alloted for the tier, entered by the user on the Tickets table',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'tickets.*.kills': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.kills',
		emsPublicIdentifier: 'tickets.*.kills',
		internalLabel: 'Individual ticket kills',
		internalDescription:
			'The number of killed tickets alloted for the tier, entered by the user on the Tickets table',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'tickets.*.killsAverage': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.killsAverage',
		emsPublicIdentifier: 'tickets.*.killsAverage',
		internalLabel: 'Ticket Tier average Kills',
		internalDescription: 'The avareage kills for a ticket tier',
		calculation: 'sum(tickets.kills) / tickets.length',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'tickets.*.sellable': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.sellable',
		emsPublicIdentifier: 'tickets.*.sellable',
		internalLabel: 'Sellable',
		internalDescription: 'Number of alloted tickets remaining after comps and kills',
		calculation: 'allotment - comps - kills',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
	},
	'tickets.*.sellableAverage': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.sellableAverage',
		emsPublicIdentifier: 'tickets.*.sellableAverage',
		internalLabel: 'Ticket Tier average Sellable',
		internalDescription: 'The avareage sellable for a ticket tier',
		calculation: 'sum(tickets.sellable) / tickets.length',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'tickets.*.platformDiscounts': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.platformDiscounts',
		emsPublicIdentifier: 'tickets.*.platformDiscounts',
		internalLabel: 'Ticket platform discount',
		internalDescription: 'The ticket platform discount',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
	},
	'tickets.*.platformFees': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.platformFees',
		emsPublicIdentifier: 'tickets.*.platformFees',
		internalLabel: 'Platform Fees',
		internalDescription:
			'only relevant when tickets are linked to eventbrite. This changes how netTicketGross is calculated',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
	},
	'tickets.*.platformGrossTicketPrice': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.platformGrossTicketPrice',
		emsPublicIdentifier: 'tickets.*.platformGrossTicketPrice',
		internalLabel: 'Platform Gross Ticket Price',
		internalDescription:
			'Total platform revenue (platform total + tax), divided total sold; 0 if non-platform ticket',
		calculation: 'ticket.totalRevenue(costCalc) / ticket.ticketsSold(costCalc)',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tickets.*.platformRebate': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.platformRebate',
		emsPublicIdentifier: 'tickets.*.platformRebate',
		internalLabel: 'Ticket platform rebate',
		internalDescription: 'The ticket platform rebate',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: true,
	},
	'tickets.*.feeDeducted': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.feeDeducted',
		emsPublicIdentifier: 'tickets.*.feeDeducted',
		internalLabel: 'Fee deducted?',
		internalDescription:
			'only relevant when tickets are linked to eventbrite. This changes how netTicketGross is calculated',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: false,
	},
	'tickets.*.feesPerTicket': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.feesPerTicket',
		emsPublicIdentifier: 'tickets.*.feesPerTicket',
		internalLabel: 'Fees Per Ticket',
		internalDescription: 'All the fees for this ticket tier, averaged per-ticket',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tickets.*.ticketsSold': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.ticketsSold',
		emsPublicIdentifier: 'tickets.*.ticketsSold',
		internalLabel: 'Tickets Sold',
		internalDescription: 'This number depends on which CostCalc perspective is being viewed',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'tickets.*.totalTicketFees': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.totalTicketFees',
		emsPublicIdentifier: 'tickets.*.totalTicketFees',
		internalLabel: 'Ticket Fees',
		internalDescription: 'total fees on the ticket',
		calculation: 'a sum of all fees that apply to this ticket',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tickets.*.ticketRevenue': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.ticketRevenue',
		emsPublicIdentifier: 'tickets.*.ticketRevenue',
		internalLabel: 'Ticket Revenue',
		internalDescription: 'will always be ticketsSold * price',
		calculation: 'ticketsSold * price',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tickets.*.netTicketGross': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.netTicketGross',
		emsPublicIdentifier: 'tickets.*.netTicketGross',
		internalLabel: 'Net Ticket Revenue',
		internalDescription: `adjusted gross minus tax`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tickets.*.netTicketPrice': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.netTicketPrice',
		emsPublicIdentifier: 'tickets.*.netTicketPrice',
		internalLabel: 'Net Ticket Price',
		internalDescription: 'The net ticket price, price - average fees deducted',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: false,
	},
	'tickets.*.adjustedTicketGross': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.adjustedTicketGross',
		emsPublicIdentifier: 'tickets.*.adjustedTicketGross',
		internalLabel: 'Adjusted Ticket Revenue',
		internalDescription: `ticket netGross minus ticket fees`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tickets.*.breakEven': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.breakEven',
		emsPublicIdentifier: 'tickets.*.breakEven',
		internalLabel: 'Ticket break even',
		internalDescription: '...its complicated...',
		calculation: '',
		type: EMSFieldType.number,
		isNullable: true,
		isRaw: false,
		costCalcDependent: true,
	},
	'tickets.*.estimatedSold': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.estimatedSold',
		emsPublicIdentifier: 'tickets.*.estimatedSold',
		internalLabel: 'Ticket Estimated Sold',
		internalDescription: 'The number of estimated tickets sold',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'tickets.*.totalTax': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.totalTax',
		emsPublicIdentifier: 'tickets.*.totalTax',
		internalLabel: 'Ticket Total tax',
		internalDescription: 'The tax for the ticket, using the tax rate for the event (and is not platform tax)',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tickets.*.platformTaxPaid': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.platformTaxPaid',
		emsPublicIdentifier: 'tickets.*.platformTaxPaid',
		internalLabel: 'Ticket Platform tax',
		internalDescription: 'The platform tax for the ticket',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tickets.*.platformIdString': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.platformIdString',
		emsPublicIdentifier: 'tickets.*.platformIdString',
		internalLabel: 'Ticket Platform ID',
		internalDescription: 'The platform id for the ticket',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		isNullable: true,
		costCalcDependent: true,
	},
	'tickets.*.isFlatTicketRevenue': {
		isSMSOnly: false,
		emsMetadataId: 'tickets.*.isFlatTicketRevenue',
		emsPublicIdentifier: 'tickets.*.isFlatTicketRevenue',
		internalLabel: 'Is Flat Revenue',
		internalDescription: 'True if this is an EMSTicket value for a flat (dynamic) ticket tier, false otherwise',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		isNullable: false,
		costCalcDependent: false,
	},
	// #endregion tickets.*
	// #region ticketTotals
	ticketTotals: {
		isObject: true,
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals',
		emsPublicIdentifier: 'ticketTotals',
		internalLabel: 'Ticket totals',
		internalDescription: 'The ticket totals for all tickets (platform, non platform and flat)',
		calculation: '',
		type: EMSFieldType.EMSTicketTotal,
		isRaw: false,
		costCalcDependent: true,
	},
	'ticketTotals.name': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.name',
		emsPublicIdentifier: 'ticketTotals.name',
		internalLabel: 'Ticket tier',
		internalDescription: 'The name for the ticket totals',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'ticketTotals.id': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.id',
		emsPublicIdentifier: 'ticketTotals.id',
		internalLabel: 'Ticket id',
		internalDescription: 'the id, null for total tickets',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'ticketTotals.groupKey': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.groupKey',
		emsPublicIdentifier: 'ticketTotals.groupKey',
		internalLabel: 'Ticket id',
		internalDescription: 'They key to group by in PDF docs; mostly for tours',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'ticketTotals.price': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.price',
		emsPublicIdentifier: 'ticketTotals.price',
		internalLabel: 'Ticket totals price',
		internalDescription: 'null for ticket totals',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'ticketTotals.allotment': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.allotment',
		emsPublicIdentifier: 'ticketTotals.allotment',
		internalLabel: 'Ticket totals allotment',
		internalDescription: 'The total allotment for the all tickets',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'ticketTotals.comps': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.comps',
		emsPublicIdentifier: 'ticketTotals.comps',
		internalLabel: 'Ticket totals comps',
		internalDescription: 'The total number of comped tickets alloted for all tickets',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'ticketTotals.kills': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.kills',
		emsPublicIdentifier: 'ticketTotals.kills',
		internalLabel: 'Ticket totals kills',
		internalDescription: 'The total number of killed tickets alloted for all tickets',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'ticketTotals.killsAverage': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.killsAverage',
		emsPublicIdentifier: 'ticketTotals.killsAverage',
		internalLabel: 'Total Ticket Tier average Kills',
		internalDescription: 'The avareage kills for all tickets',
		calculation: 'sum(tickets.kills) / tickets.length',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
	},
	'ticketTotals.sellable': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.sellable',
		emsPublicIdentifier: 'ticketTotals.sellable',
		internalLabel: 'Ticket totals Sellable',
		internalDescription: 'Total Number of alloted tickets remaining after comps and kills',
		calculation: 'allotment - comps - kills',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
	},
	'ticketTotals.sellableAverage': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.sellableAverage',
		emsPublicIdentifier: 'ticketTotals.sellableAverage',
		internalLabel: 'Total Ticket Tier average Sellable',
		internalDescription: 'The avareage sellable for all tickets',
		calculation: 'sum(tickets.sellable) / tickets.length',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
	},
	'ticketTotals.platformDiscounts': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.platformDiscounts',
		emsPublicIdentifier: 'ticketTotals.platformDiscounts',
		internalLabel: 'Total Ticket platform discount',
		internalDescription: 'The total tickets platform discount',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
	},
	'ticketTotals.platformFees': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.platformFees',
		emsPublicIdentifier: 'ticketTotals.platformFees',
		internalLabel: 'Total Platform Fees',
		internalDescription:
			'only relevant when tickets are linked to eventbrite. This changes how netTicketGross is calculated',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
	},
	'ticketTotals.platformGrossTicketPrice': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.platformGrossTicketPrice',
		emsPublicIdentifier: 'ticketTotals.platformGrossTicketPrice',
		internalLabel: 'Total Platform Gross Ticket Price',
		internalDescription: 'null for ticket totals',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		isNullable: true,
		costCalcDependent: true,
	},
	'ticketTotals.platformRebate': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.platformRebate',
		emsPublicIdentifier: 'ticketTotals.platformRebate',
		internalLabel: 'Total Ticket platform rebate',
		internalDescription: 'The total platform rebate for all tickets',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: true,
	},
	'ticketTotals.feeDeducted': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.feeDeducted',
		emsPublicIdentifier: 'ticketTotals.feeDeducted',
		internalLabel: 'Fee deducted?',
		internalDescription:
			'only relevant when tickets are linked to eventbrite. This changes how netTicketGross is calculated',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: false,
	},
	'ticketTotals.feesPerTicket': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.feesPerTicket',
		emsPublicIdentifier: 'ticketTotals.feesPerTicket',
		internalLabel: 'Total Fees for Ticket',
		internalDescription: 'All the fees for all ticket tier, averaged per-ticket',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'ticketTotals.ticketsSold': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.ticketsSold',
		emsPublicIdentifier: 'ticketTotals.ticketsSold',
		internalLabel: 'Total Tickets Sold',
		internalDescription: 'This  totalnumber depends on which CostCalc perspective is being viewed',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'ticketTotals.totalTicketFees': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.totalTicketFees',
		emsPublicIdentifier: 'ticketTotals.totalTicketFees',
		internalLabel: 'Ttoal Ticket Fees',
		internalDescription: 'total fees on all ticket',
		calculation: 'a sum of all fees that apply to this ticket',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'ticketTotals.ticketRevenue': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.ticketRevenue',
		emsPublicIdentifier: 'ticketTotals.ticketRevenue',
		internalLabel: 'Ttoal Ticket Revenue',
		internalDescription: 'Sum of ticketsSold * price on all tickets',
		calculation: 'ticketsSold * price',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'ticketTotals.netTicketGross': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.netTicketGross',
		emsPublicIdentifier: 'ticketTotals.netTicketGross',
		internalLabel: 'Total Net Ticket Revenue',
		internalDescription: `Sum of all net ticket gross on all tickets`,
		userFacingLabel: 'Ticket Net Gross',
		userFacingDescription: 'The total net gross across all ticket tiers',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'ticketTotals.netTicketPrice': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.netTicketPrice',
		emsPublicIdentifier: 'ticketTotals.netTicketPrice',
		internalLabel: 'Total Net Ticket Price',
		internalDescription: 'null for total tickets',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		isNullable: true,
		costCalcDependent: false,
	},
	'ticketTotals.adjustedTicketGross': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.adjustedTicketGross',
		emsPublicIdentifier: 'ticketTotals.adjustedTicketGross',
		internalLabel: 'Total Adjusted Ticket Revenue',
		internalDescription: `Sum of all ticket netGross minus ticket fees`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'ticketTotals.breakEven': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.breakEven',
		emsPublicIdentifier: 'ticketTotals.breakEven',
		internalLabel: 'Total Ticket break even',
		internalDescription: '...its complicated...',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'ticketTotals.estimatedSold': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.estimatedSold',
		emsPublicIdentifier: 'ticketTotals.estimatedSold',
		internalLabel: 'Total Ticket Estimated Sold',
		internalDescription: 'The total number of estimated tickets sold for all tickets',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'ticketTotals.totalTax': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.totalTax',
		emsPublicIdentifier: 'ticketTotals.totalTax',
		internalLabel: 'Total Ticket Total tax',
		internalDescription: 'The total tax for all tickets',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'ticketTotals.platformTaxPaid': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.platformTaxPaid',
		emsPublicIdentifier: 'ticketTotals.platformTaxPaid',
		internalLabel: 'Total Ticket Platform tax',
		internalDescription: 'null for ticket totals',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'ticketTotals.platformIdString': {
		isSMSOnly: false,
		emsMetadataId: 'ticketTotals.platformIdString',
		emsPublicIdentifier: 'ticketTotals.platformIdString',
		internalLabel: 'Total Ticket Platform id',
		internalDescription: 'empty for total tickets',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		isNullable: true,
		costCalcDependent: true,
	},
	// #endregion ticketTotals
	// #region flatTicketRevenue.*
	'flatTicketRevenue.*': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*',
		emsPublicIdentifier: 'flatTicketRevenue.*',
		internalLabel: 'All ticket tiers for the event',
		internalDescription: 'A list of EMSTickets, one for each ticket in the revenue table',
		calculation: '',
		type: EMSFieldType.EMSTicketArray,
		isRaw: true,
		costCalcDependent: true,
	},
	'flatTicketRevenue.*.name': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.name',
		emsPublicIdentifier: 'flatTicketRevenue.*.name',
		internalLabel: 'Flat Ticket tier',
		internalDescription: 'The name for the flat ticket tier, entered by the user on the revenue table',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'flatTicketRevenue.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.id',
		emsPublicIdentifier: 'flatTicketRevenue.*.id',
		internalLabel: 'Flat Ticket id',
		internalDescription: 'the id for the flat ticket in our database. isNullable for TMS',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		// only on tours
		isNullable: true,
		costCalcDependent: false,
	},
	'flatTicketRevenue.*.order': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.order',
		emsPublicIdentifier: 'flatTicketRevenue.*.order',
		internalLabel: 'Flat Ticket order',
		internalDescription: 'the flat tickets index in the array',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		isNullable: false,
		costCalcDependent: false,
	},
	'flatTicketRevenue.*.groupKey': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.groupKey',
		emsPublicIdentifier: 'flatTicketRevenue.*.groupKey',
		internalLabel: 'Flat Ticket id',
		internalDescription: 'They key to group by in PDF docs; mostly for tours',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'flatTicketRevenue.*.price': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.price',
		emsPublicIdentifier: 'flatTicketRevenue.*.price',
		internalLabel: 'Individual flat ticket price',
		internalDescription: 'The price for flat tickets in that tier, entered by the user on the revenue table',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
	},
	'flatTicketRevenue.*.allotment': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.allotment',
		emsPublicIdentifier: 'flatTicketRevenue.*.allotment',
		internalLabel: 'Individual flat ticket allotment',
		internalDescription: 'Individual flat ticket allotment, input by the user on the Tickets table',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'flatTicketRevenue.*.comps': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.comps',
		emsPublicIdentifier: 'flatTicketRevenue.*.comps',
		internalLabel: 'Individual flat ticket comps',
		internalDescription:
			'The number of comped flat tickets alloted for the tier, entered by the user on the Tickets table',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'flatTicketRevenue.*.kills': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.kills',
		emsPublicIdentifier: 'flatTicketRevenue.*.kills',
		internalLabel: 'Individual flat ticket kills',
		internalDescription:
			'The number of killed flat tickets alloted for the tier, entered by the user on the Tickets table',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'flatTicketRevenue.*.killsAverage': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.killsAverage',
		emsPublicIdentifier: 'flatTicketRevenue.*.killsAverage',
		internalLabel: 'Ticket Tier average Kills',
		internalDescription: 'The avareage kills for a flat ticket tier',
		calculation: 'sum(flatTicketRevenue.kills) / flatTicketRevenue.length',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'flatTicketRevenue.*.sellable': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.sellable',
		emsPublicIdentifier: 'flatTicketRevenue.*.sellable',
		internalLabel: 'Sellable',
		internalDescription: 'Number of alloted flat tickets remaining after comps and kills',
		calculation: 'allotment - comps - kills',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
	},
	'flatTicketRevenue.*.sellableAverage': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.sellableAverage',
		emsPublicIdentifier: 'flatTicketRevenue.*.sellableAverage',
		internalLabel: 'Ticket Tier average Sellable',
		internalDescription: 'The avareage sellable for a flat ticket tier',
		calculation: 'sum(flatTicketRevenue.sellable) / flatTicketRevenue.length',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'flatTicketRevenue.*.platformDiscounts': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.platformDiscounts',
		emsPublicIdentifier: 'flatTicketRevenue.*.platformDiscounts',
		internalLabel: 'Ticket platform discount',
		internalDescription: 'The flat ticket platform discount',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
	},
	'flatTicketRevenue.*.platformFees': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.platformFees',
		emsPublicIdentifier: 'flatTicketRevenue.*.platformFees',
		internalLabel: 'Platform Fees',
		internalDescription:
			'only relevant when flat tickets are linked to eventbrite. This changes how netTicketGross is calculated',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
	},
	'flatTicketRevenue.*.platformGrossTicketPrice': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.platformGrossTicketPrice',
		emsPublicIdentifier: 'flatTicketRevenue.*.platformGrossTicketPrice',
		internalLabel: 'Platform Gross Ticket Price',
		internalDescription:
			'Total platform revenue (platform total + tax), divided total sold; 0 if non-platform flat ticket',
		calculation: 'ticket.totalRevenue(costCalc) / ticket.ticketsSold(costCalc)',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'flatTicketRevenue.*.platformRebate': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.platformRebate',
		emsPublicIdentifier: 'flatTicketRevenue.*.platformRebate',
		internalLabel: 'Ticket platform rebate',
		internalDescription: 'The flat ticket platform rebate',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: true,
	},
	'flatTicketRevenue.*.feeDeducted': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.feeDeducted',
		emsPublicIdentifier: 'flatTicketRevenue.*.feeDeducted',
		internalLabel: 'Fee deducted?',
		internalDescription:
			'only relevant when flat tickets are linked to eventbrite. This changes how netTicketGross is calculated',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: false,
	},
	'flatTicketRevenue.*.feesPerTicket': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.feesPerTicket',
		emsPublicIdentifier: 'flatTicketRevenue.*.feesPerTicket',
		internalLabel: 'Fees Per Ticket',
		internalDescription: 'All the fees for this flat ticket tier, averaged per-ticket',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'flatTicketRevenue.*.ticketsSold': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.ticketsSold',
		emsPublicIdentifier: 'flatTicketRevenue.*.ticketsSold',
		internalLabel: 'Tickets Sold',
		internalDescription: 'This number depends on which CostCalc perspective is being viewed',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'flatTicketRevenue.*.totalTicketFees': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.totalTicketFees',
		emsPublicIdentifier: 'flatTicketRevenue.*.totalTicketFees',
		internalLabel: 'Ticket Fees',
		internalDescription: 'total fees on the flat ticket',
		calculation: 'a sum of all fees that apply to this flat ticket',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'flatTicketRevenue.*.ticketRevenue': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.ticketRevenue',
		emsPublicIdentifier: 'flatTicketRevenue.*.ticketRevenue',
		internalLabel: 'Ticket Revenue',
		internalDescription: 'will always be ticketsSold * price',
		calculation: 'ticketsSold * price',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'flatTicketRevenue.*.netTicketGross': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.netTicketGross',
		emsPublicIdentifier: 'flatTicketRevenue.*.netTicketGross',
		internalLabel: 'Net Ticket Revenue',
		internalDescription: `adjusted gross minus tax`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'flatTicketRevenue.*.netTicketPrice': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.netTicketPrice',
		emsPublicIdentifier: 'flatTicketRevenue.*.netTicketPrice',
		internalLabel: 'Net Ticket Price',
		internalDescription: 'The net flat ticket price, price - average fees deducted',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: false,
	},
	'flatTicketRevenue.*.adjustedTicketGross': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.adjustedTicketGross',
		emsPublicIdentifier: 'flatTicketRevenue.*.adjustedTicketGross',
		internalLabel: 'Adjusted Ticket Revenue',
		internalDescription: `flat ticket netGross minus flat ticket fees`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'flatTicketRevenue.*.breakEven': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.breakEven',
		emsPublicIdentifier: 'flatTicketRevenue.*.breakEven',
		internalLabel: 'Ticket break even',
		internalDescription: '...its complicated...',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'flatTicketRevenue.*.estimatedSold': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.estimatedSold',
		emsPublicIdentifier: 'flatTicketRevenue.*.estimatedSold',
		internalLabel: 'Ticket Estimated Sold',
		internalDescription: 'The number of estimated flat tickets sold',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'flatTicketRevenue.*.totalTax': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.totalTax',
		emsPublicIdentifier: 'flatTicketRevenue.*.totalTax',
		internalLabel: 'Ticket Total tax',
		internalDescription: 'The tax for the flat ticket, using the tax rate for the event (and is not platform tax)',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'flatTicketRevenue.*.platformTaxPaid': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.platformTaxPaid',
		emsPublicIdentifier: 'flatTicketRevenue.*.platformTaxPaid',
		internalLabel: 'Ticket Platform tax',
		internalDescription: 'The platform tax for the flat ticket',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'flatTicketRevenue.*.platformIdString': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.platformIdString',
		emsPublicIdentifier: 'flatTicketRevenue.*.platformIdString',
		internalLabel: 'Ticket Platform ID',
		internalDescription: 'The platform id for the flat ticket',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		isNullable: true,
		costCalcDependent: true,
	},
	'flatTicketRevenue.*.isFlatTicketRevenue': {
		isSMSOnly: false,
		emsMetadataId: 'flatTicketRevenue.*.isFlatTicketRevenue',
		emsPublicIdentifier: 'flatTicketRevenue.*.isFlatTicketRevenue',
		internalLabel: 'Is Flat Revenue',
		internalDescription: 'True if this is an EMSTicket value for a flat (dynamic) ticket tier, false otherwise',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		isNullable: false,
		costCalcDependent: false,
	},
	// #endregion flatTicketRevenue.*
	// #region ticketCommissions.*
	'ticketCommissions.*': {
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissions.*',
		emsPublicIdentifier: 'ticketCommissions.*',
		internalLabel: 'All ticket commissions for the event',
		internalDescription:
			'A list of EMSTicketCommission, one for each ticket commission in the revenue tab. This list only will be populated if the event is part of a broadway tour AND the CostCalc is InternalActual or ExternalReported',
		calculation: '',
		type: EMSFieldType.EMSTicketArray,
		isRaw: true,
		costCalcDependent: true,
	},
	'ticketCommissions.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissions.*.id',
		emsPublicIdentifier: 'ticketCommissions.*.id',
		internalLabel: 'Ticket Commission id',
		internalDescription: 'the id for the ticket commission in our database. isNullable for TMS',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		// only on tours
		isNullable: true,
		costCalcDependent: true,
	},
	'ticketCommissions.*.name': {
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissions.*.name',
		emsPublicIdentifier: 'ticketCommissions.*.name',
		internalLabel: 'Ticket Commission Name',
		// eslint-disable-next-line no-template-curly-in-string
		internalDescription: 'a formatted string: `${category} (${commissionPercentage}%)`. e.g. "Box Office (7%)"',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		isNullable: true,
		costCalcDependent: true,
	},
	'ticketCommissions.*.category': {
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissions.*.category',
		emsPublicIdentifier: 'ticketCommissions.*.category',
		internalLabel: 'Ticket Commission Category',
		internalDescription: 'The ticket commission category',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: true,
	},
	'ticketCommissions.*.grossRevenue': {
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissions.*.grossRevenue',
		emsPublicIdentifier: 'ticketCommissions.*.grossRevenue',
		internalLabel: 'Ticket Commission Gross Revenue',
		internalDescription: 'The ticket commission gross revenue entered by the user',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: true,
	},
	'ticketCommissions.*.commissionPercentage': {
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissions.*.commissionPercentage',
		emsPublicIdentifier: 'ticketCommissions.*.commissionPercentage',
		internalLabel: 'Ticket Commission Percentage',
		internalDescription: 'The ticket commission percentage entered by the user',
		calculation: '',
		type: EMSFieldType.percentage,
		isRaw: true,
		costCalcDependent: true,
	},
	'ticketCommissions.*.tax': {
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissions.*.tax',
		emsPublicIdentifier: 'ticketCommissions.*.tax',
		internalLabel: 'Ticket Commission Tax',
		internalDescription: 'The ticket commission tax calculated',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'ticketCommissions.*.netGrossRevenue': {
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissions.*.netGrossRevenue',
		emsPublicIdentifier: 'ticketCommissions.*.netGrossRevenue',
		internalLabel: 'Ticket Commission Net Gross Revenue',
		internalDescription: 'The ticket commission net gross calculated',
		calculation: 'ticketCommissions.*.grossRevenue - ticketCommissions.*.tax',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'ticketCommissions.*.commission': {
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissions.*.commission',
		emsPublicIdentifier: 'ticketCommissions.*.commission',
		internalLabel: 'Ticket Commission Value',
		internalDescription: 'The ticket commission calculated',
		calculation: 'ticketCommissions.*.grossRevenue * ticketCommissions.*.commissionPercentage',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'ticketCommissions.*.groupKey': {
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissions.*.groupKey',
		emsPublicIdentifier: 'ticketCommissions.*.groupKey',
		internalLabel: 'Ticket Commission Group keys',
		internalDescription: 'They key to group by in PDF docs; only for tours',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: true,
	},
	// #endregion ticketCommissions.*
	// #region ticketCommissionTotals
	ticketCommissionTotals: {
		isObject: true,
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissionTotals',
		emsPublicIdentifier: 'ticketCommissionTotals',
		internalLabel: 'Ticket Commission Totals',
		internalDescription: 'The ticket commisions totals of the event',
		calculation: 'sum(ticketCommissions)',
		type: EMSFieldType.EMSTicketCommissionTotal,
		isRaw: false,
		costCalcDependent: true,
	},
	'ticketCommissionTotals.id': {
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissionTotals.id',
		emsPublicIdentifier: 'ticketCommissionTotals.id',
		internalLabel: 'Ticket Commission id',
		internalDescription: 'the id for the ticket commission in our database. isNullable for TMS',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		// only on tours
		isNullable: true,
		costCalcDependent: false,
	},
	'ticketCommissionTotals.name': {
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissionTotals.name',
		emsPublicIdentifier: 'ticketCommissionTotals.name',
		internalLabel: 'Ticket Totals Commission name',
		internalDescription: 'this is null for totals',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		// only on tours
		isNullable: true,
		costCalcDependent: true,
	},
	'ticketCommissionTotals.category': {
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissionTotals.category',
		emsPublicIdentifier: 'ticketCommissionTotals.category',
		internalLabel: 'Ticket Commission Totals Category',
		internalDescription: 'The ticket commission totals category',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		isNullable: true,
	},
	'ticketCommissionTotals.grossRevenue': {
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissionTotals.grossRevenue',
		emsPublicIdentifier: 'ticketCommissionTotals.grossRevenue',
		internalLabel: 'Ticket Commission Totals Gross Revenue',
		internalDescription: 'The ticket commission totals gross revenue',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: true,
	},
	'ticketCommissionTotals.commissionPercentage': {
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissionTotals.commissionPercentage',
		emsPublicIdentifier: 'ticketCommissionTotals.commissionPercentage',
		internalLabel: 'Ticket Commission Totals Percentage',
		internalDescription: 'The ticket commission percentage totals',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
		isNullable: true,
	},
	'ticketCommissionTotals.tax': {
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissionTotals.tax',
		emsPublicIdentifier: 'ticketCommissionTotals.tax',
		internalLabel: 'Ticket Commission Total Tax',
		internalDescription: 'The ticket commission total tax calculated',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'ticketCommissionTotals.netGrossRevenue': {
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissionTotals.netGrossRevenue',
		emsPublicIdentifier: 'ticketCommissionTotals.netGrossRevenue',
		internalLabel: 'Ticket Commission Total Net Gross Revenue',
		internalDescription: 'The ticket commission net gross calculated',
		calculation: 'ticketCommissionTotals.grossRevenue - ticketCommissionTotals.tax',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'ticketCommissionTotals.commission': {
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissionTotals.commission',
		emsPublicIdentifier: 'ticketCommissionTotals.commission',
		internalLabel: 'Ticket Commission Total Value',
		internalDescription: 'The ticket commission totals calculated',
		userFacingLabel: 'Total Commissions',
		calculation: 'ticketCommissionTotals.grossRevenue * ticketCommissionTotals.commissionPercentage',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'ticketCommissionTotals.groupKey': {
		isSMSOnly: false,
		emsMetadataId: 'ticketCommissionTotals.groupKey',
		emsPublicIdentifier: 'ticketCommissionTotals.groupKey',
		internalLabel: 'Ticket Commission Totals group key',
		internalDescription: 'They key to group by in PDF docs; only for tours',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		isNullable: true,
	},
	// #endregion ticketCommissionTotals
	nagbor: {
		isSMSOnly: false,
		emsMetadataId: 'nagbor',
		emsPublicIdentifier: 'nagbor',
		internalLabel: 'NAGBOR',
		internalDescription: 'The NAGBOR calculated',
		calculation: 'net revenue - commission',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: 'NAGBOR',
	},
	calculateTicketCommissionByNetGross: {
		isSMSOnly: false,
		emsMetadataId: 'calculateTicketCommissionByNetGross',
		emsPublicIdentifier: 'calculateTicketCommissionByNetGross',
		internalLabel: 'If the event is part of a tour, what value do we use for ticket commissions?',
		internalDescription: 'Only relevant when the event is part of a broadway tour.',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	isEventMemberOfBroadwayTour: {
		isSMSOnly: false,
		emsMetadataId: 'isEventMemberOfBroadwayTour',
		emsPublicIdentifier: 'isEventMemberOfBroadwayTour',
		internalLabel: "Flag to tell us if the event's parent tour is a broadway tour",
		internalDescription: "Is the event's tour a broadway tour?",
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	totalSoldForCommissions: {
		isSMSOnly: false,
		emsMetadataId: 'totalSoldForCommissions',
		emsPublicIdentifier: 'totalSoldForCommissions',
		internalLabel: 'Total Sold for ticket commissions',
		internalDescription: 'Total Sold for ticket commissions',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	totalCompsForCommissions: {
		isSMSOnly: false,
		emsMetadataId: 'totalCompsForCommissions',
		emsPublicIdentifier: 'totalCompsForCommissions',
		internalLabel: 'Total Comps for ticket commissions',
		internalDescription: 'Total Comps for ticket commissions',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	totalTicketsDistributed: {
		isSMSOnly: false,
		emsMetadataId: 'totalTicketsDistributed',
		emsPublicIdentifier: 'totalTicketsDistributed',
		internalLabel: 'Total Tickets Distributed for ticket commissions',
		internalDescription: 'Total Tickets Distributed for ticket commissions',
		calculation: 'totalSoldForCommissions + totalCompsForCommissions',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
		isTopLevel: true,
	},
	ticketScalingSummary: {
		isSMSOnly: false,
		emsMetadataId: 'ticketScalingSummary',
		emsPublicIdentifier: 'ticketScalingSummary',
		internalLabel: 'Ticket Scaling Summary',
		internalDescription: 'A summary of a tickets name, sellable, price, and currency for every ticket tier.',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: false,
		userFacingLabel: 'Ticket Scaling',
		userFacingDescription: 'A summary of a tickets name, sellable, price, and currency for every ticket tier.',
		category: 'Show Metrics',
	},
	venueName: {
		isSMSOnly: false,
		emsMetadataId: 'venueName',
		emsPublicIdentifier: 'venueName',
		internalLabel: 'Venue Name',
		internalDescription: 'The name of the venue for the event.',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'Venue',
		userFacingDescription: "The event's venue.",
		category: 'Event Details',
		isTopLevel: true,
	},
	venueAddress: {
		isSMSOnly: false,
		emsMetadataId: 'venueAddress',
		emsPublicIdentifier: 'venueAddress',
		internalLabel: 'Venue Address',
		internalDescription: 'The location of the venue for the event.',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'Venue Address',
		userFacingDescription: "The address of the event's venue.",
		category: 'Event Details',
		isTopLevel: true,
	},
	venueCity: {
		isSMSOnly: false,
		emsMetadataId: 'venueCity',
		emsPublicIdentifier: 'venueCity',
		internalLabel: 'Venue City',
		internalDescription: 'The city of the venue for the event.',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'Venue City',
		userFacingDescription: "The city of the event's venue.",
		category: 'Event Details',
		isTopLevel: true,
	},
	venueState: {
		isSMSOnly: false,
		emsMetadataId: 'venueState',
		emsPublicIdentifier: 'venueState',
		internalLabel: 'Venue State/Province',
		internalDescription: 'The state/province of the venue for the event.',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'Venue State/Province',
		userFacingDescription: "The state/province of the event's venue.",
		category: 'Event Details',
		isTopLevel: true,
	},
	// #region weightedTicketAverage
	weightedTicketAverage: {
		isObject: true,
		isSMSOnly: false,
		emsMetadataId: 'weightedTicketAverage',
		emsPublicIdentifier: 'weightedTicketAverage',
		internalLabel: 'Weighted Ticket Average',
		internalDescription: 'A weighted average of all tickets on an event, and a break even value',
		calculation: '',
		type: EMSFieldType.EMSTicketAggregate,
		isRaw: false,
		costCalcDependent: true,
	},
	'weightedTicketAverage.name': {
		isSMSOnly: false,
		emsMetadataId: 'weightedTicketAverage.name',
		emsPublicIdentifier: 'weightedTicketAverage.name',
		internalLabel: 'Weighted Ticket Average Name',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: false,
	},
	'weightedTicketAverage.price': {
		isSMSOnly: false,
		emsMetadataId: 'weightedTicketAverage.price',
		emsPublicIdentifier: 'weightedTicketAverage.price',
		internalLabel: 'Weighted Ticket Average Price',
		internalDescription: 'Weighted ticket price average.. always based on ticket allotment',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: false,
		userFacingLabel: 'Weighted Ticket Average',
		userFacingDescription: 'Weighted ticket price average based on allotment',
		category: 'Show Metrics',
	},
	'weightedTicketAverage.breakEvenValue': {
		isSMSOnly: false,
		emsMetadataId: 'weightedTicketAverage.breakEvenValue',
		emsPublicIdentifier: 'weightedTicketAverage.breakEvenValue',
		internalLabel: 'Weighted Ticket Average Price Break-even',
		internalDescription:
			'The number of tickets at the weighted average ticket price that need to be sold to break even',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		isNullable: true,
		costCalcDependent: true,
		userFacingLabel: 'Ticket Average Breakeven (Budgeted)',
		userFacingDescription: 'Budgeted internal breakeven based on the weighted ticket average',
		category: 'Show Metrics',
	},
	'weightedTicketAverage.totalTicketAllotment': {
		isSMSOnly: false,
		emsMetadataId: 'weightedTicketAverage.totalTicketAllotment',
		emsPublicIdentifier: 'weightedTicketAverage.totalTicketAllotment',
		internalLabel: 'Weighted Ticket Average Price Break-even',
		internalDescription:
			'The number of tickets at the weighted average ticket price that need to be sold to break even',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: 'Allotment',
		userFacingDescription: 'Total Allotment of tickets including comps and kills',
		category: 'Show Metrics',
	},
	'weightedTicketAverage.ticketsSold': {
		isSMSOnly: false,
		emsMetadataId: 'weightedTicketAverage.ticketsSold',
		emsPublicIdentifier: 'weightedTicketAverage.ticketsSold',
		internalLabel: 'Total Tickets Sold',
		internalDescription: 'The total number of ticket sales summed across all ticket tiers',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'weightedTicketAverage.ticketRevenue': {
		isSMSOnly: false,
		emsMetadataId: 'weightedTicketAverage.ticketRevenue',
		emsPublicIdentifier: 'weightedTicketAverage.ticketRevenue',
		internalLabel: 'Total Ticket Revenue',
		internalDescription: 'The total revenue summed across all ticket tiers',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'weightedTicketAverage.netTicketGross': {
		isSMSOnly: false,
		emsMetadataId: 'weightedTicketAverage.netTicketGross',
		emsPublicIdentifier: 'weightedTicketAverage.netTicketGross',
		internalLabel: 'Total Net Ticket Revenue',
		internalDescription: 'The total adjusted revenue summed across all ticket tiers',
		userFacingLabel: 'Ticket Net Gross',
		userFacingDescription: 'The total net gross across all ticket tiers',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'weightedTicketAverage.comps': {
		isSMSOnly: false,
		emsMetadataId: 'weightedTicketAverage.comps',
		emsPublicIdentifier: 'weightedTicketAverage.comps',
		internalLabel: 'Total comps',
		internalDescription: 'The total adjusted revenue summed across all ticket tiers',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
		userFacingLabel: 'Comps',
		userFacingDescription: 'Total Comps from tickets section',
		category: 'Show Metrics',
	},
	'weightedTicketAverage.kills': {
		isSMSOnly: false,
		emsMetadataId: 'weightedTicketAverage.kills',
		emsPublicIdentifier: 'weightedTicketAverage.kills',
		internalLabel: 'Total kills',
		internalDescription: 'The total adjusted revenue summed across all ticket tiers',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
		userFacingLabel: 'Kills',
		userFacingDescription: 'Total Kills from tickets section',
		category: 'Show Metrics',
	},
	'weightedTicketAverage.sellable': {
		isSMSOnly: false,
		emsMetadataId: 'weightedTicketAverage.sellable',
		emsPublicIdentifier: 'weightedTicketAverage.sellable',
		internalLabel: 'Total sellable',
		internalDescription: 'The total adjusted revenue summed across all ticket tiers',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
	},
	// #endregion weightedTicketAverage
	ticketsSold: {
		isSMSOnly: false,
		emsMetadataId: 'ticketsSold',
		emsPublicIdentifier: 'ticketsSold',
		internalLabel: 'Total tickets sold',
		internalDescription: {
			DEFAULT: 'sum of ticketsSold for all tickets',
			InternalEstimated: 'est_sold',
			InternalPotential: 'sellable',
			ExternalEstimated: 'est_sold',
			ExternalBudgeted: 'sellable',
			ExternalReported: 'sold',
			InternalActual: 'sold',
		},
		calculation: 'tickets.*.ticketsSold',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Tickets Sold',
			InternalEstimated: 'Tickets Estimated',
			ExternalReported: 'Tickets Sold',
		},
		userFacingDescription: {
			DEFAULT: 'Tickets Sold',
			InternalEstimated: 'The estimated number of sold tickets',
			ExternalReported: 'The actual number of sold tickets',
		},
		category: 'Show Metrics',
	},
	promoterProfitPct: {
		isSMSOnly: false,
		emsMetadataId: 'promoterProfitPct',
		emsPublicIdentifier: 'promoterProfitPct',
		internalLabel: 'Promoter Profit %',
		internalDescription: 'the promoter profit from the talent tab',
		calculation: '',
		type: EMSFieldType.percentage,
		isRaw: true,
		costCalcDependent: true,
		userFacingLabel: 'Promoter Profit (%)',
		userFacingDescription: {
			DEFAULT: 'A percentage of the sum of all expenses that is added to the split point',
			ExternalReported: 'A percentage of the sum of all external expenses that is added to the split point',
		},
		category: 'Event Details',
		isTopLevel: true,
	},
	promoterProfitAmount: {
		isSMSOnly: false,
		emsMetadataId: 'promoterProfitAmount',
		emsPublicIdentifier: 'promoterProfitAmount',
		internalLabel: 'Promoter Profit Amount',
		internalDescription: 'the promoter profit from the talent tab',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Promoter Profit ($)',
			ExternalBudgeted: 'Promoter Profit ($) (Offer)',
			ExternalReported: 'Promoter Profit ($) (Settlement)',
		},
		userFacingDescription: {
			DEFAULT: 'The dollar value of promoter profit contributing to split point',
			ExternalBudgeted: 'The maximum potential dollar value of promoter profit contributing to split point',
			ExternalReported: 'The actual dollar value of promoter profit contributing to split point',
		},
		category: 'Event Details',
	},
	gross: {
		isSMSOnly: false,
		emsMetadataId: 'gross',
		emsPublicIdentifier: 'gross',
		internalLabel: 'Gross (a.k.a. Ticket revenue)',
		internalDescription: {
			DEFAULT: 'sum of ticketRevenue over all tickets',
			InternalEstimated: 'est_sold * price',
			InternalPotential: 'sellable * price',
			ExternalEstimated: 'est_sold * price',
			ExternalBudgeted: 'sellable * price',
			ExternalReported: 'sold * price',
			InternalActual: 'sold * price',
		},
		calculation: ['tickets.*.ticketRevenue', 'tickets.*.ticketsSold * tickets.*.price'],
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Gross',
			InternalPotential: 'Gross Potential',
			InternalActual: 'Gross Actual',
			ExternalBudgeted: 'Gross Potential',
		},
		userFacingDescription: {
			DEFAULT: 'Sum of ticket sales',
			ExternalBudgeted: 'Maximum potential sum of ticket sales',
			ExternalReported: 'Actual sum of all ticket sales',
		},
		category: 'Revenue',
	},
	additionalSupport: {
		isSMSOnly: false,
		emsMetadataId: 'additionalSupport',
		emsPublicIdentifier: 'additionalSupport',
		internalLabel: 'Additional Support',
		internalDescription: {
			DEFAULT: 'manual entry on talent page',
			InternalEstimated: 'manual entry on talent page - budgeted_additional_support',
			InternalPotential: 'manual entry on talent page - budgeted_additional_support',
			ExternalEstimated: 'manual entry on talent page - budgeted_additional_support',
			ExternalBudgeted: 'manual entry on talent page - budgeted_additional_support',
			ExternalReported: 'manual entry on talent page - actual_additional_support',
			InternalActual: 'manual entry on talent page - actual_additional_support',
		},
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Additional Support',
			ExternalBudgeted: 'Additional Support (Offer)',
			ExternalReported: 'Additional Support (Settlement)',
		},
		userFacingDescription: {
			DEFAULT: 'Additional Support from Talent tab',
			ExternalBudgeted: 'Additional Support Budgeted from Talent tab',
			ExternalReported: 'Additional Support Actual from Talent tab',
		},
		category: 'Event Details',
	},
	additionalSupportHiddenFromCoPro: {
		isSMSOnly: false,
		emsMetadataId: 'additionalSupportHiddenFromCoPro',
		emsPublicIdentifier: 'additionalSupportHiddenFromCoPro',
		internalLabel: 'additional supports hidden from co pro',
		internalDescription: `true if additional support is hidden from the co pro page, false otherwise`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
	},
	totalAdditionalRevenue: {
		isSMSOnly: false,
		emsMetadataId: 'totalAdditionalRevenue',
		emsPublicIdentifier: 'totalAdditionalRevenue',
		internalLabel: 'Summed Additional Revenue',
		internalDescription: {
			DEFAULT: 'sum of additional revenue',
			InternalEstimated: `sum of additional revenue - Flat: flat fee
Per attendee: est amount * est. attendance
Per ticket: est. amount * est. sales`,
			InternalPotential: `sum of additional revenue - Flat: flat fee
Per attendee: est amount * est. attendance
Per ticket: est. amount * est. sales`,
			ExternalEstimated: `sum of additional revenue - always 0 because not reported externally`,
			ExternalBudgeted: `sum of additional revenue - always 0 because not reported externally`,
			ExternalReported: `sum of additional revenue - always 0 because not reported externally`,
			InternalActual: `sum of additional revenue - Flat: flat fee (actual)
Per attendee: amount*actual attendance
Per ticket: amount*sold`,
		},
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Internal Revenue',
			InternalEstimated: 'Internal Revenue (Estimated)',
			InternalActual: 'Internal Revenue (Actual)',
		},
		userFacingDescription: {
			DEFAULT: 'Additional Internal revenue; not included into artist/promoter-facing deal',
			InternalEstimated: 'Estimated additional internal revenue; not included in artist/promoter-facing deal',
			InternalActual: 'Actual additional internal revenue; not included in artist/promoter-facing deal',
		},
		category: 'Financial Summary',
	},
	totalAdditionalRevenueTowardsNetGross: {
		isSMSOnly: false,
		emsMetadataId: 'totalAdditionalRevenueTowardsNetGross',
		emsPublicIdentifier: 'totalAdditionalRevenueTowardsNetGross',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Additional Revenue Included in Net Gross',
			InternalEstimated: 'Additional Revenue Included in Net Gross (Estimated)',
			InternalActual: 'Additional Revenue Included in Net Gross (Actual)',
		},
		userFacingDescription: {
			DEFAULT: 'The sum of the values for all additional revenue items included in Net Gross',
			InternalEstimated: 'The sum of the estimated values for all additional revenue items included in Net Gross',
			InternalActual: 'The sum of the actual values for all additional revenue items included in Net Gross',
		},
		category: 'Financial Summary',
	},
	totalAdditionalRevenueForCoPro: {
		isSMSOnly: false,
		emsMetadataId: 'totalAdditionalRevenueForCoPro',
		emsPublicIdentifier: 'totalAdditionalRevenueForCoPro',
		internalLabel: 'Summed Additional Revenue for co pro',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	additionalRevenueAndRebate: {
		isSMSOnly: false,
		emsMetadataId: 'additionalRevenueAndRebate',
		emsPublicIdentifier: 'additionalRevenueAndRebate',
		internalLabel: 'Summed Additional Revenue plus rebates',
		internalDescription: 'sum of additional internal revenue and rebates, used in the internal budget summary',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Total Additional Revenue',
			InternalEstimated: 'Total Additional Revenue (Estimated)',
			InternalActual: 'Total Additional Revenue (Actual)',
		},
		userFacingDescription: {
			DEFAULT: 'Total Additional revenue and rebates',
			InternalEstimated: 'Estimated Total Additional revenue and ticketing integration rebates',
			InternalActual: 'Actual Total Additional revenue and ticketing integration rebates',
		},
		category: 'Financial Summary',
	},
	totalExternalTalentPayouts: {
		isSMSOnly: false,
		emsMetadataId: 'totalExternalTalentPayouts',
		emsPublicIdentifier: 'totalExternalTalentPayouts',
		internalLabel: 'Total External Talent Payouts',
		internalDescription: 'The external sum of all artist payouts, plus additional support',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	totalFees: {
		isSMSOnly: false,
		emsMetadataId: 'totalFees',
		emsPublicIdentifier: 'totalFees',
		internalLabel: 'Total Fees',
		internalDescription: 'The total of all fees on the event (ems.totalFees = ems.gross - ems.adjustedGross)',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	totalFlatTicketRevenue: {
		isSMSOnly: false,
		emsMetadataId: 'totalFlatTicketRevenue',
		emsPublicIdentifier: 'totalFlatTicketRevenue',
		internalLabel: 'Total flat tickets revenue',
		internalDescription: 'sum of ticketRevenue for all flat tickets',
		calculation: 'sum(flatTicketRevenue.kills) ',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Total Dynamic Ticketing Revenue',
			ExternalBudgeted: 'Total Dynamic Ticketing Revenue (Offer)',
			ExternalReported: 'Total Dynamic Ticketing Revenue (Settlement)',
		},
		userFacingDescription: {
			DEFAULT: 'The total revenue from Dynamic ticket tiers on the event',
			ExternalBudgeted: 'The total potential revenue from Dynamic ticket tiers on the event',
			ExternalReported: 'The Total actual revenue from Dynamic ticket tiers on the event',
		},
		category: 'Show Metrics',
	},
	totalInternalTalentPayouts: {
		isSMSOnly: false,
		emsMetadataId: 'totalInternalTalentPayouts',
		emsPublicIdentifier: 'totalInternalTalentPayouts',
		internalLabel: 'Total Internal Talent Payouts',
		internalDescription: 'The sum of all artist payouts, plus additional support',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Total Artist Payout',
			ExternalBudgeted: 'Total Artist Payout (Offer)',
			ExternalReported: 'Total Artist Payout (Settlement)',
		},
		userFacingDescription: {
			DEFAULT:
				'The amount an artist will be paid at sellout including support or (for agencies) the overridden amount',
			ExternalBudgeted:
				'The maximum potential amount an artist would be paid at sellout including support or (for agencies) the overridden amount',
			ExternalReported:
				'The actual amount an artist is owed including support or (for agencies) the overridden amount',
		},
		category: 'Event Details',
	},
	// #region additionalRevenue.*
	'additionalRevenue.*': {
		isSMSOnly: false,
		emsMetadataId: 'additionalRevenue.*',
		emsPublicIdentifier: 'additionalRevenue.*',
		internalLabel: 'Additional Revenue array',
		internalDescription: 'an array of additional internal revenue',
		calculation: '',
		type: EMSFieldType.AdditionalRevenueArray,
		isRaw: true,
		costCalcDependent: true,
		canArrayIndicesVary: true,
	},
	'additionalRevenue.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'additionalRevenue.*.id',
		emsPublicIdentifier: 'additionalRevenue.*.id',
		internalLabel: 'Additional Revenue id',
		internalDescription: 'id of additional internal revenue',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		costCalcDependent: false,
		canArrayIndicesVary: true,
	},
	'additionalRevenue.*.name': {
		isSMSOnly: false,
		emsMetadataId: 'additionalRevenue.*.name',
		emsPublicIdentifier: 'additionalRevenue.*.name',
		internalLabel: 'Additional Revenue name',
		internalDescription: 'name of additional internal revenue',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		canArrayIndicesVary: true,
	},
	'additionalRevenue.*.amount': {
		isSMSOnly: false,
		emsMetadataId: 'additionalRevenue.*.amount',
		emsPublicIdentifier: 'additionalRevenue.*.amount',
		internalLabel: 'Additional Revenue amount',
		calculation: '',
		internalDescription: {
			DEFAULT: 'amount of additional revenue',
			InternalEstimated: `amount of additional revenue - Flat: flat fee
Per attendee: est amount * est. attendance
Per ticket: est. amount * est. sales`,
			InternalPotential: `amount of additional revenue - Flat: flat fee
Per attendee: est amount * est. attendance
Per ticket: est. amount * est. sales`,
			ExternalEstimated: `amount of additional revenue - always 0 because not reported externally`,
			ExternalBudgeted: `amount of additional revenue - always 0 because not reported externally`,
			ExternalReported: `amount of additional revenue - always 0 because not reported externally`,
			InternalActual: `amount of additional revenue - Flat: flat fee (actual)
Per attendee: amount*actual attendance
Per ticket: amount*sold`,
		},
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'additionalRevenue.*.postfix': {
		isSMSOnly: false,
		emsMetadataId: 'additionalRevenue.*.postfix',
		emsPublicIdentifier: 'additionalRevenue.*.postfix',
		internalLabel: 'Additional Revenue postfix',
		internalDescription: 'The postfix for the additional revenue',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: true,
	},
	'additionalRevenue.*.includeInCoProSplit': {
		isSMSOnly: false,
		emsMetadataId: 'additionalRevenue.*.includeInCoProSplit',
		emsPublicIdentifier: 'additionalRevenue.*.includeInCoProSplit',
		internalLabel: 'Additional Revenue include in co pro',
		internalDescription: 'True if the additional revenue is included in co pro, false otherwise',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
		canArrayIndicesVary: true,
	},
	'additionalRevenue.*.includeInNetGross': {
		isSMSOnly: false,
		emsMetadataId: 'additionalRevenue.*.includeInNetGross',
		emsPublicIdentifier: 'additionalRevenue.*.includeInNetGross',
		internalLabel: 'Additional Revenue include in net gross',
		internalDescription: 'True if the additional revenue is included in net gross, false otherwise',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
		canArrayIndicesVary: true,
	},
	// #endregion additionalRevenue.*
	// #region additionalRevenueTowardsNetGross.*
	'additionalRevenueTowardsNetGross.*': {
		isSMSOnly: false,
		emsMetadataId: 'additionalRevenueTowardsNetGross.*',
		emsPublicIdentifier: 'additionalRevenueTowardsNetGross.*',
		internalLabel: 'Additional Revenue towards net gross array',
		internalDescription: 'an array of additional internal revenue with include in net gross',
		calculation: '',
		type: EMSFieldType.AdditionalRevenueArray,
		isRaw: true,
		costCalcDependent: true,
	},
	'additionalRevenueTowardsNetGross.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'additionalRevenueTowardsNetGross.*.id',
		emsPublicIdentifier: 'additionalRevenueTowardsNetGross.*.id',
		internalLabel: 'Additional Revenue id',
		internalDescription: 'id of additional revenue',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		costCalcDependent: false,
	},
	'additionalRevenueTowardsNetGross.*.name': {
		isSMSOnly: false,
		emsMetadataId: 'additionalRevenueTowardsNetGross.*.name',
		emsPublicIdentifier: 'additionalRevenueTowardsNetGross.*.name',
		internalLabel: 'Additional Revenue towards net gross name',
		internalDescription: 'name of additional internal revenue',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'additionalRevenueTowardsNetGross.*.amount': {
		isSMSOnly: false,
		emsMetadataId: 'additionalRevenueTowardsNetGross.*.amount',
		emsPublicIdentifier: 'additionalRevenueTowardsNetGross.*.amount',
		internalLabel: 'Additional Revenue towards net gross amount',
		calculation: '',
		internalDescription: {
			DEFAULT: 'amount of Additional Revenue towards net gross',
			InternalEstimated: `amount of Additional Revenue towards net gross - Flat: flat fee
Per attendee: est amount * est. attendance
Per ticket: est. amount * est. sales`,
			InternalPotential: `amount of Additional Revenue towards net gross - Flat: flat fee
Per attendee: est amount * est. attendance
Per ticket: est. amount * est. sales`,
			ExternalEstimated: `amount of Additional Revenue towards net gross - Flat: flat fee
Per attendee: est amount * est. attendance
Per ticket: est. amount * est. sales`,
			ExternalBudgeted: `amount of Additional Revenue towards net gross - Flat: flat fee
Per attendee: est amount * est. attendance
Per ticket: est. amount * est. sales`,
			ExternalReported: `amount of Additional Revenue towards net gross - Flat: flat fee (actual)
Per attendee: amount*actual attendance
Per ticket: amount*sold`,
			InternalActual: `amount of Additional Revenue towards net gross - Flat: flat fee (actual)
Per attendee: amount*actual attendance
Per ticket: amount*sold`,
		},
		userFacingLabel: 'Additional Revenue Included in Net Gross',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'additionalRevenueTowardsNetGross.*.postfix': {
		isSMSOnly: false,
		emsMetadataId: 'additionalRevenueTowardsNetGross.*.postfix',
		emsPublicIdentifier: 'additionalRevenueTowardsNetGross.*.postfix',
		internalLabel: 'Additional Revenue towards net gross postfix',
		internalDescription: 'The postfix for the Additional Revenue towards net gross',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: true,
	},
	'additionalRevenueTowardsNetGross.*.includeInCoProSplit': {
		isSMSOnly: false,
		emsMetadataId: 'additionalRevenueTowardsNetGross.*.includeInCoProSplit',
		emsPublicIdentifier: 'additionalRevenueTowardsNetGross.*.includeInCoProSplit',
		internalLabel: 'Additional Revenue towards net gross include in co pro',
		internalDescription: 'True if the Additional Revenue towards net gross is included in co pro, false otherwise',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: true,
	},
	'additionalRevenueTowardsNetGross.*.includeInNetGross': {
		isSMSOnly: false,
		emsMetadataId: 'additionalRevenueTowardsNetGross.*.includeInNetGross',
		emsPublicIdentifier: 'additionalRevenueTowardsNetGross.*.includeInNetGross',
		internalLabel: 'Additional Revenue towards net gross include in net gross',
		internalDescription:
			'True if the Additional Revenue towards net gross is included in net gross, false otherwise',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: true,
	},
	// #endregion additionalRevenueTowardsNetGross.*
	totalInternalRevenue: {
		isSMSOnly: false,
		emsMetadataId: 'totalInternalRevenue',
		emsPublicIdentifier: 'totalInternalRevenue',
		internalLabel: 'Total Internal Revenue',
		internalDescription: 'gross + additionalRevenue',
		calculation: 'gross + additionalRevenue',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Total Revenue',
			InternalActual: 'Total Revenue (Internal)',
			InternalEstimated: 'Total Gross Revenue (Estimate)',
		},
		userFacingDescription: {
			DEFAULT: 'Total Revenue',
			InternalActual: 'Total gross ticket revenue and additional internal revenue',
			InternalEstimated: 'Estimated total gross ticket revenue and estimated additional internal revenue',
		},
		category: 'Financial Summary',
	},
	totalAdjustedInternalRevenue: {
		isSMSOnly: false,
		emsMetadataId: 'totalAdjustedInternalRevenue',
		emsPublicIdentifier: 'totalAdjustedInternalRevenue',
		internalLabel: 'Total Adjusted Internal Revenue',
		internalDescription: 'netGross + additionalRevenue',
		calculation: 'netGross + additionalRevenue',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	preSettlementFees: {
		isSMSOnly: false,
		emsMetadataId: 'preSettlementFees',
		emsPublicIdentifier: 'preSettlementFees',
		internalLabel: 'Event Total Presettlement Fees',
		internalDescription: 'sum of pre-settlement ticketing fees in the event settings tab',
		calculation: 'tickets.*.presettlementFee',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Pre-settlement Fees',
			ExternalBudgeted: 'Pre-settlement Fees (Offer)',
			ExternalReported: 'Pre-settlement Fees (Settlement)',
		},
		userFacingDescription: {
			DEFAULT: `Total pre-settlement ticketing fees that are removed prior to calculating the ${resolveLabel(
				NET_GROSS_FIELD_METADATA
			)}`,
			ExternalBudgeted: `Total estimated pre-settlement ticketing fees that are removed prior to calculating the ${resolveLabel(
				NET_GROSS_FIELD_METADATA,
				CostCalc2.ExternalBudgeted
			)}`,
			ExternalReported: `Total actual pre-settlement ticketing fees that are removed prior to calculating the ${resolveLabel(
				NET_GROSS_FIELD_METADATA,
				CostCalc2.ExternalReported
			)}`,
		},
		category: 'Revenue',
	},
	eventStatus: {
		isSMSOnly: false,
		emsMetadataId: 'eventStatus',
		emsPublicIdentifier: 'eventStatus',
		internalLabel: 'Status of the Event (raw)',
		internalDescription: 'The raw DB value of events status, Hold => Settled',
		calculation: 'Not calculated, this status is set manually by the user in the event header.',
		type: EMSFieldType.EventStatus,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
		isEventStatusDependent: true,
	},
	eventStatusString: {
		isSMSOnly: false,
		emsMetadataId: 'eventStatusString',
		emsPublicIdentifier: 'eventStatusString',
		internalLabel: 'Status of the Event',
		internalDescription: 'The events status, Hold => Settled',
		calculation: 'Not calculated, this status is set manually by the user in the event header.',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'Event Status',
		userFacingDescription: 'The status of the event',
		category: 'Event Details',
		isTopLevel: true,
		isEventStatusDependent: true,
	},
	// #region agentPayoutTotals.*
	'agentPayoutTotals.*.agentPayouts.*': {
		isSMSOnly: false,
		emsMetadataId: 'agentPayoutTotals.*.agentPayouts.*',
		emsPublicIdentifier: 'agentPayoutTotals.*.agentPayouts.*',
		internalLabel: 'Agent Payouts',
		internalDescription: 'A list of payouts to agents, unique for a given agent',
		calculation: '',
		type: EMSFieldType.EMSAgentsArray,
		isRaw: true,
		costCalcDependent: true,
	},
	'agentPayoutTotals.*.agentPayouts.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'agentPayoutTotals.*.agentPayouts.*.id',
		emsPublicIdentifier: 'agentPayoutTotals.*.agentPayouts.*.id',
		internalLabel: 'Agent Payouts',
		internalDescription: 'A list of payouts to agents, unique for a given agent',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		// only on tours???
		isNullable: true,
		costCalcDependent: false,
	},
	'agentPayoutTotals.*.agentPayouts.*.talentPayoutIndex': {
		isSMSOnly: false,
		emsMetadataId: 'agentPayoutTotals.*.agentPayouts.*.talentPayoutIndex',
		emsPublicIdentifier: 'agentPayoutTotals.*.agentPayouts.*.talentPayoutIndex',
		internalLabel: 'Agent Payouts',
		internalDescription: 'A list of payouts to agents, unique for a given agent',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'agentPayoutTotals.*.agentPayouts.*.talentPayoutName': {
		isSMSOnly: false,
		emsMetadataId: 'agentPayoutTotals.*.agentPayouts.*.talentPayoutName',
		emsPublicIdentifier: 'agentPayoutTotals.*.agentPayouts.*.talentPayoutName',
		internalLabel: 'Agent Payouts',
		internalDescription: 'A list of payouts to agents, unique for a given agent',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'agentPayoutTotals.*.agentPayouts.*.agentId': {
		isSMSOnly: false,
		emsMetadataId: 'agentPayoutTotals.*.agentPayouts.*.agentId',
		emsPublicIdentifier: 'agentPayoutTotals.*.agentPayouts.*.agentId',
		internalLabel: 'Agent Payout Agent Id',
		internalDescription: 'The ID of the agent for a given agent payout',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'agentPayoutTotals.*.agentPayouts.*.agentName': {
		isSMSOnly: false,
		emsMetadataId: 'agentPayoutTotals.*.agentPayouts.*.agentName',
		emsPublicIdentifier: 'agentPayoutTotals.*.agentPayouts.*.agentName',
		internalLabel: 'Agent Payout Agent Name',
		internalDescription: 'The name of the agent for a given agent payout',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'agentPayoutTotals.*.agentPayouts.*.agentCommissionPercent': {
		isSMSOnly: false,
		emsMetadataId: 'agentPayoutTotals.*.agentPayouts.*.agentCommissionPercent',
		emsPublicIdentifier: 'agentPayoutTotals.*.agentPayouts.*.agentCommissionPercent',
		internalLabel: 'Agent Payout Commissin Percent',
		internalDescription: 'The % of the artist payout that will be used to compute agentCommissionPayout',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'agentPayoutTotals.*.agentPayouts.*.contributingArtistPayoutTowardAgentPayout': {
		isSMSOnly: false,
		emsMetadataId: 'agentPayoutTotals.*.agentPayouts.*.contributingArtistPayoutTowardAgentPayout',
		emsPublicIdentifier: 'agentPayoutTotals.*.agentPayouts.*.contributingArtistPayoutTowardAgentPayout',
		internalLabel: 'Artist Payout Toward Agent Payout',
		internalDescription: 'Sum of artist payouts that contribute to this agentPayout',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'agentPayoutTotals.*.agentPayouts.*.agentCommissionPayout': {
		isSMSOnly: false,
		emsMetadataId: 'agentPayoutTotals.*.agentPayouts.*.agentCommissionPayout',
		emsPublicIdentifier: 'agentPayoutTotals.*.agentPayouts.*.agentCommissionPayout',
		internalLabel: 'Artist Payout Toward Agent Payout',
		internalDescription: 'Sum of artist payouts that contribute to this agentPayout',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'agentPayoutTotals.*.agentPayouts.*.talentPayoutId': {
		isSMSOnly: false,
		emsMetadataId: 'agentPayoutTotals.*.agentPayouts.*.talentPayoutId',
		emsPublicIdentifier: 'agentPayoutTotals.*.agentPayouts.*.talentPayoutId',
		internalLabel: 'Artist Payout Toward Agent Payout',
		internalDescription: 'Sum of artist payouts that contribute to this agentPayout',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		// only on tours???
		isNullable: true,
		costCalcDependent: false,
	},
	// #endregion agentPayoutTotals.*
	facilityFees: {
		isSMSOnly: false,
		emsMetadataId: 'facilityFees',
		emsPublicIdentifier: 'facilityFees',
		internalLabel: 'Total facility fees for the event',
		internalDescription: 'perTicketFacilityFee * ticketsSold',
		calculation: 'perTicketFacilityFee * ticketsSold',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Facility Fee',
			ExternalBudgeted: 'Facility Fee (Offer)',
			ExternalReported: 'Facility Fee (Settlement)',
		},
		userFacingDescription: {
			DEFAULT: `Total facility fees; not included in maximum ${resolveLabel(NET_GROSS_FIELD_METADATA)}`,
			ExternalBudgeted: `Total potential facility fees; not included in maximum ${resolveLabel(
				NET_GROSS_FIELD_METADATA,
				CostCalc2.ExternalBudgeted
			)}`,
			ExternalReported: `Total actual facility fees; not included in ${resolveLabel(
				NET_GROSS_FIELD_METADATA,
				CostCalc2.ExternalReported
			)}`,
		},
		category: 'Revenue',
	},
	firstHeadlinerDealTransliteration: {
		isSMSOnly: false,
		emsMetadataId: 'firstHeadlinerDealTransliteration',
		emsPublicIdentifier: 'firstHeadlinerDealTransliteration',
		internalLabel: 'Headliner Deal Info',
		internalDescription: 'Deal description for the first headliner on the event',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: 'Headliner Deal',
		userFacingDescription: 'Summary of the first headliner deal on the talent deals if applicable.',
		category: 'Event Details',
	},
	taxableGross: {
		isSMSOnly: false,
		emsMetadataId: 'taxableGross',
		emsPublicIdentifier: 'taxableGross',
		internalLabel: 'Taxable Gross',
		internalDescription: 'gross - (facility fees + preSettlementFees)',
		calculation: 'gross - (facilityFees + preSettlementFees)',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	netGross: NET_GROSS_FIELD_METADATA,
	adjustedGross: {
		isSMSOnly: false,
		emsMetadataId: 'adjustedGross',
		emsPublicIdentifier: 'adjustedGross',
		internalLabel: "Event's Adjusted Gross",
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Adjusted Gross',
			InternalPotential: 'Adjusted Gross Potential',
			InternalActual: 'Adjusted Gross Actual',
			ExternalBudgeted: 'Adjusted Gross Potential',
		},
		userFacingDescription: {
			DEFAULT: 'Equals ticketing gross less facility fees and pre-settlement fees',
			ExternalBudgeted: 'Equals maximum potential ticketing gross less facility fees and pre-settlement fees',
			ExternalReported: 'Equals total actual ticketing gross less facility fees and pre-settlement fees',
		},
		category: 'Financial Summary',
	},
	taxOwed: {
		isSMSOnly: false,
		emsMetadataId: 'taxOwed',
		emsPublicIdentifier: 'taxOwed',
		internalLabel: 'Tax Owed',
		internalDescription: 'taxableGross - ( taxableGross * withoutTaxMultiplier )',
		calculation: 'taxableGross - ( taxableGross * withoutTaxMultiplier )',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Tax Owed',
			ExternalBudgeted: 'Tax Owed (Offer)',
			ExternalReported: 'Tax Owed (Settlement)',
		},
		userFacingDescription: {
			DEFAULT: 'The sales taxes owed from tickets',
			ExternalBudgeted: 'The maximum potential sales taxes owed from tickets',
			ExternalReported: 'The actual sales taxes owed from tickets',
		},
		category: 'Revenue',
	},
	totalTaxesAndFees: {
		isSMSOnly: false,
		emsMetadataId: 'totalTaxesAndFees',
		emsPublicIdentifier: 'totalTaxesAndFees',
		internalLabel: 'Total taxes and fees',
		internalDescription: 'facilityFees + preSettlementFees + taxOwed',
		calculation: 'facilityFees + preSettlementFees + taxOwed',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Taxes and Fees',
			ExternalBudgeted: 'Taxes and Fees (Offer)',
			ExternalReported: 'Taxes and Fees (Settlement)',
		},
		userFacingDescription: {
			DEFAULT: 'The sum of taxes, facility fees, and pre-settlement fees ',
			ExternalBudgeted: 'The maximum potential sum of taxes, facility fees, and pre-settlement fees ',
			ExternalReported: 'The actual sum of taxes, facility fees, and pre-settlement fees',
		},
		category: 'Revenue',
	},
	// #region fixedCosts.*
	'fixedCosts.*': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*',
		emsPublicIdentifier: 'fixedCosts.*',
		internalLabel: 'Fixed Costs',
		internalDescription: 'List of all fixed costs for the event',
		calculation: '',
		type: EMSFieldType.EMSFixedCostArray,
		isRaw: false,
		costCalcDependent: true,
	},
	'fixedCosts.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.id',
		emsPublicIdentifier: 'fixedCosts.*.id',
		internalLabel: 'Fixed Costs Id',
		internalDescription: 'ID of the fixed cost group',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		costCalcDependent: false,
		// only on tours
		isNullable: true,
	},
	'fixedCosts.*.costsAmount': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.costsAmount',
		emsPublicIdentifier: 'fixedCosts.*.costsAmount',
		internalLabel: 'Costs Amount Total',
		internalDescription: 'Cost amount total for costs in group',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'fixedCosts.*.costsComputedTotal': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.costsComputedTotal',
		emsPublicIdentifier: 'fixedCosts.*.costsComputedTotal',
		internalLabel: 'Fixed Cost Group Total',
		internalDescription: {
			DEFAULT: 'The total for all of the costs in this group',
			InternalEstimated:
				'he total for all of the costs in this group - sum of all cost.estimated_cost (manually entered on the costs table)',
			InternalPotential:
				'he total for all of the costs in this group - sum of all cost.estimated_cost (manually entered on the costs table)',
			ExternalEstimated:
				'sum of all cost.total. ALWAYS EQUAL TO the ExternalBudgeted value. You would *think* we use cost.estimated_cost here, but for fixedCosts, the estimated_cost value is internal only.',
			ExternalBudgeted: 'he total for all of the costs in this group - sum of all cost.total',
			ExternalReported: 'he total for all of the costs in this group - sum of all cost.reported_cost',
			InternalActual: 'he total for all of the costs in this group - sum of all cost.totalActual',
		},
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'fixedCosts.*.reportedCostsComputedTotal': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.reportedCostsComputedTotal',
		emsPublicIdentifier: 'fixedCosts.*.reportedCostsComputedTotal',
		internalLabel: 'Fixed Cost Group Total',
		internalDescription: {
			DEFAULT: 'The total for all of the costs in this group',
			InternalEstimated:
				'he total for all of the costs in this group - sum of all cost.estimated_cost (manually entered on the costs table)',
			InternalPotential:
				'he total for all of the costs in this group - sum of all cost.estimated_cost (manually entered on the costs table)',
			ExternalEstimated:
				'sum of all cost.total. ALWAYS EQUAL TO the ExternalBudgeted value. You would *think* we use cost.estimated_cost here, but for fixedCosts, the estimated_cost value is internal only.',
			ExternalBudgeted: 'he total for all of the costs in this group - sum of all cost.total',
			ExternalReported: 'he total for all of the costs in this group - sum of all cost.reported_cost',
			InternalActual: 'he total for all of the costs in this group - sum of all cost.totalActual',
		},
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'fixedCosts.*.costsDifference': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.costsDifference',
		emsPublicIdentifier: 'fixedCosts.*.costsDifference',
		internalLabel: 'Costs Difference Total',
		internalDescription: 'Differnece total for costs in group',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'fixedCosts.*.costsOverUnderReportedTotal': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.costsOverUnderReportedTotal',
		emsPublicIdentifier: 'fixedCosts.*.costsOverUnderReportedTotal',
		internalLabel: 'Over Under Reported Total',
		internalDescription: 'Over Under Reported total for costs in group',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'fixedCosts.*.reportedCostsOverUnderReportedTotal': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.reportedCostsOverUnderReportedTotal',
		emsPublicIdentifier: 'fixedCosts.*.reportedCostsOverUnderReportedTotal',
		internalLabel: 'Over Under Reported Total',
		internalDescription: 'Over Under Reported total for costs in group',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'fixedCosts.*.hasSomeCostReported': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.hasSomeCostReported',
		emsPublicIdentifier: 'fixedCosts.*.hasSomeCostReported',
		internalLabel: 'Costs are Reported',
		internalDescription: 'If at least one cost in group is reported',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: true,
	},
	'fixedCosts.*.groupCategory': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.groupCategory',
		emsPublicIdentifier: 'fixedCosts.*.groupCategory',
		internalLabel: 'Fixed Cost Group Category',
		internalDescription:
			'The category for all of the costs in this group (selected manually on the Fixed Costs table)',
		calculation: '',
		type: EMSFieldType.CostGroupCategory,
		isRaw: true,
		costCalcDependent: true,
	},
	'fixedCosts.*.groupType': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.groupType',
		emsPublicIdentifier: 'fixedCosts.*.groupType',
		internalLabel: 'Fixed Cost Group Type',
		internalDescription: 'The type for all of the costs in this group (selected manually on the Fixed Costs table)',
		calculation: '',
		type: EMSFieldType.CostGroupType,
		isNullable: true,
		isRaw: true,
		costCalcDependent: true,
	},
	// #region fixedCosts.*.costs.*
	'fixedCosts.*.costs.*': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.costs.*',
		emsPublicIdentifier: 'fixedCosts.*.costs.*',
		internalLabel: 'Costs',
		internalDescription: 'All costs associated with a given fixed cost',
		calculation: '',
		type: EMSFieldType.EMSFixedCostArray,
		isRaw: false,
		costCalcDependent: true,
	},
	// EMSCost Props
	'fixedCosts.*.costs.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.costs.*.id',
		emsPublicIdentifier: 'fixedCosts.*.costs.*.id',
		internalLabel: 'Fixed costs id',
		internalDescription: `a id for the particular fixed cost `,
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: true,
	},
	'fixedCosts.*.costs.*.name': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.costs.*.name',
		emsPublicIdentifier: 'fixedCosts.*.costs.*.name',
		internalLabel: 'Name',
		internalDescription: 'Name input by user on the cost table',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: true,
	},
	'fixedCosts.*.costs.*.coProOverriddenAmount': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.costs.*.coProOverriddenAmount',
		emsPublicIdentifier: 'fixedCosts.*.costs.*.coProOverriddenAmount',
		internalLabel: 'fixed costs amount overriden for co pro',
		internalDescription: 'The overriden value for this fixed cost in coPro deal',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: true,
		isNullable: true,
	},
	'fixedCosts.*.costs.*.overUnderReported': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.costs.*.overUnderReported',
		emsPublicIdentifier: 'fixedCosts.*.costs.*.overUnderReported',
		internalLabel: 'Over Under Reported',
		internalDescription: 'The Over Under Reported amount for the cost',
		calculation: 'reportedCost - total',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'fixedCosts.*.costs.*.quantity': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.costs.*.quantity',
		emsPublicIdentifier: 'fixedCosts.*.costs.*.quantity',
		internalLabel: 'Quantity',
		internalDescription: 'The quantity for the cost',
		userFacingDescription: 'The quantity for the cost',
		userFacingLabel: 'Quantity',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: true,
	},
	'fixedCosts.*.costs.*.costAmount': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.costs.*.costAmount',
		emsPublicIdentifier: 'fixedCosts.*.costs.*.costAmount',
		internalLabel: 'Cost',
		internalDescription: 'The cost amount value for this cost',
		userFacingDescription: 'The cost amount value for this cost',
		userFacingLabel: 'Cost',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'fixedCosts.*.costs.*.total': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.costs.*.total',
		emsPublicIdentifier: 'fixedCosts.*.costs.*.total',
		internalLabel: 'Cost Calc Total',
		internalDescription: 'The total cost for a given cost, relative to the current cost calc',
		userFacingLabel: {
			DEFAULT: 'Cost',
			ExternalBudgeted: 'Offer budget',
		},
		userFacingDescription: {
			DEFAULT: 'The cost amount total value for this cost',
			ExternalBudgeted: 'The budgeted total value for this cost',
		},
		calculation: 'quantity * costAmount',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'fixedCosts.*.costs.*.computedTotal': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.costs.*.computedTotal',
		emsPublicIdentifier: 'fixedCosts.*.costs.*.computedTotal',
		internalLabel: 'Cost Calc Total',
		internalDescription: 'The total cost for a given cost, relative to the current cost calc',
		userFacingLabel: {
			DEFAULT: 'Cost',
			InternalActual: 'Actual Internal',
			ExternalReported: 'Settlement',
			InternalEstimated: 'Estimated Internal',
			ExternalBudgeted: 'Offer budget',
		},
		userFacingDescription: {
			DEFAULT: 'The cost amount total value for this cost',
			InternalActual: 'The actual reported value for this cost',
			ExternalReported: 'The reported value for this cost',
			InternalEstimated: 'The estimated value for this cost',
			ExternalBudgeted: 'The budgeted value for this cost',
		},
		calculation: 'quantity * costAmount',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'fixedCosts.*.costs.*.hiddenFromCoPro': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.costs.*.hiddenFromCoPro',
		emsPublicIdentifier: 'fixedCosts.*.costs.*.hiddenFromCoPro',
		internalLabel: 'Fixed costs hidden from co pro',
		internalDescription: `true if this fixed cost is hidden from the co pro page, false otherwise`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: true,
	},
	'fixedCosts.*.costs.*.hasCoProOverriddenAmount': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.costs.*.hasCoProOverriddenAmount',
		emsPublicIdentifier: 'fixedCosts.*.costs.*.hasCoProOverriddenAmount',
		internalLabel: 'if fixed costs have an overridden value for co pro',
		internalDescription: `true if this fixed cost has an overriden value for the co pro deal, false otherwise`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: true,
	},
	'fixedCosts.*.costs.*.difference': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.costs.*.difference',
		emsPublicIdentifier: 'fixedCosts.*.costs.*.difference',
		internalLabel: 'Actual Difference',
		internalDescription: `the difference between the actual cost and the budgeted cost`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'fixedCosts.*.costs.*.isReported': {
		isSMSOnly: false,
		emsMetadataId: 'fixedCosts.*.costs.*.isReported',
		emsPublicIdentifier: 'fixedCosts.*.costs.*.isReported',
		internalLabel: 'Is Reported?',
		internalDescription: `If the cost is reported or not`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
	},
	// #endregion fixedCosts.*.costs.*
	// #endregion fixedCosts.*
	fixedCostsTotal: {
		isSMSOnly: false,
		emsMetadataId: 'fixedCostsTotal',
		emsPublicIdentifier: 'fixedCostsTotal',
		internalLabel: 'Fixed Cost Total',
		internalDescription: 'Sum of fixedCosts.*.total',
		calculation: 'fixedCosts.*.total',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Total Fixed Expenses',
			ExternalBudgeted: 'Total Fixed Expenses (Offer)',
			InternalEstimated: 'Total Fixed Expenses (Estimated)',
			ExternalReported: 'Total Fixed Expenses (Settlement)',
			InternalActual: 'Total Fixed Expenses (Internal)',
		},
		userFacingDescription: {
			DEFAULT: 'Total fixed costs',
			ExternalBudgeted: 'Total offer budget fixed costs',
			InternalEstimated: 'Total estimated internal fixed costs',
			ExternalReported: 'Total reported external fixed costs',
			InternalActual: 'Total actual internal fixed costs',
		},
		category: 'Financial Summary',
	},
	// #region tourFixedCosts.*
	'tourFixedCosts.*': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*',
		emsPublicIdentifier: 'tourFixedCosts.*',
		internalLabel: 'Tour Fixed Costs',
		internalDescription: 'List of all fixed costs for the tour',
		calculation: '',
		type: EMSFieldType.EMSFixedCostArray,
		isRaw: false,
		costCalcDependent: true,
	},
	'tourFixedCosts.*.id': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.id',
		emsPublicIdentifier: 'tourFixedCosts.*.id',
		internalLabel: 'Tour Fixed Costs Id',
		internalDescription: 'ID of the tour fixed cost group',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		costCalcDependent: false,
		// only on tours
		isNullable: true,
	},
	'tourFixedCosts.*.groupType': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.groupType',
		emsPublicIdentifier: 'tourFixedCosts.*.groupType',
		internalLabel: 'Fixed Cost Group Type',
		internalDescription: 'The type for all of the costs in this group (selected manually on the Fixed Costs table)',
		calculation: '',
		type: EMSFieldType.CostGroupType,
		isNullable: true,
		isRaw: true,
		costCalcDependent: true,
	},
	'tourFixedCosts.*.groupCategory': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.groupCategory',
		emsPublicIdentifier: 'tourFixedCosts.*.groupCategory',
		internalLabel: 'Tour Fixed Costs Group Category',
		internalDescription: 'Category of the tour fixed cost group',
		calculation: '',
		type: EMSFieldType.CostGroupCategory,
		isRaw: true,
		costCalcDependent: false,
		// only on tours
		isNullable: true,
	},
	// EMSFixedCost Props
	'tourFixedCosts.*.costs.*': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.costs.*',
		emsPublicIdentifier: 'tourFixedCosts.*.costs.*',
		internalLabel: 'Costs',
		internalDescription: 'All costs associated with a given tour fixed cost',
		calculation: '',
		type: EMSFieldType.EMSFixedCostArray,
		isRaw: false,
		costCalcDependent: true,
	},
	// EMSCost Props
	'tourFixedCosts.*.costs.*.id': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.costs.*.id',
		emsPublicIdentifier: 'tourFixedCosts.*.costs.*.id',
		internalLabel: 'Fixed costs id',
		internalDescription: `an id for the particular tour fixed cost `,
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: true,
	},
	'tourFixedCosts.*.costs.*.name': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.costs.*.name',
		emsPublicIdentifier: 'tourFixedCosts.*.costs.*.name',
		internalLabel: 'Name',
		internalDescription: 'Name input by user on the cost table',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: true,
	},
	'tourFixedCosts.*.costs.*.quantity': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.costs.*.quantity',
		emsPublicIdentifier: 'tourFixedCosts.*.costs.*.quantity',
		internalLabel: 'Quantity',
		internalDescription: 'The quantity for the cost',
		userFacingDescription: 'The quantity for the cost',
		userFacingLabel: 'Quantity',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: true,
	},
	'tourFixedCosts.*.costs.*.costAmount': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.costs.*.costAmount',
		emsPublicIdentifier: 'tourFixedCosts.*.costs.*.costAmount',
		internalLabel: 'Cost',
		internalDescription: 'The cost amount value for this cost',
		userFacingDescription: 'The cost amount value for this cost',
		userFacingLabel: 'Cost',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tourFixedCosts.*.costs.*.total': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.costs.*.total',
		emsPublicIdentifier: 'tourFixedCosts.*.costs.*.total',
		internalLabel: 'Cost Calc Total',
		internalDescription: 'The total cost for a given cost, relative to the current cost calc',
		userFacingDescription: 'The total cost for a given cost, relative to the current cost calc',
		userFacingLabel: 'Cost Calc Total',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tourFixedCosts.*.costs.*.computedTotal': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.costs.*.computedTotal',
		emsPublicIdentifier: 'tourFixedCosts.*.costs.*.computedTotal',
		internalLabel: 'Cost Calc Total',
		internalDescription: 'The computed total cost for a given cost, relative to the current cost calc',
		userFacingDescription: 'The computed total cost for a given cost, relative to the current cost calc',
		userFacingLabel: 'Cost Calc Total',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tourFixedCosts.*.costs.*.difference': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.costs.*.difference',
		emsPublicIdentifier: 'tourFixedCosts.*.costs.*.difference',
		internalLabel: 'Estimated Cost',
		internalDescription: 'The estimated value for this cost',
		userFacingDescription: 'The estimated value for this cost',
		userFacingLabel: 'Estimated Internal',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tourFixedCosts.*.costs.*.isReported': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.costs.*.isReported',
		emsPublicIdentifier: 'tourFixedCosts.*.costs.*.isReported',
		internalLabel: 'Is Reported?',
		internalDescription: `If the cost is reported or not`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
	},
	'tourFixedCosts.*.costs.*.overUnderReported': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.costs.*.overUnderReported',
		emsPublicIdentifier: 'tourFixedCosts.*.costs.*.overUnderReported',
		internalLabel: 'Over Under Reported',
		internalDescription: 'The Over Under Reported amount for the cost',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tourFixedCosts.*.costs.*.hasCoProOverriddenAmount': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.costs.*.hasCoProOverriddenAmount',
		emsPublicIdentifier: 'tourFixedCosts.*.costs.*.hasCoProOverriddenAmount',
		internalLabel: 'Has CoPro Overridden Amount',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tourFixedCosts.*.costs.*.coProOverriddenAmount': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.costs.*.coProOverriddenAmount',
		emsPublicIdentifier: 'tourFixedCosts.*.costs.*.coProOverriddenAmount',
		internalLabel: 'CoPro Overridden Amount',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tourFixedCosts.*.costs.*.hiddenFromCoPro': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.costs.*.hiddenFromCoPro',
		emsPublicIdentifier: 'tourFixedCosts.*.costs.*.hiddenFromCoPro',
		internalLabel: 'Hidden from CoPro',
		internalDescription: 'true if this variable cost is hidden from the co pro page, false otherwise',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: true,
	},
	// END EMSCost Props
	'tourFixedCosts.*.costsAmount': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.costsAmount',
		emsPublicIdentifier: 'tourFixedCosts.*.costsAmount',
		internalLabel: 'Costs Amount Total',
		internalDescription: 'Cost amount total for costs in group',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tourFixedCosts.*.costsComputedTotal': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.costsComputedTotal',
		emsPublicIdentifier: 'tourFixedCosts.*.costsComputedTotal',
		internalLabel: 'Fixed Cost Group Total',
		internalDescription: {
			DEFAULT: 'The total for all of the costs in this group',
			InternalEstimated:
				'he total for all of the costs in this group - sum of all cost.estimated_cost (manually entered on the costs table)',
			InternalPotential:
				'he total for all of the costs in this group - sum of all cost.estimated_cost (manually entered on the costs table)',
			ExternalEstimated:
				'sum of all cost.total. ALWAYS EQUAL TO the ExternalBudgeted value. You would *think* we use cost.estimated_cost here, but for fixedCosts, the estimated_cost value is internal only.',
			ExternalBudgeted: 'he total for all of the costs in this group - sum of all cost.total',
			ExternalReported: 'he total for all of the costs in this group - sum of all cost.reported_cost',
			InternalActual: 'he total for all of the costs in this group - sum of all cost.totalActual',
		},
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tourFixedCosts.*.reportedCostsComputedTotal': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.reportedCostsComputedTotal',
		emsPublicIdentifier: 'tourFixedCosts.*.reportedCostsComputedTotal',
		internalLabel: 'Fixed Cost Group Total',
		internalDescription: {
			DEFAULT: 'The total for all of the reported costs in this group',
			InternalEstimated:
				'he total for all of the costs in this group - sum of all cost.estimated_cost (manually entered on the costs table)',
			InternalPotential:
				'he total for all of the costs in this group - sum of all cost.estimated_cost (manually entered on the costs table)',
			ExternalEstimated:
				'sum of all cost.total. ALWAYS EQUAL TO the ExternalBudgeted value. You would *think* we use cost.estimated_cost here, but for fixedCosts, the estimated_cost value is internal only.',
			ExternalBudgeted: 'he total for all of the costs in this group - sum of all cost.total',
			ExternalReported: 'he total for all of the costs in this group - sum of all cost.reported_cost',
			InternalActual: 'he total for all of the costs in this group - sum of all cost.totalActual',
		},
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tourFixedCosts.*.costsDifference': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.costsDifference',
		emsPublicIdentifier: 'tourFixedCosts.*.costsDifference',
		internalLabel: 'Costs Difference Total',
		internalDescription: 'Differnece total for costs in group',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tourFixedCosts.*.costsOverUnderReportedTotal': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.costsOverUnderReportedTotal',
		emsPublicIdentifier: 'tourFixedCosts.*.costsOverUnderReportedTotal',
		internalLabel: 'Over Under Reported Total',
		internalDescription: 'Over Under Reported total for costs in group',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tourFixedCosts.*.reportedCostsOverUnderReportedTotal': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.reportedCostsOverUnderReportedTotal',
		emsPublicIdentifier: 'tourFixedCosts.*.reportedCostsOverUnderReportedTotal',
		internalLabel: 'Over Under Reported Total',
		internalDescription: 'Over Under Reported total for costs in group',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'tourFixedCosts.*.hasSomeCostReported': {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCosts.*.hasSomeCostReported',
		emsPublicIdentifier: 'tourFixedCosts.*.hasSomeCostReported',
		internalLabel: 'Costs are Reported',
		internalDescription: 'If all costs in group are reported',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	// #endregion tourFixedCosts.*
	// END EMSFixedCost Props
	tourFixedCostsTotal: {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'tourFixedCostsTotal',
		emsPublicIdentifier: 'tourFixedCostsTotal',
		internalLabel: 'Tour Fixed Cost Total',
		internalDescription: 'Sum of fixedCosts.*.total',
		calculation: 'tourFixedCosts.*.total',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Total Fixed Expenses',
			ExternalBudgeted: 'Total Fixed Expenses (Offer)',
			InternalEstimated: 'Total Fixed Expenses (Estimated)',
			ExternalReported: 'Total Fixed Expenses (Settlement)',
			InternalActual: 'Total Fixed Expenses (Internal)',
		},
		userFacingDescription: {
			DEFAULT: 'Total tour fixed costs',
			ExternalBudgeted: 'Total offer budget tour fixed costs',
			InternalEstimated: 'Total estimated internal tour fixed costs',
			ExternalReported: 'Total reported external tour fixed costs',
			InternalActual: 'Total actual internal tour fixed costs',
		},
		category: 'Financial Summary',
	},
	hasCrossCollateralizedDeal: {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'hasCrossCollateralizedDeal',
		emsPublicIdentifier: 'hasCrossCollateralizedDeal',
		userFacingLabel: 'True if the tour has at least one cross collateralized tour deal',
		internalLabel: 'True if the tour has at least one cross collateralized tour deal',
		internalDescription: 'True if the tour has at least one cross collateralized tour deal',
		calculation: 'True if the tour has at least one cross collateralized tour deal',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: false,
	},
	areAllTourEventsOnSameVenue: {
		isSMSOnly: false,
		isTMSOnly: true,
		emsMetadataId: 'areAllTourEventsOnSameVenue',
		emsPublicIdentifier: 'areAllTourEventsOnSameVenue',
		userFacingLabel: 'True if the tour has the same venue across all tour events, false otherwise',
		internalLabel: 'True if the tour has the same venue across all tour events, false otherwise',
		internalDescription: 'True if the tour has the same venue across all tour events, false otherwise',
		calculation: 'True if the tour has the same venue across all tour events, false otherwise',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: false,
	},
	// #region variableCosts.*
	'variableCosts.*': {
		isSMSOnly: false,
		emsMetadataId: 'variableCosts.*',
		emsPublicIdentifier: 'variableCosts.*',
		internalLabel: 'Variable Costs',
		internalDescription: 'List of all variable costs associated with the event',
		calculation: '',
		type: EMSFieldType.EMSVariableCostArray,
		isRaw: false,
		costCalcDependent: true,
		canArrayIndicesVary: true,
	},
	// Start EMSVariableCost Props
	'variableCosts.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'variableCosts.*.id',
		emsPublicIdentifier: 'variableCosts.*.id',
		internalLabel: 'Variable costs id',
		internalDescription: `a id for the particular variable cost `,
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
		canArrayIndicesVary: true,
	},
	'variableCosts.*.amount': {
		isSMSOnly: false,
		emsMetadataId: 'variableCosts.*.amount',
		emsPublicIdentifier: 'variableCosts.*.amount',
		internalLabel: 'Variable Cost Amount',
		internalDescription: 'Amount for the cost, input by the user on the Variable Costs Table',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
		canArrayIndicesVary: true,
	},
	'variableCosts.*.displayAmount': {
		isSMSOnly: false,
		emsMetadataId: 'variableCosts.*.displayAmount',
		emsPublicIdentifier: 'variableCosts.*.displayAmount',
		internalLabel: 'Variable Cost Display Amount',
		internalDescription:
			'Formatted value for this variable costs, will be a dollar amount, or a percentage based on the Type',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: false,
		canArrayIndicesVary: true,
	},
	'variableCosts.*.name': {
		isSMSOnly: false,
		emsMetadataId: 'variableCosts.*.name',
		emsPublicIdentifier: 'variableCosts.*.name',
		internalLabel: 'Variable Cost name',
		internalDescription: 'The Name for this Variable Cost.',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		canArrayIndicesVary: true,
	},
	'variableCosts.*.displayName': {
		isSMSOnly: false,
		emsMetadataId: 'variableCosts.*.displayName',
		emsPublicIdentifier: 'variableCosts.*.displayName',
		internalLabel: 'Variable Cost Display Name',
		internalDescription: 'The Name for this Variable Cost, input by the user on the Variable Costs Table',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		canArrayIndicesVary: true,
	},
	'variableCosts.*.terms': {
		isSMSOnly: false,
		emsMetadataId: 'variableCosts.*.terms',
		emsPublicIdentifier: 'variableCosts.*.terms',
		internalLabel: 'Variable Cost Type',
		internalDescription: `The type for this Variable Cost, one of (Percentage of Adjusted Gross, Percentage of Gross, Flatt per Atendee, Flat per Ticket (<tier|all tiers>))`,
		calculation: '',
		type: EMSFieldType.VariableCostType,
		isRaw: true,
		costCalcDependent: false,
		canArrayIndicesVary: true,
	},
	'variableCosts.*.ticketTotal': {
		isSMSOnly: false,
		emsMetadataId: 'variableCosts.*.ticketTotal',
		emsPublicIdentifier: 'variableCosts.*.ticketTotal',
		internalLabel: 'Ticket Total',
		internalDescription: {
			DEFAULT: 'The number of tickets sold.',
			InternalEstimated: 'The number of tickets sold. - est_sold',
			InternalPotential: 'The number of tickets sold. - allotment',
			ExternalEstimated: 'The number of tickets sold. - est_sold',
			ExternalBudgeted: 'The number of tickets sold. - allotment',
			ExternalReported: 'The number of tickets sold. - sold',
			InternalActual: 'The number of tickets sold. - sold',
		},
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'variableCosts.*.total': {
		isSMSOnly: false,
		emsMetadataId: 'variableCosts.*.total',
		emsPublicIdentifier: 'variableCosts.*.total',
		internalLabel: 'Variable Cost Total',
		internalDescription: `This is the total value for the variable cost. The way it is calculated depends on the terms for this variable cost.
		Percent of Adjusted: (amount / 100) * netGross
		Percent of Gross:    (amount / 100) * gross
		Flat per Attendee:   (amount * attendance)
		Flat per Ticket (<tier, all tiers>):
		                    (amount * ticketTotal)`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'variableCosts.*.hiddenFromCoPro': {
		isSMSOnly: false,
		emsMetadataId: 'variableCosts.*.hiddenFromCoPro',
		emsPublicIdentifier: 'variableCosts.*.hiddenFromCoPro',
		internalLabel: 'variable costs hidden from co pro',
		internalDescription: `true if this variable cost is hidden from the co pro page, false otherwise`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: true,
	},
	'variableCosts.*.coProOverriddenAmount': {
		isSMSOnly: false,
		emsMetadataId: 'variableCosts.*.coProOverriddenAmount',
		emsPublicIdentifier: 'variableCosts.*.coProOverriddenAmount',
		internalLabel: 'variable costs amount overriden for co pro',
		internalDescription: `The overriden value for this variable cost in coPro deal`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: true,
		isNullable: true,
	},
	'variableCosts.*.isReported': {
		isSMSOnly: false,
		emsMetadataId: 'variableCosts.*.isReported',
		emsPublicIdentifier: 'variableCosts.*.isReported',
		internalLabel: 'Is Reported',
		internalDescription: `true if this variable cost is reported, false otherwise`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
		canArrayIndicesVary: true,
	},
	'variableCosts.*.hasCoProOverriddenAmount': {
		isSMSOnly: false,
		emsMetadataId: 'variableCosts.*.hasCoProOverriddenAmount',
		emsPublicIdentifier: 'variableCosts.*.hasCoProOverriddenAmount',
		internalLabel: 'if the variable costs has an overridden value for co pro',
		internalDescription: `true if this variable cost is hidden from the co pro page, false otherwise`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: true,
	},
	// #endregion variableCosts.*
	variableCostsTotal: {
		isSMSOnly: false,
		emsMetadataId: 'variableCostsTotal',
		emsPublicIdentifier: 'variableCostsTotal',
		internalLabel: 'Variable Costs Total',
		internalDescription: 'Sum of the total of all variable costs',
		calculation: 'variableCosts.*.total',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Variable Expenses',
			ExternalBudgeted: 'Variable Expenses (Offer)',
			InternalEstimated: 'Variable Expenses (Estimated)',
			ExternalReported: 'Variable Expenses (Settlement)',
			InternalActual: 'Variable Expenses (Internal)',
		},
		userFacingDescription: {
			DEFAULT: 'Total variable costs',
			ExternalBudgeted: 'Total offer budget variable costs',
			InternalEstimated: 'Total estimated internal variable costs',
			ExternalReported: 'Total reported external variable costs',
			InternalActual: 'Total actual internal variable costs',
		},
		category: 'Financial Summary',
	},
	// #region payouts.*
	'payouts.*': {
		isSMSOnly: false,
		emsMetadataId: 'payouts.*',
		emsPublicIdentifier: 'payouts.*',
		internalLabel: "Event's Payouts",
		internalDescription: 'List of all of the payouts associated with the event',
		calculation: '',
		type: EMSFieldType.EMSPayoutArray,
		isRaw: false,
		costCalcDependent: true,
	},
	'payouts.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'payouts.*.id',
		emsPublicIdentifier: 'payouts.*.id',
		internalLabel: 'id for the payout',
		internalDescription: 'id for the payout',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: false,
		costCalcDependent: false,
	},
	'payouts.*.talentId': {
		isSMSOnly: false,
		emsMetadataId: 'payouts.*.talentId',
		emsPublicIdentifier: 'payouts.*.talentId',
		internalLabel: '',
		internalDescription: 'the id of the talent entry on the event. null when not a spotify artist',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: false,
	},
	'payouts.*.parentId': {
		isSMSOnly: false,
		emsMetadataId: 'payouts.*.parentId',
		emsPublicIdentifier: 'payouts.*.parentId',
		internalLabel: '',
		internalDescription: 'The id of the tour for which this talent entry is a child',
		calculation: '',
		type: EMSFieldType.number,
		isNullable: true,
		isRaw: false,
		costCalcDependent: false,
	},
	'payouts.*.guarantee': {
		isSMSOnly: false,
		emsMetadataId: 'payouts.*.guarantee',
		emsPublicIdentifier: 'payouts.*.guarantee',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: false,
	},
	'payouts.*.isHeadliner': {
		isSMSOnly: false,
		emsMetadataId: 'payouts.*.isHeadliner',
		emsPublicIdentifier: 'payouts.*.isHeadliner',
		internalLabel: 'Is headliner?',
		internalDescription:
			'True if this is a headlining act, false if its supporting. Chosen by the user as a radio button on the talent deal form.',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: false,
	},
	'payouts.*.name': {
		isSMSOnly: false,
		emsMetadataId: 'payouts.*.name',
		emsPublicIdentifier: 'payouts.*.name',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: false,
	},
	'payouts.*.total': {
		isSMSOnly: false,
		emsMetadataId: 'payouts.*.total',
		emsPublicIdentifier: 'payouts.*.total',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'payouts.*.includeInDeal': {
		isSMSOnly: false,
		emsMetadataId: 'payouts.*.includeInDeal',
		emsPublicIdentifier: 'payouts.*.includeInDeal',
		internalLabel: 'Include In Deal',
		internalDescription: 'True if the payout is included in the deal, false otherwise',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
	},
	// #endregion payouts.*
	adjustedPayoutsTotal: {
		isSMSOnly: false,
		emsMetadataId: 'adjustedPayoutsTotal',
		emsPublicIdentifier: 'adjustedPayoutsTotal',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	expenses: {
		isSMSOnly: false,
		emsMetadataId: 'expenses',
		emsPublicIdentifier: 'expenses',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Total Expenses',
			ExternalBudgeted: 'Total Expenses (Offer)',
			InternalEstimated: 'Total Expenses (Estimated)',
			ExternalReported: 'Total Expenses (Settlement)',
			InternalActual: 'Total Expenses (Internal)',
		},
		userFacingDescription: {
			DEFAULT: 'Total costs, including all guarantees and bonuses',
			ExternalBudgeted: 'Total offer budget costs, including all guarantees and bonuses',
			InternalEstimated: 'Total of estimated internal costs including all guarantees and bonuses',
			ExternalReported: 'Total reported external costs, including all guarantees and bonuses',
			InternalActual: 'Total actual internal costs, including all guarantees and bonuses',
		},
		category: 'Financial Summary',
	},
	expensesForCoPro: {
		isSMSOnly: false,
		emsMetadataId: 'expensesForCoPro',
		emsPublicIdentifier: 'expensesForCoPro',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Total Expenses',
			ExternalBudgeted: 'Total Expenses (Offer)',
			InternalEstimated: 'Total Expenses (Estimated)',
			ExternalReported: 'Total Expenses (Settlement)',
			InternalActual: 'Total Expenses (Internal)',
		},
		userFacingDescription: {
			DEFAULT: 'Total costs, including all guarantees and bonuses',
			ExternalBudgeted: 'Total offer budget costs, including all guarantees and bonuses',
			InternalEstimated: 'Total of estimated internal costs including all guarantees and bonuses',
			ExternalReported: 'Total reported external costs, including all guarantees and bonuses',
			InternalActual: 'Total actual internal costs, including all guarantees and bonuses',
		},
		category: 'Financial Summary',
	},
	exchangeRate: {
		isSMSOnly: false,
		emsMetadataId: 'exchangeRate',
		emsPublicIdentifier: 'exchangeRate',
		internalLabel: 'Exchange Rate',
		internalDescription:
			'The exchange rate for the event. Depends on CostCalc perspective. 1 if no conversion takes place. This value was not used when computing EMS numbers, it can be used when converting BACK into USD.',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	showExpenses: {
		isSMSOnly: false,
		emsMetadataId: 'showExpenses',
		emsPublicIdentifier: 'showExpenses',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Show Expenses',
			ExternalBudgeted: 'Show Expenses (Offer)',
			ExternalReported: 'Show Expenses (Settlement)',
			InternalEstimated: 'Show Expenses (Estimated)',
			InternalActual: 'Show Expenses (Actual)',
		},
		userFacingDescription: {
			DEFAULT:
				'Total fixed and variable offer expenses plus promoter profit, excluding guarantees and talent bonuses',
			ExternalBudgeted:
				'Total fixed and variable offer budget expenses plus promoter profit, excluding guarantees and talent bonuses',
			ExternalReported:
				'Total fixed and variable actual reported external expenses plus promoter profit, excluding guarantees and talent bonuses',
			InternalEstimated:
				'Estimated total fixed and variable offer budget expenses plus promoter profit, excluding guarantees and talent bonuses',
			InternalActual:
				'Actual total fixed and variable offer budget expenses plus promoter profit, excluding guarantees and talent bonuses',
		},
		category: 'Financial Summary',
	},
	splitPoint: {
		isSMSOnly: false,
		emsMetadataId: 'splitPoint',
		emsPublicIdentifier: 'splitPoint',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Split Point',
			ExternalBudgeted: 'Split Point (Offer)',
			ExternalReported: 'Split Point (Settlement)',
		},
		userFacingDescription: {
			DEFAULT: 'Total fixed and variable expenses including support payout plus promoter profit',
			ExternalBudgeted:
				'Total fixed and variable offer budget expenses including support payout plus promoter profit',
			ExternalReported:
				'Total fixed and variable actual reported external expenses including support payout plus promoter profit',
		},
		category: 'Event Details',
	},
	totalTalentExpenses: {
		isSMSOnly: false,
		emsMetadataId: 'totalTalentExpenses',
		emsPublicIdentifier: 'totalTalentExpenses',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	totalHeadlinerPayoutsBeforeAdjustments: {
		isSMSOnly: false,
		emsMetadataId: 'totalHeadlinerPayoutsBeforeAdjustments',
		emsPublicIdentifier: 'totalHeadlinerPayoutsBeforeAdjustments',
		internalLabel: 'Total Headliner Talent Payouts Before Adjustments',
		internalDescription: 'Total of all headliners talent payouts before adjustments',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	netProfit: {
		isSMSOnly: false,
		emsMetadataId: 'netProfit',
		emsPublicIdentifier: 'netProfit',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Net Profit',
			InternalActual: 'Net Profit (Internal)',
			InternalEstimated: 'Estimated Net Profit',
			ExternalBudgeted: 'Net Profit (Offer)',
			ExternalReported: 'Net Profit (Settlement)',
		},
		userFacingDescription: {
			DEFAULT: `Equals ${resolveLabel(NET_GROSS_FIELD_METADATA)} less all actual internal expenses`,
			InternalActual: `Equals actual ${resolveLabel(NET_GROSS_FIELD_METADATA)} less all actual internal expenses`,
			InternalEstimated: `Equals the estimated ${resolveLabel(
				NET_GROSS_FIELD_METADATA
			)} less all estimated internal expenses`,
			ExternalBudgeted: `Equals maximum potential ${resolveLabel(
				NET_GROSS_FIELD_METADATA
			)} less all offer budget expenses`,
			ExternalReported: `Equals actual ${resolveLabel(
				NET_GROSS_FIELD_METADATA
			)} less all actual reported external expenses`,
		},
		category: 'Financial Summary',
	},
	netProfitBeforeCoPro: {
		isSMSOnly: false,
		emsMetadataId: 'netProfitBeforeCoPro',
		emsPublicIdentifier: 'netProfitBeforeCoPro',
		internalLabel: '',
		internalDescription: 'net profit, before co pro calculations',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		category: 'Financial Summary',
	},
	netProfitAfterCoPro: {
		isSMSOnly: false,
		emsMetadataId: 'netProfitAfterCoPro',
		emsPublicIdentifier: 'netProfitAfterCoPro',
		internalLabel: '',
		internalDescription: 'net profit minus co pro payouts',
		calculation: 'netProfitForCoProCalculations - totalCoProPayout',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		category: 'Financial Summary',
	},
	netProfitForCoProCalculations: {
		isSMSOnly: false,
		emsMetadataId: 'netProfitForCoProCalculations',
		emsPublicIdentifier: 'netProfitForCoProCalculations',
		internalLabel: '',
		internalDescription: 'net profit, minus additional revenue, but plus additional revenue towards co pro',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		category: 'Financial Summary',
	},
	netRevenueToSplit: {
		isSMSOnly: false,
		emsMetadataId: 'netRevenueToSplit',
		emsPublicIdentifier: 'netRevenueToSplit',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	// #region nonPlatformTicketTotals
	nonPlatformTicketTotals: {
		isObject: true,
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals',
		emsPublicIdentifier: 'nonPlatformTicketTotals',
		internalLabel: 'Ticket totals',
		internalDescription: 'The ticket totals of non-platform tickets on the event',
		calculation: '',
		type: EMSFieldType.EMSTicketTotal,
		isRaw: false,
		costCalcDependent: true,
	},
	'nonPlatformTicketTotals.name': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.name',
		emsPublicIdentifier: 'nonPlatformTicketTotals.name',
		internalLabel: 'Ticket tier',
		internalDescription: 'The name for the ticket tier, entered by the user on the revenue table',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'nonPlatformTicketTotals.id': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.id',
		emsPublicIdentifier: 'nonPlatformTicketTotals.id',
		internalLabel: 'Ticket id',
		internalDescription: 'the id for the ticket in our database. isNullable for TMS',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'nonPlatformTicketTotals.groupKey': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.groupKey',
		emsPublicIdentifier: 'nonPlatformTicketTotals.groupKey',
		internalLabel: 'Ticket id',
		internalDescription: 'They key to group by in PDF docs; mostly for tours',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'nonPlatformTicketTotals.price': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.price',
		emsPublicIdentifier: 'nonPlatformTicketTotals.price',
		internalLabel: 'Individual ticket price',
		internalDescription: 'The price for tickets in that tier, entered by the user on the revenue table',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'nonPlatformTicketTotals.allotment': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.allotment',
		emsPublicIdentifier: 'nonPlatformTicketTotals.allotment',
		internalLabel: 'Individual ticket allotment',
		internalDescription: 'Individual ticket allotment, input by the user on the Tickets table',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'nonPlatformTicketTotals.comps': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.comps',
		emsPublicIdentifier: 'nonPlatformTicketTotals.comps',
		internalLabel: 'Individual ticket comps',
		internalDescription:
			'The number of comped tickets alloted for the tier, entered by the user on the Tickets table',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'nonPlatformTicketTotals.kills': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.kills',
		emsPublicIdentifier: 'nonPlatformTicketTotals.kills',
		internalLabel: 'Individual ticket kills',
		internalDescription:
			'The number of killed tickets alloted for the tier, entered by the user on the Tickets table',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'nonPlatformTicketTotals.killsAverage': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.killsAverage',
		emsPublicIdentifier: 'nonPlatformTicketTotals.killsAverage',
		internalLabel: 'Ticket Tier average Kills',
		internalDescription: 'The avareage kills for non platform tickets',
		calculation: 'sum(tickets.kills) / tickets.length',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
	},
	'nonPlatformTicketTotals.sellable': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.sellable',
		emsPublicIdentifier: 'nonPlatformTicketTotals.sellable',
		internalLabel: 'Sellable',
		internalDescription: 'Number of alloted tickets remaining after comps and kills',
		calculation: 'allotment - comps - kills',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
	},
	'nonPlatformTicketTotals.sellableAverage': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.sellableAverage',
		emsPublicIdentifier: 'nonPlatformTicketTotals.sellableAverage',
		internalLabel: 'Ticket Tier average Sellable',
		internalDescription: 'The avareage sellable for non platform tickets',
		calculation: 'sum(tickets.sellable) / tickets.length',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
	},
	'nonPlatformTicketTotals.platformDiscounts': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.platformDiscounts',
		emsPublicIdentifier: 'nonPlatformTicketTotals.platformDiscounts',
		internalLabel: 'Ticket platform discount',
		internalDescription: 'The ticket platform discount',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
	},
	'nonPlatformTicketTotals.platformFees': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.platformFees',
		emsPublicIdentifier: 'nonPlatformTicketTotals.platformFees',
		internalLabel: 'Platform Fees',
		internalDescription:
			'only relevant when tickets are linked to eventbrite. This changes how netTicketGross is calculated',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
	},
	'nonPlatformTicketTotals.platformGrossTicketPrice': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.platformGrossTicketPrice',
		emsPublicIdentifier: 'nonPlatformTicketTotals.platformGrossTicketPrice',
		internalLabel: 'Platform Gross Ticket Price',
		internalDescription:
			'Total platform revenue (platform total + tax), divided total sold; 0 if non-platform ticket',
		calculation: 'ticket.totalRevenue(costCalc) / ticket.ticketsSold(costCalc)',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		isNullable: true,
		costCalcDependent: true,
	},
	'nonPlatformTicketTotals.platformRebate': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.platformRebate',
		emsPublicIdentifier: 'nonPlatformTicketTotals.platformRebate',
		internalLabel: 'Ticket platform rebate',
		internalDescription: 'The ticket platform rebate',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: true,
	},
	'nonPlatformTicketTotals.feeDeducted': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.feeDeducted',
		emsPublicIdentifier: 'nonPlatformTicketTotals.feeDeducted',
		internalLabel: 'Fee deducted?',
		internalDescription:
			'only relevant when tickets are linked to eventbrite. This changes how netTicketGross is calculated',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: false,
	},
	'nonPlatformTicketTotals.feesPerTicket': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.feesPerTicket',
		emsPublicIdentifier: 'nonPlatformTicketTotals.feesPerTicket',
		internalLabel: 'Fees Per Ticket',
		internalDescription: 'All the fees for this ticket tier, averaged per-ticket',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'nonPlatformTicketTotals.ticketsSold': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.ticketsSold',
		emsPublicIdentifier: 'nonPlatformTicketTotals.ticketsSold',
		internalLabel: 'Tickets Sold',
		internalDescription: 'This number depends on which CostCalc perspective is being viewed',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'nonPlatformTicketTotals.totalTicketFees': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.totalTicketFees',
		emsPublicIdentifier: 'nonPlatformTicketTotals.totalTicketFees',
		internalLabel: 'Ticket Fees',
		internalDescription: 'total fees on the ticket',
		calculation: 'a sum of all fees that apply to this ticket',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'nonPlatformTicketTotals.ticketRevenue': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.ticketRevenue',
		emsPublicIdentifier: 'nonPlatformTicketTotals.ticketRevenue',
		internalLabel: 'Ticket Revenue',
		internalDescription: 'will always be ticketsSold * price',
		calculation: 'ticketsSold * price',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'nonPlatformTicketTotals.netTicketGross': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.netTicketGross',
		emsPublicIdentifier: 'nonPlatformTicketTotals.netTicketGross',
		internalLabel: 'Net Ticket Revenue',
		internalDescription: `adjusted gross minus tax`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'nonPlatformTicketTotals.netTicketPrice': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.netTicketPrice',
		emsPublicIdentifier: 'nonPlatformTicketTotals.netTicketPrice',
		internalLabel: 'Net Ticket Price',
		internalDescription: 'The net ticket price, price - average fees deducted',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		isNullable: true,
		costCalcDependent: true,
	},
	'nonPlatformTicketTotals.adjustedTicketGross': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.adjustedTicketGross',
		emsPublicIdentifier: 'nonPlatformTicketTotals.adjustedTicketGross',
		internalLabel: 'Adjusted Ticket Revenue',
		internalDescription: `ticket netGross minus ticket fees`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'nonPlatformTicketTotals.breakEven': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.breakEven',
		emsPublicIdentifier: 'nonPlatformTicketTotals.breakEven',
		internalLabel: 'Ticket break even',
		internalDescription: '...its complicated...',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'nonPlatformTicketTotals.estimatedSold': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.estimatedSold',
		emsPublicIdentifier: 'nonPlatformTicketTotals.estimatedSold',
		internalLabel: 'Ticket Estimated Sold',
		internalDescription: 'The number of estimated tickets sold',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'nonPlatformTicketTotals.totalTax': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.totalTax',
		emsPublicIdentifier: 'nonPlatformTicketTotals.totalTax',
		internalLabel: 'Ticket Total tax',
		internalDescription: 'The tax for the ticket, using the tax rate for the event (and is not platform tax)',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'nonPlatformTicketTotals.platformTaxPaid': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.platformTaxPaid',
		emsPublicIdentifier: 'nonPlatformTicketTotals.platformTaxPaid',
		internalLabel: 'Ticket Platform tax',
		internalDescription: 'The platform tax for the ticket',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'nonPlatformTicketTotals.platformIdString': {
		isSMSOnly: false,
		emsMetadataId: 'nonPlatformTicketTotals.platformIdString',
		emsPublicIdentifier: 'nonPlatformTicketTotals.platformIdString',
		internalLabel: 'Ticket Platform id',
		internalDescription: 'The platform id for the ticket',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		isNullable: true,
		costCalcDependent: true,
	},
	// #endregion nonPlatformTicketTotals
	attendance: {
		isSMSOnly: false,
		emsMetadataId: 'attendance',
		emsPublicIdentifier: 'attendance',
		internalLabel: '',
		internalDescription: {
			DEFAULT: 'Estimated/Actual Attendance (as input in the Revenue tab)',
			InternalEstimated: 'Estimated Attendance (as input in the Revenue tab)',
			InternalPotential: 'Estimated Attendance (as input in the Revenue tab)',
			ExternalEstimated: 'Estimated Attendance (as input in the Revenue tab)',
			ExternalBudgeted: 'Estimated Attendance (as input in the Revenue tab)',
			ExternalReported: 'Actual Attendance (as input in the Revenue tab)',
			InternalActual: 'Actual Attendance (as input in the Revenue tab)',
		},
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Attendance',
			ExternalBudgeted: 'Attendance (Offer)',
			ExternalReported: 'Attendance (Settlement)',
		},
		userFacingDescription: {
			DEFAULT: 'Attendance',
			ExternalBudgeted: 'Estimated Attendance',
			ExternalReported: 'Actual Attendance',
		},
		category: 'Show Metrics',
	},
	// #region partnerDeals.*
	'partnerDeals.*': {
		isSMSOnly: false,
		category: 'Partner Deal',
		emsMetadataId: 'partnerDeals.*',
		emsPublicIdentifier: 'partnerDeals.*',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.EMSPartnerDealArray,
		isRaw: false,
		costCalcDependent: true,
	},
	'partnerDeals.*.id': {
		isSMSOnly: false,
		category: 'Partner Deal',
		emsMetadataId: 'partnerDeals.*.id',
		emsPublicIdentifier: 'partnerDeals.*.id',
		internalLabel: '',
		internalDescription: 'ID of the partnerDeal deal in the database',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
		// only on tours
		isNullable: true,
	},
	'partnerDeals.*.eventId': {
		isSMSOnly: false,
		category: 'Partner Deal',
		emsMetadataId: 'partnerDeals.*.eventId',
		emsPublicIdentifier: 'partnerDeals.*.eventId',
		internalLabel: '',
		internalDescription: 'Event ID on which the partnerDeal deal exists',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
		// only on tours???
		isNullable: true,
	},
	'partnerDeals.*.perTicketBonus': {
		isSMSOnly: false,
		category: 'Partner Deal',
		emsMetadataId: 'partnerDeals.*.perTicketBonus',
		emsPublicIdentifier: 'partnerDeals.*.perTicketBonus',
		internalLabel: 'Partner Deal Per Ticket Bonus',
		internalDescription: 'raw per-ticket bonus number for this partner deal',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
	},
	'partnerDeals.*.promoterContactId': {
		isSMSOnly: false,
		category: 'Partner Deal',
		emsMetadataId: 'partnerDeals.*.promoterContactId',
		emsPublicIdentifier: 'partnerDeals.*.promoterContactId',
		internalLabel: '',
		internalDescription: 'ID of the contact that corresponds to this partner deal',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
		isNullable: true,
	},
	'partnerDeals.*.promoterContactLabel': {
		isSMSOnly: false,
		category: 'Partner Deal',
		emsMetadataId: 'partnerDeals.*.promoterContactLabel',
		emsPublicIdentifier: 'partnerDeals.*.promoterContactLabel',
		internalLabel: '',
		internalDescription: 'If available, the contact name of the promoter on this partner deal',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'partnerDeals.*.coProSharePercentage': {
		isSMSOnly: false,
		category: 'Partner Deal',
		emsMetadataId: 'partnerDeals.*.coProSharePercentage',
		emsPublicIdentifier: 'partnerDeals.*.coProSharePercentage',
		internalLabel: 'Partner Deal Share Percentage',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.percentage,
		isRaw: true,
		costCalcDependent: true,
	},
	'partnerDeals.*.coProSplit': {
		isSMSOnly: false,
		category: 'Partner Deal',
		emsMetadataId: 'partnerDeals.*.coProSplit',
		emsPublicIdentifier: 'partnerDeals.*.coProSplit',
		internalLabel: 'Partner Deal Co Pro Split',
		internalDescription: 'the split of the net profit this promoter will get',
		calculation: 'netProfitForCoProCalculations * partnerDeal.coProSharePercentage',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'partnerDeals.*.perTicketBonusTotal': {
		isSMSOnly: false,
		category: 'Partner Deal',
		emsMetadataId: 'partnerDeals.*.perTicketBonusTotal',
		emsPublicIdentifier: 'partnerDeals.*.perTicketBonusTotal',
		internalLabel: 'Partner Deal Per Ticket Bonus Total',
		internalDescription: 'The total per-ticket bonus for this partner deal',
		calculation: 'ticketsSold * partnerDeal.perTicketBonus',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'partnerDeals.*.totalAdjustments': {
		isSMSOnly: false,
		category: 'Partner Deal',
		emsMetadataId: 'partnerDeals.*.totalAdjustments',
		emsPublicIdentifier: 'partnerDeals.*.totalAdjustments',
		internalLabel: 'Partner Deal Total Adjustments Payout',
		internalDescription: 'The total adjustments for this payout',
		calculation: 'parnerDeal.coProSharePercentage + partnerDeal.perTicketBonus',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'partnerDeals.*.totalCoProPayout': {
		isSMSOnly: false,
		category: 'Partner Deal',
		emsMetadataId: 'partnerDeals.*.totalCoProPayout',
		emsPublicIdentifier: 'partnerDeals.*.totalCoProPayout',
		internalLabel: 'Partner Deal Total Co Pro Payout',
		internalDescription: 'The total payout for this co pro deal, split + bonus + adjustments',
		calculation: 'parnerDeal.coProSharePercentage + partnerDeal.perTicketBonus + partnerDeal.totalAdjustments',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'partnerDeals.*.partnerDealDescription': {
		isSMSOnly: false,
		category: 'Partner Deal',
		emsMetadataId: 'partnerDeals.*.partnerDealDescription',
		emsPublicIdentifier: 'partnerDeals.*.partnerDealDescription',
		internalLabel: 'Partner Deal one liner',
		internalDescription: 'The oneliner for this co pro deal',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: true,
	},
	'partnerDeals.*.splitLabel': {
		isSMSOnly: false,
		category: 'Partner Deal',
		emsMetadataId: 'partnerDeals.*.splitLabel',
		emsPublicIdentifier: 'partnerDeals.*.splitLabel',
		internalLabel: 'Split label',
		internalDescription: 'A label for the split, either "Profit Split" or "Loss Split"',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: true,
	},
	'partnerDeals.*.totalLabel': {
		isSMSOnly: false,
		category: 'Partner Deal',
		emsMetadataId: 'partnerDeals.*.totalLabel',
		emsPublicIdentifier: 'partnerDeals.*.totalLabel',
		internalLabel: 'Total label',
		internalDescription: 'A label for the total earnings for this co promoter, either "Earnings" or "Loss"',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: true,
	},
	// #region partnerDeals.*.adjustments.*
	'partnerDeals.*.adjustments.*': {
		isSMSOnly: false,
		category: 'Partner Deal',
		emsMetadataId: 'partnerDeals.*.adjustments.*',
		emsPublicIdentifier: 'partnerDeals.*.adjustments.*',
		internalLabel: 'Partner Deal Adjustments',
		internalDescription: 'The adjustments for this partner deal',
		calculation: '',
		type: EMSFieldType.EMSAdjustmentsArray,
		isRaw: false,
		costCalcDependent: true,
	},
	'partnerDeals.*.adjustments.*.id': {
		isSMSOnly: false,
		category: 'Partner Deal',
		emsMetadataId: 'partnerDeals.*.adjustments.*.id',
		emsPublicIdentifier: 'partnerDeals.*.adjustments.*.id',
		internalLabel: 'Partner Deal Adjustment ID',
		internalDescription: 'the id for the partner deal adjustment',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: false,
		costCalcDependent: true,
	},
	'partnerDeals.*.adjustments.*.amount': {
		isSMSOnly: false,
		category: 'Partner Deal',
		emsMetadataId: 'partnerDeals.*.adjustments.*.amount',
		emsPublicIdentifier: 'partnerDeals.*.adjustments.*.amount',
		internalLabel: 'Partner Deal Adjustment Amount',
		internalDescription: 'The adjustment amount for this adjustment on this partner deal',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
	},
	'partnerDeals.*.adjustments.*.name': {
		isSMSOnly: false,
		category: 'Partner Deal',
		emsMetadataId: 'partnerDeals.*.adjustments.*.name',
		emsPublicIdentifier: 'partnerDeals.*.adjustments.*.name',
		internalLabel: 'Partner Deal Adjustment Name',
		internalDescription: 'The adjustment name for this adjustment on this partner deal',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	// #endregion partnerDeals.*.adjustments.*
	// #endregion partnerDeals.*
	// #region platformTicketTotals
	platformTicketTotals: {
		isObject: true,
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals',
		emsPublicIdentifier: 'platformTicketTotals',
		internalLabel: 'Ticket totals',
		internalDescription: 'The ticket totals of non-platform tickets on the event',
		calculation: '',
		type: EMSFieldType.EMSTicketTotal,
		isRaw: false,
		costCalcDependent: true,
	},
	'platformTicketTotals.name': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.name',
		emsPublicIdentifier: 'platformTicketTotals.name',
		internalLabel: 'Ticket tier',
		internalDescription: 'The name for the ticket tier, entered by the user on the revenue table',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'platformTicketTotals.id': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.id',
		emsPublicIdentifier: 'platformTicketTotals.id',
		internalLabel: 'Ticket id',
		internalDescription: 'the id for the ticket in our database. isNullable for TMS',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'platformTicketTotals.groupKey': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.groupKey',
		emsPublicIdentifier: 'platformTicketTotals.groupKey',
		internalLabel: 'Ticket id',
		internalDescription: 'They key to group by in PDF docs; mostly for tours',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'platformTicketTotals.price': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.price',
		emsPublicIdentifier: 'platformTicketTotals.price',
		internalLabel: 'Individual ticket price',
		internalDescription: 'The price for tickets in that tier, entered by the user on the revenue table',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'platformTicketTotals.allotment': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.allotment',
		emsPublicIdentifier: 'platformTicketTotals.allotment',
		internalLabel: 'Individual ticket allotment',
		internalDescription: 'Individual ticket allotment, input by the user on the Tickets table',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'platformTicketTotals.comps': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.comps',
		emsPublicIdentifier: 'platformTicketTotals.comps',
		internalLabel: 'Individual ticket comps',
		internalDescription:
			'The number of comped tickets alloted for the tier, entered by the user on the Tickets table',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'platformTicketTotals.kills': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.kills',
		emsPublicIdentifier: 'platformTicketTotals.kills',
		internalLabel: 'Individual ticket kills',
		internalDescription:
			'The number of killed tickets alloted for the tier, entered by the user on the Tickets table',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'platformTicketTotals.killsAverage': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.killsAverage',
		emsPublicIdentifier: 'platformTicketTotals.killsAverage',
		internalLabel: 'Ticket Tier average Sellable',
		internalDescription: 'The avareage kills for platform tickets',
		calculation: 'sum(tickets.kills) / tickets.length',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
	},
	'platformTicketTotals.sellable': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.sellable',
		emsPublicIdentifier: 'platformTicketTotals.sellable',
		internalLabel: 'Sellable',
		internalDescription: 'Number of alloted tickets remaining after comps and kills',
		calculation: 'allotment - comps - kills',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
	},
	'platformTicketTotals.sellableAverage': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.sellableAverage',
		emsPublicIdentifier: 'platformTicketTotals.sellableAverage',
		internalLabel: 'Ticket Tier average Sellable',
		internalDescription: 'The avareage sellable for platform tickets',
		calculation: 'sum(tickets.sellable) / tickets.length',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
	},
	'platformTicketTotals.platformDiscounts': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.platformDiscounts',
		emsPublicIdentifier: 'platformTicketTotals.platformDiscounts',
		internalLabel: 'Ticket platform discount',
		internalDescription: 'The ticket platform discount',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
	},
	'platformTicketTotals.platformFees': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.platformFees',
		emsPublicIdentifier: 'platformTicketTotals.platformFees',
		internalLabel: 'Platform Fees',
		internalDescription:
			'only relevant when tickets are linked to eventbrite. This changes how netTicketGross is calculated',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
	},
	'platformTicketTotals.platformGrossTicketPrice': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.platformGrossTicketPrice',
		emsPublicIdentifier: 'platformTicketTotals.platformGrossTicketPrice',
		internalLabel: 'Platform Gross Ticket Price',
		internalDescription:
			'Total platform revenue (platform total + tax), divided total sold; 0 if non-platform ticket',
		calculation: 'ticket.totalRevenue(costCalc) / ticket.ticketsSold(costCalc)',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		isNullable: true,
		costCalcDependent: true,
	},
	'platformTicketTotals.platformRebate': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.platformRebate',
		emsPublicIdentifier: 'platformTicketTotals.platformRebate',
		internalLabel: 'Ticket platform rebate',
		internalDescription: 'The ticket platform rebate',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: true,
	},
	'platformTicketTotals.feeDeducted': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.feeDeducted',
		emsPublicIdentifier: 'platformTicketTotals.feeDeducted',
		internalLabel: 'Fee deducted?',
		internalDescription:
			'only relevant when tickets are linked to eventbrite. This changes how netTicketGross is calculated',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: false,
	},
	'platformTicketTotals.feesPerTicket': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.feesPerTicket',
		emsPublicIdentifier: 'platformTicketTotals.feesPerTicket',
		internalLabel: 'Fees Per Ticket',
		internalDescription: 'All the fees for this ticket tier, averaged per-ticket',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'platformTicketTotals.ticketsSold': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.ticketsSold',
		emsPublicIdentifier: 'platformTicketTotals.ticketsSold',
		internalLabel: 'Tickets Sold',
		internalDescription: 'This number depends on which CostCalc perspective is being viewed',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'platformTicketTotals.totalTicketFees': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.totalTicketFees',
		emsPublicIdentifier: 'platformTicketTotals.totalTicketFees',
		internalLabel: 'Ticket Fees',
		internalDescription: 'total fees on the ticket',
		calculation: 'a sum of all fees that apply to this ticket',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'platformTicketTotals.ticketRevenue': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.ticketRevenue',
		emsPublicIdentifier: 'platformTicketTotals.ticketRevenue',
		internalLabel: 'Ticket Revenue',
		internalDescription: 'will always be ticketsSold * price',
		calculation: 'ticketsSold * price',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'platformTicketTotals.netTicketGross': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.netTicketGross',
		emsPublicIdentifier: 'platformTicketTotals.netTicketGross',
		internalLabel: 'Net Ticket Revenue',
		internalDescription: `adjusted gross minus tax`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		isNullable: true,
		costCalcDependent: true,
	},
	'platformTicketTotals.netTicketPrice': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.netTicketPrice',
		emsPublicIdentifier: 'platformTicketTotals.netTicketPrice',
		internalLabel: 'Net Ticket Price',
		internalDescription: 'The net ticket price, price - average fees deducted',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		isNullable: true,
		costCalcDependent: true,
	},
	'platformTicketTotals.adjustedTicketGross': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.adjustedTicketGross',
		emsPublicIdentifier: 'platformTicketTotals.adjustedTicketGross',
		internalLabel: 'Adjusted Ticket Revenue',
		internalDescription: `ticket netGross minus ticket fees`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'platformTicketTotals.breakEven': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.breakEven',
		emsPublicIdentifier: 'platformTicketTotals.breakEven',
		internalLabel: 'Ticket break even',
		internalDescription: '...its complicated...',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'platformTicketTotals.estimatedSold': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.estimatedSold',
		emsPublicIdentifier: 'platformTicketTotals.estimatedSold',
		internalLabel: 'Ticket Estimated Sold',
		internalDescription: 'The number of estimated tickets sold',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'platformTicketTotals.totalTax': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.totalTax',
		emsPublicIdentifier: 'platformTicketTotals.totalTax',
		internalLabel: 'Ticket Total tax',
		internalDescription: 'The tax for the ticket, using the tax rate for the event (and is not platform tax)',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'platformTicketTotals.platformTaxPaid': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.platformTaxPaid',
		emsPublicIdentifier: 'platformTicketTotals.platformTaxPaid',
		internalLabel: 'Ticket Platform tax',
		internalDescription: 'The platform tax for the ticket',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'platformTicketTotals.platformIdString': {
		isSMSOnly: false,
		emsMetadataId: 'platformTicketTotals.platformIdString',
		emsPublicIdentifier: 'platformTicketTotals.platformIdString',
		internalLabel: 'Ticket Platform id',
		internalDescription: 'The platform id for the ticket',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		isNullable: true,
		costCalcDependent: true,
	},
	// #endregion platformTicketTotals
	// #region talentPayouts.*
	'talentPayouts.*': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*',
		emsPublicIdentifier: 'talentPayouts.*',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.EMSTalentPayoutArray,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.genres.*': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.genres.*',
		emsPublicIdentifier: 'talentPayouts.*.genres',
		internalLabel: 'Artist Genres',
		internalDescription: `The genres pertaining to the artist`,
		calculation: '',
		type: EMSFieldType.stringArray,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.talentId': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.talentId',
		emsPublicIdentifier: 'talentPayouts.*.talentId',
		internalLabel: '',
		internalDescription: 'the id of the talent entry on the event. null when not a spotify artist',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		// will be null if not a spotify artist
		isNullable: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.artistEarningsAfterAgentCommission': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.artistEarningsAfterAgentCommission',
		emsPublicIdentifier: 'talentPayouts.*.artistEarningsAfterAgentCommission',
		internalLabel: 'Agent Earning After Artist Commission',
		internalDescription: 'Agents earnings after the artist is paid their commission.',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.artistEarningsOverride': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.artistEarningsOverride',
		emsPublicIdentifier: 'talentPayouts.*.artistEarningsOverride',
		internalLabel: 'Agent Earning Override',
		internalDescription: 'Manual Override of artist earnings',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
		isNullable: true,
	},
	'talentPayouts.*.merchRateSummary': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.merchRateSummary',
		emsPublicIdentifier: 'talentPayouts.*.merchRateSummary',
		internalLabel: 'Merch Rate Summary',
		internalDescription: 'The hard and soft merch rates for this event',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: false,
		isNullable: true,
		userFacingLabel: 'Merch Rate Summary',
		userFacingDescription: 'The hard and soft merch rates for this event',
	},
	'talentPayouts.*.artistId': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.artistId',
		emsPublicIdentifier: 'talentPayouts.*.artistId',
		isNullable: true,
		internalLabel: 'Artist Id',
		internalDescription:
			"id for the artist in the users artist roster. Will be null if this deal is not associated with an artist from the user's roster.",
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.paidDepositsAmount': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.paidDepositsAmount',
		emsPublicIdentifier: 'talentPayouts.*.paidDepositsAmount',
		internalLabel: 'Paid Amount for Deposit',
		internalDescription: 'The total amount paid to the artist for the deposit(s)',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		isNullable: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.isFullySettled': {
		isSMSOnly: false,
		emsMetadataId: 'talentPayouts.*.isFullySettled',
		emsPublicIdentifier: 'talentPayouts.*.isFullySettled',
		internalLabel: 'Is Fully Settled',
		internalDescription:
			'Flag indicating if the deal is fully settled or not (are all payments paid off). This is computed and set when an event is transitioned to the settled status. It is always false for talent deals that are part of unsettled shows.',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		isNullable: false,
		costCalcDependent: false,
	},
	'talentPayouts.*.parentId': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.parentId',
		emsPublicIdentifier: 'talentPayouts.*.parentId',
		internalLabel: '',
		internalDescription: 'The id of the tour for which this talent entry is a child',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		isNullable: true,
		costCalcDependent: true,
	},
	'talentPayouts.*.adjustedArtistPayout': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.adjustedArtistPayout',
		emsPublicIdentifier: 'talentPayouts.*.adjustedArtistPayout',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.adjustedArtistPayoutMinusSettlement': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.adjustedArtistPayoutMinusSettlement',
		emsPublicIdentifier: 'talentPayouts.*.adjustedArtistPayoutMinusSettlement',
		internalLabel: 'Adjust Artist Payout (minus settlement)',
		internalDescription:
			"The artist's adjusted payout, minus the total settlement adjustments. Used in offer/settlement PDFs",
		calculation: 'adjustedArtistPayout - totalAdjustmentsTowardSettlement',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.adjustedArtistPayoutMinusWithholding': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.adjustedArtistPayoutMinusWithholding',
		emsPublicIdentifier: 'talentPayouts.*.adjustedArtistPayoutMinusWithholding',
		internalLabel: 'Adjust Artist Payout (minus withholding)',
		internalDescription: "The artist's adjusted payout, minus the total withheld. Used in offer/settlement PDFs",
		calculation: 'adjustedArtistPayout - withheldTotal',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.agentCommissionPayout': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.agentCommissionPayout',
		emsPublicIdentifier: 'talentPayouts.*.agentCommissionPayout',
		internalLabel: 'Agent Commission Payout',
		internalDescription: 'The actual adjusted artist payout, to be used on internal settlement pages',
		calculation: 'talentPayout.artistPayoutBeforeAdjustments * talentPayout.agentCommissionPercent / 100',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.agentCommissionPercent': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.agentCommissionPercent',
		emsPublicIdentifier: 'talentPayouts.*.agentCommissionPercent',
		internalLabel: 'Agent Commission Percent',
		internalDescription: "The agent's commission percentage, represented as an integer",
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.agencyReceivedTotalPayments': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.agencyReceivedTotalPayments',
		emsPublicIdentifier: 'talentPayouts.*.agencyReceivedTotalPayments',
		internalLabel: 'Agency-received Settlement',
		internalDescription: 'The total dollar amount of all deposits and settlements received by the agency',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.artistReceivedDeposit': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.artistReceivedDeposit',
		emsPublicIdentifier: 'talentPayouts.*.artistReceivedDeposit',
		internalLabel: 'Artist-received Deposit',
		internalDescription: 'The total dollar amount of deposits received by the artist',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: false,
	},
	'talentPayouts.*.artistReceivedSettlement': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.artistReceivedSettlement',
		emsPublicIdentifier: 'talentPayouts.*.artistReceivedSettlement',
		internalLabel: 'Artist-received settlement',
		internalDescription: 'The total dollar amount of the settlements received by the artist',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.actualAdjustedArtistPayout': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.actualAdjustedArtistPayout',
		emsPublicIdentifier: 'talentPayouts.*.actualAdjustedArtistPayout',
		internalLabel: 'Actual Adjusted Artist Payout',
		internalDescription: 'The actual adjusted artist payout, to be used on internal settlement pages',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.artistPayout': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.artistPayout',
		emsPublicIdentifier: 'talentPayouts.*.artistPayout',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.artistPayoutBeforeAdjustments': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.artistPayoutBeforeAdjustments',
		emsPublicIdentifier: 'talentPayouts.*.artistPayoutBeforeAdjustments',
		internalLabel: 'Artist Payout (before adjustments)',
		internalDescription:
			'The final artist payout, before adjustments are applied. this is the value that is used to compute agent commission',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.artistPayoutTowardsAgentCommission': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.artistPayoutTowardsAgentCommission',
		emsPublicIdentifier: 'talentPayouts.*.artistPayoutTowardsAgentCommission',
		internalLabel: 'Artist Payout (towards commission)',
		internalDescription:
			'The final artist payout to be used to compute agent commission. this is artistPayoutBeforeAdjustments + all adjustments that affect artist earnings',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.artistPayoutWithWithholdingAdjustments': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.artistPayoutWithWithholdingAdjustments',
		emsPublicIdentifier: 'talentPayouts.*.artistPayoutWithWithholdingAdjustments',
		internalLabel: 'Artist Payout + withholding adjustments',
		internalDescription:
			'The final artist payout, plus any adjustments that are to be included in withholding. This number will be used to calculate withheldTotal',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	// #region talentPayouts.*.adjustments.*
	'talentPayouts.*.adjustments.*': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.adjustments.*',
		emsPublicIdentifier: 'talentPayouts.*.adjustments.*',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.EMSAdjustmentsArray,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.adjustments.*.id': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.adjustments.*.id',
		emsPublicIdentifier: 'talentPayouts.*.adjustments.*.id',
		internalLabel: 'adjustment id',
		internalDescription: 'adjustment id',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.adjustments.*.name': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.adjustments.*.name',
		emsPublicIdentifier: 'talentPayouts.*.adjustments.*.name',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: false,
	},
	'talentPayouts.*.adjustments.*.amount': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.adjustments.*.amount',
		emsPublicIdentifier: 'talentPayouts.*.adjustments.*.amount',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.adjustments.*.affectsWithholding': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.adjustments.*.affectsWithholding',
		emsPublicIdentifier: 'talentPayouts.*.adjustments.*.affectsWithholding',
		internalLabel: 'Adjustment Affects Withholding',
		internalDescription: 'True if an adjustment affects withholding, false otherwise',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.adjustments.*.affectsArtistEarnings': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.adjustments.*.affectsArtistEarnings',
		emsPublicIdentifier: 'talentPayouts.*.adjustments.*.affectsArtistEarnings',
		internalLabel: 'Adjustment Affects Artist Earnings',
		internalDescription:
			'True if an adjustment affects the artists earnings (and thus commission), false otherwise',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.adjustments.*.affectsSplitPoint': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.adjustments.*.affectsSplitPoint',
		emsPublicIdentifier: 'talentPayouts.*.adjustments.*.affectsSplitPoint',
		internalLabel: 'Adjustment Affects Split Point',
		internalDescription: "True if an adjustment affects and event's split point, false otherwise",
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.adjustments.*.affectsExternalSettlementOnly': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.adjustments.*.affectsExternalSettlementOnly',
		emsPublicIdentifier: 'talentPayouts.*.adjustments.*.affectsExternalSettlementOnly',
		internalLabel: 'Adjustment Affects External Settlement',
		internalDescription: 'True if an adjustment affects external settlement only, false otherwise',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.adjustments.*.isArtistBonusAdjustment': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.adjustments.*.isArtistBonusAdjustment',
		emsPublicIdentifier: 'talentPayouts.*.adjustments.*.isArtistBonusAdjustment',
		internalLabel: 'Adjustment is Artist Bonus',
		internalDescription: 'True if an adjustment should be treated as an artist bonus, false otherwise',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
	},
	// #endregion talentPayouts.*.adjustments.*
	'talentPayouts.*.adjustmentsTotal': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.adjustmentsTotal',
		emsPublicIdentifier: 'talentPayouts.*.adjustmentsTotal',
		internalLabel: 'Adjustments total',
		internalDescription: 'Sum of all adjustments.amount over this talentPayout',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	// #region talentPayouts.*.agentPayouts.*
	'talentPayouts.*.agentPayouts.*': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.agentPayouts.*',
		emsPublicIdentifier: 'talentPayouts.*.agentPayouts.*',
		internalLabel: 'Agent Payouts',
		internalDescription: 'A list of payouts to agents for a given talent deal',
		calculation: '',
		type: EMSFieldType.EMSAgentsArray,
		isRaw: true,
		costCalcDependent: true,
	},
	'talentPayouts.*.agentPayouts.*.id': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.agentPayouts.*.id',
		emsPublicIdentifier: 'talentPayouts.*.agentPayouts.*.id',
		internalLabel: 'Agent Payouts',
		internalDescription: 'A list of payouts to agents for a given talent deal',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		// only on tours????
		isNullable: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.agentPayouts.*.talentPayoutIndex': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.agentPayouts.*.talentPayoutIndex',
		emsPublicIdentifier: 'talentPayouts.*.agentPayouts.*.talentPayoutIndex',
		internalLabel: 'Agent Payouts',
		internalDescription: 'A list of payouts to agents for a given talent deal',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.agentPayouts.*.talentPayoutName': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.agentPayouts.*.talentPayoutName',
		emsPublicIdentifier: 'talentPayouts.*.agentPayouts.*.talentPayoutName',
		internalLabel: 'Agent Payouts',
		internalDescription: 'A list of payouts to agents for a given talent deal',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.agentPayouts.*.agentId': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.agentPayouts.*.agentId',
		emsPublicIdentifier: 'talentPayouts.*.agentPayouts.*.agentId',
		internalLabel: 'Agent Payout Agent Id',
		internalDescription: 'The ID of the agent for a given agent payout',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.agentPayouts.*.agentName': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.agentPayouts.*.agentName',
		emsPublicIdentifier: 'talentPayouts.*.agentPayouts.*.agentName',
		internalLabel: 'Agent Payout Agent Name',
		internalDescription: 'The name of the agent for a given agent payout',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.agentPayouts.*.agentCommissionPayout': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.agentPayouts.*.agentCommissionPayout',
		emsPublicIdentifier: 'talentPayouts.*.agentPayouts.*.agentCommissionPayout',
		internalLabel: 'Agent Payout Commission',
		internalDescription: 'The total payout for a given agent on a given talent deal',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: true,
	},
	'talentPayouts.*.agentPayouts.*.agentCommissionPercent': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.agentPayouts.*.agentCommissionPercent',
		emsPublicIdentifier: 'talentPayouts.*.agentPayouts.*.agentCommissionPercent',
		internalLabel: 'Agent Payout Commissin Percent',
		internalDescription: 'The % of the artist payout that will be used to compute agentCommissionPayout',
		calculation: '',
		type: EMSFieldType.percentage,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.agentPayouts.*.contributingArtistPayoutTowardAgentPayout': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.agentPayouts.*.contributingArtistPayoutTowardAgentPayout',
		emsPublicIdentifier: 'talentPayouts.*.agentPayouts.*.contributingArtistPayoutTowardAgentPayout',
		internalLabel: 'Artist Payout Toward Agent Payout',
		internalDescription: 'Sum of artist payouts that contribute to this agentPayout',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.agentPayouts.*.talentPayoutId': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.agentPayouts.*.talentPayoutId',
		emsPublicIdentifier: 'talentPayouts.*.agentPayouts.*.talentPayoutId',
		internalLabel: 'Artist Payout Toward Agent Payout',
		internalDescription: 'Sum of artist payouts that contribute to this agentPayout',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		// only on tours???
		isNullable: true,
		costCalcDependent: false,
	},
	// #endregion talentPayouts.*.agentPayouts.*
	'talentPayouts.*.balancePaymentTotals': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.balancePaymentTotals',
		emsPublicIdentifier: 'talentPayouts.*.balancePaymentTotals',
		internalLabel: 'Balance Payment totals',
		internalDescription: 'The total of all the balance payments for this talent deal on this event',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: false,
	},
	// #region talentPayouts.*.bonuses.*
	'talentPayouts.*.bonuses.*': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.bonuses.*',
		emsPublicIdentifier: 'talentPayouts.*.bonuses.*',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.EMSBonusArray,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.bonuses.*.id': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.bonuses.*.id',
		emsPublicIdentifier: 'talentPayouts.*.bonuses.*.id',
		internalLabel: '',
		internalDescription: 'The id for this bonus. isNullable for TMS',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: false,
		// only on tours
		isNullable: true,
		costCalcDependent: true,
	},
	'talentPayouts.*.bonuses.*.parentId': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.bonuses.*.parentId',
		emsPublicIdentifier: 'talentPayouts.*.bonuses.*.parentId',
		internalLabel: '',
		internalDescription: 'The id for the EMSTalentPayout for which this EMSBonus is a child',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.bonuses.*.amount': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.bonuses.*.amount',
		emsPublicIdentifier: 'talentPayouts.*.bonuses.*.amount',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
	},
	'talentPayouts.*.bonuses.*.payoutTotal': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.bonuses.*.payoutTotal',
		emsPublicIdentifier: 'talentPayouts.*.bonuses.*.payoutTotal',
		internalLabel: 'Bonus Payout Total',
		internalDescription:
			'The payout amount of a bonus in dollars. This amount can not be negative since that would imply the artist owes money to the organisation.',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.bonuses.*.payoutTotalWithNegative': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.bonuses.*.payoutTotalWithNegative',
		emsPublicIdentifier: 'talentPayouts.*.bonuses.*.payoutTotalWithNegative',
		internalLabel: 'Bonus Payout Total (Negative)',
		internalDescription:
			'The payout amount of a bonus in dollars. This one can be allowed to be negative since it is only used in the UI for display purposes.',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.bonuses.*.after': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.bonuses.*.after',
		emsPublicIdentifier: 'talentPayouts.*.bonuses.*.after',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.AfterType,
		isNullable: true,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.bonuses.*.afterAmount': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.bonuses.*.afterAmount',
		emsPublicIdentifier: 'talentPayouts.*.bonuses.*.afterAmount',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.bonuses.*.type': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.bonuses.*.type',
		emsPublicIdentifier: 'talentPayouts.*.bonuses.*.type',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.BonusTypes,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.bonuses.*.selectedRetroBonusId': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.bonuses.*.selectedRetroBonusId',
		emsPublicIdentifier: 'talentPayouts.*.bonuses.*.selectedRetroBonusId',
		internalLabel: '',
		internalDescription: 'The ID of the retro bonus selected for the talent deal',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
		isNullable: true,
	},
	'talentPayouts.*.bonuses.*.isPercentageBonus': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.bonuses.*.isPercentageBonus',
		emsPublicIdentifier: 'talentPayouts.*.bonuses.*.isPercentageBonus',
		internalLabel: '',
		internalDescription: 'Flag to determine is bonus is % of net or gross to help with currency converting',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: false,
		// this seems wrong...
		isNullable: true,
	},
	'talentPayouts.*.bonuses.*.currency': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.bonuses.*.currency',
		emsPublicIdentifier: 'talentPayouts.*.bonuses.*.currency',
		internalLabel: '',
		internalDescription: 'The currency to be use for bonus conversion',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: false,
		// this seems wrong
		isNullable: true,
	},
	'talentPayouts.*.bonuses.*.convertedPayoutTotal': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.bonuses.*.convertedPayoutTotal',
		emsPublicIdentifier: 'talentPayouts.*.bonuses.*.convertedPayoutTotal',
		internalLabel: '',
		internalDescription: 'The payout total converted to the currency of the event',
		calculation: 'talentPayouts.*.bonuses.*.payoutTotal / talentPayouts.*.exchangeRate',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		// this seems wrong
		costCalcDependent: true,
	},
	'talentPayouts.*.bonuses.*.dealOneLiner': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.bonuses.*.dealOneLiner',
		emsPublicIdentifier: 'talentPayouts.*.bonuses.*.dealOneLiner',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: true,
	},
	// #endregion talentPayouts.*.bonuses.*
	// #region talentPayouts.*.payments.*
	'talentPayouts.*.payments.*': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.payments.*',
		emsPublicIdentifier: 'talentPayouts.*.payments.*',
		internalLabel: 'Payments',
		internalDescription:
			'List of payments associated with this talent payout. 2 payouts per talent deal, a Deposit and a Settlement.',
		calculation: '',
		type: EMSFieldType.EMSPaymentsArray,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.payments.*.paidDate': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.payments.*.paidDate',
		emsPublicIdentifier: 'talentPayouts.*.payments.*.paidDate',
		internalLabel: 'Paid Date for deposit',
		internalDescription: 'The date of payment for the deposit paid to othe artist(s)',
		calculation: '',
		type: EMSFieldType.date,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.payments.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'talentPayouts.*.payments.*.id',
		emsPublicIdentifier: 'talentPayouts.*.payments.*.id',
		internalLabel: 'id',
		internalDescription: 'Front-end generated id for the payment',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.payments.*.collectedBy': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.payments.*.collectedBy',
		emsPublicIdentifier: 'talentPayouts.*.payments.*.collectedBy',
		internalLabel: 'Collected By',
		internalDescription: 'Who collected the deposit or settlement payment',
		calculation: '',
		type: EMSFieldType.CollectedBy,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.payments.*.dueDate': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.payments.*.dueDate',
		emsPublicIdentifier: 'talentPayouts.*.payments.*.dueDate',
		internalLabel: 'Payment due date',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.date,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.payments.*.dueDays': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.payments.*.dueDays',
		emsPublicIdentifier: 'talentPayouts.*.payments.*.dueDays',
		internalLabel: 'Payment due days',
		internalDescription: 'The number of days before the event the payment is due.',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
		isNullable: true,
	},
	'talentPayouts.*.payments.*.notes': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.payments.*.notes',
		emsPublicIdentifier: 'talentPayouts.*.payments.*.notes',
		internalLabel: 'Payment notes',
		internalDescription: 'User specified notes for the payment',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.payments.*.status': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.payments.*.status',
		emsPublicIdentifier: 'talentPayouts.*.payments.*.status',
		internalLabel: 'Payment status',
		internalDescription: 'The status of the payment. This status is manually set by the user.',
		calculation: '',
		type: EMSFieldType.PaymentStatus,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.payments.*.talentId': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.payments.*.talentId',
		emsPublicIdentifier: 'talentPayouts.*.payments.*.talentId',
		internalLabel: 'Payment talent id',
		internalDescription: 'The id for the talent deal that this payment is associated with',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.payments.*.rentalId': {
		isSMSOnly: false,
		emsMetadataId: 'talentPayouts.*.payments.*.rentalId',
		emsPublicIdentifier: 'talentPayouts.*.payments.*.rentalId',
		internalLabel: 'Payment rental id',
		internalDescription: 'The id for the rental deal that this payment is associated with',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		costCalcDependent: false,
		isNullable: true,
	},
	'talentPayouts.*.payments.*.total': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.payments.*.total',
		emsPublicIdentifier: 'talentPayouts.*.payments.*.total',
		internalLabel: 'Payment total',
		internalDescription:
			"The total amount for the payment. For deposits, this is sometimes a flat deposit amount and others it is derived as a percentage of the guarantee. For Settlements, this number is computed based on the deal and the show's performance.",
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.payments.*.artistPayTotal': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.payments.*.artistPayTotal',
		emsPublicIdentifier: 'talentPayouts.*.payments.*.artistPayTotal',
		internalLabel: 'Payment total (artist pay currency)',
		internalDescription: 'The total amount for the payment in the artist pay currency',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.payments.*.type': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.payments.*.type',
		emsPublicIdentifier: 'talentPayouts.*.payments.*.type',
		internalLabel: 'Payment type',
		internalDescription: 'Type of the payment. Deposit or Settlement',
		calculation: '',
		type: EMSFieldType.PaymentType,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.payments.*.depositId': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.payments.*.depositId',
		emsPublicIdentifier: 'talentPayouts.*.payments.*.depositId',
		internalLabel: 'Deposit ID',
		internalDescription: 'The ID of the deposit in the DB if the payment is a deposit; null otherwise',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.payments.*.payoutType': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.payments.*.payoutType',
		emsPublicIdentifier: 'talentPayouts.*.payments.*.payoutType',
		internalLabel: 'Payout Type',
		internalDescription: 'The type of payout, either "flat" or "percentage"',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		isNullable: false,
		costCalcDependent: false,
	},
	'talentPayouts.*.payments.*.rawPaymentAmount': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.payments.*.rawPaymentAmount',
		emsPublicIdentifier: 'talentPayouts.*.payments.*.rawPaymentAmount',
		internalLabel: 'Raw Payment Amount',
		internalDescription:
			'The raw number for input from the user for the deposit amount. if payoutType === "flat", the number is a dollar amount, if payoutType === "percent" the number is a percentage. This is only cost calc dependent for settlements',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		isNullable: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.payments.*.paidAmount': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.payments.*.paidAmount',
		emsPublicIdentifier: 'talentPayouts.*.payments.*.paidAmount',
		internalLabel: 'Raw Paid Amount',
		internalDescription:
			'The raw number for input from the user for the paid deposit amount. When setting a deposit to paid this value is used to set the paid amount.',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.payments.*.useSpecificDueDate': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.payments.*.useSpecificDueDate',
		emsPublicIdentifier: 'talentPayouts.*.payments.*.useSpecificDueDate',
		internalLabel: 'Use Specific Date',
		internalDescription:
			"True if the payment's due date is a specific date; false if the payment's date is relative",
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		isNullable: false,
		costCalcDependent: false,
	},
	'talentPayouts.*.payments.*.totalPaymentAmount': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.payments.*.totalPaymentAmount',
		emsPublicIdentifier: 'talentPayouts.*.payments.*.totalPaymentAmount',
		internalLabel: 'Total Payment Amount',
		internalDescription:
			'The effective total amount for the payment. This considers the paid amount if defined and the payment total if this is not the case',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		isNullable: false,
		costCalcDependent: true,
	},
	// #endregion talentPayouts.*.payments.*
	'talentPayouts.*.isValid': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.isValid',
		emsPublicIdentifier: 'talentPayouts.*.isValid',
		internalLabel: 'Valid Payout?',
		internalDescription: 'Is a given payout valid or invalid?',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.remainingAgentArtistBalance': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.remainingAgentArtistBalance',
		emsPublicIdentifier: 'talentPayouts.*.remainingAgentArtistBalance',
		internalLabel: 'The amount of money the agent owes to the artist',
		internalDescription:
			'The amount to be paid by the agent to the artist during settlement. (Total earned - commission = net earnings after commission - deposit recieved - settlement recieved + adjustments) = remaining Agent Artist Balance. Labeled as total owed to artist on the agent artist balance table.',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		isNullable: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.artistShowBalance': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.artistShowBalance',
		emsPublicIdentifier: 'talentPayouts.*.artistShowBalance',
		internalLabel: 'Artist Show Balance',
		internalDescription: 'The balance remaining for this artist on this show.',
		calculation: 'remainingAgentArtistBalance - total of balance payments',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		isNullable: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.artistShowIsBalanced': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.artistShowIsBalanced',
		emsPublicIdentifier: 'talentPayouts.*.artistShowIsBalanced',
		internalLabel: 'Is Artist Show Balanced?',
		internalDescription: 'True if artistShowBalance === 0, false otherwise',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		isNullable: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.remainingSettlementPayout': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.remainingSettlementPayout',
		emsPublicIdentifier: 'talentPayouts.*.remainingSettlementPayout',
		internalLabel: 'Remaining Settlement Payout',
		internalDescription: 'The amount to be paid during settlement. (Artist payout less deposit)',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		isNullable: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.convertedDocumentDeposit': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.convertedDocumentDeposit',
		emsPublicIdentifier: 'talentPayouts.*.convertedDocumentDeposit',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.crossCollateralized': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.crossCollateralized',
		emsPublicIdentifier: 'talentPayouts.*.crossCollateralized',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: false,
	},
	'talentPayouts.*.coProOverrideArtistPayout': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.coProOverrideArtistPayout',
		emsPublicIdentifier: 'talentPayouts.*.coProOverrideArtistPayout',
		internalLabel: 'Artist payout overriden for co pro',
		internalDescription: `The overriden value for the artist payout in coPro deal`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: true,
		isNullable: true,
	},
	'talentPayouts.*.hasCoProOverrideArtistPayout': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.hasCoProOverrideArtistPayout',
		emsPublicIdentifier: 'talentPayouts.*.hasCoProOverrideArtistPayout',
		internalLabel: 'if the artist payout has overriden for co pro',
		internalDescription: `True when there are overriden value for the artist payout in coPro deal false otherwise`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: false,
	},
	'talentPayouts.*.dealTransliteration': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.dealTransliteration',
		emsPublicIdentifier: 'talentPayouts.*.dealTransliteration',
		internalLabel: 'Deal Description',
		internalDescription: 'Single line description of the deal.',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Deal Summary',
			ExternalReported: 'Deal Summary (Settlement)',
			ExternalBudgeted: 'Deal Summary (Offer)',
		},
		userFacingDescription: 'A one line summary, including bonus description if applicable',
	},
	'talentPayouts.*.dealContainsManualSplit': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.dealContainsManualSplit',
		emsPublicIdentifier: 'talentPayouts.*.dealContainsManualSplit',
		internalLabel: 'Does the deal contain a manual split point',
		internalDescription: 'Does the deal contain a manual split point?',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: false,
	},
	'talentPayouts.*.retroactiveBonusSwitchesText': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.retroactiveBonusSwitchesText',
		emsPublicIdentifier: 'talentPayouts.*.retroactiveBonusSwitchesText',
		internalLabel: 'Retroactive deal information',
		internalDescription: 'Single line description of the retroactive deal, if any',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.simpleDealTransliteration': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.simpleDealTransliteration',
		emsPublicIdentifier: 'talentPayouts.*.simpleDealTransliteration',
		internalLabel: 'A simplefied version of the deal description used as part of the waterfall in pdfs',
		internalDescription:
			'Single line description of the deal. Does not give enough context to describe the deal on its own.',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.convertedDocumentGuarantee': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.convertedDocumentGuarantee',
		emsPublicIdentifier: 'talentPayouts.*.convertedDocumentGuarantee',
		internalLabel: "The guarantee converted to the event's currency",
		internalDescription: "The guarantee in the event's currency",
		calculation: 'talentPayouts.*.guarantee / talentPayouts.*.exchangeRate',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: 'Guarantee',
		userFacingDescription: 'The artist guarantee',
	},
	'talentPayouts.*.dealType': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.dealType',
		emsPublicIdentifier: 'talentPayouts.*.dealType',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.DealTypes,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: 'Deal Type',
		userFacingDescription: 'Flat, Door, Plus or Versus deals',
	},
	'talentPayouts.*.retroactiveAfterType': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.retroactiveAfterType',
		emsPublicIdentifier: 'talentPayouts.*.retroactiveAfterType',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.AfterType,
		isRaw: false,
		isNullable: true,
		costCalcDependent: true,
	},
	'talentPayouts.*.retroactiveBonus': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.retroactiveBonus',
		emsPublicIdentifier: 'talentPayouts.*.retroactiveBonus',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.retroactiveBonuses.*': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.retroactiveBonuses.*',
		emsPublicIdentifier: 'talentPayouts.*.retroactiveBonuses.*',
		internalLabel: 'Retroactive Bonuses',
		internalDescription: 'All of the retroactive bonuses attached to the talent deal',
		calculation: '',
		type: EMSFieldType.EMSRetroactiveBonusArray,
		isRaw: true,
		costCalcDependent: true,
	},
	'talentPayouts.*.retroactiveBonuses.*.id': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.retroactiveBonuses.*.id',
		emsPublicIdentifier: 'talentPayouts.*.retroactiveBonuses.*.id',
		internalLabel: 'Retro Bonus ID',
		internalDescription: 'The ID of the retro bonus in the DB',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.retroactiveBonuses.*.retroactiveAfterAmount': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.retroactiveBonuses.*.retroactiveAfterAmount',
		emsPublicIdentifier: 'talentPayouts.*.retroactiveBonuses.*.retroactiveAfterAmount',
		internalLabel: 'Retro Bonus Trigger',
		internalDescription: 'The amount at/after which we trigger a bonus',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
	},
	'talentPayouts.*.retroactiveBonuses.*.retroactiveBonusPercentage': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.retroactiveBonuses.*.retroactiveBonusPercentage',
		emsPublicIdentifier: 'talentPayouts.*.retroactiveBonuses.*.retroactiveBonusPercentage',
		internalLabel: 'Retroactive Bonus Percentage',
		internalDescription: 'The percentage of the chosen event numbers with which the retro bonus can be calculated',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.retroactiveBonuses.*.thresholdType': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.retroactiveBonuses.*.thresholdType',
		emsPublicIdentifier: 'talentPayouts.*.retroactiveBonuses.*.thresholdType',
		internalLabel: 'Threshold Type',
		internalDescription: 'When the retro active bonus triggers, defaults to "at"',
		calculation: '',
		type: EMSFieldType.ThresholdType,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.retroactiveBonuses.*.retroactiveBonusSwitchesText': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.retroactiveBonuses.*.retroactiveBonusSwitchesText',
		emsPublicIdentifier: 'talentPayouts.*.retroactiveBonuses.*.retroactiveBonusSwitchesText',
		internalLabel: 'Retro Bonus switch text',
		internalDescription: 'Single line description of the retroactive deal, if any',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.dealDescription': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.dealDescription',
		emsPublicIdentifier: 'talentPayouts.*.dealDescription',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.dealStatus': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.dealStatus',
		emsPublicIdentifier: 'talentPayouts.*.dealStatus',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.DealStatus,
		isRaw: true,
		costCalcDependent: false,
		isNullable: true,
		userFacingLabel: 'Deal Status',
		userFacingDescription: 'The status of the deal',
	},
	'talentPayouts.*.dealStatusLastUpdatedDate': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.dealStatusLastUpdatedDate',
		emsPublicIdentifier: 'talentPayouts.*.dealStatusLastUpdatedDate',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		isNullable: true,
		userFacingLabel: 'Deal Status Last Updated',
		userFacingDescription: "The last time this deal's status was updated in Prism",
	},
	'talentPayouts.*.contractDueDate': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.contractDueDate',
		emsPublicIdentifier: 'talentPayouts.*.contractDueDate',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		isNullable: true,
		userFacingLabel: 'Contract Due Date',
		userFacingDescription: 'The date this contract is due',
	},
	'talentPayouts.*.hiddenFromCoPro': {
		isSMSOnly: false,
		emsMetadataId: 'talentPayouts.*.hiddenFromCoPro',
		emsPublicIdentifier: 'talentPayouts.*.hiddenFromCoPro',
		internalLabel: 'Talent payout hidden from co pro',
		internalDescription: `true if this talent payout is hidden from the co pro page, false otherwise`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.isCapped': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.isCapped',
		emsPublicIdentifier: 'talentPayouts.*.isCapped',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.isTalentFromArtistRoster': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.isTalentFromArtistRoster',
		emsPublicIdentifier: 'talentPayouts.*.isTalentFromArtistRoster',
		internalLabel: 'Is talent from Artist Roster',
		internalDescription: 'Lets us know if the talent entry is from the artist roster or not.',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.depositAmount': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.depositAmount',
		emsPublicIdentifier: 'talentPayouts.*.depositAmount',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: 'Deposit Amount',
		userFacingDescription: 'The sum of all deposits on the deal',
	},
	'talentPayouts.*.depositSummary': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.depositSummary',
		emsPublicIdentifier: 'talentPayouts.*.depositSummary',
		internalLabel: 'List of all deposits',
		internalDescription: 'Lists out all of the deposits for a talent payout',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: 'Deposit(s) Summary',
		userFacingDescription: 'A bulleted list of all deposits for an artist',
		isEventStatusDependent: true,
	},
	'talentPayouts.*.depositAmountInEventCurrency': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.depositAmountInEventCurrency',
		emsPublicIdentifier: 'talentPayouts.*.depositAmountInEventCurrency',
		internalLabel: "The deposit amount converted to the event's currency",
		internalDescription: "The deposit amount in the event's currency",
		calculation: 'talentPayouts.*.depositAmount / talentPayouts.*.exchangeRate',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: 'Deposit Amount',
		userFacingDescription: 'The sum of all deposits on the deal',
	},
	'talentPayouts.*.exchangeRate': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.exchangeRate',
		emsPublicIdentifier: 'talentPayouts.*.exchangeRate',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.guarantee': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.guarantee',
		emsPublicIdentifier: 'talentPayouts.*.guarantee',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'Guarantee',
		userFacingDescription: 'The artist guarantee',
	},
	'talentPayouts.*.hasArtistEarningsOverride': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.hasArtistEarningsOverride',
		emsPublicIdentifier: 'talentPayouts.*.hasArtistEarningsOverride',
		internalLabel: 'Has Artist Earnings Override',
		internalDescription: 'Does this talent deal have an earnings override or not?',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.hasWithholding': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.hasWithholding',
		emsPublicIdentifier: 'talentPayouts.*.hasWithholding',
		internalLabel: 'Talent Has Withholding',
		internalDescription: 'If true, the talent has withholding taken out; false otherwise',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.id': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.id',
		emsPublicIdentifier: 'talentPayouts.*.id',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.includeInDeal': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.includeInDeal',
		emsPublicIdentifier: 'talentPayouts.*.includeInDeal',
		internalLabel: 'Include In Deal',
		internalDescription: 'True if the talent payout is included in the deal, false otherwise',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.talentName': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.talentName',
		emsPublicIdentifier: 'talentPayouts.*.talentName',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: false,
		userFacingLabel: 'Artist Name',
		userFacingDescription: 'The name of the artist on the deal',
	},
	'talentPayouts.*.isHeadliner': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.isHeadliner',
		emsPublicIdentifier: 'talentPayouts.*.isHeadliner',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.totalAdjustmentsTowardSettlement': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.totalAdjustmentsTowardSettlement',
		emsPublicIdentifier: 'talentPayouts.*.totalAdjustmentsTowardSettlement',
		internalLabel: 'Total Adjustments added to split point',
		internalDescription:
			'The total sum of adjustments that are added to the values included in external settlement',
		calculation: 'Sum of adjustments where affects_external_settlement === true',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: false,
	},
	'talentPayouts.*.totalAdjustmentsTowardSplitPoint': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.totalAdjustmentsTowardSplitPoint',
		emsPublicIdentifier: 'talentPayouts.*.totalAdjustmentsTowardSplitPoint',
		internalLabel: 'Total Adjustments added to split point',
		internalDescription:
			'The total sum of adjustments that are added to the values included in split point calculations',
		calculation: 'Sum of adjustments where affects_split_point === true',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.totalNonEarningsAdjustments': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.totalNonEarningsAdjustments',
		emsPublicIdentifier: 'talentPayouts.*.totalNonEarningsAdjustments',
		internalLabel: 'Total non-earning adjustments',
		internalDescription: 'The total sum of adjustments that are not included in artist earnings',
		calculation: 'Sum of adjustments where affects_artist_earnings === true',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: true,
	},
	'talentPayouts.*.totalAdjustmentsTowardWithholding': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.totalAdjustmentsTowardWithholding',
		emsPublicIdentifier: 'talentPayouts.*.totalAdjustmentsTowardWithholding',
		internalLabel: 'Total Adjustments Included In Withholding',
		internalDescription: 'The total sum of adjustments that affect withholding',
		calculation: 'Sum of adjustments where affects_withholding === true',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: false,
	},
	'talentPayouts.*.withheldTotal': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.withheldTotal',
		emsPublicIdentifier: 'talentPayouts.*.withheldTotal',
		internalLabel: 'Total Withheld',
		internalDescription: 'The total amount withheld from this talent deal',
		calculation: 'artistPayoutBeforeAdjustments * withholdingPercentage / 100',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.withholdingDueDaysAfter': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.withholdingDueDaysAfter',
		emsPublicIdentifier: 'talentPayouts.*.withholdingDueDaysAfter',
		internalLabel: 'Withholding Due Days After',
		internalDescription: 'How many days after the event will the withholding be due',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.withholdingPercentage': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.withholdingPercentage',
		emsPublicIdentifier: 'talentPayouts.*.withholdingPercentage',
		internalLabel: 'Withholding Percentage',
		internalDescription: 'The percent of the artist payout that is withheld',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.withholdingThreshold': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.withholdingThreshold',
		emsPublicIdentifier: 'talentPayouts.*.withholdingThreshold',
		internalLabel: 'Withholding Threshold',
		internalDescription: 'The threshold at which the withholding kicks in',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.withholdingThresholdMet': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.withholdingThresholdMet',
		emsPublicIdentifier: 'talentPayouts.*.withholdingThresholdMet',
		internalLabel: 'Withholding Threshold met',
		internalDescription: 'True if the withholdingTheshold has been met, false otherwise',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.royaltyPercentage': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.royaltyPercentage',
		emsPublicIdentifier: 'talentPayouts.*.royaltyPercentage',
		internalLabel: 'Royalty Percentage',
		internalDescription: 'The royalty percentage of this artist deal',
		calculation: '',
		type: EMSFieldType.percentage,
		isRaw: true,
		costCalcDependent: false,
	},
	'talentPayouts.*.royaltyAmount': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.royaltyAmount',
		emsPublicIdentifier: 'talentPayouts.*.royaltyAmount',
		userFacingLabel: 'Royalty',
		internalLabel: 'Royalty',
		internalDescription: 'The royalty amount calculated for this artist deal',
		calculation: 'ticketCommissionTotals.nagbor * (royaltyPercentage / 100)',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.talentPromoterProfitAmount': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.talentPromoterProfitAmount',
		emsPublicIdentifier: 'talentPayouts.*.talentPromoterProfitAmount',
		userFacingLabel: 'Talent Promoter Profit Amount',
		internalLabel: 'Talent Promoter Profit Amount',
		internalDescription: `The talent promoter profit amount will be the same as the EMS/TMS promoter profit amount, except when it involves a tour deal that is cross-collateralized.
			In that case, it will be calculated as the EMS/TMS promoter profit amount plus the product of the tour costs and the deal promoter profit percentage.`,
		calculation: `if(ems is TMS and tour deal is cross-collateralized) {
				return ems.promoterProfitAmount + tourFixedCostsTotal * ((tourDeal.promoterProfitPct / 100) * tourFixedCostsTotal);
			} else {
				return ems.promoterProfitAmount;
			}`,
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.talentNetProfit': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.talentNetProfit',
		emsPublicIdentifier: 'talentPayouts.*.talentNetProfit',
		userFacingLabel: 'Talent Net Profit',
		internalLabel: 'Talent Net Profit',
		internalDescription: `The talentNetProfit will mirror the netProfit for EMS/SMS.
			For TMS, we will always subtract the tourFixedCostsTotal from the netProfit for intermediate calculations.
			However, we will not subtract it from the talentNetProfit unless the deal is cross-collateralized.
			In cases where the deal is cross-collateralized, we will deduct the tourFixedCostsTotal from the talentNetDeal.
			Additionally, we must also deduct the additional promoter profit amount, which is influenced by the tour costs, but this adjustment is only necessary for cross deals`,
		calculation: `if (ems is TMS) {
				ems.netProfit = ems.netProfit - tourFixedCostsTotal;
				if (tour deal is cross-collateralized) {
					return ems.netProfit - additionalPromoterProfitDueToTourCosts;
				} else {
					return ems.netProfit + tourFixedCostsTotal;
					(We add tourFixedCostsTotal because in this scenario we still need to discount tourFixedCostsTotal from the netProfit but we shouldn't discount it from the talentNetProfit)
				}
			} else {
				return ems.netProfit
			}`,
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.talentExpenses': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.talentExpenses',
		emsPublicIdentifier: 'talentPayouts.*.talentExpenses',
		userFacingLabel: 'Talent Expenses',
		internalLabel: 'Talent Expenses',
		internalDescription: `The talentExpenses will mirror the expenses for EMS/SMS.
			For TMS, we will always add the tourFixedCostsTotal to the expenses for intermediate calculations.
			However, we will not add it to the talentExpenses unless the deal is cross-collateralized.
			In cases where the deal is cross-collateralized, we will add the tourFixedCostsTotal to the talentExpenses.`,
		calculation: `if (ems is TMS) {
				ems.netProfit = ems.netProfit - tourFixedCostsTotal;
				if (tour deal is cross-collateralized) {
					return ems.netProfit - additionalPromoterProfitDueToTourCosts;
				} else {
					return ems.netProfit + tourFixedCostsTotal;
					(We add tourFixedCostsTotal because in this scenario we still need to discount tourFixedCostsTotal from the netProfit but we shouldn't discount it from the talentNetProfit)
				}
			} else {
				return ems.netProfit
			}`,
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.talentNetRevenueToSplit': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.talentNetRevenueToSplit',
		emsPublicIdentifier: 'talentPayouts.*.talentNetRevenueToSplit',
		userFacingLabel: 'Talent net revenue to split',
		internalLabel: 'Talent net revenue to split',
		internalDescription: `The talent net revenue to split will be the same as the EMS/TMS net revenue to split, except when it involves a tour deal that is cross-collateralized.
			 In that case, it will be calculated as the EMS/TMS net revenue to split minus the tour costs.`,
		calculation: `if(ems is TMS and deal is cross-collateralized) {
				ems.netRevenueToSplit - tourFixedCostsTotal
			} else {
				ems.netRevenueToSplit
			}`,
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.talentSplitPoint': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.talentSplitPoint',
		emsPublicIdentifier: 'talentPayouts.*.talentSplitPoint',
		userFacingLabel: 'Talent split point',
		internalLabel: 'Talent split point',
		internalDescription: `The talent split point will be the same as the EMS/TMS split point, except when it involves a tour deal that is cross-collateralized.
			 In that case, it will be calculated as the EMS/TMS split point plus the tour costs.`,
		calculation: `if(ems is TMS and deal is cross-collateralized) {
				ems.splitPoint + tourFixedCostsTotal
			} else {
				ems.splitPoint
			}`,
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'talentPayouts.*.talentConvertedDocumentSplitPoint': {
		isSMSOnly: false,
		category: 'Artist Deal',
		emsMetadataId: 'talentPayouts.*.talentConvertedDocumentSplitPoint',
		emsPublicIdentifier: 'talentPayouts.*.talentConvertedDocumentSplitPoint',
		userFacingLabel: 'Talent converted document split point',
		internalLabel: 'Talent converted document split point',
		internalDescription: `The talent converted document split point will be the same as the EMS/TMS converted doicument split point, except when it involves a tour deal that is cross-collateralized.
			 In that case, it will be calculated as the EMS/TMS converted document split point plus the tour costs.`,
		calculation: `if(ems is TMS and deal is cross-collateralized) {
				ems.convertedDocumentSplitPoint + tourFixedCostsTotal
			} else {
				ems.convertedDocumentSplitPoint
			}`,
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	// #endregion talentPayouts.*
	// #region agentPayoutTotals.*
	'agentPayoutTotals.*': {
		isSMSOnly: false,
		emsMetadataId: 'agentPayoutTotals.*',
		emsPublicIdentifier: 'agentPayoutTotals.*',
		internalLabel: 'Agent Payouts',
		internalDescription: 'An list of all the payouts per-agent per-event',
		calculation: '',
		type: EMSFieldType.EMSAgentPayoutTotalsTotalsArray,
		isRaw: false,
		costCalcDependent: true,
	},
	'agentPayoutTotals.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'agentPayoutTotals.*.id',
		emsPublicIdentifier: 'agentPayoutTotals.*.id',
		internalLabel: 'Agent payout ID',
		internalDescription: 'The user ID of the agent on a deal, or null if no agent is on the deal',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'agentPayoutTotals.*.name': {
		isSMSOnly: false,
		emsMetadataId: 'agentPayoutTotals.*.name',
		emsPublicIdentifier: 'agentPayoutTotals.*.name',
		internalLabel: 'Agent payout name',
		internalDescription: 'The human readable name of the agent on a deal, or and empty string if no name',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'agentPayoutTotals.*.contributingArtistPayoutTowardAgentPayout': {
		isSMSOnly: false,
		emsMetadataId: 'agentPayoutTotals.*.contributingArtistPayoutTowardAgentPayout',
		emsPublicIdentifier: 'agentPayoutTotals.*.contributingArtistPayoutTowardAgentPayout',
		internalLabel: 'Total Artist payout for agent',
		internalDescription: 'The total payouts for all the artists represented by this agent',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'agentPayoutTotals.*.totalAgentPayouts': {
		isSMSOnly: false,
		emsMetadataId: 'agentPayoutTotals.*.totalAgentPayouts',
		emsPublicIdentifier: 'agentPayoutTotals.*.totalAgentPayouts',
		internalLabel: 'Total agent payout across all events in the EMS',
		internalDescription: 'The total payouts for this agent across all artists on the event',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	// #endregion agentPayoutTotals.*
	// end agent payout props
	totalArtistEarningsForCommission: {
		isSMSOnly: false,
		emsMetadataId: 'totalArtistEarningsForCommission',
		emsPublicIdentifier: 'totalArtistEarningsForCommission',
		internalLabel: 'Total Artist Earnings for Commission',
		internalDescription:
			"The total of all the pre-adjusted artist payouts for every talent deal on the event that is on an agency's artist roster",
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	totalAdjustmentsTowardSettlement: {
		isSMSOnly: false,
		emsMetadataId: 'totalAdjustmentsTowardSettlement',
		emsPublicIdentifier: 'totalAdjustmentsTowardSettlement',
		internalLabel: 'Total Adjustments Towards Settlement',
		internalDescription: 'The Total of all the adjustments on the event that are for external settlement only',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		isTopLevel: false,
	},
	totalAdjustmentsTowardSplitPoint: {
		isSMSOnly: false,
		emsMetadataId: 'totalAdjustmentsTowardSplitPoint',
		emsPublicIdentifier: 'totalAdjustmentsTowardSplitPoint',
		internalLabel: 'Total Adjustments Towards Split Point',
		internalDescription: 'The Total of all the adjustments on the event that contribute to split point',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: false,
		isTopLevel: false,
	},
	totalAgentCommissionPayout: {
		isSMSOnly: false,
		emsMetadataId: 'totalAgentCommissionPayout',
		emsPublicIdentifier: 'totalAgentCommissionPayout',
		internalLabel: 'Total Agent Commission Payout',
		internalDescription:
			"The total of all agent commissions for every talent deal on the event that is on an agency's artist roster",
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	totalCoProSplit: {
		isSMSOnly: false,
		emsMetadataId: 'totalCoProSplit',
		emsPublicIdentifier: 'totalCoProSplit',
		internalLabel: 'Total Co Pro Split',
		internalDescription: 'Total of all co-pro share splits on the event',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	totalCoProPayout: {
		isSMSOnly: false,
		emsMetadataId: 'totalCoProPayout',
		emsPublicIdentifier: 'totalCoProPayout',
		internalLabel: 'Total Co Pro Payout',
		internalDescription: 'total co pro splits + total co pro bonues',
		calculation: 'totalCoProSplit + totalCoProTicketBonuses',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	totalCoProTicketBonuses: {
		isSMSOnly: false,
		emsMetadataId: 'totalCoProTicketBonuses',
		emsPublicIdentifier: 'totalCoProTicketBonuses',
		internalLabel: 'Total Co Pro Ticket Bonuses',
		internalDescription: 'Total of all the co pro ticket bonuses on the event',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	totalCoProAdjustments: {
		isSMSOnly: false,
		emsMetadataId: 'totalCoProAdjustments',
		emsPublicIdentifier: 'totalCoProAdjustments',
		internalLabel: 'Total Co Pro Adjustments',
		internalDescription: 'Total of all the co pro adjustments on the event',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	// #region rentalData
	rentalData: {
		isObject: true,
		isSMSOnly: false,
		emsMetadataId: 'rentalData',
		emsPublicIdentifier: 'rentalData',
		internalLabel: 'Renter Data object',
		internalDescription: `always present, all numbers 0 when not Rental show`,
		calculation: '',
		type: EMSFieldType.RentalData,
		isRaw: false,
		costCalcDependent: true,
	},
	'rentalData.name': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.name',
		emsPublicIdentifier: 'rentalData.name',
		internalLabel: 'Renter Name',
		internalDescription: `Name of the renter`,
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'Renter Name',
		userFacingDescription: 'The Renter name on Rental events',
		category: 'Rental Details',
	},
	'rentalData.renterId': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.renterId',
		emsPublicIdentifier: 'rentalData.renterId',
		internalLabel: '',
		internalDescription: ``,
		calculation: '',
		type: EMSFieldType.id,
		// this seems wrong
		isNullable: true,
		isRaw: true,
		costCalcDependent: false,
	},
	'rentalData.barMinimum': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.barMinimum',
		emsPublicIdentifier: 'rentalData.barMinimum',
		internalLabel: 'Bar minimum for the Rental deal',
		internalDescription: ``,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
	},
	'rentalData.perTicketBonus': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.perTicketBonus',
		emsPublicIdentifier: 'rentalData.perTicketBonus',
		internalLabel: '',
		internalDescription: ``,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: false,
	},
	'rentalData.doesRenterOwe': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.doesRenterOwe',
		emsPublicIdentifier: 'rentalData.doesRenterOwe',
		internalLabel: 'For Rental Shows, does the renter owe any money?',
		internalDescription: `true when paymentAmount <= 0`,
		calculation: 'paymentAmount <= 0',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: true,
	},
	'rentalData.payoutOrOwes': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payoutOrOwes',
		emsPublicIdentifier: 'rentalData.payoutOrOwes',
		internalLabel: 'For Rental Shows, how much do we need to pay the renter or how much do they need to pay us?',
		internalDescription: `paymentAmount + depositAmountInEventCurrency | paymentAmount - depositAmountInEventCurrency`,
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'rentalData.roomFee': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.roomFee',
		emsPublicIdentifier: 'rentalData.roomFee',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Room fee',
			ExternalBudgeted: 'Room fee (Offer)',
		},
		userFacingDescription: 'The room fee for a Rental event either manually entered or built based on costs',
		category: 'Rental Details',
	},
	'rentalData.rentalAmount': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.rentalAmount',
		emsPublicIdentifier: 'rentalData.rentalAmount',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'rentalData.rentalAmountWithAdjustments': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.rentalAmountWithAdjustments',
		emsPublicIdentifier: 'rentalData.rentalAmountWithAdjustments',
		internalLabel: 'Rental amount including adjustments',
		internalDescription:
			'Generally people think of the rental fee on rentals as the fee + adjustments, since that is how much they get paid. We display this number for rental shows in the rental summary and internal settlement',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'rentalData.isManualRoomFee': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.isManualRoomFee',
		emsPublicIdentifier: 'rentalData.isManualRoomFee',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: false,
	},
	'rentalData.renterPercentage': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.renterPercentage',
		emsPublicIdentifier: 'rentalData.renterPercentage',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'rentalData.percentage': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.percentage',
		emsPublicIdentifier: 'rentalData.percentage',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.percentage,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'Promoter Percentage',
		userFacingDescription: 'The percentage of gross ticket sales the promoter is entitled to on a Rental event',
		category: 'Rental Details',
	},
	'rentalData.netBoxOffice': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.netBoxOffice',
		emsPublicIdentifier: 'rentalData.netBoxOffice',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Net Box Office',
			ExternalBudgeted: 'Net Box Office (Offer)',
			ExternalReported: 'Net Box Office (Settlement)',
		},
		userFacingDescription: {
			DEFAULT: 'Equals ticketing gross less taxes and pre-settlement fees on Rental events',
			ExternalBudgeted:
				'Equals maximum potential ticketing gross less taxes and pre-settlement fees on Rental events',
			ExternalReported: 'Equals total actual ticketing gross less taxes and pre-settlement fees on Rental events',
		},
		category: 'Rental Details',
	},
	'rentalData.rentalSplit': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.rentalSplit',
		emsPublicIdentifier: 'rentalData.rentalSplit',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'rentalData.depositAmount': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.depositAmount',
		emsPublicIdentifier: 'rentalData.flatDeposit',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: false,
		userFacingLabel: 'Renter Deposit',
		userFacingDescription: 'The deposit amount on a Rental event',
		category: 'Rental Details',
	},
	'rentalData.depositAmountInEventCurrency': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.depositAmountInEventCurrency',
		emsPublicIdentifier: 'rentalData.depositAmountInEventCurrency',
		internalLabel: "The deposit amount converted to the event's currency",
		internalDescription: "The deposit amount in the event's currency",
		calculation: 'rentalData.depositAmount / exchangeRate',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: 'Deposit Amount',
		userFacingDescription: 'The sum of all deposits on the rental deal',
		category: 'Rental Details',
	},
	'rentalData.bonusAmount': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.bonusAmount',
		emsPublicIdentifier: 'rentalData.bonusAmount',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Renter Bonus',
			ExternalBudgeted: 'Renter Bonus (Offer)',
			ExternalReported: 'Renter Bonus (Settlement)',
		},
		userFacingDescription: {
			DEFAULT: 'Bonus paid out to the Renter if a certain sales threshold is met on a Rental event',
			ExternalBudgeted:
				'Maximum potential bonus paid out to the Renter if a certain sales threshold is met on a Rental event',
			ExternalReported:
				'Actual bonus paid out to the Renter if a certain sales threshold is met on a Rental event',
		},
		category: 'Rental Details',
	},
	'rentalData.payoutAmount': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payoutAmount',
		emsPublicIdentifier: 'rentalData.payoutAmount',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Renter Payout',
			ExternalBudgeted: 'Renter Payout (Offer)',
			ExternalReported: 'Renter Payout (Settlement)',
		},
		userFacingDescription: {
			DEFAULT: 'Total renter payout on a Rental event',
			ExternalBudgeted: 'Total potential renter payout on a Rental event',
			ExternalReported: 'Total actual Renter Payout the renter is owed on a Rental event',
		},
		category: 'Rental Details',
	},
	'rentalData.paymentAmount': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.paymentAmount',
		emsPublicIdentifier: 'rentalData.paymentAmount',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		userFacingLabel: {
			DEFAULT: 'Promoter Payment',
			ExternalBudgeted: 'Promoter Payment (Offer)',
			ExternalReported: 'Promoter Payment (Settlement)',
		},
		userFacingDescription: {
			DEFAULT: 'The amount a renter owes a venue on a Rental event',
			ExternalBudgeted: 'The projected amount a renter would owe if a Rental event is confirmed',
			ExternalReported: 'Total total actual amount a renter owes a venue on a Rental event',
		},
		category: 'Rental Details',
	},
	// #region rentalData.rentalRoomCosts
	'rentalData.rentalRoomCosts': {
		isObject: true,
		isSMSOnly: false,
		emsMetadataId: 'rentalData.rentalRoomCosts',
		emsPublicIdentifier: 'rentalData.rentalRoomCosts',
		internalLabel: 'Itemized Renter Room Costs',
		internalDescription: 'A collection of fixed and variable costs that contribute to Rental room fees',
		calculation: '',
		type: EMSFieldType.EMSRentalRoomCosts,
		isRaw: false,
		costCalcDependent: true,
	},
	// #region rentalData.rentalRoomCosts.fixedCosts.*
	'rentalData.rentalRoomCosts.fixedCosts.*': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.rentalRoomCosts.fixedCosts.*',
		emsPublicIdentifier: 'rentalData.rentalRoomCosts.fixedCosts.*',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.EMSRentalRoomCostArray,
		isRaw: false,
		costCalcDependent: true,
	},
	'rentalData.rentalRoomCosts.fixedCosts.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.rentalRoomCosts.fixedCosts.*.id',
		emsPublicIdentifier: 'rentalData.rentalRoomCosts.fixedCosts.*.id',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: false,
		costCalcDependent: true,
	},
	'rentalData.rentalRoomCosts.fixedCosts.*.name': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.rentalRoomCosts.fixedCosts.*.name',
		emsPublicIdentifier: 'rentalData.rentalRoomCosts.fixedCosts.*.name',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: false,
	},
	'rentalData.rentalRoomCosts.fixedCosts.*.label': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.rentalRoomCosts.fixedCosts.*.label',
		emsPublicIdentifier: 'rentalData.rentalRoomCosts.fixedCosts.*.label',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: false,
	},
	'rentalData.rentalRoomCosts.fixedCosts.*.value': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.rentalRoomCosts.fixedCosts.*.value',
		emsPublicIdentifier: 'rentalData.rentalRoomCosts.fixedCosts.*.value',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'rentalData.rentalRoomCosts.fixedCosts.*.isReported': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.rentalRoomCosts.fixedCosts.*.isReported',
		emsPublicIdentifier: 'rentalData.rentalRoomCosts.fixedCosts.*.isReported',
		internalLabel: 'Is Reported?',
		internalDescription: `If the cost is reported or not`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: true,
	},
	// #endregion rentalData.rentalRoomCosts.fixedCosts.*
	// #region rentalData.rentalRoomCosts.variableCosts.*
	'rentalData.rentalRoomCosts.variableCosts.*': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.rentalRoomCosts.variableCosts.*',
		emsPublicIdentifier: 'rentalData.rentalRoomCosts.variableCosts.*',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.EMSRentalRoomCostArray,
		isRaw: false,
		costCalcDependent: true,
		canArrayIndicesVary: true,
	},
	'rentalData.rentalRoomCosts.variableCosts.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.rentalRoomCosts.variableCosts.*.id',
		emsPublicIdentifier: 'rentalData.rentalRoomCosts.variableCosts.*.id',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
		canArrayIndicesVary: true,
	},
	'rentalData.rentalRoomCosts.variableCosts.*.name': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.rentalRoomCosts.variableCosts.*.name',
		emsPublicIdentifier: 'rentalData.rentalRoomCosts.variableCosts.*.name',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: false,
		canArrayIndicesVary: true,
	},
	'rentalData.rentalRoomCosts.variableCosts.*.label': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.rentalRoomCosts.variableCosts.*.label',
		emsPublicIdentifier: 'rentalData.rentalRoomCosts.variableCosts.*.label',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: false,
		canArrayIndicesVary: true,
	},
	'rentalData.rentalRoomCosts.variableCosts.*.value': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.rentalRoomCosts.variableCosts.*.value',
		emsPublicIdentifier: 'rentalData.rentalRoomCosts.variableCosts.*.value',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		canArrayIndicesVary: true,
	},
	'rentalData.rentalRoomCosts.variableCosts.*.isReported': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.rentalRoomCosts.variableCosts.*.isReported',
		emsPublicIdentifier: 'rentalData.rentalRoomCosts.variableCosts.*.isReported',
		internalLabel: 'Is Reported?',
		internalDescription: `If the cost is reported or not`,
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		costCalcDependent: true,
		canArrayIndicesVary: true,
	},
	// #endregion rentalData.rentalRoomCosts.variableCosts.*
	// #endregion rentalData.rentalRoomCosts
	// #region rentalData.payoutAdjustments.*
	'rentalData.payoutAdjustments.*': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payoutAdjustments.*',
		emsPublicIdentifier: 'rentalData.payoutAdjustments.*',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.EMSAdjustmentsArray,
		isRaw: false,
		costCalcDependent: true,
	},
	'rentalData.payoutAdjustments.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payoutAdjustments.*.id',
		emsPublicIdentifier: 'rentalData.payoutAdjustments.*.id',
		internalLabel: 'rental payout adjustment id',
		internalDescription: 'rental payout adjustment id',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: false,
		costCalcDependent: true,
	},
	'rentalData.payoutAdjustments.*.name': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payoutAdjustments.*.name',
		emsPublicIdentifier: 'rentalData.payoutAdjustments.*.name',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: false,
	},
	'rentalData.payoutAdjustments.*.amount': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payoutAdjustments.*.amount',
		emsPublicIdentifier: 'rentalData.payoutAdjustments.*.amount',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	// #endregion rentalData.payoutAdjustments.*
	// #region rentalData.payments.*
	'rentalData.payments.*': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payments.*',
		emsPublicIdentifier: 'rentalData.payments.*',
		internalLabel: 'Payments',
		internalDescription:
			'List of payments associated with this talent payout. 2 payouts per talent deal, a Deposit and a Settlement.',
		calculation: '',
		type: EMSFieldType.EMSPaymentsArray,
		isRaw: false,
		costCalcDependent: true,
	},
	'rentalData.payments.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payments.*.id',
		emsPublicIdentifier: 'rentalData.payments.*.id',
		internalLabel: 'id',
		internalDescription: 'Front-end generated id for the payment',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		isNullable: true,
		costCalcDependent: false,
	},
	'rentalData.payments.*.paidDate': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payments.*.paidDate',
		emsPublicIdentifier: 'rentalData.payments.*.paidDate',
		internalLabel: 'Paid Date for deposit',
		internalDescription: 'The date of payment for the deposit paid to othe artist(s)',
		calculation: '',
		type: EMSFieldType.date,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'rentalData.payments.*.collectedBy': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payments.*.collectedBy',
		emsPublicIdentifier: 'rentalData.payments.*.collectedBy',
		internalLabel: 'Collected By',
		internalDescription: 'Who collected the deposit or settlement payment',
		calculation: '',
		type: EMSFieldType.CollectedBy,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'rentalData.payments.*.dueDate': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payments.*.dueDate',
		emsPublicIdentifier: 'rentalData.payments.*.dueDate',
		internalLabel: 'Payment due date',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.date,
		isRaw: true,
		costCalcDependent: false,
	},
	'rentalData.payments.*.dueDays': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payments.*.dueDays',
		emsPublicIdentifier: 'rentalData.payments.*.dueDays',
		internalLabel: 'Payment due days',
		internalDescription: 'The number of days before the event the payment is due.',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
	},
	'rentalData.payments.*.notes': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payments.*.notes',
		emsPublicIdentifier: 'rentalData.payments.*.notes',
		internalLabel: 'Payment notes',
		internalDescription: 'User specified notes for the payment',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'rentalData.payments.*.status': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payments.*.status',
		emsPublicIdentifier: 'rentalData.payments.*.status',
		internalLabel: 'Payment status',
		internalDescription: 'The status of the payment. This status is manually set by the user.',
		calculation: '',
		type: EMSFieldType.PaymentStatus,
		isRaw: false,
		costCalcDependent: true,
	},
	'rentalData.payments.*.talentId': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payments.*.talentId',
		emsPublicIdentifier: 'rentalData.payments.*.talentId',
		internalLabel: 'Payment talent id',
		internalDescription: 'The id for the talent deal that this payment is associated with',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		costCalcDependent: false,
		isNullable: true,
	},
	'rentalData.payments.*.rentalId': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payments.*.rentalId',
		emsPublicIdentifier: 'rentalData.payments.*.rentalId',
		internalLabel: 'Payment rental id',
		internalDescription: 'The id for the rental deal that this payment is associated with',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		costCalcDependent: false,
		isNullable: true,
	},
	'rentalData.payments.*.total': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payments.*.total',
		emsPublicIdentifier: 'rentalData.payments.*.total',
		internalLabel: 'Payment total',
		internalDescription:
			"The total amount for the payment. For deposits, this is sometimes a flat deposit amount and others it is derived as a percentage of the guarantee. For Settlements, this number is computed based on the deal and the show's performance.",
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	'rentalData.payments.*.artistPayTotal': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payments.*.artistPayTotal',
		emsPublicIdentifier: 'rentalData.payments.*.artistPayTotal',
		internalLabel: 'Payment total (artist pay currency)',
		internalDescription: 'The total amount for the payment in the artist pay currency',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		isNullable: true,
	},
	'rentalData.payments.*.type': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payments.*.type',
		emsPublicIdentifier: 'rentalData.payments.*.type',
		internalLabel: 'Payment type',
		internalDescription: 'Type of the payment. Deposit or Settlement',
		calculation: '',
		type: EMSFieldType.PaymentType,
		isRaw: true,
		costCalcDependent: false,
	},
	'rentalData.payments.*.depositId': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payments.*.depositId',
		emsPublicIdentifier: 'rentalData.payments.*.depositId',
		internalLabel: 'Deposit ID',
		internalDescription: 'The ID of the deposit in the DB if the payment is a deposit; null otherwise',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'rentalData.payments.*.payoutType': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payments.*.payoutType',
		emsPublicIdentifier: 'rentalData.payments.*.payoutType',
		internalLabel: 'Payout Type',
		internalDescription: 'The type of payout, either "flat" or "percentage"',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		isNullable: false,
		costCalcDependent: false,
	},
	'rentalData.payments.*.rawPaymentAmount': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payments.*.rawPaymentAmount',
		emsPublicIdentifier: 'rentalData.payments.*.rawPaymentAmount',
		internalLabel: 'Raw Payment Amount',
		internalDescription:
			'The raw number for input from the user for the deposit amount. if payoutType === "flat", the number is a dollar amount, if payoutType === "percent" the number is a percentage. This is only cost calc dependent for settlements',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		isNullable: false,
		costCalcDependent: true,
	},
	'rentalData.payments.*.paidAmount': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payments.*.paidAmount',
		emsPublicIdentifier: 'rentalData.payments.*.paidAmount',
		internalLabel: 'Raw Paid Amount',
		internalDescription:
			'The raw number for input from the user for the paid deposit amount. When setting a deposit to paid this value is used to set the paid amount.',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
	},
	'rentalData.payments.*.useSpecificDueDate': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payments.*.useSpecificDueDate',
		emsPublicIdentifier: 'rentalData.payments.*.useSpecificDueDate',
		internalLabel: 'Use Specific Date',
		internalDescription:
			"True if the payment's due date is a specific date; false if the payment's date is relative",
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: true,
		isNullable: false,
		costCalcDependent: false,
	},
	'rentalData.payments.*.totalPaymentAmount': {
		isSMSOnly: false,
		emsMetadataId: 'rentalData.payments.*.totalPaymentAmount',
		emsPublicIdentifier: 'rentalData.payments.*.totalPaymentAmount',
		internalLabel: 'Total Payment Amount',
		internalDescription:
			'The effective total amount for the payment. This considers the paid amount if defined and the payment total if this is not the case',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		isNullable: false,
		costCalcDependent: true,
	},
	// #endregion rentalData.payments.*
	// #endregion rentalData
	shouldApplyBroadwayTicketCommissionsOverrides: {
		isSMSOnly: false,
		emsMetadataId: 'shouldApplyBroadwayTicketCommissionsOverrides',
		emsPublicIdentifier: 'shouldApplyBroadwayTicketCommissionsOverrides',
		internalLabel: 'Should Apply Broadway Ticket Commissions',
		internalDescription:
			'True if this is a cost calc where broadway ticket commissions are considered (InternalActual and ExternalReported) false otherwise',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: true,
	},
	promoterProfitExpenses: {
		isSMSOnly: false,
		emsMetadataId: 'promoterProfitExpenses',
		emsPublicIdentifier: 'promoterProfitExpenses',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	isVenueSellingTickets: {
		isSMSOnly: false,
		emsMetadataId: 'isVenueSellingTickets',
		emsPublicIdentifier: 'isVenueSellingTickets',
		internalLabel: 'Is the venue doing the selling?',
		internalDescription: 'True if the venue is selling tickets, false if the renter is',
		calculation: '!isForRentalEvent || event.promoter_data.in_house_tickets',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: false,
		isTopLevel: true,
	},
	// #region eventFees
	'eventFees.*': {
		isSMSOnly: false,
		emsMetadataId: 'eventFees.*',
		emsPublicIdentifier: 'eventFees.*',
		internalLabel: 'Event fees',
		internalDescription: 'List of event fees for the event',
		calculation: '!isForRentalEvent || event.promoter_data.in_house_tickets',
		type: EMSFieldType.EMSEventFeeArray,
		isRaw: true,
		costCalcDependent: true,
	},
	'eventFees.*.id': {
		isSMSOnly: false,
		emsMetadataId: 'eventFees.*.id',
		emsPublicIdentifier: 'eventFees.*.id',
		internalLabel: 'the id of the event fee',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.id,
		isRaw: true,
		// only on tours
		isNullable: true,
		costCalcDependent: false,
	},
	'eventFees.*.type': {
		isSMSOnly: false,
		emsMetadataId: 'eventFees.*.type',
		emsPublicIdentifier: 'eventFees.*.type',
		internalLabel: 'the type of the event fee',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.EventFeeType,
		isRaw: true,
		costCalcDependent: false,
	},
	'eventFees.*.amount': {
		isSMSOnly: false,
		emsMetadataId: 'eventFees.*.amount',
		emsPublicIdentifier: 'eventFees.*.amount',
		internalLabel: 'Event Fee Amount',
		internalDescription: 'List of event fees for the event',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: true,
		costCalcDependent: false,
		isNullable: true,
	},
	'eventFees.*.displayName': {
		isSMSOnly: false,
		emsMetadataId: 'eventFees.*.displayName',
		emsPublicIdentifier: 'eventFees.*.displayName',
		internalLabel: 'Event Fee Name',
		internalDescription: 'Display name for this event fee',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'eventFees.*.name': {
		isSMSOnly: false,
		emsMetadataId: 'eventFees.*.name',
		emsPublicIdentifier: 'eventFees.*.name',
		internalLabel: 'Event Fee Name',
		internalDescription: 'The name for this event fee',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
	},
	'eventFees.*.value': {
		isSMSOnly: false,
		emsMetadataId: 'eventFees.*.value',
		emsPublicIdentifier: 'eventFees.*.value',
		internalLabel: 'Event Fee Value',
		internalDescription:
			"The value for this event fee. Calculated by multiplying the relevant ticket's sold/sellable/est_sold (or the sum when for all ticket tiers) by the fee's amount.",
		calculation: 'amount * tickets.*.sold',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	// #endregion eventFees
	totalActualInternalRevenue: {
		isSMSOnly: false,
		emsMetadataId: 'totalActualInternalRevenue',
		emsPublicIdentifier: 'totalActualInternalRevenue',
		internalLabel: 'Total Actual Internal Revenue ',
		internalDescription: 'The actual internal revenue numbers',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
		isTopLevel: true,
	},
	ticketingPlatformRebate: {
		isSMSOnly: false,
		emsMetadataId: 'ticketingPlatformRebate',
		emsPublicIdentifier: 'ticketingPlatformRebate',
		internalLabel: 'Total rebate',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
		internalDescription: {
			DEFAULT: 'Ticketing platform rebate',
			InternalEstimated: `Ticketing platform rebate - Always 0. This number comes from the linked ticket platform, and we provide no UI to edit estimated or budget numbers.`,
			InternalPotential: `Ticketing platform rebate - No variance from InternalActual.`,
			ExternalEstimated: `Ticketing platform rebate - No variance from InternalActual.`,
			ExternalBudgeted: `Ticketing platform rebate - No variance from InternalEstimated.`,
			ExternalReported: `Ticketing platform rebate - No variance from InternalActual.`,
			InternalActual: `Reported by the linked ticket platform at the ticket level, and summed up over all tickets for the event. Presumably this is computed based on actual $ of tickets sold.`,
		},
		userFacingLabel: {
			DEFAULT: 'Rebate',
			InternalActual: 'Rebate (Internal)',
		},
		userFacingDescription: {
			DEFAULT:
				'The amount of ticket rebates/royalties owed by a ticketing partner. Estimated Rebate numbers are always 0.',
			InternalActual:
				'The actual amount of ticket rebates/royalties owed by a ticketing partner. Estimated Rebate numbers are always 0.',
		},
		category: 'Revenue',
	},
	totalPlatformDiscount: {
		isSMSOnly: false,
		emsMetadataId: 'totalPlatformDiscount',
		emsPublicIdentifier: 'totalPlatformDiscount',
		internalLabel: 'Total Platform Discount',
		internalDescription: 'The sum of all platform discounts across all tickets in an event',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: false,
		isTopLevel: true,
	},
	totalPlatformTaxCollected: {
		isSMSOnly: false,
		emsMetadataId: 'totalPlatformTaxCollected',
		emsPublicIdentifier: 'totalPlatformTaxCollected',
		internalLabel: 'Total Platform Tax Collected',
		internalDescription: 'The sum of all platform tax paid across all tickets in an event',
		calculation: '',
		type: EMSFieldType.number,
		isRaw: false,
		costCalcDependent: true,
	},
	totalRevenueForCoPro: {
		isSMSOnly: false,
		emsMetadataId: 'totalRevenueForCoPro',
		emsPublicIdentifier: 'totalRevenueForCoPro',
		internalLabel: 'Total Revenue for Co Pro',
		internalDescription:
			'The total revenue collected for co pro caluclations; gross + totalAdditionalRevenueForCoPro',
		calculation: 'gross + totalAdditionalRevenueForCoPro',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	costCalc2: {
		isSMSOnly: false,
		emsMetadataId: 'costCalc2',
		emsPublicIdentifier: 'costCalc2',
		internalLabel: 'Cost Calc',
		internalDescription: 'The cost calc value; stored on the EMS interface for ease of use',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: true,
		private: true,
	},
	currency: {
		isSMSOnly: false,
		emsMetadataId: 'currency',
		emsPublicIdentifier: 'currency',
		internalLabel: 'Currency',
		internalDescription: "The event's currency",
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'Currency',
		userFacingDescription: "The event's currency",
		category: 'Financial Summary',
		isTopLevel: true,
	},
	coProOverridenAdditionalSupport: {
		isSMSOnly: false,
		emsMetadataId: 'coProOverridenAdditionalSupport',
		emsPublicIdentifier: 'coProOverridenAdditionalSupport',
		internalLabel: 'additional support overriden for co pro',
		internalDescription: 'The overriden value for the additional support in coPro deal',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: true,
		costCalcDependent: true,
		isNullable: true,
	},
	hasCoProOverridenAdditionalSupport: {
		isSMSOnly: false,
		emsMetadataId: 'hasCoProOverridenAdditionalSupport',
		emsPublicIdentifier: 'hasCoProOverridenAdditionalSupport',
		internalLabel: 'if there is additional support overriden for co pro',
		internalDescription:
			'true if there is an overriden value for the additional support in coPro deal, false otherwise',
		calculation: '',
		type: EMSFieldType.boolean,
		isRaw: false,
		costCalcDependent: false,
	},
	artistPayCurrency: {
		isSMSOnly: false,
		emsMetadataId: 'artistPayCurrency',
		emsPublicIdentifier: 'artistPayCurrency',
		internalLabel: 'Artist Pay Currency',
		internalDescription:
			"if convert_to_usd is false, this matches event.currency; if convert_to_usd is true, this is 'USD'",
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		isNullable: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	convertedDocumentSplitPoint: {
		isSMSOnly: false,
		emsMetadataId: 'convertedDocumentSplitPoint',
		emsPublicIdentifier: 'convertedDocumentSplitPoint',
		internalLabel: '',
		internalDescription: '',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	averageNet: {
		isSMSOnly: true,
		emsMetadataId: 'averageNet',
		emsPublicIdentifier: 'averageNet',
		internalLabel: 'Averange Net',
		internalDescription: 'The average net revenue from all events in an SMS',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	profitableShowCount: {
		isSMSOnly: true,
		emsMetadataId: 'profitableShowCount',
		emsPublicIdentifier: 'profitableShowCount',
		internalLabel: 'Profitable Show Count',
		internalDescription: 'The number of shows that were profitable in a given SMS report',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: false,
		costCalcDependent: true,
	},
	announceDate: {
		isSMSOnly: false,
		emsMetadataId: 'announceDate',
		emsPublicIdentifier: 'announceDate',
		internalLabel: 'Announce Date',
		internalDescription: 'The announce date of the show',
		calculation: '',
		type: EMSFieldType.date,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'Announce Date',
		userFacingDescription: 'The Announce Date set in the Event Settings for the event',
		category: 'Event Details',
		isTopLevel: true,
	},
	onSaleDate: {
		isSMSOnly: false,
		emsMetadataId: 'onSaleDate',
		emsPublicIdentifier: 'onSaleDate',
		internalLabel: 'On Sale Date',
		internalDescription: 'The on-sale date of the show',
		calculation: '',
		type: EMSFieldType.date,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'On Sale Date',
		userFacingDescription: 'The On Sale Date set in the Event Settings for the event',
		category: 'Event Details',
		isTopLevel: true,
	},
	orgName: {
		isSMSOnly: false,
		emsMetadataId: 'orgName',
		emsPublicIdentifier: 'orgName',
		internalLabel: 'Org Name',
		internalDescription: 'The name of the org',
		calculation: '',
		type: EMSFieldType.string,
		isRaw: true,
		costCalcDependent: false,
		isTopLevel: true,
	},
	preSaleDate: {
		isSMSOnly: false,
		emsMetadataId: 'preSaleDate',
		emsPublicIdentifier: 'preSaleDate',
		internalLabel: 'Pre Sale Date',
		internalDescription: 'The pre-sale date of the show',
		calculation: '',
		type: EMSFieldType.date,
		isRaw: true,
		costCalcDependent: false,
		userFacingLabel: 'Pre Sale Date',
		userFacingDescription: 'The Pre Sale Date set in the Event Settings for the event',
		category: 'Event Details',
		isTopLevel: true,
	},
	totalAdjustedGrossFees: {
		isSMSOnly: false,
		emsMetadataId: 'totalAdjustedGrossFees',
		emsPublicIdentifier: 'totalAdjustedGrossFees',
		internalLabel: 'Total Adjusted Gross Fees',
		internalDescription:
			'Similar to Percent of Gross but using adjustedGross as base: adjustedGross - adjustedGross / (1 + percentage-defined-by-the-user / 100)',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	totalFlatPreSettlementFeesOutOfGross: {
		isSMSOnly: false,
		emsMetadataId: 'totalFlatPreSettlementFeesOutOfGross',
		emsPublicIdentifier: 'totalFlatPreSettlementFeesOutOfGross',
		internalLabel: 'Flat Fees Before Tax',
		userFacingLabel: 'Flat Fees Before Tax',
		internalDescription: 'The sum of all flat fees before tax, calculated from the gross revenue of the event',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	totalFlatPreSettlementFeesOutOfAdjustedGross: {
		isSMSOnly: false,
		emsMetadataId: 'totalFlatPreSettlementFeesOutOfAdjustedGross',
		emsPublicIdentifier: 'totalFlatPreSettlementFeesOutOfAdjustedGross',
		internalLabel: 'Flat Fees After Tax',
		userFacingLabel: 'Flat Fees After Tax',
		internalDescription: 'The sum of all flat fees after tax, calculated from the net revenue of the event',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
	netTicketGrossMinusPreSettlementFlatFees: {
		isSMSOnly: false,
		emsMetadataId: 'netTicketGrossMinusPreSettlementFlatFees',
		emsPublicIdentifier: 'netTicketGrossMinusPreSettlementFlatFees',
		internalLabel: 'Ticket Net Gross',
		internalDescription:
			'The ticket total net gross minus pre settlement flat fees. Normally this will be the same as netGross - additional revenue',
		userFacingLabel: 'Ticket Net Gross',
		userFacingDescription:
			'The ticket total net gross minus pre settlement flat fees. Normally this will be the same as netGross - additional revenue',
		calculation: '',
		type: EMSFieldType.dollarAmount,
		isRaw: false,
		costCalcDependent: true,
	},
};
// #endregion EMSFieldsMeta

export const EMSTopLevelFieldsMetaKeys: (keyof EMSFieldDefs)[] = _.chain(EMSFieldsMeta)
	// filter top level props only
	.filter((metadata: EMSFieldMeta): boolean => {
		return metadata.isTopLevel;
	})
	// grab their metadata ids
	.map((metadata: EMSFieldMeta): keyof EMSFieldDefs => {
		const metadataId: keyof EMSFieldDefs = metadata.emsMetadataId;
		if (!metadataId.includes('.*')) {
			return metadataId;
		}
		return metadataId.substring(0, metadataId.indexOf('.*')) as keyof EMSFieldDefs;
	})
	// discard any repeated values. eg. 'customFields.*.id' and
	// 'customFields.*.name' would be parsed to 'customFields'
	.uniq()
	.value();

export const EMSFieldMetadataByEMSPublicIdentifier: Record<string, EMSFieldMeta> = {};
_.forEach(EMSFieldsMeta, (metadata: EMSFieldMeta): void => {
	EMSFieldMetadataByEMSPublicIdentifier[metadata.emsPublicIdentifier] = metadata;
});

// if (!environment.production) {
// 	console.log(emsMathStatements);
// }
// try {
// 	evaluate(emsMathStatements);
// } catch (e) {
// 	console.log(e);
// }
