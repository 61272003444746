import { Injectable } from '@angular/core';
import { sprintf } from 'sprintf-js';

/*
 	Define our API information as a keyed object
	in sprintf syntax for easy parameter replacement.

	Care must be taken to make sure this is 1:1 with the laravel routes
 */
@Injectable()
export class ApiConfig {
	// New RESTful routes
	public readonly HOLDS: string = 'holds';
	public readonly HOLD: string = 'holds/%i';
	public readonly PROMOTERS: string = 'promoters';
	public readonly CONTACTS: string = 'contacts';
	public readonly DATE: string = 'dates/%i';
	public readonly EVENTS: string = 'events';
	public readonly EVENT: string = 'events/%i';
	public readonly REQUESTS: string = 'requests';

	// One-off, bespoke endpoints that should probably be in gql
	public readonly VENUE_SUGGESTIONS: string = 'central-venues/suggestions';
	public readonly GOOGLE_PLACE_DETAILS: string = 'google-places/%s';
	public readonly GOOGLE_PLACE_ADDRESS_SEARCH: string = 'google-places/autocomplete';

	/* SYSTEM */
	public readonly CHECK_TOKEN: string = 'check-token';
	public readonly DASH: string = 'dash';

	/* USERS */
	public readonly USER: string = 'user';
	public readonly USER_BY_ID: string = 'user/%i';
	public readonly USER_CREATE: string = 'user/create';
	public readonly USER_DISABLE: string = 'user/disable';
	public readonly USER_ALL: string = 'user/all';
	public readonly GET_USER_SETTING: string = 'user/setting/%s';
	public readonly SET_USER_SETTING: string = 'user/setting';
	public readonly UPDATE_USER_PREFERENCES: string = 'user/%i/preferences';

	/* TOURS */
	public readonly TOURS: string = 'tours';
	public readonly TOUR: string = 'tours/%i';
	public readonly TOUR_EVENTS: string = 'tours/%i/events';
	public readonly TOUR_TALENT: string = 'tours/%i/talent';
	public readonly TOUR_TICKETS: string = 'tours/%i/tickets';
	public readonly TOUR_TEMPLATE_APPLY: string = 'tours/apply-template';

	/* TALENT */
	public readonly TALENTS: string = 'talent';
	public readonly TALENT: string = 'talent/%i';

	/* TICKET PLATFORMS */
	public readonly TICKET_PLATFORMS: string = 'ticket-platforms';
	public readonly TICKET_PLATFORM_USER_TOKEN: string = 'ticket-platforms/%s/user-token';
	public readonly TICKET_PLATFORM_EVENTS: string = 'ticket-platforms/%s/events/%s';
	public readonly TICKET_PLATFORM_EVENT: string = 'ticket-platforms/%s/event/%s';
	public readonly TICKET_PLATFORM_EVENT_TICKETS: string = 'ticket-platforms/%s/events/%s/tickets';
	public readonly TICKET_PLATFORM_EVENT_CONNECTION: string = 'ticket-platforms/%s/events/%s/connection';
	public readonly TICKET_PLATFORM_EVENT_REFRESH_ACTUALS: string = 'ticket-platforms/events/%s/refresh-actuals';

	/* EVENTS */
	public readonly PULL_EMS_ROLLUPS_BATCH: string = 'ems/rollups/batches/%s';
	public readonly PULL_EMS_ROLLUPS_BATCH_STATUS: string = `ems/rollups/batches/%s/status`;
	public readonly PULL_EMS_ROLLUPS_BATCH_CANCEL: string = `ems/rollups/batches/%s/cancel`;
	/**
	 * Used to explicitly tell the backend to clear a report from the cache.
	 * This will force the next request of the report to be a fresh set of events,
	 * all of whose ems is up to date with the latest data.
	 */
	public readonly PULL_EMS_ROLLUPS_BATCH_CLEAR: string = `ems/rollups/batches/%s/clear`;
	public readonly EVENTS_CREATE: string = 'events';
	public readonly EVENTS_DATA: string = 'events/data/%i';
	public readonly EVENTS_GET: string = 'events/%i';
	public readonly EVENTS_DELETE: string = 'events/%i/delete';
	public readonly EVENTS_HOLDS: string = 'events/%i/holds';
	public readonly EVENTS_HOLDS_CLEAR_ALL: string = 'events/%i/clear-holds';
	public readonly EVENTS_HOLDS_RESTORE_ALL: string = 'events/%i/restore-holds';
	public readonly EVENTS_HOLDS_DEPRECATED: string = 'events/%i/holds-deprecated';
	public readonly EVENTS_HOLDS_UPDATE: string = 'events/%i/update-holds';
	public readonly EVENTS_DATES_UPDATE: string = 'events/%i/update-dates';
	public readonly EVENTS_VENUE_STAGES_UPDATE: string = 'events/%i/stages';
	public readonly EVENTS_BUILD: string = 'events/%i/build';
	public readonly UPDATE_EVENT_ATTRIBUTES: string = 'events/%i/update';
	public readonly EVENTS_SETTLE: string = 'events/%i/settle';
	public readonly EVENTS_UNSETTLE: string = 'events/%i/unsettle';
	public readonly EVENTS_MARK_SETTLED: string = 'events/%i/mark-settled';
	public readonly EVENTS_ARCHIVE: string = 'events/%i/archive';
	public readonly EVENTS_UNARCHIVE: string = 'events/%i/unarchive';
	public readonly EVENTS_UNCONFIRM: string = 'events/%i/unconfirm';
	public readonly EVENTS_RUN_OF_SHOW: string = 'events/%i/run-of-show';
	public readonly EVENT_RUN_OF_SHOW_ITEM: string = 'events/%i/run-of-show/%i';
	public readonly EVENTS_CONFIRM: string = 'events/%i/confirm';
	public readonly EVENT_CONFIRMATION_RECIPIENTS: string = 'events/%i/confirmation-email-recipients';
	public readonly EVENTS_NOTE: string = 'events/%i/notes';
	public readonly EVENTS_MARKETING: string = 'events/%i/marketing';
	public readonly ADVANCE: string = 'events/%i/advance';
	public readonly EVENTS_TICKET_SAVE: string = 'events/%i/ticket/save';
	public readonly EVENTS_TICKET_DELETE: string = 'events/%i/ticket/delete';
	public readonly EVENTS_ADD_REV_SAVE: string = 'events/%i/revenue/save';
	public readonly EVENTS_ADD_REV_DELETE: string = 'events/%i/revenue/delete';
	public readonly EVENTS_FEE_SAVE: string = 'events/%i/event-fees';
	public readonly EVENTS_FEE_DELETE: string = 'events/%i/event-fees/%i';
	public readonly EVENTS_FEE_UPDATE: string = 'events/%i/event-fees/%i';
	public readonly EVENTS_UPDATE_NAME: string = 'events/%i/update-name';
	public readonly EVENTS_LOGO: string = 'events/%i/logo';
	public readonly EVENTS_ATTENDANCE: string = 'events/%i/attendance';
	public readonly EVENTS_ADDITIONAL_DATE: string = 'events/%i/additional-date';
	public readonly EVENTS_ADDITIONAL_DATE_UPDATE: string = 'events/%i/additional-date/%i';
	public readonly EVENTS_ADDITIONAL_DATE_DELETE: string = 'events/%i/additional-date/%i';

	/* EVENT TASKS */
	public readonly EVENTS_TASKS: string = 'events/%i/tasks';
	public readonly EVENTS_TASK: string = 'events/%i/tasks/%i';
	public readonly EVENTS_TASKS_ASSIGN: string = 'events/%i/task/%i/assign';
	public readonly EVENTS_TASKS_SEARCH: string = 'tasks/search';

	public readonly EVENTS_EDIT_TEMPLATE_CONTACT: string = 'events/template-edit-contact';
	public readonly EVENTS_ADD_TEMPLATE_CONTACT: string = 'events/template-add-contact';
	public readonly EVENTS_TEMPLATE_GET: string = 'events/get-template/%i';
	public readonly EVENTS_TEMPLATE_EDIT: string = 'events/edit-template';
	public readonly EVENTS_TEMPLATE_CREATE: string = 'events/create-template';
	public readonly EVENTS_TEMPLATE_LIST: string = 'events/list-templates';
	public readonly EVENTS_TEMPLATE_DELETE: string = 'events/delete-template';
	public readonly EVENTS_TEMPLATE_APPLY: string = 'events/apply-template';

	public readonly EVENTS_COSTS_SAVE_CG: string = 'events/%i/cost_group';
	public readonly EVENTS_COSTS_DELETE_CG: string = 'events/%i/cost_group/%i/delete';
	public readonly EVENTS_COSTS_CREATE_CI: string = 'events/%i/cost_group/%i/item';
	public readonly EVENTS_COSTS_UPDATE_CI: string = 'events/%i/cost_group/%i/item/%i';
	public readonly EVENTS_COSTS_DELETE_CI: string = 'events/%i/cost_group/%i/item/%i';
	public readonly EVENTS_COSTS_SAVE_CI_SPEND: string = 'events/%i/cost_group/%i/item/%i/spend';
	public readonly EVENTS_COSTS_DELETE_CI_SPEND: string = 'events/%i/cost_group/%i/item/%i/spend/%i/delete';
	public readonly EVENTS_COSTS_SAVE_VC: string = 'events/%i/variable_cost';
	public readonly EVENTS_COSTS_DELETE_VC: string = 'events/%i/variable_cost/%i/delete';
	public readonly EVENTS_STATUS_TRANSITION: string = 'events/%i/status';

	/* SHARED EVENTS */
	public readonly SHARED_EVENT_UNLINK: string = 'events/%i/link';

	/* LINKED DEALS */
	public readonly LINK_DEAL_CHECK_TOKEN: string = 'deal-link/%s';
	public readonly LINK_DEAL_CREATE_LINK: string = 'deal-link';

	/* TASKS */
	public readonly TASK_TEMPLATE_LIST: string = 'task-templates/group/%i/templates';
	public readonly TASK_TEMPLATE_GROUPS: string = 'task-templates/groups';
	public readonly TASK_TEMPLATE_SAVE: string = 'task-templates/save/%i/%i';
	public readonly TASK_TEMPLATE_DELETE: string = 'task-templates/delete/%i/%i';
	public readonly TASK_TEMPLATE_GROUP_SAVE: string = 'task-templates/group/save/%i';
	public readonly TASK_TEMPLATE_GROUP_DELETE: string = 'task-templates/group/delete/%i';
	public readonly TASK_TEMPLATE_APPLY: string = 'apply-task-template';

	/* VENUES & STAGES */
	public readonly VENUE_PERMISSIONS: string = 'venue-permissions';
	public readonly VENUE_CONFIRMATION_RECIPIENTS: string = 'venues/%i/confirmation-email-recipients';

	/* HOLDS */
	public readonly HOLDS_LIST: string = 'holds-deprecated';
	public readonly CONFIRMED_AND_META_DATES: string = 'confirmed-and-meta-dates';
	public readonly HOLDS_CHALLENGE: string = 'holds/%i/challenge';
	public readonly HOLDS_GET: string = 'holds/%i';
	public readonly HOLD_DELETE: string = 'holds/%i/delete';
	public readonly HOLD_CLEAR: string = 'holds/%i/clear';
	public readonly HOLD_RESTORE: string = 'holds/%i/restore';

	/* DATES */
	public readonly EVENT_META_DATE: string = 'events/%i/meta-date';

	/* UNIFIED TEXT TEMPLATES TERMS */
	public readonly TEXT_TEMPLATES: string = 'terms_templates';
	public readonly TEXT_TEMPLATES_DELETE_OR_EDIT: string = 'terms_templates/%i';

	/**
	 * DOCUMENT TEMPLATE TERMS.
	 * ROUTE event_talents/ WORKS FOR BOTH EVENT AND TOUR TALENT_IDs WHILE ROUTE invoices/ WORKS WITH INVOICE_IDs */
	public readonly TALENT_TERMS: string = 'event_talents/%i/terms/%s';
	public readonly INVOICE_TERMS: string = 'invoices/%i/terms/%s';

	/* UNIVERSAL */
	public readonly UNIVERSAL_SAVE: string = 'save';

	/* MISC */
	public readonly LOGO_UPLOAD: string = 'org/settings/upload-logo';
	public readonly LOGO_LIST: string = 'org/settings/list-logos';
	public readonly LOGO: string = 'org/settings/logo/%s';
	public readonly LOGO_UPDATE: string = 'org/settings/update-logos';
	public readonly LOGO_DELETE: string = 'org/settings/delete-logo';
	public readonly LOG_EVENT: string = 'logs/event/%i';
	public readonly ORG_SETTINGS: string = 'org/settings';
	public readonly ORG_LIST_ALL_SETTINGS: string = 'organization-settings';
	public readonly UPLOAD: string = 'upload';
	public readonly FILES: string = 'events/%i/files';
	public readonly FILE_DELETE: string = 'files/%i/delete';
	public readonly FILE_EDIT: string = 'files/%i/edit';
	public readonly FILE_VERSION_UPLOAD: string = 'files/upload/version';
	public readonly FILE_DOWNLOAD: string = 'files/download';

	public readonly NOTES_SAVE: string = 'notes/save';
	public readonly NOTES_DELETE: string = 'notes/%i/delete';

	public readonly ARTISTSEARCH: string = 'artistsearch';

	// Artist Route Sheets
	public readonly ARTIST_ROUTE_SHEET: string = 'artist-dates/%i/%s/%s';

	public readonly GOOGLE_CHECK_TOKEN: string = 'google/token';
	public readonly GOOGLE_TOGGLE_ACCOUNT: string = 'google/token/toggle';

	public readonly WEBHOOK_ADD: string = 'org/settings/add-webhook';
	public readonly WEBHOOK_TEST: string = 'org/settings/test-webhook';
	public readonly WEBHOOK_REMOVE: string = 'org/settings/remove-webhook';
	public readonly WEBHOOK_LIST: string = 'org/settings/list-webhooks';

	public readonly PERMISSIONS_ORG_COLLABORATORS: string = 'collaborators';
	public readonly PERMISSIONS_EVENT_COLLABORATORS: string = 'events/%i/collaborators';
	public readonly PERMISSIONS_VENUE_COLLABORATORS: string = 'venues/%i/collaborators';
	public readonly PERMISSIONS_FOR_EVENT: string = 'event/%i/user-permissions';
	public readonly PERMISSIONS_FOR_VENUE: string = 'venue/%i/user-permissions';
	public readonly PERMISSIONS_VENUE_TEAMS: string = 'venues/team-list';
	public readonly PERMISSIONS_ARTIST_TEAMS: string = 'talent-agent/team-list';
	public readonly PERMISSIONS_FOR_ROLE: string = 'roles/%i/permissions';
	public readonly PERMISSIONS_LIST: string = 'permissions-list';
	public readonly PERMISSIONS_CREATE_ROLE: string = 'permissions-create-role';
	public readonly PERMISSIONS_DELETE_ROLE: string = 'permissions-delete-role';
	public readonly PERMISSIONS_SYNC_TO_ROLE: string = 'permissions-sync-ability-to-role';
	public readonly PERMISSIONS_ADD_ROLE_TO_USER: string = 'permissions-add-role-to-user';
	public readonly PERMISSIONS_REMOVE_ROLE_FROM_USER: string = 'permissions-remove-role-from-user';
	public readonly PERMISSIONS_ADD_TEAM_USERS: string = 'permissions-add-team-users';
	public readonly PERMISSIONS_DELETE_TEAM_USER: string = 'permissions-delete-team-user';
	public readonly PERMISSIONS_GET_USER_ROLES: string = 'permissions-get-user-roles/%i';
	public readonly UPDATE_RECORD_ORDER: string = 'update-record-order';

	/* USER PREFERENCES */
	public readonly USER_PREFERENCES_UPDATE: string = 'user/preferences';
	public readonly USER_EMAIL_PREFERENCES: string = 'user/email-preferences';

	/* STREAMS (newsfeeds, notification feeds) */
	public readonly GET_EVENT_FEED: string = 'newsfeeds/event/%i';
	public readonly GET_EVENT_FEED_TOKEN: string = 'newsfeeds/event/%i/token';

	/* SALES IMPORTER */
	public readonly HISTORICAL_LIST_VENUE_MAPS: string = 'historical-list-venues';
	public readonly HISTORICAL_LIST_BATCHES: string = 'historical-list-batches';
	public readonly HISTORICAL_RUN_BATCH: string = 'historical-run-batch/%i';
	public readonly HISTORICAL_REMOVE_BATCH: string = 'historical-remove-batch/%i';
	public readonly HISTORICAL_PREVIEW_BATCH: string = 'historical-preview-batch/%i';
	public readonly HISTORICAL_REVIEW_BATCH: string = 'historical-review-batch/%i';
	public readonly HISTORICAL_DATA_UPLOAD: string = 'historical-data-upload';

	public readonly HEADLINER_GENRES: string = 'headliner-genres';
	public readonly ORG_CURRENCIES: string = 'currencies';

	/* AGENCY ENDPOINTS */
	public readonly NEXT_AVAILABLE_INVOICE_ID: string = 'invoices/next-available-id';

	/* NETWORKING */
	public readonly GET_NETWORK_ORGS: string = 'network/organizations';

	/* DOCUMENT EMAIL SENDS */
	public readonly SEND_EVENT_DOCUMENT_EMAIL: string = 'send-email-with-attachments';
	public readonly SEND_BULK_EMAIL: string = 'send-bulk-email';

	/* EMAIL TEMPLATES */
	public readonly DEFAULT_EMAIL_TEMPLATES: string = 'default_email_templates';

	/* STATEMENT ADJUSTMENTS */
	public readonly ARTIST_STATEMENT_ADJUSTMENTS: string = 'artist/%i/invoice_adjustments';
	public readonly STATEMENT_ADJUSTMENTS: string = 'invoice_adjustments/%i';

	/* INSIGHTS */
	public readonly INSIGHTS_SEARCH: string = 'insights/search';
	public readonly INSIGHTS_EVENT_EXCLUDE_REASONS: string = 'insights/event-exclude-reasons';

	/* Delete all tokens */
	public readonly LOGOUT: string = 'logout';

	// Admin stuff

	public readonly LOG_IN_AS: string = 'log-in-as';
	public readonly LOG_OUT_AS: string = 'log-out-as';

	public readonly ADMIN_LIST_ORGS: string = 'portal/organizations';
	public readonly ADMIN_LIST_ORG_USERS: string = 'portal/organization/%i/users';
	public readonly ADMIN_UPDATE_ORG: string = 'portal/update-organization/%i';
	public readonly ADMIN_DELETE_ORG: string = 'portal/delete-organization/%i';
	public readonly ADMIN_RUN_IMPORT: string = 'portal/run-data-import';
	public readonly ADMIN_LIST_IMPORTS: string = 'portal/list-data-imports/%i';
	public readonly ADMIN_REVERSE_IMPORT: string = 'portal/reverse-data-import/%i';

	// Testing
	public readonly EMS_EVENT_GENERATE: string = 'testing/emsEventList';
	// AI
	public readonly AI_CHAT_GPT: string = 'ai/gpt/chat';

	public compileString(template: string, ...stuffs: (string | number)[]): string {
		return sprintf(template, ...stuffs);
	}
}
