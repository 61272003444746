import { CustomFieldEventBackend } from '@prism-frontend/entities/custom-fields/custom-fields-typedefs';
import { RunOfShow } from '@prism-frontend/entities/run-of-show/run-of-show';
import { TaskTemplate } from '@prism-frontend/entities/task-templates/task-templates-typedefs';
import { VariableCost } from '@prism-frontend/entities/variable-costs/variable-cost-typedefs';
import { AdvanceData } from '@prism-frontend/typedefs/AdvanceData';
import { DesiredHoldType } from '@prism-frontend/typedefs/DesiredHoldType';
import { EventPropsForTalentGuarantee } from '@prism-frontend/typedefs/EventPropsForTalentGuarantee';
import { InstantHoldEventLink } from '@prism-frontend/typedefs/EventsCreatePostData';
import { FlatTicketRevenue } from '@prism-frontend/typedefs/FlatTicketRevenue';
import { LinkDealEventData } from '@prism-frontend/typedefs/LinkDealEventData';
import { TypedTransformFnParams } from '@prism-frontend/typedefs/TypedTransformFnParams';
import { AdditionalDate } from '@prism-frontend/typedefs/additional-date';
import { AdditionalRevenue } from '@prism-frontend/typedefs/additional-revenue';
import { Bonus } from '@prism-frontend/typedefs/bonus';
import { Contact, PrismEventContact } from '@prism-frontend/typedefs/contact';
import { CostGroup } from '@prism-frontend/typedefs/costGroup';
import { Datez } from '@prism-frontend/typedefs/datez';
import { Deposit, depositTotal } from '@prism-frontend/typedefs/deposit';
import { getExchangeRate } from '@prism-frontend/typedefs/determine-exchange-rate';
import {
	calculatePromoterProfit,
	checkLegacyArgs,
	computeBreakEven,
	fixedCostGroups,
} from '@prism-frontend/typedefs/ems/ems-static-helpers/ems-static-helpers';
import { EMSTicketAggregate } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { BonusTypes } from '@prism-frontend/typedefs/enums/BonusTypes';
import { EventFeeType } from '@prism-frontend/typedefs/enums/EventFeeType';
import { EventInsightsExcludeReason } from '@prism-frontend/typedefs/enums/EventInsightsExcludeReason';
import { VariableCostType } from '@prism-frontend/typedefs/enums/VariableCostType';
import { AdditionalRevenueType } from '@prism-frontend/typedefs/enums/additional-revenue-type';
import { CostCalc } from '@prism-frontend/typedefs/enums/calc';
import { Currency } from '@prism-frontend/typedefs/enums/currency';
import { DealTypes } from '@prism-frontend/typedefs/enums/deal-types';
import { EventStatus } from '@prism-frontend/typedefs/enums/event-status';
import { TaxType } from '@prism-frontend/typedefs/enums/tax';
import {
	CalculateTotalArtistPayoutOptions,
	CalculateTotalArtistPayoutOptionsArgs,
	FixedCostsOptionsArg,
	WithCoProOptions,
	WithForSplitPointOptions,
	WithForSplitPointOptionsArgs,
} from '@prism-frontend/typedefs/event-method-api-params';
import { EventData } from '@prism-frontend/typedefs/eventData';
import { EventFee } from '@prism-frontend/typedefs/eventFee';
import { Organization, OrganizationSettings, Staff } from '@prism-frontend/typedefs/organization';
import { EventPropsForPartnerDeal, PartnerDeal } from '@prism-frontend/typedefs/partner-deal';
import { PayoutAdjustment } from '@prism-frontend/typedefs/payoutAdjustment';
import { Permission } from '@prism-frontend/typedefs/permission';
import { RentalData } from '@prism-frontend/typedefs/rentalData';
import { Stage } from '@prism-frontend/typedefs/stage';
import { TalentData } from '@prism-frontend/typedefs/talentData';
import { EventPropsForTicket, Ticket } from '@prism-frontend/typedefs/ticket';
import { TicketCommission } from '@prism-frontend/typedefs/ticket-commission';
import { PlatformImage } from '@prism-frontend/typedefs/ticket-platforms';
import { Tour } from '@prism-frontend/typedefs/tour';
import { Venue } from '@prism-frontend/typedefs/venue';
import { formatDateRange, sortedDateRangeForEvents } from '@prism-frontend/utils/static/dateHelper';
import { deprecatedDoNotUsePreventNaN } from '@prism-frontend/utils/static/deprecatedDoNotUsePreventNaN';
import { fetchCostCalc2FromLegacyEMSParams } from '@prism-frontend/utils/static/fetchCostCalc2FromLegacyEMSParams';
import { Debug, getDebug, verboseDebug } from '@prism-frontend/utils/static/getDebug';
import { sumPropOverObjects } from '@prism-frontend/utils/static/sumPropOverObjects';
import { withoutTaxMultiplier } from '@prism-frontend/utils/static/tax';
import { castToBoolean } from '@prism-frontend/utils/transformers/castToBoolean';
import { castToNumber } from '@prism-frontend/utils/transformers/castToNumber';
import { castToUTCDate } from '@prism-frontend/utils/transformers/castToUTCDate';
import { Transform, Type, plainToClass } from 'class-transformer';
import {
	IsArray,
	IsBoolean,
	IsDate,
	IsEnum,
	IsInt,
	IsNumber,
	IsOptional,
	IsString,
	Max,
	Min,
	ValidateNested,
} from 'class-validator';
import * as _ from 'lodash';
import moment from 'moment';

const debug: Debug = getDebug('prism-event');

export interface PrismEventInterface extends PrismEvent {}
export class ContactID {
	public contact_id: number;
	public include_in_pdfs: boolean;
}

/**
 * this is the type for JSON POST data for updating an event. this should match the JSON
 * object expected in EventController::updateEventData on the b/e
 */

// TODO PRSM-XXXX Switch this from using 'Pick' to use 'Omit'.
export type PrismEventPostData = Pick<
	PrismEvent,
	| 'actual_additional_support'
	| 'age_limit'
	| 'has_age_limit'
	| 'is_all_ages'
	| 'budgeted_additional_support'
	| 'promoter_profit'
	| 'currency'
	| 'convert_to_usd'
	| 'use_custom_exchange_rates'
	| 'contract_signer_id'
	| 'offer_exchange_rate'
	| 'settled_exchange_rate'
	| 'facility_fee'
	| 'on_sale_date'
	| 'pre_sale_date'
	| 'announce_date'
	| 'do_not_announce'
	| 'tax_rate'
	| 'tax_type'
	| 'tour_id'
	| 'data'
	| 'promoter_contact_id'
	| 'total_sold_for_commissions'
	| 'total_comps_for_commissions'
> & {
	deal_terms: TalentData[];
	// TODO PRSM-6164 Change promoter_terms to rental_terms
	promoter_terms: RentalData;
};

/**
 * this represents the source event when linking; we limit the information that's
 * shown here
 *
 * TODO PRSM-6212 fill this out with all fields that come back in the checkHash endpoint
 */
export type SourcePrismEvent = Pick<PrismEvent, 'id' | 'organization' | 'talent_data' | 'agency_event_link'>;

/*
 * This represents the fields that can be used to calculate the break-even for an event when the
 * evaluated deal is a versus net gross deal.
 */
type BreakEvenFieldForVersusNetGrossDeal = 'guarantee' | 'netGross';

/**
 * This is where all of the magic happens. For better or worse, this class
 * represents both the backend data model for an event, AND all of the logic
 * related to performing math against that data model.
 *
 * If you are looking for the static view that results from running all of the
 * PrismEvent math, see fetchEMSRollup, EMSRollup, and EMS.
 */
export class PrismEvent {
	public constructor(event?: Partial<PrismEvent>) {
		return plainToClass(PrismEvent, event);
	}

	@IsNumber(
		{},
		{
			groups: ['metadata'],
		}
	)
	public id: number | null = null;

	@IsOptional()
	@Type((): typeof AdvanceData => {
		return AdvanceData;
	})
	@ValidateNested()
	public advance_data: AdvanceData = new AdvanceData();

	@IsOptional()
	@IsString()
	public advanceText?: string;

	@IsNumber() public actual_additional_support: number = 0;

	@IsNumber() public budgeted_additional_support: number = 0;

	@IsOptional({
		groups: ['metadata'],
	})
	@IsString({
		groups: ['metadata'],
	})
	public announce_date: string | null = '';

	@IsBoolean()
	public do_not_announce: boolean;

	public agency_event_link: LinkDealEventData | null = null;

	@IsEnum(EventStatus) public confirmed: EventStatus = EventStatus.Hold;

	@IsString()
	@IsOptional()
	public archived_at: string | null;

	@IsNumber()
	@IsOptional()
	public confirmed_by_user_id?: number | null;

	@IsOptional({
		groups: ['metadata'],
	})
	public confirmed_time?: string | null;

	@IsOptional()
	@IsString()
	@Transform(castToUTCDate())
	public created_at?: string;

	@IsOptional()
	@IsString()
	@Transform(castToUTCDate())
	public updated_at?: string;

	/**
	 * updated_at is managed by the system, and ems service sets some backend
	 * columns whenever an event's emsRollup needs to regenerate. This happens
	 * often–such as when an organization setting is changed.
	 *
	 * Because of this, we created a separate column, display_updated_at, which
	 * we manage ourselves. This is the date that we display to the user, and
	 * it only changes when the user makes a change to the event that in their
	 * mental model would register the event as "changed recently".
	 *
	 * e.g. updating an event's talent deal or attendance would both cause
	 * display_updated_at to change, but updating the event's organization's
	 * name would not (even though changing an event's org name, due to
	 * the way ems service is architected would change updated_at).
	 */
	@IsString()
	@Transform(castToUTCDate())
	public display_updated_at: string;

	@IsNumber() public created_by: number;

	@IsOptional()
	@Type((): typeof Staff => {
		return Staff;
	})
	@ValidateNested()
	public created_by_user?: Staff;

	@IsEnum(Currency) public currency: Currency;

	@IsBoolean()
	@Transform(castToBoolean())
	public convert_to_usd: boolean;

	@IsBoolean()
	@Transform(castToBoolean())
	public use_custom_exchange_rates: boolean;

	@IsNumber()
	@Transform(castToNumber())
	public offer_exchange_rate: number;

	@IsOptional()
	@IsNumber()
	@Transform(castToNumber())
	public settled_exchange_rate: number;

	@IsNumber()
	@Transform(castToNumber())
	public usd_exchange_rate: number;

	@IsString()
	@Transform(castToUTCDate())
	public calculated_start_date: string;

	@IsArray()
	@Type((): typeof Datez => {
		return Datez;
	})
	@ValidateNested()
	public datez: Datez[] = [];

	@IsArray()
	@ValidateNested()
	public genre_strings: string[] = [];

	@IsArray()
	@Type((): typeof CustomFieldEventBackend => {
		return CustomFieldEventBackend;
	})

	/**
	 * Array of custom fields that have had values set on this event. Note, that
	 * this array does NOT provide the complete picture of custom fields for
	 * this event, because there are org level custom fields whose values may not
	 * have been set on the event. There are also advanced custom fields at the
	 * org level who need to have their event value derived. Those are NEVER returned
	 * at the event level, since their value is always derived.
	 *
	 * As far as our UI is concerned, you can not use this property by itself
	 * for anything of value.
	 */
	@ValidateNested()
	public custom_fields: CustomFieldEventBackend[] = [];

	@IsNumber()
	@Transform(castToNumber())
	public facility_fee: number = 0;

	@IsBoolean()
	@Transform(castToBoolean())
	public is_event_member_of_broadway_tour: boolean;

	@IsBoolean()
	@Transform(castToBoolean())
	public does_tour_calculate_ticket_commission_by_net_gross: boolean;

	@IsString() public name: string = '';

	@IsOptional()
	@Min(0)
	@Max(1)
	@IsNumber(
		{},
		{
			groups: ['metadata'],
		}
	)
	public imported?: boolean;

	@IsString()
	@IsOptional()
	public on_sale_date: string | null = '';

	@IsString()
	@IsOptional()
	public pre_sale_date: string | null = '';

	@IsNumber() public organization_id: number = null;

	@IsNumber() public last_applied_template_id: number = null;

	@IsOptional()
	@IsString()
	public ticket_api_settlement_auth: string | null = null;

	@IsBoolean()
	@Transform(castToBoolean())
	// TODO PRSM-6028 Change outside_promoter to is_rental
	public outside_promoter: boolean = false;

	@IsString()
	@IsOptional()
	public platform_type: string | null = null;

	@IsOptional() public platform_images: PlatformImage | null = null;

	@IsDate()
	@IsOptional()
	// could be null if no platform is linked
	public platform_last_update: Date | null;

	@IsBoolean() public is_event_owned_by_agency: boolean;

	@IsNumber()
	@Min(0)
	@Max(1)
	public platform_active_sales: 1 | 0;

	@IsNumber()
	@Transform(castToNumber())
	public promoter_profit: number = 0;

	@Type((): typeof OrganizationSettings => {
		return OrganizationSettings;
	})
	@ValidateNested()
	public settings: OrganizationSettings = {} as OrganizationSettings;

	@Type((): typeof Organization => {
		return Organization;
	})
	@ValidateNested()
	public organization: Organization = {} as Organization;

	@IsNumber()
	@Transform(castToNumber())
	public tax_rate: number = 0;

	@IsEnum(TaxType) public tax_type: TaxType = TaxType.DIVISOR;

	@IsString() public timezone: string;

	@Type((): typeof Venue => {
		return Venue;
	})
	@ValidateNested()
	public venue: Venue = null;

	@IsNumber() public venue_id: number = null;

	@IsOptional()
	@IsInt()
	public tour_id: number | null = null;

	@IsOptional()
	public tour: Tour | undefined;

	@IsNumber() public actual_attendance: number = 0;
	@IsNumber() public estimated_attendance: number = 0;

	@Type((): typeof TaskTemplate => {
		return TaskTemplate;
	})
	@ValidateNested()
	@IsOptional()
	// only set when working with templates
	public task_templates?: TaskTemplate[] = [];

	@IsString()
	@IsOptional()
	public settlement_terms?: string;

	// This sometimes exists on the event object
	@IsOptional()
	@IsString()
	public date?: string | Date;

	@Type((): typeof AdditionalDate => {
		return AdditionalDate;
	})
	@ValidateNested()
	public additional_dates?: AdditionalDate[] = [];

	@Type((): typeof AdditionalRevenue => {
		return AdditionalRevenue;
	})
	@ValidateNested()
	public additional_revenue: AdditionalRevenue[] = [];

	@Type((): typeof PrismEventContact => {
		return PrismEventContact;
	})
	@ValidateNested()
	public contacts: PrismEventContact[] = [];

	@Type((): typeof ContactID => {
		return ContactID;
	})
	@ValidateNested()
	public contact_ids: ContactID[] = [];

	@Type((): typeof CostGroup => {
		return CostGroup;
	})
	@ValidateNested()
	public cost_groups: CostGroup[] = [];

	@Type((): typeof EventData => {
		return EventData;
	})
	@ValidateNested()
	public data: EventData = new EventData();

	/**
	 * promoter_data is filled in two cases:
	 *
	 * 	1) non-agent rental events (where outside_promoter = true)
	 * 	2) agency shows on which promoters are filled
	 */
	@Type((): typeof RentalData => {
		return RentalData;
	})
	@ValidateNested()
	@IsOptional()
	// TODO PRSM-6028 Change promoter_data to rental_data
	public promoter_data: RentalData;

	@Type((): typeof VariableCost => {
		return VariableCost;
	})
	@ValidateNested()
	public variable_costs: VariableCost[] = [];

	@Type((): typeof TalentData => {
		return TalentData;
	})
	@ValidateNested()
	public talent_data: TalentData[] = [];

	@Type((): typeof Ticket => {
		return Ticket;
	})
	@ValidateNested()
	public tickets: Ticket[] = [];

	@Type((): typeof FlatTicketRevenue => {
		return FlatTicketRevenue;
	})
	@ValidateNested()
	public flat_ticket_revenues: FlatTicketRevenue[] = [];

	@Type((): typeof TicketCommission => {
		return TicketCommission;
	})
	@ValidateNested()
	public ticket_commissions: TicketCommission[] = [];

	@IsNumber()
	public total_sold_for_commissions: number | undefined;

	@IsNumber()
	public total_comps_for_commissions: number | undefined;

	@Type((): typeof EventFee => {
		return EventFee;
	})
	@ValidateNested()
	public event_fees: EventFee[] = [];

	@Type((): typeof RunOfShow => {
		return RunOfShow;
	})
	@ValidateNested()
	public run_of_show: RunOfShow[] = [];

	@IsArray()
	@IsOptional()
	public permissions: Permission[];

	@IsBoolean()
	@Transform(castToBoolean())
	public isMadShow: boolean = false;

	@IsNumber()
	public age_limit: number | null = null;

	@IsBoolean()
	@Transform(castToBoolean())
	public has_age_limit: boolean = false;

	@IsBoolean()
	@Transform(castToBoolean())
	public is_all_ages: boolean = false;

	@Transform((transformParams: TypedTransformFnParams<Stage[]>): Stage[] => {
		const value: Stage[] | undefined = transformParams.value;

		if (!value) {
			verboseDebug('An event with an undefined stages has been returned by the API');
			return [];
		}

		if (!_.isArray(value)) {
			verboseDebug('An event with a non-array stages has been returned by the API');
			return [];
		}

		return value;
	})
	@Type((): typeof Stage => {
		return Stage;
	})
	@ValidateNested()
	public stages: Stage[] = [];

	@IsArray()
	@Type((): typeof PartnerDeal => {
		return PartnerDeal;
	})
	@ValidateNested()
	public partner_deals: PartnerDeal[] = [];

	/**
	 * This property controls whether or not the event is excluded from insights.
	 * Users are able to manage this setting via the event-insights-setting component,
	 * which appears on the event-settings-page.
	 */
	@IsBoolean()
	@Transform(castToBoolean())
	public insights_exclude: boolean;

	@IsEnum(EventInsightsExcludeReason)
	public insights_exclude_reason: EventInsightsExcludeReason;

	////////////////////////////////////////////////////////////////////////////
	// BACK END ONLY PROPERTIES - Can likely be removed entirely (and dropped from db)
	////////////////////////////////////////////////////////////////////////////
	@IsBoolean()
	@Transform(castToBoolean())
	public all_day: boolean = false;
	////////////////////////////////////////////////////////////////////////////
	// END BACK END ONLY PROPERTIES
	////////////////////////////////////////////////////////////////////////////

	////////////////////////////////////////////////////////////////////////////
	// UI ONLY PROPERTIES
	////////////////////////////////////////////////////////////////////////////
	// TODO PRSM-XXXX the properties in the proceeding section are UI only properties, and
	//      should very likely be moved to their respective components.
	//
	// Note these properties deliberately lack class-transformer decorators.
	// This is because we do not expect the backend to return these properties
	// when serving us an event model, so there is nothing to validate.
	////////////////////////////////////////////////////////////////////////////

	// TODO PRSM-6192 rename to renter_contact_id
	public promoter_contact_id: number = null;
	@IsNumber()
	@IsOptional()
	public contract_signer_id: number = null;

	@Type((): typeof Contact => {
		return Contact;
	})
	public contract_signer: Contact = null;
	public selectedStageIds: number[] = [];
	public desiredHoldType: DesiredHoldType = 'next-available';
	public contact: Contact = new Contact();
	////////////////////////////////////////////////////////////////////////////
	// END UI ONLY PROPERTIES
	////////////////////////////////////////////////////////////////////////////

	// Make this private to avoid directly reading
	@IsString()
	@IsOptional()
	private platform_id: string | null = null;

	/**
	 * shared holds things
	 */

	// these are values from the API
	@IsBoolean()
	@Transform(castToBoolean())
	private is_shared: boolean = false;

	@IsBoolean()
	@Transform(castToBoolean())
	private is_promoter_shared_event: boolean = false;

	@IsBoolean()
	@Transform(castToBoolean())
	private is_venue_shared_event: boolean = false;

	@IsOptional() private venue_event_link?: InstantHoldEventLink;

	@IsOptional() private promoter_event_link?: InstantHoldEventLink;

	@IsNumber()
	@IsOptional()
	@Transform(castToNumber(true))
	public copro_overridden_additional_support: number | null = null;

	@IsOptional()
	@IsString()
	public copro_additional_support_last_updated_at: string | null = null;

	@IsBoolean()
	@Transform(castToBoolean())
	public copro_additional_support_hidden: boolean = false;

	@IsBoolean()
	@Transform(castToBoolean())
	public show_headliner_financials: boolean = false;

	////////////////////////////////////////////////////////////////////////////
	// DERIVED PROPERTIES
	////////////////////////////////////////////////////////////////////////////

	// these are getters that pull the values from the api values.
	// this helps prevent underscores in our codebase
	public get isShared(): boolean {
		return this.is_shared;
	}

	public get isPromoterSharedEvent(): boolean {
		return this.is_shared && this.is_promoter_shared_event;
	}

	public get isVenueSharedEvent(): boolean {
		return this.is_shared && this.is_venue_shared_event;
	}

	public get isArchived(): boolean {
		return !_.isNil(this.archived_at);
	}

	public get eventLink(): InstantHoldEventLink {
		if (!this.is_shared) {
			return null;
		}

		if (this.is_promoter_shared_event) {
			return this.venue_event_link;
		}

		if (this.is_venue_shared_event) {
			return this.promoter_event_link;
		}

		return null;
	}

	public get sharedPartnerName(): string | null {
		if (this.isPromoterSharedEvent) {
			return this.eventLink.partnership.venue_venue.name;
		}
		if (this.isVenueSharedEvent) {
			return this.eventLink.partnership.promoter_organization.name;
		}
		return null;
	}

	/**
	 * end shared hold things
	 */

	/**
	 * The JSON encoder on the back end converts the VARCHAR platform_id
	 * into a pure number type. Some of the ids are very long and out of
	 * range for the Javascript number type. When the number type hits JS
	 * it will often get changed. The solution, at least until we figure this bug out
	 * is to add the '*' to the platform_id, which forces laravel to encode the value
	 * as a string. In order to access the pure ID on the front end, we can chop the
	 * asterick
	 */
	public get platformIdString(): string | null {
		if (this.platform_id) {
			return this.platform_id.replace('*', '');
		}
		return null;
	}

	/**
	 * gets a number to be used for multiplying a value and getting the total value minus tax.
	 *
	 * 	e.g. totalMinusTax = total * event.withoutTaxMultiplier()
	 *
	 */
	public get withoutTaxMultiplier(): number {
		return withoutTaxMultiplier(this.tax_rate, this.tax_type);
	}

	public sumVariableCosts(
		variableCosts: VariableCost[],
		costCalc: CostCalc,
		external: boolean,
		computeForCoPro: boolean
	): number {
		return _.sumBy(variableCosts, (vc: VariableCost): number => {
			// do this computation to keep the value out of the total
			if (computeForCoPro && vc.copro_cost_hidden) {
				return 0;
			}

			return vc.total(this, costCalc, external, computeForCoPro);
		});
	}

	public isNew(): boolean {
		return !this.id;
	}

	public get title(): string {
		switch (this.confirmed) {
			case EventStatus.Hold:
				return !this.isNew() ? this.name : 'New Hold';
			case EventStatus.N_A:
			case EventStatus.Confirmed:
			case EventStatus.Settlement:
				return this.isNew() ? 'New Confirmed' : 'Confirmed Event';
			case EventStatus.Settled:
				return 'Settled Event';
			default:
				throw new Error(`Unrecognized event status ${this.confirmed}`);
		}
	}

	////////////////////////////////////////////////////////////////////////////
	// facility fee
	////////////////////////////////////////////////////////////////////////////
	public facilityFee(costCalc: CostCalc, external: boolean): number {
		if (this.shouldApplyBroadwayTicketCommissionsOverrides(costCalc, external)) {
			return 0;
		}
		return _.sumBy(this.tickets, (ticket: Ticket): number => {
			return ticket.totalFacilityFee(costCalc, this.eventPropsForTicket);
		});
	}

	////////////////////////////////////////////////////////////////////////////
	// Ticket Commissions
	////////////////////////////////////////////////////////////////////////////

	public ticketCommissionsTotal(costCalc: CostCalc, external: boolean): number {
		if (!this.shouldApplyBroadwayTicketCommissionsOverrides(costCalc, external)) {
			return 0;
		}
		return _.sumBy(this.ticket_commissions, (ticketCommission: TicketCommission): number => {
			const netGrossRevenue: number = ticketCommission.calculateNetGrossRevenue(this.withoutTaxMultiplier);
			return ticketCommission.calculateCommission(netGrossRevenue, this.calculateTicketCommissionByNetGross);
		});
	}

	////////////////////////////////////////////////////////////////////////////
	// total taxes and fees
	////////////////////////////////////////////////////////////////////////////
	public totalTaxesAndFees(costCalc: CostCalc, external: boolean): number {
		if (this.shouldApplyBroadwayTicketCommissionsOverrides(costCalc, external)) {
			return _.sumBy(this.ticket_commissions, (ticketCommission: TicketCommission): number => {
				return ticketCommission.calculateTax(this.withoutTaxMultiplier);
			});
		}
		return (
			this.totalFees(costCalc, external) +
			this.totalTax(costCalc, external) +
			this.adjustedGrossFees(costCalc) +
			this.calculateFlatPreSettlementFeesAfterTax(costCalc) -
			this.calculatePercentOfAdjustedGrossFeeAdjustment(costCalc)
		);
	}

	public totalFees(costCalc: CostCalc, external: boolean): number {
		if (this.shouldApplyBroadwayTicketCommissionsOverrides(costCalc, external)) {
			return 0;
		}
		return this.eventFees(costCalc, external) + this.facilityFee(costCalc, external);
	}

	////////////////////////////////////////////////////////////////////////////
	// event fees aka presettlement fees
	////////////////////////////////////////////////////////////////////////////
	public eventFees(costCalc: CostCalc, external: boolean): number {
		if (this.shouldApplyBroadwayTicketCommissionsOverrides(costCalc, external)) {
			return 0;
		}
		const ticketFees: number = _.sumBy(this.tickets, (ticket: Ticket): number => {
			return ticket.totalEventFees(costCalc, this.eventPropsForTicket, true);
		});
		const flatTicketFees: number = _.sumBy(this.flat_ticket_revenues, (flatTicket: FlatTicketRevenue): number => {
			return flatTicket.totalEventFees(costCalc, this.eventPropsForTicket, true);
		});

		const presettlementFeesBeforeTax: number = this.calculateFlatPreSettlementFeesBeforeTax(costCalc);
		return ticketFees + flatTicketFees + presettlementFeesBeforeTax;
	}

	////////////////////////////////////////////////////////////////////////////
	// rental bonus
	////////////////////////////////////////////////////////////////////////////
	public rentalBonus(costCalc: CostCalc): number {
		if (!this.promoter_data) {
			return 0;
		}
		return this.promoter_data.bonus_per_ticket * this.ticketsSold(costCalc);
	}

	public get calculateTicketCommissionByNetGross(): boolean {
		return this.does_tour_calculate_ticket_commission_by_net_gross;
	}

	public get isEventMemberOfBroadwayTour(): boolean {
		return this.is_event_member_of_broadway_tour;
	}

	/**
	 * Determines whether to apply Broadway ticket commissions overrides for the given cost calculation and external flag.
	 *
	 * @function shouldApplyBroadwayTicketCommissionsOverrides
	 * @param {CostCalc} costCalc - The cost calculation type.
	 * @param {boolean} external - Flag indicating if the calculation is for an external document.
	 * @returns {boolean} - True if the event is a member of Broadway Tour and the input conditions match the allowed criteria, false otherwise.
	 *
	 * @example
	 * Checks if the Broadway ticket commissions overrides should be applied for the given cost calculation and external flag
	 * shouldApplyBroadwayTicketCommissionsOverrides(CostCalc.Actual, true);
	 */
	public shouldApplyBroadwayTicketCommissionsOverrides(costCalc: CostCalc, external: boolean): boolean {
		return (
			this.isEventMemberOfBroadwayTour &&
			// InternalActual
			((costCalc === CostCalc.Actual && !external) ||
				// ExternalReported
				(costCalc === CostCalc.Reported && external))
		);
	}

	// #region gross
	////////////////////////////////////////////////////////////////////////////
	// gross
	////////////////////////////////////////////////////////////////////////////
	public gross(costCalc: CostCalc, external: boolean): number {
		if (this.shouldApplyBroadwayTicketCommissionsOverrides(costCalc, external)) {
			return _.sumBy(this.ticket_commissions, (ticketCommission: TicketCommission): number => {
				return ticketCommission.gross_revenue;
			});
		}

		return this.ticketRevenue(costCalc) + this.flatTicketRevenue(costCalc);
	}

	public ticketRevenue(costCalc: CostCalc): number {
		return _.sumBy(this.tickets, (ticket: Ticket): number => {
			return ticket.ticketRevenue(costCalc);
		});
	}

	public flatTicketRevenue(costCalc: CostCalc): number {
		return _.sumBy(this.flat_ticket_revenues, (flatTicket: FlatTicketRevenue): number => {
			return flatTicket.ticketRevenue(costCalc);
		});
	}

	public get estimatedGross(): number {
		return this.gross(CostCalc.Estimated, false);
	}

	public get actualGross(): number {
		return this.gross(CostCalc.Actual, false);
	}

	public get potentialGross(): number {
		return this.gross(CostCalc.Potential, false);
	}
	// #endregion

	// #region netGross
	////////////////////////////////////////////////////////////////////////////
	// net gross
	////////////////////////////////////////////////////////////////////////////
	// TODO PRSM-XXXX there is another way of calculating net gross, the formula
	// taxableGross/(1+tax_rate/100).
	public netGross(costCalc: CostCalc, external: boolean): number {
		return (
			this.netGrossBeforePresettlementFeesAfterTax(costCalc, external) -
			this.calculateFlatPreSettlementFeesAfterTax(costCalc) +
			// For fees of type 'Percent of Adjusted Gross', the percentage part is initially discounted from the 'Flat Out of Gross' fees.
			// However, the net gross calculation is performed at the ticket level, where the 'Flat Out of Gross' fees are not yet included.
			// This means that when calculating the net gross, the values from 'Percent of Adjusted Gross' fees do not account for the corresponding percentage part of the 'Flat Out of Gross' fees.
			// Therefore, it is necessary to include this adjustment here.
			this.calculatePercentOfAdjustedGrossFeeAdjustment(costCalc)
		);
	}

	private netGrossBeforePresettlementFeesAfterTax(costCalc: CostCalc, external: boolean): number {
		if (this.shouldApplyBroadwayTicketCommissionsOverrides(costCalc, external)) {
			return _.sumBy(this.ticket_commissions, (ticketCommission: TicketCommission): number => {
				return ticketCommission.calculateNetGrossRevenue(this.withoutTaxMultiplier);
			});
		}

		return (
			this.ticketsNetGross(costCalc) +
			this.flatTicketsNetGross(costCalc) +
			this.totalAdditionalRevenueTowardsNetGross(costCalc, external) -
			this.calculateFlatPreSettlementFeesBeforeTaxWithTaxAdjustment(costCalc)
		);
	}

	public calculatePercentOfAdjustedGrossFeeAdjustment(costCalc: CostCalc): number {
		return this.event_fees.reduce((memo: number, eventFee: EventFee): number => {
			if (eventFee.type !== EventFeeType.PERCENT_OF_ADJUSTED_GROSS) {
				return memo;
			}

			return memo + eventFee.getPercentAmount(this.calculateFlatPreSettlementFeesBeforeTax(costCalc));
		}, 0);
	}

	public ticketsNetGross(costCalc: CostCalc): number {
		return _.sumBy(this.tickets, (ticket: Ticket): number => {
			return this.netGrossForTicket(ticket, costCalc);
		});
	}

	public netGrossForTicket(ticket: Ticket, costCalc: CostCalc): number {
		return ticket.netGross(costCalc, this.eventPropsForTicket);
	}

	public flatTicketsNetGross(costCalc: CostCalc): number {
		return _.sumBy(this.flat_ticket_revenues, (flatTicket: FlatTicketRevenue): number => {
			return this.netGrossForFlatTicket(flatTicket, costCalc);
		});
	}

	public netGrossForFlatTicket(flatTicket: FlatTicketRevenue, costCalc: CostCalc): number {
		return flatTicket.netGross(costCalc, this.eventPropsForTicket);
	}

	public totalRevenueForCoPro(costCalc: CostCalc, external: boolean): number {
		return this.netGross(costCalc, external) + this.totalAdditionalRevenueForCoPro(costCalc, external);
	}
	// #endregion

	// #region adjustedGross
	////////////////////////////////////////////////////////////////////////////
	// adjusted gross - Created for
	// https://onesolstice.atlassian.net/wiki/spaces/PRISM/pages/2170322945/Industry-Leading+Offer+and+Settlement
	//
	// adjustedGross = gross - totalFees
	//
	////////////////////////////////////////////////////////////////////////////
	public adjustedGross(costCalc: CostCalc, external: boolean): number {
		if (this.shouldApplyBroadwayTicketCommissionsOverrides(costCalc, external)) {
			// there are no fees on the broadway commissions table, so we use gross here
			return this.gross(costCalc, external);
		}

		// return this.gross(costCalc) - this.totalFees(costCalc);
		const totalAdjustedGrossForTicket: number = _.sumBy(this.tickets, (ticket: Ticket): number => {
			return this.adjustedGrossForTicket(ticket, costCalc);
		});
		const totalAdjustedGrossForFlatTicket: number = _.sumBy(
			this.flat_ticket_revenues,
			(flatTicket: FlatTicketRevenue): number => {
				return this.adjustedGrossForFlatTicket(flatTicket, costCalc);
			}
		);
		const presettlementFeesBeforeTax: number = this.calculateFlatPreSettlementFeesBeforeTax(costCalc);
		return totalAdjustedGrossForTicket + totalAdjustedGrossForFlatTicket - presettlementFeesBeforeTax;
	}

	public adjustedGrossForTicket(ticket: Ticket, costCalc: CostCalc): number {
		return ticket.adjustedGross(costCalc, this.eventPropsForTicket);
	}

	public adjustedGrossForFlatTicket(flatTicket: FlatTicketRevenue, costCalc: CostCalc): number {
		return flatTicket.adjustedGross(costCalc, this.eventPropsForTicket);
	}
	// #endregion

	// this is currently only used by EMS, but it is a crucial number along the way of our calculations
	public taxableGross(costCalc: CostCalc, external: boolean): number {
		return (
			this.gross(costCalc, external) - this.eventFees(costCalc, external) - this.facilityFee(costCalc, external)
		);
	}

	public externalExpensesTowardSplitPoint(costCalc: CostCalc, optionsArg: WithCoProOptions = {}): number {
		const totalTalentPayTowardsSplitPoint: number = this.talentPayTowardsSplitPoint(costCalc);
		const totalAdjustmentsTowardSplitPoint: number = this.totalAdjustmentsTowardSplitPoint(this.talent_data);

		const totalReportedFixedCosts: number = this.fixedCosts(costCalc, true, optionsArg);
		const totalReportedVariableCosts: number = this.variableCostsTotal(costCalc, true, optionsArg.computeForCoPro);

		return (
			totalReportedFixedCosts +
			totalTalentPayTowardsSplitPoint +
			totalReportedVariableCosts +
			totalAdjustmentsTowardSplitPoint
		);
	}

	public get reportedBudgetedShowExpenses(): number {
		return this.externalExpensesTowardSplitPoint(CostCalc.Budgeted);
	}

	public get reportedReportedShowExpenses(): number {
		return this.externalExpensesTowardSplitPoint(CostCalc.Reported);
	}

	public exchangeRate(costCalc: CostCalc): number {
		let exchangeRate: number = getExchangeRate(costCalc, this.eventPropsForGuarantees);
		if (!exchangeRate) {
			exchangeRate = 1;
		}
		exchangeRate = 1 / exchangeRate;
		return parseFloat(exchangeRate.toString());
	}

	public talentPayTowardsSplitPoint(costCalc: CostCalc): number {
		const filteredTalentData: TalentData[] = this.getTalentForExternalCalculationsFromEventTalentDeals({
			forSplitPoint: true,
		});
		const payouts: number = this.calculatecontributingArtistPayoutTowardAgentPayout(
			costCalc,
			true,
			filteredTalentData,
			{
				forSplitPoint: true,
			}
		);

		const additionalSupport: number = this.additionalSupport(costCalc, false);

		return payouts + additionalSupport;
	}

	/**
	 * Sum all the adjustments across all talent deals passed as a parameter where `forSplitPoint` is set
	 * to true.
	 *
	 * @param {TalentData[]} talentDeals - An array of talent deals passed as a parameter.
	 * @returns {number} A total of all the adjustments across all talent deals passed as a parameter where `forSplitPoint` is set
	 * to true.
	 */
	private totalAdjustmentsTowardSplitPoint(talentDeals: TalentData[]): number {
		const filteredTalentData: TalentData[] = this.getTalentForExternalCalculationsFromProvidedTalentDeals(
			talentDeals,
			{ forSplitPoint: true }
		);
		return filteredTalentData.reduce((adjustmentTotal: number, talentData: TalentData): number => {
			adjustmentTotal += talentData.totalAdjustmentsTowardSplitPoint;
			return adjustmentTotal;
		}, 0);
	}

	public netRevenueToSplit(costCalc: CostCalc, external: boolean): number {
		const commission: number = this.ticketCommissionsTotal(costCalc, external);
		return (
			this.netGross(costCalc, external) -
			this.splitPoint(costCalc) -
			commission -
			this.calculateRoyalty(costCalc, external)
		);
	}

	////////////////////////////////////////////////////////////////////////////
	// split point
	////////////////////////////////////////////////////////////////////////////
	public splitPoint(costCalc: CostCalc, optionsArg: WithForSplitPointOptionsArgs = {}): number {
		const defaultOptions: WithForSplitPointOptions = {
			forSplitPoint: false,
		};
		const options: WithForSplitPointOptions = _.defaults({}, optionsArg, defaultOptions);
		if (options.forSplitPoint) {
			return 0;
		}
		switch (costCalc) {
			case CostCalc.Reported:
			case CostCalc.Actual:
				return (
					this.externalExpensesTowardSplitPoint(CostCalc.Reported) + this.promoterProfit(CostCalc.Reported)
				);
			case CostCalc.Estimated:
				return (
					this.externalExpensesTowardSplitPoint(CostCalc.Estimated) + this.promoterProfit(CostCalc.Estimated)
				);
			case CostCalc.Budgeted:
			case CostCalc.Potential:
				return (
					this.externalExpensesTowardSplitPoint(CostCalc.Budgeted) + this.promoterProfit(CostCalc.Budgeted)
				);
			default:
				debug(`Unrecognized CostCalc ${costCalc}.`);
				return this.externalExpensesTowardSplitPoint(costCalc) + this.promoterProfit(costCalc);
		}
	}

	// the point in ticket sales where artist bonus kicks
	public get actualSplitPoint(): number {
		return this.reportedReportedShowExpenses + this.promoterProfit(CostCalc.Reported);
	}

	public get budgetedSplitPoint(): number {
		return this.reportedBudgetedShowExpenses + this.promoterProfit(CostCalc.Budgeted);
	}

	// only used by document.service
	// can we call splitPoint directly?
	public convertedDocumentSplitPoint(costCalc: CostCalc, optionsArg: WithCoProOptions = {}): number {
		const show_expenses: number = this.externalExpensesTowardSplitPoint(costCalc, optionsArg);
		const promoter_profit: number = this.promoterProfit(costCalc);

		return show_expenses + promoter_profit;
	}

	public promoterProfit(costCalc: CostCalc, optionsArg: WithCoProOptions = {}): number {
		const showExpenses: number = this.externalExpensesTowardSplitPoint(costCalc, optionsArg);
		return calculatePromoterProfit({
			costsTotal: showExpenses,
			promoterProfitPercentage: deprecatedDoNotUsePreventNaN(+this.promoter_profit),
		});
	}

	public promoterProfitPercentage(external: boolean): number {
		let promoterProfit: number = 0;
		if (external) {
			promoterProfit = 1 + +this.promoter_profit / 100;
		} else {
			promoterProfit = 1;
		}
		return promoterProfit;
	}

	////////////////////////////////////////////////////////////////////////////
	// total tax
	////////////////////////////////////////////////////////////////////////////
	public totalTax(costCalc: CostCalc, external: boolean): number {
		return (
			this.gross(costCalc, external) -
			this.netGrossBeforePresettlementFeesAfterTax(costCalc, external) +
			this.totalAdditionalRevenueTowardsNetGross(costCalc, external) -
			this.facilityFee(costCalc, external) -
			this.eventFees(costCalc, external) -
			this.adjustedGrossFees(costCalc)
		);
	}

	public adjustedGrossFees(costCalc: CostCalc): number {
		const ticketAdjustedGross: number = this.tickets.reduce((memo: number, ticket: Ticket): number => {
			return (
				memo +
				ticket.calculateAdjustedGrossFees(
					this.eventPropsForTicket,
					ticket.adjustedGross(costCalc, this.eventPropsForTicket)
				)
			);
		}, 0);
		const flatTicketAdjustedGross: number = this.flat_ticket_revenues.reduce(
			(memo: number, flatTicket: FlatTicketRevenue): number => {
				return (
					memo +
					flatTicket.calculateAdjustedGrossFees(
						this.eventPropsForTicket,
						flatTicket.adjustedGross(costCalc, this.eventPropsForTicket)
					)
				);
			},
			0
		);

		return ticketAdjustedGross + flatTicketAdjustedGross;
	}

	// return amount of tax paid on Tickets for a ongoing or past show
	public get platformTaxCollected(): number {
		let total: number = 0;
		for (const ticket of this.tickets) {
			total += deprecatedDoNotUsePreventNaN(ticket.platformTaxPaid);
		}
		return deprecatedDoNotUsePreventNaN(total);
	}

	////////////////////////////////////////////////////////////////////////////
	// variable costs and fees
	////////////////////////////////////////////////////////////////////////////
	public variableCostsTotal(costCalc: CostCalc, external: boolean, computeForCoPro: boolean): number {
		checkLegacyArgs(costCalc, external);
		// or return Event.sumVariableCosts(this, this.variableCosts(external), costCalc)
		return this.sumVariableCosts(this.variableCosts(external), costCalc, external, computeForCoPro);
	}

	public variableCosts(external: boolean): VariableCost[] {
		if (external) {
			return this.externalVariableCosts;
		}
		return this.variable_costs;
	}

	private get externalVariableCosts(): VariableCost[] {
		const externalVariableCosts: VariableCost[] = this.variable_costs.filter((vc: VariableCost): boolean => {
			return vc.reported;
		});
		return externalVariableCosts;
	}

	public get actualVariableCosts(): number {
		return this.variableCostsTotal(CostCalc.Actual, false, false);
	}

	public get budgetedVariableCosts(): number {
		return this.variableCostsTotal(CostCalc.Budgeted, false, false);
	}

	public get estimatedVariableCosts(): number {
		return this.variableCostsTotal(CostCalc.Estimated, false, false);
	}

	public get potentialVariableCosts(): number {
		return this.variableCostsTotal(CostCalc.Potential, false, false);
	}

	public get reportedBudgetedVariableCosts(): number {
		return this.variableCostsTotal(CostCalc.Budgeted, true, false);
	}

	////////////////////////////////////////////////////////////////////////////
	// fixed costs
	////////////////////////////////////////////////////////////////////////////
	public fixedCosts(costCalc: CostCalc, external: boolean = false, optionsArg: FixedCostsOptionsArg = {}): number {
		checkLegacyArgs(costCalc, external);
		const filteredGroups: CostGroup[] = fixedCostGroups(this.cost_groups, costCalc, external, optionsArg);
		return _.sumBy(filteredGroups, (costGroup: CostGroup): number => {
			return costGroup.total(costCalc, external, optionsArg.computeForCoPro);
		});
	}

	// TODO PRSM-XXXX delete all of these getters and call fixedCosts directly
	// only used by helpers.ts
	public get budgetedFixedCosts(): number {
		return this.fixedCosts(CostCalc.Budgeted);
	}

	public get actualFixedCosts(): number {
		return this.fixedCosts(CostCalc.Actual);
	}

	public get estimatedFixedCosts(): number {
		return this.fixedCosts(CostCalc.Estimated);
	}

	// only used by document.service
	public get reportedReportedFixedCosts(): number {
		return this.fixedCosts(CostCalc.Reported, true);
	}

	public get reportedBudgetedFixedCosts(): number {
		return this.fixedCosts(CostCalc.Budgeted, true);
	}

	////////////////////////////////////////////////////////////////////////////
	// revenue
	////////////////////////////////////////////////////////////////////////////
	public get totalActualInternalRevenue(): number {
		const opRevenue: number = this.rentalPayment(CostCalc.Actual, false);
		return (
			opRevenue +
			this.netGross(CostCalc.Actual, false) +
			this.totalAdditionalRevenue +
			this.ticketingPlatformRebate(CostCalc.Actual)
		);
	}

	public totalInternalRevenue(costCalc: CostCalc): number {
		const gross: number = this.gross(costCalc, false);
		const additionalRevenue: number = this.additionalRevenue(costCalc, false);
		const rebate: number = this.ticketingPlatformRebate(costCalc);
		const opRevenue: number = this.rentalPayment(costCalc, false);
		return gross + additionalRevenue + rebate + opRevenue;
	}

	public totalAdjustedInternalRevenue(costCalc: CostCalc): number {
		const deltaGross: number = this.gross(costCalc, false) - this.netGross(costCalc, false);
		return this.totalInternalRevenue(costCalc) - deltaGross;
	}

	public totalAdditionalRevenueTowardsNetGross(costCalc: CostCalc, external: boolean): number {
		if (this.shouldApplyBroadwayTicketCommissionsOverrides(costCalc, external)) {
			return 0;
		}
		// additional revenue towards net gross has the same value for internal or external
		return this.additionalRevenue(costCalc, false, true);
	}

	////////////////////////////////////////////////////////////////////////////
	// additional revenue
	////////////////////////////////////////////////////////////////////////////
	/**
	 * Calculates the total additional revenue amount based on a cost calculation and external flag.
	 * This is done based on the additional revenue items. Those that are included in net gross are
	 * mutually exclusive with the ones that are not included, so depending if its towards net gross
	 * we will use either those items that are included in net gross or not, reaching different results
	 * for each case.
	 * @param costCalc The cost calc to be used
	 * @param external Determines if we should calculate based on an internal or external perspective
	 * @param towardsNetGross Flag that indicates which additional revenue items to use
	 * @returns The total additional revenue amount towards net gross or not
	 */
	public additionalRevenue(costCalc: CostCalc, external: boolean, towardsNetGross: boolean = false): number {
		checkLegacyArgs(costCalc, external);
		let additionalRevenue: number = 0;
		this.additional_revenue
			.filter((item: AdditionalRevenue): boolean => {
				if (!towardsNetGross) {
					return !item.include_in_net_gross;
				}
				return item.include_in_net_gross;
			})
			.forEach((item: AdditionalRevenue): void => {
				additionalRevenue += item.getTotal(costCalc, external, this);
			});
		return additionalRevenue;
	}

	private get totalAdditionalRevenue(): number {
		return this.additionalRevenue(CostCalc.Actual, false);
	}

	public get estAdditionalRevenue(): number {
		return this.additionalRevenue(CostCalc.Estimated, false);
	}

	public get estAdditionalRevenueTowardsNetGross(): number {
		return this.additionalRevenue(CostCalc.Estimated, false, true);
	}

	public totalAdditionalRevenueForCoPro(costCalc: CostCalc, external: boolean): number {
		return this.additional_revenue.reduce((revenueTowardsCoPro: number, item: AdditionalRevenue): number => {
			return (revenueTowardsCoPro += item.getTotalTowardsCoPro(costCalc, external, this));
		}, 0);
	}

	////////////////////////////////////////////////////////////////////////////
	// expenses
	////////////////////////////////////////////////////////////////////////////
	public totalExpenses(costCalc: CostCalc, external: boolean, computeForCoPro: boolean): number {
		checkLegacyArgs(costCalc, external);
		if (external) {
			return this.totalExternalExpenses(costCalc, computeForCoPro);
		}
		return this.totalInternalExpenses(costCalc, computeForCoPro);
	}

	public showExpenses(costCalc: CostCalc, external: boolean, computeForCoPro: boolean): number {
		checkLegacyArgs(costCalc, external);
		return (
			this.fixedCosts(costCalc, external, { computeForCoPro }) +
			this.variableCostsTotal(costCalc, external, computeForCoPro) +
			this.promoterProfit(costCalc, { computeForCoPro })
		);
	}

	/**
	 * Total artist facing expenses. Used for:
	 *	- "total expenses" in offer/settlement documents
	 *	- breakeven expenses
	 */
	public totalExternalExpenses(costCalc: CostCalc, computeForCoPro: boolean): number {
		const filteredAdjustedPayouts: number = this.externalPayoutExpenses(costCalc, computeForCoPro);
		const totalReportedFixedCosts: number = this.fixedCosts(costCalc, true, { computeForCoPro });
		const totalReportedVariableCosts: number = this.variableCostsTotal(costCalc, true, computeForCoPro);
		const promoterProfit: number = this.promoterProfit(costCalc, { computeForCoPro });

		return totalReportedFixedCosts + filteredAdjustedPayouts + totalReportedVariableCosts + promoterProfit;
	}

	public totalInternalExpenses(costCalc: CostCalc, computeForCoPro: boolean): number {
		const fixedCosts: number = this.fixedCosts(costCalc, false, { computeForCoPro });
		const variableCostsInternalTotal: number = this.variableCostsTotal(costCalc, false, computeForCoPro);
		const talentCosts: number = this.totalInternalTalentPayouts(costCalc, computeForCoPro);
		const rentalPayout: number = this.rentalPayout(costCalc, false);
		return fixedCosts + variableCostsInternalTotal + talentCosts + rentalPayout;
	}

	/**
	 * this value is only used in the payouts table in the fixed cost section
	 * on PDF documents.
	 *
	 * @param costCalc the current cost calc
	 * @returns the total expenses.
	 */
	public talentExpenseAmount(costCalc: CostCalc): number {
		let talentExpenseAmount: number = this.externalPayoutExpenses(costCalc, false);

		if (this.hasNetDeal) {
			talentExpenseAmount = this.talentPayTowardsSplitPoint(costCalc);
		}
		return talentExpenseAmount;
	}

	/**
	 * only used by budget summary legacy outside of this document
	 */
	public totalInternalTalentPayouts(costCalc: CostCalc, computeForCoPro: boolean): number {
		const additionalSupport: number = this.additionalSupport(costCalc, computeForCoPro);
		const talentPayouts: number = this.calculateTotalAdjustedArtistPayouts(
			costCalc,
			false,
			this.talent_data,
			computeForCoPro
		);
		return additionalSupport + talentPayouts;
	}

	/**
	 * considers adjusted in-deal talent payouts + additional support
	 */
	public externalPayoutExpenses(costCalc: CostCalc, computeForCoPro: boolean): number {
		const filteredTalentData: TalentData[] = _.cloneDeep(this.talent_data).filter((deal: TalentData): boolean => {
			return deal.include_in_deal;
		});

		const additionalSupport: number = this.additionalSupport(costCalc, computeForCoPro);
		const adjustedPayouts: number = this.calculatecontributingArtistPayoutTowardAgentPayout(
			costCalc,
			true,
			filteredTalentData,
			{
				forSplitPoint: false,
				adjusted: true,
			}
		);

		const totalAdjustmentsTowardSettlement: number = sumPropOverObjects<TalentData>(
			filteredTalentData,
			'totalAdjustmentsTowardSettlement'
		);
		return additionalSupport + adjustedPayouts - totalAdjustmentsTowardSettlement;
	}

	////////////////////////////////////////////////////////////////////////////
	// expenses
	////////////////////////////////////////////////////////////////////////////
	public totalCoProPayout(costCalc: CostCalc, external: boolean): number {
		const eventPropsForPartnerDeal: EventPropsForPartnerDeal = this.eventPropsForPartnerDeal(costCalc, external);

		return this.partner_deals.reduce((totalCoProPayout: number, partnerDeal: PartnerDeal): number => {
			return totalCoProPayout + partnerDeal.totalCoProPayout(eventPropsForPartnerDeal);
		}, 0);
	}

	////////////////////////////////////////////////////////////////////////////
	// net profit
	////////////////////////////////////////////////////////////////////////////
	public netProfit(costCalc: CostCalc, external: boolean, computeForCoPro: boolean): number {
		checkLegacyArgs(costCalc, external);
		if (external) {
			return this.externalNetProfit(costCalc);
		}
		return this.internalNetProfit(costCalc, computeForCoPro);
	}

	public externalNetProfit(costCalc: CostCalc): number {
		const netGross: number = this.netGross(costCalc, true);
		let netProfit: number = netGross - this.totalExternalExpenses(costCalc, false);

		if (this.isRental) {
			netProfit = netProfit - this.rentalPayout(costCalc, true);
		}

		return netProfit;
	}

	private internalNetProfit(costCalc: CostCalc, computeForCoPro: boolean): number {
		// partner deals
		const partnerDealPayout: number = !computeForCoPro ? this.totalCoProPayout(costCalc, false) : 0;

		// actual/reported math is a little more simple
		if (costCalc === CostCalc.Reported || costCalc === CostCalc.Actual) {
			return (
				this.totalActualInternalRevenue -
				this.totalInternalExpenses(CostCalc.Actual, computeForCoPro) -
				partnerDealPayout
			);
		}

		// the rest of this logic is for Estimated/Budgeted/Potential
		// revenue
		const additionalRevenue: number = this.additionalRevenue(costCalc, false);
		const netGross: number = this.netGross(costCalc, false);
		const opRevenue: number = this.rentalPayment(costCalc, false);

		// costs
		const opExpense: number = this.rentalPayout(costCalc, false);
		const fixedCosts: number = this.fixedCosts(costCalc, false, { computeForCoPro });
		const variableCosts: number = this.variableCostsTotal(costCalc, false, computeForCoPro);
		const totalInternalTalentPayout: number = this.totalInternalTalentPayouts(costCalc, computeForCoPro);

		return (
			// incoming money
			additionalRevenue +
			netGross +
			opRevenue -
			//  outgoing money
			opExpense -
			(fixedCosts + variableCosts + totalInternalTalentPayout) -
			partnerDealPayout
		);
	}

	/**
	 * netProfitForCoProCalculations is similar to netProfit, except the only additional
	 * revenues that are added are those that have the include_in_copro_split boolean
	 * turned on
	 *
	 * @param costCalc cost calc
	 * @param external external
	 * @returns net profit for co pro, as defined above
	 */
	public netProfitForCoProCalculations(costCalc: CostCalc, external: boolean): number {
		checkLegacyArgs(costCalc, external);

		// you MUST call netProfitBeforeCoPro() here. if netProfit is called directly, this
		// code will infinite loop
		//
		// this is because partner deals are dependent on net profit to calculate
		// their totals, but also need to be subtracted from netProfit for the final numbers
		const netProfit: number =
			this.netProfitBeforeCoPro(costCalc, external) - this.ticketingPlatformRebate(costCalc);

		// we only apply additional revenue for internal net profit.
		// if this is external, return the raw number without doing any additional
		// revenue calculations
		if (external) {
			return netProfit;
		}

		// if we're internal, subtract the total additional revenue from the netprofit
		// and add totalAdditionalRevenueTowardsCoPro
		return (
			netProfit -
			this.additionalRevenue(costCalc, false) +
			this.totalAdditionalRevenueForCoPro(costCalc, external)
		);
	}

	/**
	 * this is the net profit used to compute partner deal splits. this means that the number
	 * here is netProfit() before the partner deals are removed from that value
	 *
	 * @param costCalc the cost calc
	 * @param external external?
	 * @returns the net profit used before partner deals are removed
	 */
	public netProfitBeforeCoPro(costCalc: CostCalc, external: boolean): number {
		return this.netProfit(costCalc, external, true);
	}

	/**
	 * copro-specific net profit. this number represents the final net profit for the show owner
	 * after all the partner deals are settled
	 *
	 * @param costCalc cost calc
	 * @param external external
	 * @returns net profit rendered on the co pro page
	 */
	public netProfitAfterCoPro(costCalc: CostCalc, external: boolean): number {
		return this.netProfitForCoProCalculations(costCalc, external) - this.totalCoProPayout(costCalc, external);
	}

	////////////////////////////////////////////////////////////////////////////
	// tickets
	////////////////////////////////////////////////////////////////////////////
	public weightedTicketAverage(costCalc: CostCalc, external: boolean): EMSTicketAggregate<number, string, boolean> {
		return this.computeWeightedTicketAverage(this.tickets, costCalc, external);
	}

	/**
	 * unit logic for computing break even. this function can compute break even for a single ticket (ticket-level break even),
	 * or an array of tickets on the event (event-level break even)
	 *
	 * the rough equation for break even is:
	 *
	 * 	 	costs / ticket price
	 *
	 * the result of this computation is the number of tickets that need to be sold for the show
	 * to become profitable.
	 *
	 * when computing event-level break even, ticket prices, additional revenue, and fees
	 * will be weighed based on the sellable of each ticket vs the total sellable of all tickets across the event.
	 * when computing ticket-level break even, there will be no weighing, and the ticket price and all related
	 * ticket numbers will be used as is.
	 *
	 * for more details for break even, see our intercom docs: https://intercom.help/prism-fm/en/articles/2886561-prism-breakeven-101
	 * that documentation further details our break even compuations, like where we consider fixed and variable costs, additional revenue,
	 * dynamic tickets, etc and how they all factor in to the final break even number
	 *
	 * @param ticketsToCompute either an array of tickets, or a single ticket
	 * @param costCalc the cost calc computing for
	 * @param external true if external, false if internal
	 * @returns break even numbers
	 */
	private computeWeightedTicketAverage(
		ticketsToCompute: Ticket[] | Ticket,
		costCalc: CostCalc,
		external: boolean
	): EMSTicketAggregate<number, string, boolean> {
		checkLegacyArgs(costCalc, external);

		const isForSingleTicket: boolean = !Array.isArray(ticketsToCompute);
		const tickets: Ticket[] = Array.isArray(ticketsToCompute) ? ticketsToCompute : [ticketsToCompute];

		// this is business logic! see this page for info:
		//   https://intercom.help/prism-fm/en/articles/2886561-breakeven
		// we computed a "weighted average" ticket, based on ticket price and
		// sellable
		let ticketsSold: number = 0;
		let netTicketGross: number = 0;
		let ticketRevenue: number = 0;
		let comps: number = 0;
		let kills: number = 0;
		let sellable: number = 0;
		const pricedTickets: Ticket[] = tickets.filter((t: Ticket): boolean => {
			return t.ticket_price > 0;
		});
		const totalTicketAllotment: number = _.chain(pricedTickets)
			.map((ticket: Ticket): number => {
				return ticket.sellable;
			})
			.sum()
			.value();
		const weightedTicketAveragePrice: number = _.sum(
			_.map(pricedTickets, (ticket: Ticket): number => {
				ticketsSold += ticket.ticketsSold(costCalc);
				netTicketGross += this.netGrossForTicket(ticket, costCalc);
				ticketRevenue += ticket.ticketRevenue(costCalc);
				comps += ticket.comps;
				kills += ticket.kills;
				sellable += ticket.sellable;

				if (isForSingleTicket) {
					return ticket.ticket_price;
				}

				if (!totalTicketAllotment) {
					return 0;
				}

				return (ticket.ticket_price * ticket.sellable) / totalTicketAllotment;
			})
		);

		this.flat_ticket_revenues
			.filter((t: FlatTicketRevenue): boolean => {
				return t.ticketRevenue(costCalc) > 0;
			})
			.forEach((flatTicket: FlatTicketRevenue): void => {
				netTicketGross += flatTicket.netGross(costCalc, this.eventPropsForTicket);
				ticketRevenue += flatTicket.ticketRevenue(costCalc);
			});

		const totalWeightedPerTicketTierVariableCost: number = _.sum(
			_.map(pricedTickets, (ticket: Ticket): number => {
				if (!totalTicketAllotment) {
					return 0;
				}

				const perTicketVariableCostSum: number = this.fetchVariableCostTotalForTicket(ticket, external);
				return (perTicketVariableCostSum * ticket.sellable) / totalTicketAllotment;
			})
		);

		// we filter out additional revenues not included in net gross when calculating
		// external cost calcs
		const additionalRevenueItems: AdditionalRevenue[] = this.additional_revenue.filter(
			(additional_revenue: AdditionalRevenue): boolean => {
				return !external || additional_revenue.include_in_net_gross;
			}
		);

		const totalWeightedPerTicketTierAdditionalRevenue: number = _.chain(pricedTickets)
			.map((ticket: Ticket): number => {
				const perTicketAdditionalRevenueAmount: number = _.chain(additionalRevenueItems)
					.filter((additionalRevenue: AdditionalRevenue): boolean => {
						return additionalRevenue.isPerTicketType(costCalc);
					})
					.filter((additionalRevenue: AdditionalRevenue): boolean => {
						return additionalRevenue.isForTicket(costCalc, Number(ticket.id));
					})
					.map((additionalRevenue: AdditionalRevenue): number => {
						return additionalRevenue.getAmountForTicket(costCalc);
					})
					.sum()
					.value();

				if (isForSingleTicket) {
					return perTicketAdditionalRevenueAmount;
				}

				// if there is no ticket id or sellable don't compute the priced ticket
				if (!ticket.id || !totalTicketAllotment) {
					return 0;
				}

				return (perTicketAdditionalRevenueAmount * ticket.sellable) / totalTicketAllotment;
			})
			.sum()
			.value();

		const totalAllTiersAdditionalRevenue: number = _.chain(additionalRevenueItems)
			.map((additionalRevenue: AdditionalRevenue): number => {
				return additionalRevenue.getAmountForAllTicketsAndAttendees(costCalc);
			})
			.sum()
			.value();

		const variableCostFlatPerTicket: number = this.fetchVariableCostTotal(
			VariableCostType.FLAT_PER_TICKET_ANY,
			external
		);

		const variableCostFlatPerAttendee: number = this.fetchVariableCostTotal(
			VariableCostType.FLAT_PER_ATTENDEE,
			external
		);

		const variableCostOfNetGross: number =
			this.fetchVariableCostTotal(VariableCostType.PERCENT_OF_NET_GROSS, external) / 100;
		const variableCostOfAdjustedGross: number =
			this.fetchVariableCostTotal(VariableCostType.PERCENT_OF_ADJUSTED_GROSS, external) / 100;
		const variableCostPctOfGross: number =
			this.fetchVariableCostTotal(VariableCostType.PERCENT_OF_GROSS, external) / 100;

		const totalWeightedPerTicketTierEventFees: number = _.sum(
			_.map(pricedTickets, (ticket: Ticket): number => {
				let eventFees: number = 0;
				if (!ticket.id) {
					return eventFees;
				}
				// Get sum of all PER_TICKET_TYPE type event fees that match the ticket(by id) that we're currently calculating breakeven for
				eventFees = ticket.feesPerTicket(this.eventPropsForTicket);

				if (isForSingleTicket) {
					return eventFees;
				}

				if (!totalTicketAllotment) {
					return 0;
				}
				return (eventFees * ticket.sellable) / totalTicketAllotment;
			})
		);

		// For flat presettlement fees we always need to consider all the tickets of the event.
		const sellableTicketsFromEvent: number = this.tickets.reduce(
			(sellableTickets: number, ticket: Ticket): number => {
				if (ticket.ticket_price > 0) {
					return sellableTickets + ticket.sellable;
				}

				return sellableTickets;
			},
			0
		);

		let weightedFlatPreSettlementFeesBeforeTax: number = 0;
		if (sellableTicketsFromEvent) {
			// Presettlement fees before tax divided by sellable tickets to get the weighted average
			weightedFlatPreSettlementFeesBeforeTax =
				this.calculateFlatPreSettlementFeesBeforeTax(costCalc) / sellableTicketsFromEvent;
		}

		/**
		 * adjusted gross ticket price, roughly:
		 *
		 * 	ticket price - facility fees - event fees - flatPreSettlementFeesBeforeTax
		 *
		 * note that adjusted gross event fees are NOT included in this calculation. they are included below
		 */
		const adjustedGrossTicketPrice: number =
			weightedTicketAveragePrice - totalWeightedPerTicketTierEventFees - weightedFlatPreSettlementFeesBeforeTax;

		/**
		 * adjusted gross event fees use the adjusted gross ticket price to
		 * compute total fees
		 */
		const percentAdjustedGrossEventFees: number = _.sum(
			this.event_fees
				.filter((ef: EventFee): boolean => {
					return ef.type === EventFeeType.PERCENT_OF_ADJUSTED_GROSS;
				})
				.map((ef: EventFee): number => {
					return adjustedGrossTicketPrice - adjustedGrossTicketPrice / (1 + ef.amount / 100);
				})
		);

		let weightedFlatPreSettlementFeesAfterTax: number = 0;
		if (sellableTicketsFromEvent) {
			// Presettlement fees after tax divided by sellable tickets to get the weighted average
			weightedFlatPreSettlementFeesAfterTax =
				this.calculateFlatPreSettlementFeesAfterTax(costCalc) / sellableTicketsFromEvent;
		}

		/**
		 * adjusted ticket price, rougly:
		 *
		 * 	adjustedGrossTicketPrice - tax - percentAdjustedGrossEventFees - flatPreSettlementFeesAfterTax
		 */
		const adjustedTicketPrice: number =
			adjustedGrossTicketPrice * this.withoutTaxMultiplier -
			percentAdjustedGrossEventFees -
			weightedFlatPreSettlementFeesAfterTax;

		const ticketVariableCostOfGross: number = weightedTicketAveragePrice * variableCostPctOfGross;
		const ticketVariableCostOfAdjustedGross: number = adjustedGrossTicketPrice * variableCostOfAdjustedGross;
		const ticketVariableCostOfNetGross: number = adjustedTicketPrice * variableCostOfNetGross;

		const sumOfVariableCosts: number = _.sum([
			totalWeightedPerTicketTierVariableCost,
			variableCostFlatPerAttendee,
			ticketVariableCostOfGross,
			ticketVariableCostOfAdjustedGross,
			ticketVariableCostOfNetGross,
			variableCostFlatPerTicket,
		]);
		const promoterProfit: number = this.promoterProfitPercentage(external);
		let variableCostsTowardsPromoterProfit: number = sumOfVariableCosts * promoterProfit;
		if (this.isRental) {
			variableCostsTowardsPromoterProfit = sumOfVariableCosts;
		}

		// we do not display the Renter Payout as an expense externally, so
		// it does not get factored into net profit when we compute external break
		// even. zero it out here, when external
		const rentalPercentage: number = external ? 0 : this.rentalPercentage();
		const variableCostsTowardsRentalGrossBonus: number =
			(adjustedTicketPrice - sumOfVariableCosts) * rentalPercentage;

		const opBonusPerTicket: number = !external && this.isRental ? this.promoter_data.bonus_per_ticket || 0 : 0;

		/**
		 * contributionMarginPerTicket is the denominator in the break even calculation
		 * the computation is roughly:
		 *
		 *  	adjusted ticket price + per ticket additional revenue - variable costs
		 */
		const totalAdditionalRevenue: number =
			totalAllTiersAdditionalRevenue + totalWeightedPerTicketTierAdditionalRevenue;

		// If there is at least one versus net gross deal we will simulate the breakeven calculation for only that versus net gross deal and all the rest of deals that are not versus net gross.
		// This is to determine which field to use for ALL the versus net gross deals. It could be  either guarantee or net gross.
		// We discovered that most events with multiple versus net gross deals have the same exact versus net gross deal repeated multiple times.
		// We are aware that in some edge cases there may be a case where the breakeven is slightly off, when one of the vs net gross deals should not have used the same
		// calc as the highest gnt one but that is such a slim edge case that we agreed that we can take that risk for the following reasons:
		// 1) performance 2) for CS to actually be able to explain this to clients 3) it keeps the code simpler.
		const simulate: boolean = _.some(this.talent_data, (td: TalentData): boolean => {
			return td.isVerseNetGross;
		});

		// If the simulation is not performed, we don't provide any field and the calculation is done as always.
		let fieldToUseForVerseNetGrossDeals: BreakEvenFieldForVersusNetGrossDeal;
		if (simulate) {
			// This function will determine which field to use for verse net gross deals based on the deal with the highest guarantee. It could be either guarantee or net gross.
			fieldToUseForVerseNetGrossDeals = this.getBreakEvenFieldForVerseNetGrossDeals(
				costCalc,
				external,
				totalAdditionalRevenue,
				adjustedTicketPrice,
				variableCostsTowardsPromoterProfit,
				opBonusPerTicket,
				variableCostsTowardsRentalGrossBonus
			);
		}

		// We calculate the break even value based on the field we determined above.
		// If sumulations is not performed, we don't provide any field and the calculation is done as always.
		let breakEvenValue: number | null = this.calculateBreakeven(
			costCalc,
			external,
			this.talent_data,
			// Use the determined calculation (either the guarantee or the net gross calculation) for the rest of the versus net gross deals in the subsequent calculations.
			// For the rest of types the calculation will be performed as before.
			fieldToUseForVerseNetGrossDeals,
			totalAdditionalRevenue,
			adjustedTicketPrice,
			variableCostsTowardsPromoterProfit,
			opBonusPerTicket,
			variableCostsTowardsRentalGrossBonus
		);

		if (this.isRental) {
			breakEvenValue = null;
		}

		const weightedTicketAverage: EMSTicketAggregate<number, string, boolean> = {
			emsPath: `weightedTicketAverage`,
			emsMetadataId: `weightedTicketAverage`,
			costCalc: fetchCostCalc2FromLegacyEMSParams(external, costCalc),
			name: 'Ticket Average',
			price: weightedTicketAveragePrice,
			breakEvenValue,
			totalTicketAllotment,
			ticketsSold,
			netTicketGross,
			ticketRevenue,
			comps,
			kills,
			sellable,
		};
		return weightedTicketAverage;
	}

	// #region Break Even Calculation

	/**
	 * Determines the break-even field for Versus Net Gross deals.
	 *
	 * This function calculates the break-even point for talent deals based on the fields `guarantee` and `netGross`,
	 * and selects the field that yields the highest break-even value.
	 * To determine which field to use, the function considers the Versus Net Gross deal with the highest guarantee.
	 *
	 * @param {CostCalc} costCalc - The cost calculation object.
	 * @param {boolean} external - Flag indicating if the calculations are external.
	 * @param {number} totalAdditionalRevenue - The total additional revenue.
	 * @param {number} adjustedTicketPrice - The adjusted ticket price.
	 * @param {number} variableCostsTowardsPromoterProfit - Variable costs towards promoter profit.
	 * @param {number} opBonusPerTicket - Operational bonus per ticket.
	 * @param {number} variableCostsTowardsRentalGrossBonus - Variable costs towards rental gross bonus.
	 * @returns {BreakEvenFieldForVersusNetGrossDeal} The field (either `guarantee` or `netGross`) that provides the highest break-even value for Versus Net Gross deals.
	 */
	private getBreakEvenFieldForVerseNetGrossDeals(
		costCalc: CostCalc,
		external: boolean,
		totalAdditionalRevenue: number,
		adjustedTicketPrice: number,
		variableCostsTowardsPromoterProfit: number,
		opBonusPerTicket: number,
		variableCostsTowardsRentalGrossBonus: number
	): BreakEvenFieldForVersusNetGrossDeal {
		// Variable to store the field with the highest break-even value.
		let fieldToUseForVerseNetGrossDeals: BreakEvenFieldForVersusNetGrossDeal;

		// We determine which field to use for verse net gross deals based on the deal with the highest guarantee.
		const versusNetGrossDealWithHighestGuarantee: TalentData = _.maxBy(
			_.filter(this.talent_data, (td: TalentData): boolean => {
				return td.isVerseNetGross;
			}),
			'guarantee'
		);

		// Filter out deals to exclude Versus Net Gross deals except the one with the highest guarantee.
		const dealsWithoutVerseNetGrossExceptTheOneWithHighestGNT: TalentData[] = _.filter(
			this.talent_data,
			(td: TalentData): boolean => {
				return td.id === versusNetGrossDealWithHighestGuarantee.id || !td.isVerseNetGross;
			}
		);

		// Variable to track the highest break-even value found.
		let highestBeakeven: number = 0;

		// Run the simulation.
		// Iterate over possible fields `guarantee` and `netGross` to determine the one with the highest break-even value.
		['guarantee', 'netGross'].forEach((field: BreakEvenFieldForVersusNetGrossDeal): void => {
			const breakEvenValue: number | null = this.calculateBreakeven(
				costCalc,
				external,
				dealsWithoutVerseNetGrossExceptTheOneWithHighestGNT,
				field,
				totalAdditionalRevenue,
				adjustedTicketPrice,
				variableCostsTowardsPromoterProfit,
				opBonusPerTicket,
				variableCostsTowardsRentalGrossBonus
			);

			// Update the field and highest break-even value if a higher value is found.
			if (breakEvenValue > highestBeakeven) {
				highestBeakeven = breakEvenValue;
				fieldToUseForVerseNetGrossDeals = field;
			}
		});

		// Return the field that provides the highest break-even value.
		return fieldToUseForVerseNetGrossDeals;
	}

	/**
	 * Calculates the break-even point for talent deals.
	 *
	 * This function computes the break-even point using the provided cost calculations, external flag,
	 * talent deals, and various cost and revenue parameters.
	 *
	 * @param {CostCalc} costCalc - The cost calculation object.
	 * @param {boolean} external - Flag indicating if the calculations are external.
	 * @param {TalentData[]} talentDeals - An array of talent deals.
	 * @param {BreakEvenFieldForVersusNetGrossDeal} fieldToUseForVerseNetGrossDeals - The field (either `guarantee` or `netGross`). Only relevant for Versus Net Gross deals (The field will be ignored id it is undefined).
	 * @param {number} totalAdditionalRevenue - The total additional revenue.
	 * @param {number} adjustedTicketPrice - The adjusted ticket price.
	 * @param {number} variableCostsTowardsPromoterProfit - Variable costs towards promoter profit.
	 * @param {number} opBonusPerTicket - Operational bonus per ticket.
	 * @param {number} variableCostsTowardsRentalGrossBonus - Variable costs towards rental gross bonus.
	 * @returns {number} The calculated break-even point.
	 */
	private calculateBreakeven(
		costCalc: CostCalc,
		external: boolean,
		talentDeals: TalentData[],
		fieldToUseForVerseNetGrossDeals: BreakEvenFieldForVersusNetGrossDeal,
		totalAdditionalRevenue: number,
		adjustedTicketPrice: number,
		variableCostsTowardsPromoterProfit: number,
		opBonusPerTicket: number,
		variableCostsTowardsRentalGrossBonus: number
	): number {
		// adjustedFixed cost is the numerator in the break even calculation
		let adjustedFixedCosts: number = this.totalAdjustedFixedCosts(
			costCalc,
			external,
			talentDeals,
			fieldToUseForVerseNetGrossDeals
		);

		// If it is a rental and not external, subtract the total additional revenue from the adjusted fixed costs
		if (this.isRental && !external) {
			adjustedFixedCosts -= totalAdditionalRevenue;
		}

		// Calculate the ticket gross bonus
		const ticketGrossBonus: number =
			adjustedTicketPrice * this.grossBonusPercentage(external, fieldToUseForVerseNetGrossDeals, talentDeals);

		// Calculate the variable costs
		const variableCost: number =
			ticketGrossBonus +
			variableCostsTowardsPromoterProfit +
			opBonusPerTicket +
			variableCostsTowardsRentalGrossBonus;

		// contributionMarginPerTicket cost is the denominator in the break even calculation
		const contributionMarginPerTicket: number = adjustedTicketPrice + totalAdditionalRevenue - variableCost;

		// Calculate and return the break-even point
		return computeBreakEven(adjustedFixedCosts, contributionMarginPerTicket);
	}

	private totalAdjustedFixedCosts(
		costCalc: CostCalc,
		external: boolean,
		talentDeals: TalentData[],
		fieldToUseForVerseNetGrossDeals: BreakEvenFieldForVersusNetGrossDeal
	): number {
		// costs
		const filteredTalentDeals: TalentData[] = external ? this.includedTalentDeals(talentDeals) : talentDeals;
		const guarantee: number = this.totalGuarantees(filteredTalentDeals, fieldToUseForVerseNetGrossDeals);
		const additionalSupport: number = this.additionalSupport(costCalc, false);
		const fixedCosts: number = this.fixedCosts(costCalc, external);
		// revenue
		const flatTicketRevenue: number = this.flatTicketsNetGross(costCalc);
		const flatAdditionalRevenue: number = this.flatAdditionalRevenue(costCalc, external);
		const flatRevenue: number = flatTicketRevenue + flatAdditionalRevenue;
		let totalFixedCosts: number = guarantee + fixedCosts + additionalSupport - flatRevenue;

		if (external) {
			// multiply adjusted fixed costs * promoter profit percent
			const splitPointAdjustments: number = this.totalAdjustmentsTowardSplitPoint(talentDeals);
			const promoterProfitTowardsBreakEven: number =
				((totalFixedCosts + splitPointAdjustments) * (this.promoter_profit || 0)) / 100;
			totalFixedCosts += promoterProfitTowardsBreakEven;
		}

		// get all non-external adjustments to be added to the fixed costs in split point
		const nonExternalAdjustmentTotal: number = this.totalBreakEvenAdjustments(filteredTalentDeals);

		// add adjustments after taking promoter profit
		return totalFixedCosts + nonExternalAdjustmentTotal;
	}

	private grossBonusPercentage(
		external: boolean,
		fieldToUseForVerseNetGrossDeals: BreakEvenFieldForVersusNetGrossDeal,
		talentDeals: TalentData[]
	): number {
		return _.chain(talentDeals)
			.filter((td: TalentData): boolean => {
				if (external) {
					return td.include_in_deal;
				}
				return true;
			})
			.map((td: TalentData): Bonus[] => {
				let bonuses: Bonus[] = td.bonuses;
				if (td.deal_type === DealTypes.Verse && fieldToUseForVerseNetGrossDeals === 'guarantee') {
					bonuses = bonuses.filter((bonus: Bonus): boolean => {
						// If it is a versus net gross deal and we are calculating based on the guarantee
						// we want to exclude the bonus from the calculation.
						return bonus.type !== BonusTypes.NetGross;
					});
				}
				return bonuses;
			})
			.flatten()
			.filter((bonus: Bonus): boolean => {
				return bonus.type === BonusTypes.NetGross;
			})
			.map((bonus: Bonus): number => {
				return bonus.amount / 100;
			})
			.sum()
			.value();
	}

	/**
	 * Sums together all of the guarantees for all talent deals provided.
	 *
	 * This function is used for the break-even calculation.
	 *
	 * @param {TalentData[]} talentDeals - An array of talent deals.
	 * @param {BreakEvenFieldForVersusNetGrossDeal} fieldToUseForVerseNetGrossDeals - Only if the deal is of type Versus Net Gross.
	 *  If this value is 'netGross', the guarantee will be excluded from the total sum.
	 * @returns {number} The total sum of all guarantees for all talent deals provided.
	 */
	private totalGuarantees(
		talentDeals: TalentData[],
		fieldToUseForVerseNetGrossDeals: BreakEvenFieldForVersusNetGrossDeal
	): number {
		let total_guarantee: number = 0;
		// Iterate over each talent deal and sum the guarantees.
		talentDeals.forEach((terms: TalentData): void => {
			// If the deal is Versus Net Gross and the field to use is 'netGross', skip this deal.
			if (terms.isVerseNetGross && fieldToUseForVerseNetGrossDeals === 'netGross') {
				return;
			}

			// Convert the guarantee and add it to the total_guarantee, ensuring no NaN value is added.
			total_guarantee += deprecatedDoNotUsePreventNaN(
				terms.convertedGuarantee(CostCalc.Actual, this.eventPropsForGuarantees)
			);
		});

		// Return the total sum of all guarantees
		return total_guarantee;
	}

	/**
	 * Filters the talent deals to exclude those where `include_in_deal` is set to false.
	 *
	 * This function returns an array of talent deals that should be included based on the `include_in_deal` flag.
	 *
	 * @param {TalentData[]} talentDeals - An array of talent deals.
	 * @returns {TalentData[]} An array of talent deals where `include_in_deal` is true.
	 */
	private includedTalentDeals(talentDeals: TalentData[]): TalentData[] {
		return talentDeals.filter((talentData: TalentData): boolean => {
			// if configured, exclude deals where `include_in_deal` is set to false
			return talentData.include_in_deal;
		});
	}

	// Currently only used for breakeven calculation to provide a sum of only non-variable additional revenue per
	private flatAdditionalRevenue(costCalc: CostCalc, external: boolean): number {
		let additionalRevenue: number = 0;
		let additional_revenue_items: AdditionalRevenue[] = [...this.additional_revenue];
		// when external, only compute revenue items included in net gross
		if (external) {
			additional_revenue_items = additional_revenue_items.filter((item: AdditionalRevenue): boolean => {
				return item.include_in_net_gross;
			});
		}
		// filter only flat additional revenue items
		additional_revenue_items = additional_revenue_items.filter((item: AdditionalRevenue): boolean => {
			switch (costCalc) {
				case CostCalc.Estimated:
				case CostCalc.Potential:
				case CostCalc.Budgeted:
					return item.est_type === AdditionalRevenueType.FLAT;
				case CostCalc.Reported:
				case CostCalc.Actual:
					return item.actual_type === AdditionalRevenueType.FLAT;
				default:
					return false;
			}
		});
		// sum the total of each flat additional revenue item
		additional_revenue_items.forEach((item: AdditionalRevenue): void => {
			additionalRevenue += item.getTotal(costCalc, external, this);
		});
		return additionalRevenue;
	}

	/**
	 * Get the total $ adjustments from all the talent deals provided,
	 *  - Sum all non-external adjustments for all talent deals in the event where `include_in_deal` is true.
	 *
	 * @param {TalentData[]} talentDeals - An array of talent deals.
	 * @returns {number} The adjustment total for break even, which will be all non-external adjustments.
	 */
	private totalBreakEvenAdjustments(talentDeals: TalentData[]): number {
		return talentDeals.reduce((adjustmentTotal: number, terms: TalentData): number => {
			// only add non-external adjustments to break even calculation
			return adjustmentTotal + terms.totalAdjustments(false);
		}, 0);
	}

	private fetchVariableCostsByType(type: VariableCostType, external: boolean): VariableCost[] {
		return this.variable_costs
			.filter((vc: VariableCost): boolean => {
				if (external) {
					return vc.reported;
				}
				return true;
			})
			.filter((vc: VariableCost): boolean => {
				return vc.terms === type;
			});
	}

	private fetchVariableCostTotalForTicket(ticket: Ticket, external: boolean): number {
		if (!ticket.id) {
			return 0;
		}
		return _.sum(
			this.fetchVariableCostsByType(VariableCostType.FLAT_PER_TICKET_TYPE, external)
				.filter((vc: VariableCost): boolean => {
					return vc.ticket_id === ticket.id;
				})
				.map((vc: VariableCost): number => {
					return vc.amount;
				})
		);
	}

	/**
	 * compute the break even for a given ticket. this represents the minimum amount of
	 * tickets that need to be sold for this tier to make the show profitable
	 *
	 * note that this number does not take into account other ticket tiers on the event
	 * in essence, this number considers how many tickets have to be sold to become profitable
	 * if this was the only ticket tier on the entire event
	 *
	 * @param ticket the ticket on the event to compute break even for
	 * @param costCalc the cost calc
	 * @param external true if external, false if internal
	 * @returns the break even number for the ticket in question
	 */
	public getBreakEvenForTicket(ticket: Ticket, costCalc: CostCalc, external: boolean): number {
		return this.computeWeightedTicketAverage(ticket, costCalc, external).breakEvenValue;
	}

	private fetchVariableCostTotal(type: VariableCostType, external: boolean): number {
		return _.sum(
			this.fetchVariableCostsByType(type, external).map((vc: VariableCost): number => {
				return vc.amount;
			})
		);
	}
	// #endregion Break Even Calculation

	public get platform_tickets(): Ticket[] {
		const returnList: Ticket[] = [];
		this.tickets.forEach((t: Ticket): void => {
			if (t.platformIdString) {
				returnList.push(t);
			}
		});
		return returnList;
	}

	public get non_platform_tickets(): Ticket[] {
		const returnList: Ticket[] = [];

		this.tickets.forEach((t: Ticket): void => {
			if (!t.platformIdString) {
				returnList.push(t);
			}
		});
		return returnList;
	}

	// only used on the settlement page
	public get totalDiscounts(): number {
		let total: number = 0;
		for (const ticket of this.tickets) {
			total += deprecatedDoNotUsePreventNaN(ticket.platformDiscounts);
		}
		return deprecatedDoNotUsePreventNaN(total);
	}

	public ticketsSold(costCalc: CostCalc): number {
		return _.sumBy(this.tickets, (ticket: Ticket): number => {
			return ticket.ticketsSold(costCalc);
		});
	}

	////////////////////////////////////////////////////////////////////////////
	// payouts
	////////////////////////////////////////////////////////////////////////////
	public get eventPropsForGuarantees(): EventPropsForTalentGuarantee {
		return {
			event_status: this.confirmed,
			convert_to_usd: this.convert_to_usd || false,
			use_custom_exchange_rates: this.use_custom_exchange_rates || false,
			offer_exchange_rate: this.offer_exchange_rate || 0,
			settled_exchange_rate: this.settled_exchange_rate || 0,
			usd_exchange_rate: this.usd_exchange_rate || 1,
			dateRange: this.sortedDates(),
			artistPayCurrency: this.convert_to_usd ? Currency.USD : this.currency,
			eventOwnedByAgency: this.is_event_owned_by_agency,
		};
	}

	public get eventPropsForTicket(): EventPropsForTicket {
		return {
			facilityFee: +this.facility_fee,
			withoutTaxMultiplier: this.withoutTaxMultiplier,
			allEventFees: this.event_fees,
		};
	}

	public eventPropsForPartnerDeal(costCalc: CostCalc, external: boolean): EventPropsForPartnerDeal {
		return {
			netProfitForCoProCalculations: this.netProfitForCoProCalculations(costCalc, external),
			ticketsSold: this.ticketsSold(costCalc),
		};
	}

	public sortedDates(): moment.Moment[] {
		return sortedDateRangeForEvents([this]);
	}

	public formattedDateRange(): [string, string] {
		const sorted: moment.Moment[] = this.sortedDates();
		if (sorted.length) {
			return formatDateRange(_.first(sorted), _.last(sorted));
		}
		return ['TBD', 'TBD'];
	}

	public calculatecontributingArtistPayoutTowardAgentPayout(
		costCalc: CostCalc,
		external: boolean,
		talentData: TalentData[],
		optionsArg: CalculateTotalArtistPayoutOptionsArgs = {}
	): number {
		const defaultOptions: CalculateTotalArtistPayoutOptions = {
			adjusted: false,
			forSplitPoint: false,
		};
		const options: CalculateTotalArtistPayoutOptions = _.defaults({}, optionsArg, defaultOptions);
		const ticketsSold: number = this.ticketsSold(costCalc);
		const totalSellable: number = this.ticketsSold(CostCalc.Potential);
		const splitPoint: number = this.splitPoint(costCalc, {
			forSplitPoint: options.forSplitPoint,
		});

		const netGross: number = this.netGross(costCalc, external);

		const payouts: number = _.sumBy(talentData, (talent: TalentData): number => {
			if (optionsArg.computeForCoPro && talent.copro_artist_payout_hidden) {
				return 0;
			}

			return deprecatedDoNotUsePreventNaN(
				talent.calculateArtistPayout(
					costCalc,
					external,
					this.eventPropsForGuarantees,
					netGross,
					splitPoint,
					ticketsSold,
					totalSellable,
					this.ticketCommissionsTotal(costCalc, external),
					this.calculateNagbor(costCalc, external),
					options
				)
			);
		});

		return deprecatedDoNotUsePreventNaN(payouts);
	}

	/*
		Calculates the total adjusted payout for the array of talent passed in
	*/
	public calculateTotalAdjustedArtistPayouts(
		costCalc: CostCalc,
		external: boolean,
		filteredTalentData: TalentData[] = this.talent_data,
		computeForCoPro: boolean
	): number {
		return this.calculatecontributingArtistPayoutTowardAgentPayout(costCalc, external, filteredTalentData, {
			forSplitPoint: false,
			adjusted: true,
			computeForCoPro,
		});
	}

	////////////////////////////////////////////////////////////////////////////
	// attendance
	////////////////////////////////////////////////////////////////////////////
	public attendance(costCalc: CostCalc): number {
		switch (costCalc) {
			case CostCalc.Reported:
			case CostCalc.Actual:
				return this.actual_attendance;
			case CostCalc.Budgeted:
			case CostCalc.Potential:
				return this.estimated_attendance;
			case CostCalc.Estimated:
				return this.estimated_attendance;
		}
	}

	////////////////////////////////////////////////////////////////////////////
	// RENTAL methods
	////////////////////////////////////////////////////////////////////////////
	// a postive number paid by the renter, considered as revenue
	public rentalPayment(costCalc: CostCalc, external: boolean): number {
		if (!this.outside_promoter) {
			return 0;
		}
		return Math.min(this.rentalPayoutAdjusted(costCalc, external), 0) * -1;
	}

	// a negative number paid to the renter, considered as a cost
	public rentalPayout(costCalc: CostCalc, external: boolean): number {
		if (!this.outside_promoter) {
			return 0;
		}
		return Math.max(this.rentalPayoutAdjusted(costCalc, external), 0);
	}

	public rentalNetBoxOffice(costCalc: CostCalc, external: boolean): number {
		let nbo: number = 0;

		if (!this.outside_promoter) {
			return nbo;
		}

		switch (costCalc) {
			case CostCalc.Estimated:
				nbo = this.netGross(CostCalc.Estimated, external) - this.resolvedRoomFee(CostCalc.Estimated);
				break;
			case CostCalc.Budgeted:
			case CostCalc.Potential:
				nbo = this.netGross(CostCalc.Potential, external) - this.resolvedRoomFee(CostCalc.Budgeted);
				break;
			case CostCalc.Reported:
			case CostCalc.Actual:
				nbo = this.netGross(CostCalc.Actual, external) - this.resolvedRoomFee(CostCalc.Reported);
				break;
			default:
				throw new Error(`Unrecognized CostCalc ${costCalc}.`);
		}
		return nbo;
	}

	public rentalSplit(costCalc: CostCalc, external: boolean): number {
		if (!this.outside_promoter) {
			return 0;
		}
		return this.rentalNetBoxOffice(costCalc, external) * this.rentalPercentage();
	}

	private rentalPayoutAdjusted(costCalc: CostCalc, external: boolean): number {
		if (!this.outside_promoter) {
			return 0;
		}
		const roomFee: number = this.resolvedRoomFee(costCalc);
		const bonus: number = this.rentalBonus(costCalc);

		if (!this.promoter_data) {
			return 0;
		}

		let adjustments: number;
		if (this.promoter_data.in_house_tickets) {
			adjustments = this.promoter_data ? this.promoter_data.totalRentalAdjustments : 0;
			return this.rentalSplit(costCalc, external) + adjustments + bonus;
		}
		adjustments = this.promoter_data ? this.promoter_data.totalRentalAdjustments : 0;
		return adjustments - roomFee;
	}

	public rentalPercentage(): number {
		if (!this.promoter_data) {
			return 0;
		}
		return Number(this.promoter_data.promoter_percentage || 0) / 100;
	}

	// only used by rental-summary outside of this file
	// returns the cost of the room booked at the venue via manual room fee set by promoter or by adding up room costs
	public resolvedRoomFee(costCalc: CostCalc): number {
		// This method does not care about internal/external. Room fee is always
		// computed from the external perspective. If we are passed a CostCalc of
		// actual, which only exists from an internal perspective, switch it over
		// to reported so that we pass the right costCalc when we compute fixed and variable costs.
		if (costCalc === CostCalc.Actual) {
			costCalc = CostCalc.Reported;
		}
		let room_fee: number = 0;
		// SHORT CIRCUIT FOR IF NOT A RENTAL SHOW
		if (!this.promoter_data) {
			return room_fee;
		}
		// SHORT CIRCUIT FOR MANUAL ROOM FEES
		if (this.promoter_data.manual_room_fee) {
			if (this.promoter_data.room_fee == null) {
				return 0;
			}
			return this.promoter_data.room_fee;
		}
		const variableCosts: number = this.variableCostsTotal(costCalc, true, false);
		const fixedCosts: number = this.fixedCosts(costCalc, true);
		room_fee += fixedCosts;
		room_fee += variableCosts;
		return room_fee;
	}

	public resolvedRentalAmount(costCalc: CostCalc): number {
		if (this.promoter_data && this.promoter_data.in_house_tickets) {
			return 0;
		}
		return this.resolvedRoomFee(costCalc);
	}

	public resolvedRentalAmountWithAdjustments(costCalc: CostCalc): number {
		if (this.promoter_data && this.promoter_data.in_house_tickets) {
			return 0;
		}
		const adjustmentsTotal: number = _.reduce(
			this.promoter_data?.payout_adjustments,
			(memo: number, adjustment: PayoutAdjustment): number => {
				return memo + adjustment.amount;
			},
			0
		);
		// negative adjustments === incoming rental money, so we subtract instead of add here to reflect that
		return this.resolvedRoomFee(costCalc) - adjustmentsTotal;
	}

	/**
	 * Determines if the Renter of the event owes the Venue money
	 * or if the Venue owes the Renter money. To check this we don't
	 * only check the renter payment amount but also the final payout
	 * balance considering split point, adjustments, bonus, deposits, etc.
	 * If both are positive it means the Renter needs to pay the Venue.
	 * @param costCalc The cost calc to be used
	 * @returns True if Renter owes Venue, false if Venue owes Renter
	 */
	public rentalDoesRenterOwe(costCalc: CostCalc, external: boolean): boolean {
		const renterPaymentAmount: number = this.rentalPayment(costCalc, external);
		const finalPayoutAmount: number = this.rentalPayoutOrOwesBalance(costCalc, external);
		return renterPaymentAmount > 0 && finalPayoutAmount > 0;
	}

	/**
	 * This is the total rental payment amount, considering
	 * a rental might be owe or owed, for which case we might
	 * use either the payment (positive) or a payout (negative) amount,
	 * which underlying value is the same.
	 */
	public rentalPayAmount(costCalc: CostCalc, external: boolean): number {
		const rentalPayment: number = this.rentalPayment(costCalc, external);
		if (rentalPayment > 0) {
			return rentalPayment;
		}
		return this.rentalPayout(costCalc, external);
	}

	public rentalDepositAmount(costCalc: CostCalc, external: boolean): number {
		if (!this.isRental) {
			return 0;
		}
		const payAmount: number = this.rentalPayAmount(costCalc, external);
		return this.promoter_data.deposits.reduce((totalDepositAmount: number, deposit: Deposit): number => {
			return totalDepositAmount + depositTotal(deposit, payAmount, this.eventPropsForGuarantees, costCalc);
		}, 0);
	}

	/*
	 * When there is no currency change between the event and the rental payout
	 * use the original deposit amount, otherwise convert it based on the exchange rate
	 */
	public rentalDepositAmountInEventCurrency(costCalc: CostCalc, external: boolean): number {
		const depositAmount: number = this.rentalDepositAmount(costCalc, external);
		let depositAmountInEventCurrency: number = depositAmount;
		const rentalPayCurrency: Currency = !!this.convert_to_usd ? Currency.USD : this.currency;
		if (this.currency !== rentalPayCurrency) {
			depositAmountInEventCurrency = depositAmount / this.exchangeRate(costCalc);
		}
		return depositAmountInEventCurrency;
	}

	/**
	 * This is the final rental payout amount, either a rental payout (Venue owes Renter)
	 * or a promoter payment (Renter owes Venue). In general this is a positive number
	 * but under some circumstances it can be negative.
	 * @param costCalc The cost calc to be used
	 * @returns the final rental payout amount
	 */
	private rentalPayoutOrOwesBalance(costCalc: CostCalc, external: boolean): number {
		const payAmount: number = this.rentalPayAmount(costCalc, external);
		const depositAmountInEventCurrency: number = this.rentalDepositAmountInEventCurrency(costCalc, external);
		const depositMultiplier: number = this.rentalPayment(costCalc, external) > 0 ? -1 : 1;
		return payAmount + depositAmountInEventCurrency * depositMultiplier;
	}

	/**
	 * The rental payout amount to be paid, always expressed as a positive number
	 * @param costCalc  The cost calc to be used
	 * @returns The final rental payout amount as a positive value
	 */
	public rentalPayoutOrOwes(costCalc: CostCalc, external: boolean): number {
		const payoutOrOwes: number = this.rentalPayoutOrOwesBalance(costCalc, external);
		if (payoutOrOwes >= 0) {
			return payoutOrOwes;
		}
		// in some cases the payoutOrOwes can be negative but we need to show this as a positive number
		// so we return the absolute value of the payoutOrOwes and add context on who owes who
		return payoutOrOwes * -1;
	}

	////////////////////////////////////////////////////////////////////////////
	// helpers
	////////////////////////////////////////////////////////////////////////////
	/**
	 * Returns a filtered version of event talent deals used for calculating expenses.
	 *
	 * - 0 guarantee for verse deals (if split point)
	 * - remove any net and gross bonuses. (if split point)
	 * - only inlcude headliner or support with "included in deal"
	 *
	 * @param {WithForSplitPointOptionsArgs} [optionsArg={}] - Options for filtering talent deals.
	 * @returns {TalentData[]} A filtered array of talent deals used for external calculations.
	 */
	public getTalentForExternalCalculationsFromEventTalentDeals(
		optionsArg: WithForSplitPointOptionsArgs = {}
	): TalentData[] {
		return this.getTalentForExternalCalculationsFromProvidedTalentDeals(this.talent_data, optionsArg);
	}

	private getTalentForExternalCalculationsFromProvidedTalentDeals(
		talentDeals: TalentData[],
		optionsArg: WithForSplitPointOptionsArgs = {}
	): TalentData[] {
		const defaultOptions: WithForSplitPointOptions = {
			forSplitPoint: false,
		};
		const options: WithForSplitPointOptions = _.defaults({}, optionsArg, defaultOptions);
		const stripBonusTypes: BonusTypes[] = options.forSplitPoint ? [BonusTypes.Net, BonusTypes.NetGross] : [];
		return _.cloneDeep(talentDeals)
			.filter((deal: TalentData): boolean => {
				return deal.contributesToSplitPoint;
			})
			.map((deal: TalentData): TalentData => {
				const filteredDeal: TalentData = deal;
				if (filteredDeal.deal_type === DealTypes.Verse && options.forSplitPoint) {
					filteredDeal.guarantee = 0;
				}
				filteredDeal.bonuses = filteredDeal.bonuses.filter((bonus: Bonus): boolean => {
					return !stripBonusTypes.includes(<BonusTypes>bonus.type);
				});
				return filteredDeal;
			});
	}

	public get firstHeadliner(): TalentData | null {
		if (!this.talent_data) {
			return null;
		}
		const headlinerTerms: TalentData[] = this.talent_data.filter((terms: TalentData): boolean => {
			return terms.headliner;
		});
		if (!headlinerTerms.length) {
			return null;
		}
		return headlinerTerms[0];
	}

	public additionalSupport(costCalc: CostCalc, computeForCoPro: boolean): number {
		if (computeForCoPro && this.copro_additional_support_hidden) {
			return 0;
		}

		if (computeForCoPro && this.copro_overridden_additional_support) {
			return this.copro_overridden_additional_support;
		}

		switch (costCalc) {
			case CostCalc.Budgeted:
			case CostCalc.Potential:
			case CostCalc.Estimated:
				return this.budgeted_additional_support;
			case CostCalc.Reported:
			case CostCalc.Actual:
				return this.actual_additional_support;
			default:
				throw new Error(`Unrecognized CostCalc ${costCalc}.`);
		}
	}

	/**
	 * Represents the sum of platformRebates for all tickets on the event. Note that these values come from the
	 * linked ticketing platform, and will be 0 if no platform is linked. Additionally, these numbers are treated as
	 * actuals and reported numbers, and we provide the user no means to enter budgets, estimates or potentials for
	 * the platform rebate, thus it will be 0 for any CostCalcs besides actual and reported.
	 * @param costCalc
	 */
	public ticketingPlatformRebate(costCalc: CostCalc): number {
		let rebate: number = 0;
		for (const ticket of this.tickets) {
			rebate += ticket.platformRebate(costCalc);
		}
		return rebate;
	}

	public get isConfirmed(): boolean {
		return this.confirmed > EventStatus.N_A;
	}

	public get isSettled(): boolean {
		return this.confirmed > EventStatus.Settlement;
	}

	public get has_platform(): boolean {
		return !!this.platformIdString;
	}

	public get hasTicketsLinked(): boolean {
		return !!this.platformIdString && !!this.platform_type;
	}

	public get hasNetDeal(): boolean {
		return (
			this.talent_data.filter((td: TalentData): boolean => {
				if (!td.bonuses.length) {
					return false;
				}
				return td.bonuses[0].type === BonusTypes.Net;
			}).length > 0
		);
	}

	/**
	 * returns true if the event has a split point, false other wise
	 *
	 * as of writing this, what i can surmise from the code is that splitPoint is only rendered
	 * in budget summarys when there is a NET deal on the event. if possible, this code should be updated
	 * with more cases where there exists a split
	 *
	 * @returns boolean
	 */
	public get hasSplitPoint(): boolean {
		return this.hasNetDeal;
	}

	/*
		returns array of talent ids that are conflicting
		and will be reset if saved. Passing "true" for the second
		parameter will warn only and will not change data.
	*/
	public dealConflicts(dealTerms: TalentData, dryRun: boolean = true): number[] {
		const dealSideEffects: number[] = [];
		const thisBonus: Bonus | false = (dealTerms.bonuses && dealTerms.bonuses[0]) || false;

		if (!thisBonus) return dealSideEffects;

		switch (thisBonus.type) {
			// if we are switching to a gross deal, we must reset all net deals
			case BonusTypes.NetGross:
				this.talent_data.forEach((deal: TalentData): void => {
					if (deal.bonuses.length && deal.bonuses[0].type === BonusTypes.Net) {
						if (!dryRun) deal.resetDeal();
						dealSideEffects.push(deal.id);
					}
				});
				break;
			// if we are swiNetGrossg to a net deal, we must reset all gross deals
			case BonusTypes.Net:
				this.talent_data.forEach((deal: TalentData): void => {
					if (deal.bonuses.length && deal.bonuses[0].type === BonusTypes.NetGross) {
						if (!dryRun) deal.resetDeal();
						dealSideEffects.push(deal.id);
					}
				});
				break;
			default:
				break;
		}

		// we can only have net/gross deals in either a verse or a plus/door show..but not both
		switch (dealTerms.deal_type) {
			case DealTypes.Plus:
			case DealTypes.DoorDeal:
				if (thisBonus.type !== BonusTypes.Net && thisBonus.type !== BonusTypes.NetGross) {
					break;
				}
				this.talent_data.forEach((deal: TalentData): void => {
					if (
						deal.deal_type === DealTypes.Verse &&
						(deal.bonuses[0].type === BonusTypes.Net || deal.bonuses[0].type === BonusTypes.NetGross)
					) {
						if (!dryRun) deal.resetDeal();
						dealSideEffects.push(deal.id);
					}
				});
				break;
			case DealTypes.Verse:
				if (thisBonus.type !== BonusTypes.Net && thisBonus.type !== BonusTypes.NetGross) {
					break;
				}
				this.talent_data.forEach((deal: TalentData): void => {
					if (
						deal.deal_type === DealTypes.DoorDeal ||
						(deal.deal_type === DealTypes.Plus &&
							(deal.bonuses[0].type === BonusTypes.Net || deal.bonuses[0].type === BonusTypes.NetGross))
					) {
						if (!dryRun) deal.resetDeal();
						dealSideEffects.push(deal.id);
					}
				});
				break;
			default:
				break;
		}

		// return unique ids
		return _.uniq(dealSideEffects);
	}

	/**
	 * Determines if an event is a rental event or not.
	 * Besides the rental deal information been held in promoter_data
	 * property we also use this object to store an event promoter
	 * when having a talent event as an agent, the outside_promoter
	 * flag indicates along with the promoter data that the event is a rental.
	 */
	public get isRental(): boolean {
		return !!this.promoter_data && this.outside_promoter;
	}

	/**
	 * Determines if a rental event is ticketed outside house
	 * or not. This is determined by the promoter_data.in_house_tickets
	 * flag.
	 */
	public get isNonTicketedRental(): boolean {
		if (!this.isRental) {
			return false;
		}
		return !this.promoter_data.in_house_tickets;
	}

	public ageLimitExplainerText(): string {
		if (!this.has_age_limit) {
			return '';
		}
		if (this.is_all_ages) {
			return 'All Ages';
		}
		if (this.age_limit === null) {
			return '';
		}
		return `${this.age_limit}+`;
	}

	public get hasLinkedDeal(): boolean {
		return !!this.agency_event_link && this.agency_event_link?.deal_hashes?.length > 0;
	}

	public totalCapacityForEvent(): number {
		if (!this.datez || !this.stages) {
			return 0;
		}

		const uniqueStageDates: Set<number> = new Set(
			this.datez.map((date: Datez): number => {
				return date.stage_id;
			})
		);

		return this.stages.reduce((totalCapacity: number, stage: Stage): number => {
			if (uniqueStageDates.has(stage.id)) {
				totalCapacity += stage.capacity;
			}

			return totalCapacity;
		}, 0);
	}

	/**
	 * Calculates the Net Adjusted Gross Box Office Receipts (NAGBOR) for an event, taking into account Broadway tour membership and ticket commission overrides.
	 * NAGBOR is defined as the netGross minus the sum of all ticket commissions.
	 * If the event is not a member of a Broadway tour, the NAGBOR is 0.
	 * If the event is a member of a Broadway tour but but the cost calc is not InternalActual or ExternalReported, ticket commissions don't apply and the NAGBOR is the netGross.
	 * If the event is a member of a Broadway tour and the cost calc is InternalActual or ExternalReported, ticket commissions apply and the NAGBOR is the netGross minus the sum of all ticket commissions.
	 * IMPORTANT: If we calculate NAGBOR based on ticket commissions, the net gross we use is the one defined in the ticket commission and NOT the one defined in the event.
	 * @function calculateNagbor
	 * @param {CostCalc} costCalc - The cost calculation type (e.g., CostCalc.Actual, CostCalc.Reported).
	 * @param {boolean} external - Flag indicating if the calculation is for an external document.
	 * @returns {number} - The calculated NAGBOR for the event.
	 *
	 * @example
	 * // Calculate the NAGBOR for the event given a cost calculation type and external flag
	 * calculateNagbor(CostCalc.Actual, true);
	 */
	public calculateNagbor(costCalc: CostCalc, external: boolean): number {
		// If the event is not a member of a Broadway tour, the NAGBOR is 0.
		if (!this.isEventMemberOfBroadwayTour) {
			return 0;
		}

		// If the event is a member of a Broadway tour but but the cost calc is not InternalActual or ExternalReported,
		// ticket commissions don't apply and the NAGBOR is the netGross.
		if (!this.shouldApplyBroadwayTicketCommissionsOverrides(costCalc, external)) {
			return this.netGross(costCalc, external);
		}

		// If the event is a member of a Broadway tour and the cost calc is InternalActual or ExternalReported,
		// ticket commissions apply and the NAGBOR is the netGross minus the sum of all ticket commissions.
		return _.sumBy(this.ticket_commissions, (ticketCommission: TicketCommission): number => {
			return ticketCommission.calculateNagbor(
				this.withoutTaxMultiplier,
				this.calculateTicketCommissionByNetGross
			);
		});
	}

	private calculateRoyalty(costCalc: CostCalc, external: boolean): number {
		const ticketCommissionsNagbor: number = this.calculateNagbor(costCalc, external);
		return _.sumBy(this.talent_data, (talentData: TalentData): number => {
			return talentData.calculateRoyalty(ticketCommissionsNagbor);
		});
	}

	/**
	 * Calculates the flat pre-settlement fees before tax.
	 *
	 * The method calculates fees based on the gross amount before any taxes are applied.
	 *
	 * @param {CostCalc} costCalc - The cost calculation object, which contains necessary data for fee calculation.
	 * @returns {number} The calculated pre-settlement fees before tax.
	 */
	public calculateFlatPreSettlementFeesBeforeTax(costCalc: CostCalc): number {
		return this.calculateFlatPreSettlementFees(EventFeeType.FLAT_OUT_OF_GROSS, costCalc);
	}

	/**
	 * Calculates the flat pre-settlement fees before tax with an adjustment for tax.
	 *
	 * The method first calculates the flat pre-settlement fees before tax. If the transaction
	 * is part of a platform event, it simply returns the calculated fees. If it is not a platform event,
	 * it adjusts these fees by subtracting the tax portion.
	 *
	 * @param {CostCalc} costCalc - The cost calculation object, which contains necessary data for fee calculation.
	 * @returns {number} The calculated pre-settlement fees before tax, possibly adjusted for tax if it is not a platform event.
	 */
	public calculateFlatPreSettlementFeesBeforeTaxWithTaxAdjustment(costCalc: CostCalc): number {
		const presettlementFeesBeforeTax: number = this.calculateFlatPreSettlementFeesBeforeTax(costCalc);
		if (this.platformIdString) {
			return presettlementFeesBeforeTax;
		}

		// If it is not a platform event we need to substract the tax portion of the presettlement fees because it is already included in the net gross calculation.
		return presettlementFeesBeforeTax * this.withoutTaxMultiplier;
	}

	/**
	 * Calculates the flat pre-settlement fees after tax.
	 *
	 * The method calculates fees based on the adjusted gross amount after taxes have been applied.
	 *
	 * @param {CostCalc} costCalc - The cost calculation object, which contains necessary data for fee calculation.
	 * @returns {number} The calculated pre-settlement fees after tax.
	 */
	public calculateFlatPreSettlementFeesAfterTax(costCalc: CostCalc): number {
		return this.calculateFlatPreSettlementFees(EventFeeType.FLAT_OUT_OF_ADJUSTED_GROSS, costCalc);
	}

	/**
	 * Helper function to calculate the flat pre-settlement fees based on the specified fee type.
	 *
	 * The method iterates over the event fees and sums up the fees that match the specified fee type.
	 *
	 * @param {EventFeeType} feeType - The type of the event fee to calculate.
	 * @param {CostCalc} costCalc - The cost calculation object, which contains necessary data for fee calculation.
	 * @returns {number} The total calculated pre-settlement fees that match the specified fee type.
	 */
	private calculateFlatPreSettlementFees(feeType: EventFeeType, costCalc: CostCalc): number {
		return this.event_fees.reduce((total: number, eventFee: EventFee): number => {
			if (eventFee.type !== feeType) {
				return total;
			}
			return total + eventFee.eventFee(costCalc, null, null, null);
		}, 0);
	}
}
