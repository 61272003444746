import { Injectable } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { CalendarAPIService, HoldAPIParameters } from '@prism-frontend/services/api/calendar-api.service';
import { AbstractListService } from '@prism-frontend/services/api/list-services/abstract-list.service';
import { ManageHoldsService } from '@prism-frontend/services/api/manage-holds.service';
import { ApiService } from '@prism-frontend/services/legacy/api.service';
import { StorageService } from '@prism-frontend/services/legacy/storage.service';
import { FeatureGateService } from '@prism-frontend/services/utils/feature-gate.service';
import { PusherHoldUpdatedAlert, PusherService } from '@prism-frontend/services/utils/pusher.service';
import { SpinnerService } from '@prism-frontend/services/utils/spinner.service';
import { HoldForCalendar } from '@prism-frontend/typedefs/hold-for-calendar';
import {
	DateRange,
	aggregateAllOverlappingDateRanges,
	anyDateRangeContains,
} from '@prism-frontend/utils/static/dateRangeHelper';
import { Debug, getDebug } from '@prism-frontend/utils/static/getDebug';
import * as _ from 'lodash';
import moment from 'moment';
import { Observable, Subject, timer } from 'rxjs';
import { bufferWhen, map, switchMap } from 'rxjs/operators';

const debug: Debug = getDebug('hold-list-service');
const HOLDS_NOTIFICATION_EVENT_KEY: string = 'HoldUpdated';
@Injectable({
	providedIn: 'root',
})
export class HoldService extends AbstractListService<HoldForCalendar> {
	public spinnerCount: number = 0;
	public dateRangesLoading: DateRange[] = [];
	public dateRangesLoaded: DateRange[] = [];
	protected override isCacheable: boolean = false;

	private allHoldsRecord: Record<number, HoldForCalendar> = {};
	private holdNotificationKeys: string[] = [];
	private holdUpdates$: Subject<PusherHoldUpdatedAlert> = new Subject();

	private static getFilteredDateRange(
		holds: HoldForCalendar[],
		start: moment.Moment,
		end: moment.Moment,
		// Optionally exclude a date range
		excludeDuration?: {
			excludeFrom: string;
			excludeUntil: string;
		}
	): HoldForCalendar[] {
		return holds.filter((hold: HoldForCalendar): boolean => {
			const inDateRange: boolean =
				moment(hold.hold_date).isSameOrAfter(start) && moment(hold.hold_date).isSameOrBefore(end);
			let inExcludeDateRange: boolean = false;

			if (excludeDuration) {
				inExcludeDateRange =
					moment(hold.hold_date).isSameOrAfter(moment(excludeDuration.excludeFrom)) &&
					moment(hold.hold_date).isSameOrBefore(moment(excludeDuration.excludeUntil));
			}

			return inDateRange && !inExcludeDateRange;
		});
	}

	public constructor(
		private calendarApiService: CalendarAPIService,
		private featureGateService: FeatureGateService,
		private manageHoldsService: ManageHoldsService,
		private pusherService: PusherService,
		protected override apiService: ApiService,
		protected spinnerService: SpinnerService,
		protected override storageService: StorageService
	) {
		super(apiService, spinnerService, storageService);
		this.handleHoldUpdates();
	}

	public getHoldsInDateRange(params: Pick<HoldAPIParameters, 'start' | 'end'>): HoldForCalendar[] {
		return HoldService.getFilteredDateRange(this._all$.value, moment(params.start), moment(params.end));
	}

	public getHoldById(id: number): HoldForCalendar | undefined {
		return this._all$.value.find((hold: HoldForCalendar): boolean => {
			return hold.id === id;
		});
	}

	public containsHoldsForDateRange(params: Pick<HoldAPIParameters, 'start' | 'end'>): boolean {
		return this.getHoldsInDateRange(params).length > 0;
	}

	public holdsInDateRange$(params: HoldAPIParameters): Observable<HoldForCalendar[]> {
		return this.all$.pipe(
			map((holds: HoldForCalendar[]): HoldForCalendar[] => {
				return HoldService.getFilteredDateRange(holds, moment(params.start), moment(params.end));
			})
		);
	}

	public holdsForEvent$(eventId: number): Observable<HoldForCalendar[]> {
		return this.all$.pipe(
			map((holds: HoldForCalendar[]): HoldForCalendar[] => {
				return holds.filter((hold: HoldForCalendar): boolean => {
					return hold.event_id === eventId;
				});
			})
		);
	}

	public loadHoldsForEvent(eventId: number): Promise<HoldForCalendar[]> {
		return this.manageHoldsService.loadHoldsForEvent(eventId);
	}

	// date string format: 2020-01-01
	public async loadDateRange(
		params: HoldAPIParameters,
		displaySpinnerIfNotLoaded: boolean = true
	): Promise<HoldForCalendar[]> {
		const dateRange: DateRange = {
			end: moment(params.end),
			start: moment(params.start),
		};
		this.dateRangesLoading.push(dateRange);

		// Only display the spinner if the date range is not loaded yet
		const displaySpinner: boolean =
			displaySpinnerIfNotLoaded && !anyDateRangeContains(this.dateRangesLoaded, dateRange);

		if (displaySpinner) {
			this.spinnerCount++;
		}

		const newHolds: HoldForCalendar[] = await this.calendarApiService.loadHoldDateRange(params);

		if (displaySpinner) {
			this.spinnerCount--;
		}

		// we iterate too much here. we should take what we get from the API and add to the array
		// and de-dupe based on ID
		let currentHolds: HoldForCalendar[] = [...this._all$.value];

		const existingHoldsInDateRange: HoldForCalendar[] = HoldService.getFilteredDateRange(
			currentHolds,
			moment(params.start),
			moment(params.end),
			params.excludeDuration
		);

		// Replace existing matching holds with freshly loaded holds
		_.pullAll(currentHolds, existingHoldsInDateRange);

		// If new holds have the same id than currentHolds we should replace them.
		const newHoldsIds: Set<number> = new Set<number>(
			newHolds.map((hold: HoldForCalendar): number => {
				return hold.id;
			})
		);

		currentHolds = currentHolds.filter((hold: HoldForCalendar): boolean => {
			return !newHoldsIds.has(hold.id);
		});
		const allHolds: HoldForCalendar[] = currentHolds.concat(newHolds);
		this.dateRangesLoaded.push(dateRange);
		this.dateRangesLoaded = aggregateAllOverlappingDateRanges(this.dateRangesLoaded);
		_.pull(this.dateRangesLoading, dateRange);

		debug('%c dateRangesLoaded', 'font-weight: bold', this.dateRangesLoaded.length);

		this._all$.next(allHolds);
		// once the holds have been loaded, listen for updates.
		this.bindHoldChangeNotifications();
		return newHolds;
	}

	/**
	 * This function will listen hold updates sent through
	 * push notifications. We will listen a possible hold update
	 * alert per hold we load in the calendar.
	 */
	private bindHoldChangeNotifications(): void {
		// first unbind any existing notifications
		this.unbindHoldChangeNotifications();
		// generate a Record for all the current holds where their id will be the key
		this.allHoldsRecord = this._all$.value.reduce(
			(record: Record<number, HoldForCalendar>, hold: HoldForCalendar): Record<number, HoldForCalendar> => {
				// skip new holds with no id, we wont recieve updates for them
				if (_.isNil(hold.id)) {
					return record;
				}
				// generate the push notification key for the hold
				const holdNotificationKey: string = `${HOLDS_NOTIFICATION_EVENT_KEY}::${hold.id}`;
				// add the key to the set of keys we are listening to, so we can unbind them later
				this.holdNotificationKeys.push(holdNotificationKey);
				// bind the pusher notification for the hold update
				this.pusherService.bind(holdNotificationKey, (pusherAlert: PusherHoldUpdatedAlert): void => {
					// trigger the hold update
					this.holdUpdates$.next(pusherAlert);
				});
				// set the hold in the record
				record[hold.id] = hold;
				return record;
			},
			{} as Record<string, HoldForCalendar>
		);
	}

	/**
	 * This function will unbind all the hold change notifications
	 * we are currently listening to based on the hold notification keys
	 * we previously generated
	 */
	private unbindHoldChangeNotifications(): void {
		this.holdNotificationKeys.forEach((notificationKey: string): void => {
			this.pusherService.unbind(notificationKey);
		});
		this.holdNotificationKeys = [];
	}

	/**
	 * This function will handle hold updates via push notifications
	 * that we have previously subscribed to.
	 */
	private handleHoldUpdates(): void {
		// only listen to hold updates if the feature is enabled
		if (!this.featureGateService.isFeatureEnabled('BULK_HOLD_UPDATES')) {
			return;
		}
		// Listen to any holdUpdates event that is emitted via push notifications.
		// Considering multiple holds can be updated by a single action we expect
		// to receive multiple hold updates simultaneously so we need to handle
		// them in batches.
		this.holdUpdates$
			.pipe(untilDestroyed(this))
			.pipe(
				// Each time a hold update is emitted, we will collect
				// the emitted value and wait for the timer to go off
				// before actually handling the changes. The timer
				// gets reseted each time a new hold update is emitted.
				// We can't debounce since each notification is for a particular
				// hold we will have to update. When the buffer emits, we will
				// handle all the emmited changes at once.
				bufferWhen((): Observable<0> => {
					return this.holdUpdates$.pipe(
						switchMap((): Observable<0> => {
							return timer(1000);
						})
					);
				})
			)
			/**
			 * For now we wont notify users that didn't originated the action leading
			 * to the hold change when they receive a notification as we do over events
			 * since it could get too noisy, we will just update the calendar data.
			 */
			.subscribe((holdUpdates: PusherHoldUpdatedAlert[]): void => {
				holdUpdates.forEach((alert: PusherHoldUpdatedAlert): void => {
					if (!alert.message) {
						return;
					}
					// get the updated hold from the current allHoldsRecord
					const hold: HoldForCalendar | undefined = this.allHoldsRecord[alert.message.holdId];
					if (!hold) {
						return;
					}
					// update its value
					this.allHoldsRecord[alert.message.holdId] = new HoldForCalendar({
						...hold,
						hold_level: alert.message.holdLevel,
						cleared: alert.message.cleared ? true : false,
						cleared_by_user_id: alert.message.cleared ? alert.message.clearedByUserId : null,
						// pusher notifications support clear and restore actions from calendar for now
						// so a hold would never be cleared by an event. If we get an update upon an event
						// confirmation we would need to update this value.
						cleared_by_event_id: null,
					});
				});
				// after all holds have been updated emit the new full list of holds
				// for the calendar to update them on the view
				this._all$.next(Object.values(this.allHoldsRecord));
			});
	}

	public override refreshListFromAPI(): Promise<HoldForCalendar[]> {
		throw new Error('loadDateRange() should be used instead of refreshListFromAPI() for holds');
	}

	public override ensureListLoaded(): Promise<HoldForCalendar[]> {
		throw new Error('loadDateRange() should be used instead of ensureListLoaded() for holds');
	}

	protected get endpointUrl(): string {
		return this.apiService.ep.HOLDS;
	}

	protected get ClassConstructor(): typeof HoldForCalendar {
		return HoldForCalendar;
	}
}
