import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
	AggregationsMultiBucketAggregate,
	SearchHit,
	SearchRequest,
	SearchResponse,
} from '@opensearch-project/opensearch/api';
import {
	EventFilterConfig,
	FilterOptionConfig,
} from '@prism-frontend/components/event-filters/event-filter-config-types';
import { FilterParams } from '@prism-frontend/components/event-filters/filter-params';
import { ArtistLeaderboard } from '@prism-frontend/entities/dataplay/artist-leaderboard/artist-leaderboard-typedef';
import { ArtistResearch } from '@prism-frontend/entities/dataplay/artist-research/artist-research-typedef';
import { ExcludeFromEventInsightsSelectOptionConfig } from '@prism-frontend/pages/event-page/pages/event-settings-page/event-insights-setting/ExcludeFromEventInsightsSelectValue';
import {
	AverageCapacityRangeQueryObject,
	CapacityRangeQueryObject,
} from '@prism-frontend/pages/insights-page/insights-page-base-queries';
import {
	ArtistListQuery,
	GenreListQuery,
	LeaderboardQuery,
	ResearchQuery,
	VenueCitiesListQuery,
	VenueStatesListQuery,
} from '@prism-frontend/pages/insights-page/insights-page-query-builder';
import { Genre } from '@prism-frontend/services/api/genre-service';
import { ApiService } from '@prism-frontend/services/legacy/api.service';
import { PermissionsService } from '@prism-frontend/services/legacy/api/permissions.service';
import { AdminModeService } from '@prism-frontend/services/ui/admin-mode.service';
import { FeatureGateService } from '@prism-frontend/services/utils/feature-gate.service';
import { BaseFilterSidebar, FilterRouteConfig } from '@prism-frontend/services/utils/filter-sidebar.service';
import { OrganizationTypeGateService } from '@prism-frontend/services/utils/organization-type-gate.service';
import { DateRangeChoices } from '@prism-frontend/typedefs/enums/DateRangeChoices';
import { EventFilterOption } from '@prism-frontend/typedefs/enums/EventFilterOption';
import { EventTicketType } from '@prism-frontend/typedefs/enums/EventTicketType';
import { HeadlinerBadgeItem } from '@prism-frontend/typedefs/headliner-badge-item';
import { resolveTalentImageUrl } from '@prism-frontend/typedefs/resolveTalentImageUrl';
import { Talent, TalentImage } from '@prism-frontend/typedefs/talent';
import { Ticket } from '@prism-frontend/typedefs/ticket';
import { Venue } from '@prism-frontend/typedefs/venue';
import _ from 'lodash';

export interface ArtistHit {
	images: TalentImage[];
	name: string;
	id: number;
	spotify_id: string;
	headliner: boolean;
}

// #region Artist Leaderboard Interfaces
interface ArtistLeaderboardBucket {
	total_gross_sales: {
		event_count: { value: number };
		gross_sales_sum: {
			value: { value: number };
		};
	};
	average_gross_sales: {
		gross_sales_avg: {
			value: { value: number };
		};
	};
	average_capacity: {
		capacity_avg: {
			value: { value: number };
		};
	};
	artist_info: {
		hits: {
			hits: SearchHit<ArtistHit>[];
		};
	};
}
interface ArtistLeaderboardAggregations {
	artists_nested: {
		artist_ids: {
			buckets: ArtistLeaderboardBucket[];
		};
	};
}

type ArtistLeaderboardAPIResponse = SearchResponse & { aggregations: ArtistLeaderboardAggregations };
// #endregion Artist Leaderboard Interfaces

// #region Artist Research Interfaces
interface ArtistResearchHit {
	id?: number;
	artists?: ArtistHit[];
	location?: {
		city: string;
		state_short: string;
	};
	capacity?: string;
	gross_sales?: number;
	date?: string;
	tickets?: Ticket[];
	ticketing_data?: EventTicketType;
}

interface ArtistResearchSearchResponse extends SearchResponse {
	hits: {
		total: number;
		hits: SearchHit<ArtistResearchHit>[];
	};
}
// #endregion Artist Research Interfaces

// #region All Insights Artists Interfaces
interface AllInsightsArtistBucket {
	artist_info: {
		hits: {
			hits: SearchHit<ArtistHit>[];
		};
	};
}
interface AllInsightsArtistAggregations {
	unique_artists: {
		artist_names: {
			buckets: AllInsightsArtistBucket[][];
		};
	};
}

type AllInsightsArtistSearchResponse = SearchResponse & { aggregations: AllInsightsArtistAggregations };
// #endregion All Insights Artists Interfaces

// #region Venue Cities and States Interfaces
interface VenueCitiesListResponse {
	aggregations: VenueCitiesListAggreggations;
}

interface VenueCitiesListAggreggations {
	all_cities: {
		cities: CityStateData;
	};
}

interface VenueStatesListResponse {
	aggregations: VenueStatesListAggreggations;
}
interface VenueStatesListAggreggations {
	states: CityStateData;
}

interface CityStateData {
	buckets: CityStateBucket[];
}
interface CityStateBucket {
	key: string;
	city_info: CityStateInfo;
}
interface CityStateInfo {
	hits: CityStateHits;
}
interface CityStateHits {
	hits: SearchHit<CityStateSource>[];
}
interface CityStateSource {
	location?: {
		city: string;
		state_short: string;
	};
}
// #endregion Venue Cities and States Interfaces

// #region Genre Interfaces
type GenreResponse = SearchResponse & { aggregations: GenreAggregations };

interface GenreAggregations {
	all_genres: GenreAllGenres;
}

interface GenreAllGenres extends AggregationsMultiBucketAggregate {
	genres_names: GenreGenreName;
}

interface GenreGenreName extends AggregationsMultiBucketAggregate {
	buckets: GenreBucket[];
}

interface GenreBucket {
	key: string;
	doc_count: number;
	genre_info: {
		hits: {
			hits: SearchHit<GenreSource>[];
		};
	};
}

interface GenreSource {
	name: string;
	id: number;
}

// #endregion Genre Interfaces

/**
 * maintains filter logic across pages on the insights page
 */
@Injectable({
	providedIn: 'root',
})
export class InsightsPageService extends BaseFilterSidebar {
	public filterConfigByRoute: Record<string, FilterRouteConfig> = {
		'/insights/leaderboard': {
			entityType: 'artist',
			filterConfig: {
				...this.sharedFilterConfig(),
				[EventFilterOption.Capacity]: {
					show: true,
					average: true,
				},
			},
			pageTitle: 'Artist Leaderboard',
			storageKey: 'insights-artist-leaderboard',
		},
		'/insights/research': {
			entityType: 'artist',
			filterConfig: {
				...this.sharedFilterConfig(),
				[EventFilterOption.Capacity]: {
					show: true,
					average: false,
				},
				[EventFilterOption.SpotifyArtists]: {
					show: true,
				},
				[EventFilterOption.DaysOfWeek]: {
					show: true,
				},
				[EventFilterOption.MonthsOfYear]: {
					show: true,
				},
			},
			pageTitle: 'Artist Research',
			storageKey: 'insights-artist-research',
		},
	};

	/**
	 * Function is called whenever filters are changed, intended to be bound by components
	 */
	public onFilterChange: (filterParams: FilterParams) => Promise<void>;
	public hasSingleArtistFilter: boolean = false;
	public hasVenueCityFilter: boolean = false;
	public hasVenueStateFilter: boolean = false;

	public constructor(
		private adminModeService: AdminModeService,
		private apiService: ApiService,
		private featureGateService: FeatureGateService,
		private orgFeatureGateService: FeatureGateService,
		private permissionsService: PermissionsService,
		router: Router,
		organizationTypeGateService: OrganizationTypeGateService
	) {
		super(router, organizationTypeGateService);
	}

	public async handleFilterChange(filterParams: FilterParams): Promise<void> {
		const route: string =
			this.storageKey === 'insights-artist-leaderboard' ? '/insights/leaderboard' : '/insights/research';
		// we need to check if a city or state filter is active to disable the other
		this.hasVenueCityFilter = filterParams.cities.length >= 1;
		if (this.hasVenueCityFilter) {
			this.updateFilterState(route, EventFilterOption.VenuesStates, { disabled: true });
		} else {
			this.updateFilterState(route, EventFilterOption.VenuesStates, { disabled: false });
		}
		this.hasVenueStateFilter = filterParams.states.length >= 1;
		if (this.hasVenueStateFilter) {
			this.updateFilterState(route, EventFilterOption.VenuesCities, { disabled: true });
		} else {
			this.updateFilterState(route, EventFilterOption.VenuesCities, { disabled: false });
		}
		// if we are on the artist research we need to check if we have a single artist
		if (this.storageKey === 'insights-artist-research') {
			this.hasSingleArtistFilter = filterParams.artists.length === 1;
			// if this is the case we hide the genres filter
			if (this.hasSingleArtistFilter) {
				this.updateFilterState(route, EventFilterOption.Genre, { disabled: true });
			} else {
				this.updateFilterState(route, EventFilterOption.Genre, { disabled: false });
			}
		}
		await this.onFilterChange(filterParams);
	}

	private updateFilterState(
		route: string,
		filterOption: EventFilterOption,
		state: Pick<FilterOptionConfig, 'disabled'>
	): void {
		(this.filterConfigByRoute[route].filterConfig[filterOption] as FilterOptionConfig) = {
			...this.filterConfigByRoute[route].filterConfig[filterOption],
			...state,
		};
	}

	public async getArtistLeaderboard(query: LeaderboardQuery): Promise<ArtistLeaderboard[]> {
		const data: ArtistLeaderboardAPIResponse = await this.executeSearchRequest<ArtistLeaderboardAPIResponse>(
			query.getQuery()
		);

		const artistLeaderboard: ArtistLeaderboard[] = data.aggregations.artists_nested.artist_ids.buckets.map(
			(bucket: ArtistLeaderboardBucket): ArtistLeaderboard => {
				const artistData: ArtistHit = bucket.artist_info.hits.hits[0]._source;
				const headlinerBadgeItem: HeadlinerBadgeItem = {
					imageSrc: resolveTalentImageUrl(artistData.images),
					isHeadliner: true,
					name: artistData.name,
					artistId: null,
				};
				return new ArtistLeaderboard({
					artist: headlinerBadgeItem,
					totalGrossSales: bucket.total_gross_sales.gross_sales_sum.value.value,
					eventCount: bucket.total_gross_sales.event_count.value || 0,
					averageGrossSales: bucket.average_gross_sales.gross_sales_avg.value.value,
					averageCapacity: Math.floor(bucket.average_capacity.capacity_avg.value.value),
					spotifyId: artistData.spotify_id,
				});
			}
		);

		return artistLeaderboard;
	}

	public async getArtistResearch(query: ResearchQuery): Promise<ArtistResearch[]> {
		const response: ArtistResearchSearchResponse = await this.executeSearchRequest<ArtistResearchSearchResponse>(
			query.getQuery()
		);
		const artistResearch: ArtistResearch[] = response.hits.hits.map(
			(hit: SearchHit<ArtistResearchHit>): ArtistResearch => {
				const source: ArtistResearchHit = hit._source;
				const artists: HeadlinerBadgeItem[] = source.artists.map((artist: ArtistHit): HeadlinerBadgeItem => {
					return {
						imageSrc: resolveTalentImageUrl(artist.images),
						isHeadliner: artist.headliner,
						name: artist.name,
						artistId: null,
					};
				});

				const headliners: HeadlinerBadgeItem[] = _.filter(artists, (artist: HeadlinerBadgeItem): boolean => {
					return artist.isHeadliner;
				});

				const supportingArtists: HeadlinerBadgeItem[] = _.filter(
					artists,
					(artist: HeadlinerBadgeItem): boolean => {
						return !artist.isHeadliner;
					}
				);

				return new ArtistResearch({
					eventId: source.id ?? 0,
					headliners,
					supportingArtists,
					city: source.location ? `${source.location.city}, ${source.location.state_short}` : '',
					capacity: parseInt(source.capacity, 10) ?? 0,
					ticketsSold: _.sumBy(source.tickets, 'sold') ?? 0,
					grossSales: source.gross_sales ?? 0,
					date: source.date ? new Date(source.date) : new Date(),
					ticketScaling: source.tickets
						?.map((ticket: Ticket): string => {
							return `${ticket.name}: ${ticket.sold} @ $${ticket.ticket_price}`;
						})
						.join(`\n`),
					...(source.ticketing_data && { ticketIntegration: source.ticketing_data }),
				});
			}
		);
		return artistResearch;
	}

	public async artistLookup(params: Partial<FilterParams>): Promise<Record<string, ArtistLeaderboard>> {
		const query: SearchRequest = new LeaderboardQuery();
		_.extend(query, params);
		const filteredArtistLeaderboard: ArtistLeaderboard[] = await this.getArtistLeaderboard(query);
		return _.keyBy(filteredArtistLeaderboard, 'spotifyId');
	}

	// TODO PRSM-10489 test coverage
	/**
	 * Gates the current user's access to features that expose insights-related
	 * permissions and settings. This function indicates that users will soon
	 * have access to insights data, but not yet. Once we know an organization
	 * will be joining the beta, this function will allow us to expose some features
	 * related to insights (but not the insights data itself) to users, so that they
	 * can start managing their insights experience data BEFORE their data reaches the
	 * shared pool.
	 *
	 * Getting users into the insights feature is a multi-step process. Step 1
	 * is to turn on "PRE_PRISM_INSIGHTS", which presently does 3 things:
	 * 1. Displays the Insights icon in the app nav, and teases it as "Coming Soon"
	 * 2. Exposes the "View Insights" permission, which org admins can assign to users
	 * 3. Exposes an event setting that allows users to enable insights for their events
	 *
	 * For the function that gates access to step 2, see canUserSeeInsights
	 *
	 * These features are gates by this function.
	 * @param forEvent
	 * @returns
	 */
	public canUsersSeePreInsights(): boolean {
		// Agents can never see insights (we should never enable the DATA_ShARE
		// org flag for agents, this is just a safguard)
		if (this.orgFeatureGateService.orgHasFeature('IS_AGENT')) {
			return false;
		}

		// If PRISM_INSIGHTS environment flag is off, nothing related to insights works at all
		if (!this.featureGateService.isFeatureEnabled('PRISM_INSIGHTS')) {
			return false;
		}

		// If the org doesn't have either of PRE_PRISM_INSIGHTS or PRISM_INSIGHTS
		// feature, then there is no point for them to see this
		if (
			!this.orgFeatureGateService.orgHasFeature('PRE_PRISM_INSIGHTS') &&
			!this.orgFeatureGateService.orgHasFeature('PRISM_INSIGHTS')
		) {
			return false;
		}

		// if the org is in the beta, then admin mode unlocks this and bypsses permissions
		if (this.adminModeService.adminMode) {
			return true;
		}

		// PURPOSELY DO NOT CHECK CAN_USERS_SEE_INSIGHTS FLAG HERE
		// This flag should only gate the insights data itself, not user's with PRE_PRISM_INSIGHTS or PRISM_INSIGHTS
		// ability to see the pre insights features (permissions and event setting)

		// If we get this far, then only allow users to see this setting
		// if they have the view-insights permission at the global level
		// we purposely do not check for the event level permission here
		return this.permissionsService.userCan('view-insights');
	}

	// TODO PRSM-10489 test coverage
	/**
	 * Gates the current user's access to features that expose insights data.
	 * This function will never allow orgs that are not opted into PRISM_INSIGHTS
	 * to see insights data. This would fundamentally violate the philosophy of insights:
	 * if you can see the data, you are also sharing your own.
	 *
	 * Geetting users into the insights feature is a multi-step process. Step 2
	 * is to turn on "PRISM_INSIGHTS", which does all of the above plus:
	 * 1. pulls the org's data into the insights pool
	 * 2. Converts the app nav's ingihts icon from "Coming Soon" to "Insights" and makes it a live link
	 * 3. Grants access to any user with the view-insights permission to see the insights page
	 * 4. Exposes contextually relevant insights data in the deal builder form to any user with the view-insights permission
	 *    when they select an artist that has insights data available
	 *
	 * For the function that gates access to step 1, see canUsersSeePreInsights
	 * @param forEvent
	 * @returns
	 */
	public canUserSeeInsights(): boolean {
		// Agents can never see insights (we should never enable the DATA_ShARE
		// org flag for agents, this is just a safguard)
		if (this.orgFeatureGateService.orgHasFeature('IS_AGENT')) {
			return false;
		}

		// If PRISM_INSIGHTS environment flag is off, nothing related to insights works at all
		if (!this.featureGateService.isFeatureEnabled('PRISM_INSIGHTS')) {
			return false;
		}

		// If the org doesn't have the PRISM_INSIGHTS feature, then their data
		// is not in the pool and they cant see Prism Insights at all
		if (!this.orgFeatureGateService.orgHasFeature('PRISM_INSIGHTS')) {
			return false;
		}

		// prism admins can access this page so long as the org can see insights
		if (this.adminModeService.adminMode) {
			return true;
		}

		// If CAN_USERS_SEE_INSIGHTS is off, non Prism admins can't see this
		if (!this.featureGateService.isFeatureEnabled('CAN_USERS_SEE_INSIGHTS')) {
			return false;
		}

		// If we get this far, then only allow users to see this setting
		// if they have the view-insights permission
		return this.permissionsService.userCan('view-insights');
	}

	/**
	 * Gets the list of all available artists for the insights page
	 * it uses an empty query to get all artists from the datashare program
	 * @returns List of available artists for the insights page
	 */
	public async getInisghtArtists(): Promise<Talent[]> {
		return await this.executeSearchRequest<AllInsightsArtistSearchResponse>(new ArtistListQuery().getQuery()).then(
			(response: AllInsightsArtistSearchResponse): Talent[] => {
				const artistBuckets: AllInsightsArtistBucket[][] =
					response.aggregations.unique_artists.artist_names?.buckets || [];
				return _.chain(artistBuckets)
					.flatten()
					.map((bucket: AllInsightsArtistBucket): Talent => {
						const artistHit: ArtistHit = bucket.artist_info.hits.hits[0]._source;
						return new Talent({
							id: null,
							spotify_id: artistHit.spotify_id,
							name: artistHit.name,
							images: artistHit.images,
						});
					})
					.value();
			}
		);
	}

	/**
	 * Gets the list of all available venue states for the insights page
	 * it uses an empty query to get all venue states from the datashare program
	 * @returns List of available venue states for the insights page
	 */
	public async getInsightsStates(): Promise<Venue[]> {
		return await this.executeSearchRequest<VenueStatesListResponse>(new VenueStatesListQuery().getQuery()).then(
			(response: VenueStatesListResponse): Venue[] => {
				const stateBuckets: CityStateBucket[] = response.aggregations.states.buckets || [];
				return stateBuckets.map((state: CityStateBucket): Venue => {
					return {
						stateValue: state.key,
					} as unknown as Venue;
				});
			}
		);
	}

	/**
	 * Gets the list of all available venue cities for the insights page
	 * it uses an empty query to get all venue cities from the datashare program
	 * @returns List of available venue cities for the insights page
	 */
	public async getInsightsCities(): Promise<Venue[]> {
		return await this.executeSearchRequest<VenueCitiesListResponse>(new VenueCitiesListQuery().getQuery()).then(
			(response: VenueCitiesListResponse): Venue[] => {
				const cityBuckets: CityStateBucket[] = response.aggregations.all_cities.cities.buckets || [];
				return cityBuckets.map((state: CityStateBucket): Venue => {
					const cityState: CityStateSource = state.city_info.hits.hits[0]._source;
					return {
						cityStateShortValue: `${cityState.location.city}, ${cityState.location.state_short}`,
					} as unknown as Venue;
				});
			}
		);
	}

	/**
	 * Gets the list of all available artist genres for the insights page
	 * it uses an empty query to get all artist genres from the datashare program
	 * @returns List of available artist genres for the insights page
	 */
	public async getInsightsGenres(): Promise<Genre[]> {
		return await this.executeSearchRequest<GenreResponse>(new GenreListQuery().getQuery()).then(
			(response: GenreResponse): Genre[] => {
				const genreBuckets: GenreBucket[] = response.aggregations.all_genres.genres_names?.buckets || [];
				return genreBuckets.flatMap((bucket: GenreBucket): Genre => {
					const genreInfo: GenreSource = bucket.genre_info.hits.hits[0]._source;
					const genre: Genre = {
						id: genreInfo.id,
						genre: _.upperFirst(genreInfo.name),
						count: bucket.doc_count,
					};
					return genre;
				});
			}
		);
	}

	public async fetchCapacityRange(average: boolean): Promise<[number, number]> {
		let response: SearchResponse;
		if (average) {
			response = await this.executeSearchRequest(new AverageCapacityRangeQueryObject());
			return [
				response.aggregations.unique_artists.average_capacity_stats.min,
				response.aggregations.unique_artists.average_capacity_stats.max,
			];
		}
		response = await this.executeSearchRequest(new CapacityRangeQueryObject());
		return [
			response.aggregations.artists_nested.overall_capacity.min_capacity.value,
			response.aggregations.artists_nested.overall_capacity.max_capacity.value,
		];
	}

	private async executeSearchRequest<T = SearchResponse>(searchRequest: SearchRequest): Promise<T> {
		const response: SearchResponse = await this.apiService.postP<SearchRequest, T>(
			this.apiService.ep.INSIGHTS_SEARCH,
			searchRequest
		);
		if (response.error) {
			// eslint-disable-next-line no-console
			console.error('Error fetching data from insights search', JSON.parse(response.message));
			throw new Error('Error fetching data from insights search');
		}
		return response;
	}

	public async fetchEventInsightsExcludeReasons(): Promise<ExcludeFromEventInsightsSelectOptionConfig[]> {
		const response: SearchResponse = await this.apiService.getP<
			never,
			ExcludeFromEventInsightsSelectOptionConfig[]
		>(this.apiService.ep.INSIGHTS_EVENT_EXCLUDE_REASONS);
		return response;
	}

	private sharedFilterConfig(): EventFilterConfig {
		return {
			// date range options
			[EventFilterOption.DateRange]: {
				dateOptions: [
					DateRangeChoices.ThisWeek,
					DateRangeChoices.LastWeek,
					DateRangeChoices.ThisMonth,
					DateRangeChoices.LastMonth,
					DateRangeChoices.YearToYestrday,
					DateRangeChoices.PastYesterday,
					DateRangeChoices.Custom,
				],
				allowMAD: false,
				allowOnlyMAD: false,
				show: true,
			},
			[EventFilterOption.VenuesStates]: {
				show: true,
			},
			[EventFilterOption.VenuesCities]: {
				show: true,
			},
			[EventFilterOption.Genre]: {
				show: true,
			},
		};
	}
}
