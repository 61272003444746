import { Contact } from '@prism-frontend/typedefs/contact';
import { CoreAdjustment } from '@prism-frontend/typedefs/CoreAdjustment';
import { EMSPartnerDeal } from '@prism-frontend/typedefs/ems/ems-typedefs';
import { sumPropOverObjects } from '@prism-frontend/utils/static/sumPropOverObjects';
import { castToNumber } from '@prism-frontend/utils/transformers/castToNumber';
import { plainToClass, Transform, Type } from 'class-transformer';
import { IsArray, IsInt, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';

export class PartnerAdjustment implements CoreAdjustment {
	public constructor(adjustment?: Partial<PartnerAdjustment>) {
		return plainToClass(PartnerAdjustment, adjustment);
	}

	@IsInt() public id: number | null = null;

	@IsInt() public partner_deal_id: number;

	@IsString() public name: string = '';

	@IsNumber()
	@Transform(castToNumber())
	public amount: number = 0;

	@IsString() public notes: string = '';
}

export interface EventPropsForPartnerDeal {
	netProfitForCoProCalculations: number;
	ticketsSold: number;
}

export class PartnerDealBackend {
	@IsInt()
	public copro_share_percentage: number;

	@IsOptional()
	@IsString()
	public created_at: string | null;

	@IsInt()
	public event_id: number;

	@IsInt()
	public id: number;

	@IsInt()
	public organization_id: number;

	@IsInt()
	public per_ticket_bonus: number = 0;

	@IsOptional()
	@IsString()
	public updated_at: string | null;

	@IsInt()
	public promoter_contact_id: number;

	@IsArray()
	@Type((): typeof PartnerAdjustment => {
		return PartnerAdjustment;
	})
	@ValidateNested()
	public adjustments: PartnerAdjustment[] = [];

	@Type((): typeof Contact => {
		return Contact;
	})
	@ValidateNested()
	public promoter: Contact;
}

export class PartnerDeal extends PartnerDealBackend {
	public coproRemainingSharePercentage: number = 0;

	// This is necessary since the contact picker only accepts an array.
	public get promoterContactIdAsArray(): number[] {
		return !!this.promoter_contact_id ? [this.promoter_contact_id] : undefined;
	}

	public constructor(partnerDeal?: Partial<PartnerDeal>) {
		super();
		return plainToClass(PartnerDeal, partnerDeal);
	}

	/**
	 * a decimal number for copro share percentage. used to easily multiply by a number
	 * to get the % of the share
	 */
	public get coProSharePercentageMultiplier(): number {
		return (this.copro_share_percentage || 0) / 100;
	}

	/**
	 * compute the co pro split for this partner deal
	 *
	 * @param eventPropsForPartnerDeal props needed to compute partner deals
	 * @returns the partnerDeal deal's copro split
	 */
	public coProSplit(eventPropsForPartnerDeal: EventPropsForPartnerDeal): number {
		return eventPropsForPartnerDeal.netProfitForCoProCalculations * this.coProSharePercentageMultiplier;
	}

	/**
	 * the total ticket bonus
	 *
	 * @param eventPropsForPartnerDeal props needed to compute partner deals
	 * @returns the partner's per-ticket bonus total
	 */
	public totalPerTicketBonus(eventPropsForPartnerDeal: EventPropsForPartnerDeal): number {
		return eventPropsForPartnerDeal.ticketsSold * (this.per_ticket_bonus || 0);
	}

	/**
	 * sum up all the adjustments on the parter deal
	 *
	 * @returns the total of all the adjustments on this partner deal
	 */
	public totalAdjustments(): number {
		return sumPropOverObjects<PartnerAdjustment>(this.adjustments, 'amount');
	}

	/**
	 * the total payout for this partner, which is coProSplit + totalPerTicketBonus
	 *
	 * @param eventPropsForPartnerDeal props needed to compute partner deals
	 * @returns the partner's total payout for this event
	 */
	public totalCoProPayout(eventPropsForPartnerDeal: EventPropsForPartnerDeal): number {
		return (
			this.coProSplit(eventPropsForPartnerDeal) +
			this.totalPerTicketBonus(eventPropsForPartnerDeal) +
			this.totalAdjustments()
		);
	}
}

export type EMSPartnerDealRow = Pick<
	EMSPartnerDeal<number, string, boolean>,
	'id' | 'partnerDealDescription' | 'emsPath'
> &
	Pick<
		PartnerDeal,
		| 'copro_share_percentage'
		| 'per_ticket_bonus'
		| 'promoter_contact_id'
		| 'coproRemainingSharePercentage'
		| 'adjustments'
	> & {
		estimatedCoProPayout: number;
		actualCoProPayout: number;
		promoterName: string;
		promoterCompany: string;
	};
